<template>
  <div class="login-page">
    <div class="login-page-container">
      <div class="login-logo"><img :src="require(`images/${logo}.png`)"/></div>
      <template v-if="isIosNotSupportedWebView()">
        <p class="webview-text">{{ $t('global.message.browser_not_supported') }}</p>
        <div class="webview-btn">
          <!-- <a :href="redirectUrl">{{ $t('global.action.ok') }}</a> -->
          <a :href="redirectUrl" target="_blank">{{ $t('global.action.ok') }}</a>
        </div>
        
      </template>
      <template v-else>
        <!--h1 class="login-title">{{ $t('LoginPage.title') }}</h1-->
        <h1 class="login-title">Entry</h1>
        <div class="login-content">
          <!--Lang :btnType="'web'" class="login-lang" /-->
          <a-form-model layout="horizontal" class="login-form" :model="formInline" @submit="login" @submit.native.prevent>
            <!--a-form-model-item>
              <a-input v-model="formInline.login" :placeholder="$t('LoginPage.account')" size="large">
                <a-icon slot="prefix" type="user"/>
              </a-input>
            </a-form-model-item>
            <a-form-model-item>
              <a-input v-model="formInline.password" type="password" :placeholder="$t('LoginPage.password')" size="large">
                <a-icon slot="prefix" type="lock"/>
              </a-input>
            </a-form-model-item>
            <a-form-model-item>
              <a-input type="hidden" v-model="formInline.subdomain"></a-input>
            </a-form-model-item>
            <a-form-model-item>
              <a-button
                type="primary"
                html-type="submit"
                :disabled="formInline.login === '' || formInline.password === '' || formInline.subdomain === ''"
                size="large"
                block
              >
                {{ $t('LoginPage.submit') }}
              </a-button-->
            <a-form-model-item>
              <a-button :href="merchantUrl" target="_blank">Merchant</a-button>
              <a-button :href="masterUrl" target="_blank">Master</a-button>
              <a-button :href="agentUrl" target="_blank">Agent</a-button>
              <a-button :href="demoUrl" target="_blank">Player</a-button>
            </a-form-model-item>
          </a-form-model>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
// import storage, { AUTH_TOKEN } from '@/../storage';
import { mapActions } from 'vuex';
import Lang from '@/../src/components/lang';
import { isIosNotSupportedWebView } from '@/../tools/common';
import { setLogoFileName } from '@/../tools/common';

export default {
  components: {
    Lang,
  },
  data() {
    return {
      redirectUrl: window.location.href,
      merchantUrl: '',
      masterUrl: '',
      agentUrl: '',
      demoUrl: '',
      adminUrl: '',
      formInline: {
        login: '',
        password: '',
        subdomain: '',
      },
      logo: ''
    };
  },
  methods: {
    ...mapActions({ goto: 'auth/goto', userlogin: 'auth/userlogin' }),
    isIosNotSupportedWebView,
    setLogoFileName,
    login() {
      const { login, password, subdomain } = this.formInline;
      this.userlogin({login, password, subdomain})
        .then(() => {
              this.afterLogin();
              window.location.reload();
            })
            .catch((err) => {
              this.$message.error('failed login', 3);
            });
    },
    afterLogin() {
      this.$message.success('Login Success', 4);
    },
    userSubDomain() {
      this.formInline.subdomain = window.location.hostname
    },
    setDomainPrefix() {
      const location = window.location;
      const hostname = location.hostname;
      const protocol = location.protocol;
      const port = location.port == "" ? "" : (":"+location.port)
      const prefix = hostname.split('-')[0];
      const domainName = [hostname.split(".")[1], hostname.split(".")[2]].join(".");
      this.domainPrefix = prefix;

      this.merchantUrl = location.protocol + "//" + prefix + "-me" + "." + domainName + port
      this.masterUrl = location.protocol + "//" + prefix + "-ma" + "." + domainName + port
      this.agentUrl = location.protocol + "//" + prefix + "-ag" + "." + domainName + port
      this.demoUrl = location.protocol + "//" + prefix + "-demo" + "." + domainName + port
      this.adminUrl = process.env.ADMIN_URL;
    }
  },
  mounted() {
    this.logo = setLogoFileName();
    this.userSubDomain();
    this.setDomainPrefix();
  }
};
</script>


