export const iconList = ['cn', 'en', 'zh-TW', 'id', 'vi', 'th', 'pt', 'mm', 'in', 'ru', 'kr'];

export const GAME_TYPE_SIDE = {
  cockfight: {
    meron: 'meron',
    wala: 'wala',
    draw: 'draw',
    cancel: 'cancel',
  },
  spider: {
    meron: 'meron',
    wala: 'wala',
    draw: 'draw',
    cancel: 'cancel',
  },
  color_game: {
    meron: 'red',
    wala: 'blue',
    draw: 'gold',
    cancel: 'cancel',
  },
  apple: {
    meron: 'red',
    wala: 'white',
    red3: 'red3',
    white3: 'white3',
    cancel: 'cancel',
  },
  up_down: {
    meron: 'down',
    wala: 'up',
    draw: 'draw2',
    cancel: 'cancel',
  },
  marble: {
    meron: 'red',
    wala: 'blue',
    draw: 'draw2',
    cancel: 'cancel',
  },
}

const stagingDomainList = [
  ['fiestafruit.xyz', 'https://fiestafruit-demo.fiestafruit.xyz/api'],
  ['ffruit.xyz', 'https://fiesta-demo.ffruit.xyz/api'],
  ['ds88sabong.xyz', 'https://ds88-demo.ds88sabong.xyz/api'],
  ['ws168fight.com', 'https://ws168-demo.ws168fight.com/api/'],
  ['littlemouse.click', 'https://admin.littlemouse.click/api'],
  ['ds88aaa.com', 'https://admin.ds88aaa.com/api'], //QAT
  ['odincockfight.xyz', 'https://odin-demo.odincockfight.xyz/api'],
  ['geminia0.xyz', 'https://gemini-demo.geminia0.xyz/api'],
  ['geminibo1.xyz', 'https://gemini-demo.geminia0.xyz/api'],
  ['battlemarble.xyz', 'https://marble-demo.battlemarble.xyz/api'],
  ['sabongsupreme.com', 'https://admin.sabongsupreme.com/api']
]

const productionDomainList = [
  ['ds88sabong.com', 'https://admin.ds88sabong.com/api'],
  ['diamondsabong.com', 'https://admin.diamondsabong.com/api'],
  ['ds88game.com', 'https://admin.ds88game.com/api'],
  ['ds88bo.com', 'https://admin.ds88bo.com/api'],
  ['ds88.live', 'https://admin.ds88.live/api'],
  ['ds88aaa.live', 'https://admin.ds88aaa.live/api'],
  ['ds888ddd.com', 'https://admin.ds888ddd.com/api'],
  ['ws168game.com', 'https://admin.ws168game.com/api'],
  ['ws168bo.com', 'https://admin.ws168bo.com/api'],
  ['fpsabong.com', 'https://admin.fpsabong.com/api'],
  ['fiestabo1.live', 'https://admin.fiestabo1.live/api'],
  ['fiestabo2.live', 'https://admin.fiestabo2.live/api'],
  ['fiestabo3.live', 'https://admin.fiestabo3.live/api'],
  ['fiestafruitbo1.live', 'https://admin.fiestafruitbo1.live/api'],
  ['fiestafruitbo2.live', 'https://admin.fiestafruitbo2.live/api'],
  ['fiestafruitbo3.live', 'https://admin.fiestafruitbo3.live/api'],
  ['odingames.net', 'https://admin.odingames.net/api'],
  ['odinbo.net', 'https://admin.odinbo.net/api'],
  ['odinbench.net', 'https://admin.odinbench.net/api'],
  ['geminibo1.live', 'https://admin.geminibo1.live/api'],
  ['geminibo2.live', 'https://admin.geminibo2.live/api'],
  ['geminibo3.live', 'https://admin.geminibo3.live/api'],
  ['battlemarble.com', 'https://admin.battlemarble.live/api'],
  ['battlemarblebo.com', 'https://admin.battlemarblebo.live/api'],
  ['battlemarblebo1.com', 'https://admin.battlemarblebo1.live/api'],
]

export const getHostnameInfo = () => {
  const env = process.env.NODE_ENV;
  if (env === 'development') {
    return {
      api_url: 'http://admin.lvh.me:3000/api',
      env: '測試站'
    }
  } else {
    const hostname = window.location.hostname;
    const findStaging = stagingDomainList.find(listItem => hostname.includes(listItem[0]));
    if (findStaging) {
      return {
        api_url: findStaging[1],
        env: '測試站'
      }
    } else {
      const findProduction = productionDomainList.find(listItem => hostname.includes(listItem[0]))
      if (findProduction) {
        return {
          api_url: findProduction[1],
          env: '正式站'
        }
      } else {
        return {
          api_url: 'https://fatpit-admin.111fatgame.com/api',
          env: '測試站'
        }
      }
    }
  }
}
