<template>
  <a-modal 
    :visible="showLogDialog" 
    :title="$t('global.common.log')"
    :footer="null" 
    :width="720"
    :afterClose="clearLog"
    @cancel="closeLogDialog"
    class="create-agent-page">
      <p class="create-agent-result">
        {{ $t('CreateAgentPage.message.log_saved_time', { value: 6 })}}
      </p>
      
      <a-table
          bordered
          size="small"
          :columns="columns"
          :row-key="data => data.id"
          :data-source="filterData"
          :loading="logLoading"
          :pagination="pagination"
          @change="handleTableChange"
      >
        <template v-for="column in columns">
          <template :slot="column.slots.title">{{ column.singleI18n ? $t(`CreateAgentPage.${column.slots.title}`) :$t(`global.common.${column.slots.title}`) }}</template>
        </template>

        <template slot="account" slot-scope="accounts">
          <div v-if="accounts.length >0">
            <p v-for="accountItem in accounts" :key="accountItem" class="create-agent-table-item">{{ accountItem }}</p>
          </div>
        </template>

        <template slot="mode" slot-scope="mode">
          {{ mode === 'credit' ? $t('CreateAgentPage.transfer_wallet') : $t('CreateAgentPage.single_wallet') }}
        </template>

        <template slot="env" slot-scope="env">
          {{ env === '測試站' ? $t('CreateAgentPage.staging') : $t('CreateAgentPage.production') }}
        </template>

        <template slot="info" slot-scope="info">
          <a-button type="primary" icon="search" size="small" @click="showSingleLog(info)"></a-button>
          <!-- <a-button type="primary" size="small" @click="showSingleLog(info)">{{ $t('global.common.log') }}</a-button> -->
        </template>
      </a-table>
  </a-modal>
</template>
<script>
import { cloneDeep } from 'lodash';

const columns = [
  {
    slots: { title: 'account' },
    dataIndex: 'account',
    scopedSlots: { customRender: 'account' },
  },
  {
    slots: { title: 'parent_agent' },
    dataIndex: 'parentAgent',
    align: 'center',
    singleI18n: true,
  },
  {
    slots: { title: 'trade_mode' },
    dataIndex: 'mode',
    scopedSlots: { customRender: 'mode' },
    align: 'center',
    singleI18n: true,
  },
  {
    slots: { title: 'environment' },
    dataIndex: 'env',
    scopedSlots: { customRender: 'env' },
    align: 'center',
    singleI18n: true,
  },
  {
    slots: { title: 'log' },
    dataIndex: 'info',
    scopedSlots: { customRender: 'info'},
    align: 'center',
    width: 90,
  },
];

export default {
  props:{
    showLogDialog: Boolean,
    closeLogDialog: Function,
    propData: Array,
    activeResultDialog: Function,
    setCreateResult: Function,
    clearLog: Function,
    logLoading: Boolean
  },
  data() {
    return {
      columns,
      data:[],
      pagination: {
        current: 1,
        pageSize: 5, // 默认每页显示数量
        // showSizeChanger: true, // 显示可改变每页数量
        // pageSizeOptions: ["5", "10", "20", "50", "100"], // 每页数量选项
        showTotal: (total) => `Total ${total} items`, // 显示总数
        // showSizeChange: (current, pageSize) => (this.pageSize = pageSize), // 改变每页数量时更新显示
        total: 0
      },
    };
  },
  async mounted() {},
  methods: {
    showSingleLog(data){
      this.setCreateResult(data);
      this.activeResultDialog();
    },
    handleTableChange(pagination, filters, sorter){
      const currentPage = this.pagination.pageSize !== pagination.pageSize ? 1 : pagination.current;
      this.pagination.current = currentPage;
      this.pagination.pageSize = pagination.pageSize;
    }
  },
  computed:{
    filterData(){
      const startIndex = (this.pagination.current - 1) * this.pagination.pageSize;
      const endIndex = this.pagination.current * this.pagination.pageSize;
      return this.data.slice(startIndex, endIndex);
    }
  },
  watch:{
    propData: {
      handler(newValue, oldValue){
        if(newValue.length !== 0){
          const reverseData = cloneDeep(newValue).reverse();
          const splitData = reverseData.join(';;').split('devide_line;;');

          const regroupData = [];
          splitData.forEach(itemData=>{
            if(itemData !== ''){
              const splitSubData = itemData.split(';;');
              regroupData.push(splitSubData);
            }
          })

          const newDataArr = [];
          regroupData.forEach((itemData,index)=>{
            const getParentAgent = () => {
              const findParentAgent = itemData.find(str=>str.includes('上層帳號'))
              return findParentAgent ? findParentAgent.split(': ')[1] : ''
            };
            const getEnv = () => {
              const findEnv = itemData.find(str=>str.includes('環境類型'))
              return findEnv ? findEnv.split(': ')[1] : ''
            } 
            const getMode = () => {
              const findMode = itemData.find(str=>str.includes('交易模式'))
              return findMode ? findMode.split(': ')[1] : ''
            }

            const getAccounts = () => {
              const findAccount = itemData.filter(str=>str.includes('account: '))
              if(findAccount.length > 0){
                return findAccount.map(account=>account.split(': ')[1]);
              }else{
                return [];
              }
            }
            
            const data = {
              id: index,
              account: getAccounts(),
              parentAgent: getParentAgent(),
              env: getEnv(),
              mode: getMode(),
              info: itemData,
            }

            newDataArr.push(data);
          })

          this.$set(this.pagination, 'total', newDataArr.length);
          this.data = newDataArr;
        }else{
          this.$set(this.pagination, 'current', 1);
          // this.$set(this.pagination, 'pageSize', 10);
          this.$set(this.pagination, 'total', 0);
        }
      },
      deep: true,
    }
  }
};
</script>


