<template>
  <a-form-model :layout="'vertical'" ref="fightForm" :model="fightForm" :rules="rules">
    <a-form-model-item prop="fight_no">
      <a-input v-model="fightForm.fight_no" />
    </a-form-model-item>

    <a-form-model-item>
      <a-button type="primary" @click="fightIdFormSubmit(fightForm)" block>
        {{ $t('global.action.submit') }}
      </a-button>
    </a-form-model-item>
  </a-form-model>
</template>
<script>
import { modifyFightId } from '@/../api';

export default {
  props: {
    fightForm: Object,
    main_pool_id: [String, Number],
  },
  data() {
    const checkFightNo = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('global.message.fill_input', {value: this.$t('global.common.fight_id')})));
      } else {
        callback();
      }
    };
    return {
      rules: {
        fight_no: [{ validator: checkFightNo, trigger: 'blur' }],
      },
    };
  },
  created() {
  },
  methods: {
    fightIdFormSubmit() {
      const { pool_id, fight_no } = this.fightForm
      this.$refs.fightForm.validate((valid) => {
        if (!valid) {
          this.$message.error(this.$t('global.message.failed_to_update', {value: this.$t('global.common.fight_id')}));
          return false;
        } else {
          modifyFightId({pool_id, fight_no, main_pool_id: this.main_pool_id})
              .then((data)=>{
                this.$message.success(this.$t('global.message.success_to_change', {value: this.$t('global.common.fight_id')}));
                this.$emit('update-fightId', this.fightForm);
              })
              .catch((err)=>{
                this.$message.error(err.response.data.message)
              })
        }
      });
    },
  },
};
</script>
