<template>
  <MerchantRoles :data='data'
                 :pagination='pagination'
                 :loading='loading'
                 :fetchData='fetchData'
  />
</template>

<script>
import { getMerchantRoles } from '@/../api';
import MerchantRoles from '@/../src/components/merchants/merchant_roles';

export default {
  components: {
    MerchantRoles
  },
  data() {
    return {
      data: [],
      pagination: {
        pageSize: 10,
        current: 1,
        total: 0
      },
      loading: false,
    };
  },
  created(){
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData(params = {}) {
      this.loading = true;
      getMerchantRoles({
        form: {
          results: this.pagination.pageSize,
          page: this.pagination.current,
          ...params,
        }
      }).then(({ data }) => {
        const pagination = { ...this.pagination };
        pagination.total = data.total;
        this.loading = false;
        this.data = data.roles;
        this.pagination = pagination;
      }).finally(()=>{
        this.loading = false;
      });
    },
  },
}
</script>
