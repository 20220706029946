var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{staticClass:"malay-odds-setting-page",attrs:{"visible":_vm.visible,"footer":null,"width":'60%',"afterClose":_vm.clearLog},on:{"cancel":_vm.closeLogModal}},[_c('template',{slot:"title"},[_c('span',[_vm._v(_vm._s(_vm.$t('global.common.log')))]),_vm._v(" "),_c('span',[_vm._v(" - ")]),_vm._v(" "),_c('span',{staticClass:"primary-font-color"},[_vm._v(_vm._s(_vm.link_master))])]),_vm._v(" "),_c('a-table',{attrs:{"bordered":"","size":"small","columns":_vm.columns,"row-key":function (data) { return data.id; },"data-source":_vm.data,"loading":_vm.logLoading,"pagination":_vm.pagination},on:{"change":_vm.handleTableChange},scopedSlots:_vm._u([{key:"action",fn:function(action){return [_c('div',[_vm._v(_vm._s(_vm.$t(("global.action." + action))))])]}},{key:"formatTimes",fn:function(times){return [_c('div',{staticClass:"table-time"},[_vm._v(_vm._s(_vm._f("formatTimesToUTC8")(times)))])]}},{key:"schedule",fn:function(schedule, row){return [(schedule.length > 0 && _vm.poolPremissions.viewSchedule)?_c('a-button',{attrs:{"type":"primary","size":"small"},on:{"click":function($event){return _vm.setDialogData({
              id: null, 
              editable: false, 
              merchant_provider_id: null, 
              schedule_info: schedule,
              effective_time: row.effective_time,
              link_master: _vm.link_master,
              log_no: row.log_no
            })}}},[_vm._v("\n            "+_vm._s(_vm.$t('PoolSettingPage.view'))+"\n          ")]):_c('span',[_vm._v("-")])]}}])},_vm._l((_vm.columns),function(column){return _c('div',{key:column.slots.title,staticClass:"title",attrs:{"slot":column.slots.title},slot:column.slots.title},[(column.slots.title !== 'per_bet_risk_threshold' && column.slots.title !== 'per_round_risk_threshold')?_c('span',[_vm._v("\n            "+_vm._s(column.singleI18n ? _vm.$t(("PoolSettingPage." + (column.slots.title))) :_vm.$t(("global.common." + (column.slots.title))))+"\n          ")]):_c('div',[_c('p',[_vm._v(_vm._s(_vm.$t('PoolSettingPage.risk_threshold')))]),_vm._v(" "),_c('p',[_vm._v(_vm._s(column.slots.title === 'per_bet_risk_threshold' ? _vm.$t('PoolSettingPage.per_bet') : _vm.$t('PoolSettingPage.per_round'))+" (USD)")])])])}),0),_vm._v(" "),_c('PoolSettingDialog',{attrs:{"show":_vm.dialogVisible,"closeModal":_vm.closeDialog,"dialogData":_vm.dialogData,"oddsProfile":_vm.oddsProfile}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }