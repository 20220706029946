<template>
  <div class="new-merchant">
    <a-button class="add-btn" icon="user-add" @click="showModal">
      {{ $t('MerchantAdminPage.create_manager') }}
    </a-button>
    <a-modal v-model="visible" :title="$t('MerchantAdminPage.create_manager')" :footer="null" :width="380" :destroyOnClose="true">
      <a-form-model :layout="'vertical'" ref="newAdmin" :model="newAdmin" :rules="rules">
        <a-form-model-item :label="$t('global.common.account')" prop="account">
          <a-input v-model="newAdmin.account" :placeholder="$t('global.message.account_placeholder')" autocomplete="off" />
        </a-form-model-item>

        <a-form-model-item has-feedback :label="$t('global.common.password')" prop="password">
          <a-input-password v-model="newAdmin.password" type="password" :placeholder="$t('global.message.password_placeholder')" autocomplete="off" />
        </a-form-model-item>

        <a-form-model-item :label="$t('global.common.name')" prop="name">
          <a-input v-model="newAdmin.name" />
        </a-form-model-item>

        <a-form-model-item :label="$t('global.common.role')" prop="role">
          <a-select v-model="newAdmin.role">
            <a-select-option v-for="role in roles" :value="role.id" :key="role.id">
              <a-tag :color="role.color">{{ role.name }}</a-tag>
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" @click="newAdminSubmit(newAdmin)" block>
            {{ $t('global.action.create') }}
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
import { getMerchantAllRoles, createSubMerchant } from '@/../api';

export default {
  data() {
    const checkAccount = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('global.message.fill_input', {value: this.$t('global.common.account')})));
      }else if (value.length < 4) {
        callback(new Error(this.$t('global.message.must_be_at_4_characters', {value: this.$t('global.common.account')})));
      } else if (!/^[a-zA-Z0-9_\.]*$/.test(value)) {
        callback(new Error(this.$t('global.message.must_be_alphanumeric', {value: this.$t('global.common.account')})));
      } else {
        callback();
      }
    };
    const checkPassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('global.message.fill_input', {value: this.$t('global.common.password')})));
      } else if (value.length < 6) {
        callback(new Error(this.$t('global.message.must_be_at_6_characters', {value: this.$t('global.common.password')})));
      } else {
        callback();
      }
    };
    return {
      visible: false,
      roles: [
        {
          id: 1,
          name: 'Super',
          color: 'red',
        },
        {
          id: 2,
          name: 'Operator',
          color: 'blue',
        },
      ],
      newAdmin: {
        account: '',
        password: '',
        confirm_password: '',
        name: '',
        // 用來給 Admin 做判斷 如果是 Admin Create 這才有用
        merchant_id: this.$route.params.id,
      },
      rules: {
        account: [{ required: true, validator: checkAccount, trigger: 'change' }],
        password: [{ required: true, validator: checkPassword, trigger: 'change' }],
        name: [
          { required: true,
            trigger: 'submit',
            message: this.$t('global.message.fill_input', {value: this.$t('global.common.name')}),
          },
        ],
        role: [
          { required: true,
            message: this.$t('global.message.select_value', {value: this.$t('global.common.role')}),
            trigger: 'blur'
          },
        ],
      },
    };
  },
  created() {
    getMerchantAllRoles()
      .then((data)=>{
        this.roles = data.data.role;
      })
  },
  methods: {
    showModal() {
      this.visible = true;
    },
    newAdminSubmit() {
      this.$refs.newAdmin.validate((valid) => {
        if (!valid) {
          this.$message.error(this.$t('global.message.failed_to_create', {value: this.$t('global.common.account')}));
          return false;
          } else {
          const { account, password, name, role, merchant_id} = this.newAdmin;
          createSubMerchant({account, password, name, role_id: role, merchant_id})
            .then((data) => {
              if (data.data.code == 'OK') {
                this.$message.success(this.$t('global.message.success_to_create', {value: this.$t('global.common.account')}));
              } else {
                this.$message.error(this.$t('global.message.failed_to_create', {value: this.$t('global.common.account')}));
                this.$message.error(`${Object.keys(data.data.message)[0]} ${data.data.message.account[0]}`);
              }
              this.visible = false;
              this.$refs.newAdmin.resetFields();
              this.$emit('update-data', this.adminForm);
            })
        }
      });
    },
  },
};
</script>

