<template>
  <div class="edit-agent">
    <a-button type="primary" icon="edit" @click="showModal">
      {{ $t("global.action.edit") }}
    </a-button>
    <a-modal
      v-model="visible"
      :title="$t('AgentsPage.edit_agent')"
      :footer="null"
      :width="360"
    >
      <a-form-model
        :layout="'vertical'"
        ref="form"
        :model="form"
        :rules="rules"
      >
        <a-row :gutter="[16, 16]">
          <!-- <a-col :xs="24" :sm="12" v-if="role === 'merchant'">
            <a-form-model-item :label="$t('global.common.name')" prop="name">
              <a-input v-model="form.name" />
            </a-form-model-item>
            <a-form-model-item :label="$t('global.common.email')" prop="email">
              <a-input v-model="form.email" />
            </a-form-model-item>
            <a-form-model-item :label="$t('global.common.theme')" prop="theme">
              <a-select v-model="form.theme">
                <a-select-option v-for="theme in themes" :key="theme" :value="theme">
                  {{ theme }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item :label="$t('global.common.password')" prop="password">
              <a-input type="password" v-model="form.password" />
            </a-form-model-item>
          </a-col> -->
          <a-col :xs="24" :sm="24">
            <!-- <a-form-model-item :label="$t('global.common.status')" prop="status">
              <a-select v-model="form.status">
                <a-select-option v-for="status in agent_status" :key="status" :value="status">
                  {{ $t(`global.common.active_status_list.${status}`) }}
                </a-select-option>
              </a-select>
            </a-form-model-item> -->
            <!-- <template v-if="is_master_agent === true && role === 'merchant'">
              備註：Only Master Agent's curreny can be editor
              <a-form-model-item :label="$t('global.common.currency')" prop="currency">
                <a-select v-model="form.currency">
                  <a-select-option v-for="currency in currencies" :key="currency" :value="currency">
                    {{ currency }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </template> -->
            <!-- 原本 v-if="is_merchant_account" 改成 false 是目前需求是要隱藏該欄位 -->
            <!-- <a-form-model-item :label="$t('AgentsPage.rate')" prop="rate" :extra="$t('AgentsPage.rate_note')" v-if="false">
              <a-row>
                <a-col :span="16">
                  <a-slider v-model="form.rate" :min="rate_min" :max="rate_max" :step="0.1" />
                </a-col>
                <a-col :span="8">
                  <a-input-number
                    :value="form.rate"
                    :min="rate_min"
                    :max="rate_max"
                    :step="0.1"
                    :formatter="value => `${value}%`"
                    :parser="parserValue"
                    @change="handleChangeRate"
                    style="marginLeft: 16px"
                  />
                </a-col>
              </a-row>
            </a-form-model-item> -->

            <!-- <a-form-model-item 
              :label="$t('global.common.skin')" 
              prop="skin">
              <a-select 
              v-model="form.skin">
                <a-select-option v-for="skinItem in skin_list" :key="skinItem.value" :value="skinItem.value">
                  {{ $t(`global.common.skin_list.${skinItem.label}`) }}
                </a-select-option>
              </a-select>
            </a-form-model-item> -->

            <a-form-model-item
              :label="$t('global.common.callback_url')"
              prop="callbackUrl"
              v-if="tradeMode === 'transfer'"
            >
              <a-textarea
                v-model.trim="form.callbackUrl"
                placeholder="https://..."
                :rows="3"
              />
            </a-form-model-item>

            <a-form-model-item
              :label="$t('global.common.settle_zero')"
              prop="settleZero"
            >
              <a-select v-model="form.settleZero">
                <a-select-option
                  v-for="(settleZeroItem, index) in settle_zero_list"
                  :key="index"
                  :value="settleZeroItem.value"
                >
                  {{
                    $t(
                      `global.common.active_status_list.${settleZeroItem.label}`
                    )
                  }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-form-model-item>
          <a-button type="primary" @click="formSubmit" block>
            {{ $t("global.action.submit") }}
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
// import { getAgentProfileFindById } from '@/../api';
// import numeral from 'numeral';
// import { mapState } from 'vuex';

import {
  // getAgents,
  // getAgentById,
  // getClientTheme,
  // getClientCurrency,
  // getAgentProfile,
  updateAgentProfile,
  // getCurrentManageRate
  // getLanguages
} from "@/../api";

export default {
  props: {
    data: Object,
    updateOptions: Function,
  },
  data() {
    const checkUrl = (rule, value, callback) => {
      try {
        new URL(value);
        callback();
      } catch (err) {
        callback(new Error(this.$t("global.message.whole_url")));
      }
    };
    // const checkPassword = (rule, value, callback) => {
    //   if (value.length < 6 && value.length > 0) {
    //     callback(new Error(this.$t('global.message.must_be_at_6_characters', {value: this.$t('ChangePasswordPage.new_password')})));
    //   } else {
    //     callback();
    //   }
    // };
    // const checkRate = (rule, value, callback) => {
    //   if (Number(value) > this.rate_max) {
    //     callback(new Error(this.$t('AgentsPage.max', {value: Number(this.rate_max).toFixed(1)})));
    //   } else {
    //     callback();
    //   }
    // };
    return {
      visible: false,
      // themes: [],
      // languages: [],
      // agent_status: ['active', 'close'],
      // skin_list: [
      //   {
      //     label: 'default',
      //     value: 'default',
      //   },
      //   {
      //     label: 'brown',
      //     value: 'brown',
      //   },
      //   {
      //     label: 'white',
      //     value: 'white',
      //   }
      // ],
      settle_zero_list: [
        {
          label: "enable",
          value: 1,
        },
        {
          label: "disable",
          value: 0,
        },
      ],
      // currencies: [],
      // rate_max: 0, // This agent max rate
      // rate_min: 0,
      // is_master_agent: false,
      // is_merchant_account: false,
      tradeMode: "",
      form: {
        // email: '',
        // name: '',
        // theme: '',
        // status: '',
        // rate: 0,
        // currency: '',
        // password: '',
        // language: '',
        callbackUrl: "",
        // skin: '',
        settleZero: 0,
      },
      rules: {
        // password: [{ validator: checkPassword, trigger: 'change' }],
        // name: [{ validator: checkName, trigger: 'change' }],
        // theme: [{ required: true, trigger: 'change' }],
        // currency: [{ required: true, trigger: 'change' }],
        // status: [{ required: true, trigger: 'change' }],
        // rate: [{ validator:checkRate, trigger: 'change' }],
        callbackUrl: [{ validator: checkUrl, trigger: "change" }],
      },
    };
  },
  mounted() {
    // this.init();
  },
  computed: {
    // ...mapState({role: state => state.auth.role}),
  },
  watch: {
    data: {
      handler(newValue, oldValue) {
        if (newValue) {
          this.tradeMode = newValue.trade_mode || "credit";
          this.form.callbackUrl = newValue.swt_callback_url || "";
          this.form.settleZero = newValue.settle_zero ? 1 : 0;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    init() {
      // getClientTheme()
      //   .then((data)=>{
      //     this.themes = data.data.themes;
      //   })
      // getLanguages()
      //   .then((data)=>{
      //     this.languages = data.data.languages;
      //   })
      // getCurrentManageRate()
      //   .then((data) => {
      //     const { odds_mode, rate } = data.data;
      //     if(odds_mode !== 'rake'){
      //       this.rate_min = numeral(rate).multiply(100).value();
      //       this.rate_max = 100;
      //     }else{
      //       this.rate_min = 0;
      //       this.rate_max = numeral(rate).multiply(100).value();
      //     }
      // })
      // getClientCurrency()
      //   .then((data)=>{
      //     this.currencies = data.data.currencies;
      //   })
      // getAgentProfileFindById({ id: this.id })
      //   .then((data)=>{
      //     this.form.theme = data.data.theme;
      //     this.form.status = data.data.status;
      //     this.form.currency = data.data.currency;
      //     this.form.rate = numeral(data.data.rate).multiply(100).value();
      //     this.is_master_agent = data.data.is_master_agent;
      //     this.is_merchant_account = data.data.is_merchant_account;
      //     this.form.name = data.data.name;
      //     this.form.email = data.data.email;
      //     this.form.skin = data.data.skin_option || 'default';
      //     this.tradeMode = data.data.trade_mode || 'credit';
      //     this.form.callbackUrl = data.data.swt_callback_url || '';
      //     this.form.settleZero = data.data.settle_zero || 0;
      //   })
      // getAgentProfile()
      //   .then((data)=>{
      //     this.form.theme = data.data.theme;
      //     this.form.status = data.data.status;
      //     this.form.currency = data.data.currency;
      //     this.form.rate = data.data.rate * 100;
      //     this.is_master_agent = data.data.is_master_agent;
      //   })
    },
    showModal() {
      this.visible = true;
    },
    // parserValue(value) {
    //   const replacePercentValue = value.replace('%', '');
    //   const newValue = /[^\d.]/g.test(replacePercentValue) ? replacePercentValue.replace(/[^\d.]/g,'') : replacePercentValue ;
    //   const numberValue = newValue.split('.');
    //   const integerValue = numberValue[0];
    //   const decimalValue = numberValue[1];
    //   if(decimalValue && decimalValue.length > 3){
    //     return `${integerValue}.${decimalValue.substring(0, 3)}`;
    //   }else if(Number(newValue) >= this.rate_max){
    //     return String(this.rate_max);
    //   }else {
    //     return newValue;
    //   }
    // },
    // handleChangeRate(value){
    //   const newValue = String(value).length > 5 ? Number(String(value).slice(0,5)) : Number(value);
    //   this.form.rate = newValue;
    // },
    formSubmit() {
      const { id } = this;
      const {
        // currency,
        // email,
        // name,
        // rate,
        // status,
        // theme,
        // password,
        callbackUrl,
        // skin,
        settleZero,
      } = this.form;
      const settle_zero = settleZero === 1 ? true : false;
      const formData = {
        // id,
        // currency,
        // email,
        // name,
        // fee: ratePercentage,
        // active,
        // status,
        // client_theme: theme,
        // password,
        // skin_option: skin,
        settle_zero,
        // language
      };

      if (this.tradeMode === "transfer") {
        formData["swt_callback_url"] = callbackUrl;
      }

      // const ratePercentage = rate / 100;
      this.$refs.form.validate((valid) => {
        if (!valid) {
          // this.$message.error('error');
          return false;
        } else {
          updateAgentProfile(formData)
            .then((data) => {
              this.$message.success(
                this.$t("global.message.success_to_change", {
                  value: this.$t("global.common.account"),
                })
              );
              this.updateOptions(formData);
              this.visible = false;
            })
            .catch((err) => {
              this.$message.error(err.response.data.message);
            });
        }
      });
    },
  },
};
</script>
