<template>
  <div class="frame player-credits-page">
    <a-page-header
      :title="$t('global.menu.PlayerCredits')"
      @back="() => $router.go(-1)"
    >
    </a-page-header>
    <div class="page-content">
      <div class="search-bar">
        <a-form-model ref="searchForm" :model="form" :rules="rules">
          <div class="search-group">
            <Currency :inputCurrency.sync="form.currency"
                      :inputMaster.sync="form.master"
                      :inputAgent.sync="form.agent"
                      :showCurrency="false"
                      :showMaster="false"/>
            <a-form-model-item ref="player" :label="$t('global.common.player_account')" prop="player">
              <a-input
                v-model="form.player"
              />
            </a-form-model-item>
            <a-form-model-item ref="merchant_order_num" :label="$t('global.common.order_num')" prop="merchant_order_num">
              <a-input
                  v-model="form.merchant_order_num"
              />
            </a-form-model-item>
          </div>
          <div class="search-group">
            <Datetime :input.sync="form.date"
                      :periodDefault.sync="periodDefault"/>
            <div class="search-btns">
              <a-button type="primary" icon="search" :loading="loading" @click="onSearch">{{ $t('global.action.search') }}</a-button>
              <a-button :disabled="loading" @click="resetForm">{{ $t('global.action.reset') }}</a-button>
            </div>
          </div>
        </a-form-model>
      </div>
      <div class="ant-card">
        <div class="ant-card-body">
          <div class="table-control">
            <a-popover trigger="click" placement="bottomRight">
              <template slot="content">
                <div class="table-filter-content">
                  <a-checkbox class="table-filter-check"
                              v-for="(item, index) in columns"
                              :key="item.dataIndex"
                              :defaultChecked="item.filterType"
                              :disabled="item.disabledFilter"
                              @click="columnsChange(index, item)"
                              :value="item.dataIndex">
                    {{ item.singleI18n ? $t(`PlayerCreditsPage.${item.slots.title}`) :$t(`global.common.${item.slots.title}`) }}
                  </a-checkbox>
                </div>
              </template>
              <a-button icon="filter"></a-button>
            </a-popover>
          </div>
          <a-table
            bordered
            size="small"
            :columns="columns.filter( item => item.filterType || item.filterType === undefined)"
            :data-source="credits"
            :pagination="pagination"
            :loading="loading"
            :row-key="credits => credits.id"
            @change="handleTableChange"
          >
            <template v-for="column in columns">
              <span :slot="column.slots.title" :key="column.slots.title">{{ column.singleI18n ? $t(`PlayerCreditsPage.${column.slots.title}`) :$t(`global.common.${column.slots.title}`) }}</span>
            </template>
            <template slot="id" slot-scope="id">
              <div>{{ id }}</div>
            </template>
            <template slot="source_type" slot-scope="source_type">
              <div :data-credit-type="source_type">{{ $t(`global.common.bet_type_list.${source_type}`) }}</div>
            </template>
            <template slot="type_and_slug" slot-scope="type_and_slug">
              <div class="table-link" :data-credit-type="type_and_slug" @click="gotoType(type_and_slug)">{{ type_and_slug.slug }}</div>
            </template>
            <template slot="amount" slot-scope="amount">
              <div class="table-amount" :class="{'is-positive' : numberType(amount)}">{{ amount | formatNumberWithPoint }}</div>
            </template>
            <template slot="formatTimes" slot-scope="times">
              <div class="table-time">{{ times | formatTimes }}</div>
            </template>
            <template slot="formatNumberWithPoint" slot-scope="amount">
              <div>{{ amount | formatNumberWithPoint }}</div>
            </template>
          </a-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getPlayerCredit } from '@/../api';
import Datetime from '@/../src/components/datetime_picker';
import Currency from '@/../src/components/currency_selection';


const columns = [
  {
    slots: { title: 'id' },
    dataIndex: 'id',
    filterType: true,
    disabledFilter: true,
    scopedSlots: { customRender: 'id' },
    singleI18n: true,
  },
  {
    slots: { title: 'player_account' },
    dataIndex: 'player_account',
    filterType: true,
  },
  {
    slots: { title: 'type' },
    dataIndex: 'source_type',
    scopedSlots: { customRender: 'source_type' },
    align: 'center',
    customCell:(text) => {
      return {
        class: 'source-type type-'+ text.source_type,
      };
    },
    filterType: true,
  },
  {
    slots: { title: 'slug' },
    dataIndex: 'type_and_slug',
    scopedSlots: { customRender: 'type_and_slug' },
    align: 'center',
    filterType: true,
  },
  {
    slots: { title: 'order_num' },
    dataIndex: 'merchant_order_num',
    align: 'center',
    // scopedSlots: { customRender: 'merchant_order_num' },
    filterType: true,
  },
  {
    slots: { title: 'amount' },
    dataIndex: 'amount',
    align: 'right',
    scopedSlots: { customRender: 'amount' },
    filterType: true,
  },
  {
    slots: { title: 'before_amount' },
    dataIndex: 'before_balance',
    align: 'right',
    scopedSlots: { customRender: 'formatNumberWithPoint' },
    filterType: true,
    singleI18n: true,
  },
  {
    slots: { title: 'after_amount' },
    dataIndex: 'after_balance',
    align: 'right',
    scopedSlots: { customRender: 'formatNumberWithPoint' },
    filterType: true,
    singleI18n: true,
  },
  {
    slots: { title: 'created_at' },
    dataIndex: 'created_at',
    scopedSlots: { customRender: 'formatTimes' },
    filterType: true,
  },
  {
    slots: { title: 'updated_at' },
    dataIndex: 'updated_at',
    scopedSlots: { customRender: 'formatTimes' },
    filterType: true,
  },
];

export default {
  components: {
    Datetime,
    Currency,
  },
  data() {
    return {
      data: [],
      pagination: {
        current: 1,
        pageSize: 10, // 默认每页显示数量
        showSizeChanger: true, // 显示可改变每页数量
        pageSizeOptions: ["10", "20", "30", "40"], // 每页数量选项
        showTotal: (total) => `Total ${total} items`, // 显示总数
        showSizeChange: (current, pageSize) => (this.pageSize = pageSize), // 改变每页数量时更新显示
        total: 0
      },
      expand: false,
      loading: false,
      columns,
      form: {
        date: [new Date(), new Date()],
        player: '',
        agent: null,
        currency: '',
        master: null,
        merchant_order_num: '',
      },
      periodDefault: 'today',
      rules: {
        agent: [{ required: true, trigger: 'submit' }],
      },
      credits: [],
    };
  },
  mounted(){
    // this.onSearch();
  },
  methods: {
    handleTableChange(pagination, filters, sorter) {
      const currentPage = this.pagination.pageSize !== pagination.pageSize ? 1 : pagination.current;
      this.pagination.current = currentPage;
      this.pagination.pageSize = pagination.pageSize;

      const { date, player, agent } = this.form;
      this.fetch({
        results: pagination.pageSize,
        page: currentPage,
        date: date,
        player: player,
        agent: agent,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
      });
    },
    fetch(params = {}) {
      if(this.form.agent === null){
        this.$message.error(this.$t('global.message.select_value', { value: this.$t('global.common.agent') }), 3);
        return;
      }

      this.loading = true;
      getPlayerCredit({
        ...params,
      }).then(({ data }) => {
        const pagination = { ...this.pagination };
        pagination.total = data.total;

        this.credits = data.credits;
        this.pagination = pagination;
      }).catch((err)=>{
        this.$message.error(err.response.data.message, 3);
      }).finally(()=>{
        this.loading = false;
      });
    },
    onSearch() {
      if(this.loading) return;
      const { pageSize } = this.pagination;
      this.pagination.current = 1;
      const { date, player, agent, merchant_order_num } = this.form;
      this.$refs.searchForm.validate(valid => {
        if (valid) {
          this.fetch({ date, player, results: pageSize, page: 1, agent, merchant_order_num });
        } else {
          // console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm() {
      if(this.loading) return;
      this.$refs.searchForm.resetFields();
      this.periodDefault = 'today';
      // this.onSearch();
    },
    numberType(arg) {
      if (typeof arg == "string") {
        return !!(arg.match(/^\d+((\.\d+){0,})?$/) && parseFloat(arg) > 0)
      } else if (typeof arg == "number") {
        return !isNaN(arg) && arg> 0;
      }
      return false;
    },
    columnsChange(index, item) {
      item.filterType = !item.filterType;
    },
    gotoType(type_and_slug){
      let name = '';
      if(type_and_slug.source_type === 'OrderDeposit' || type_and_slug.source_type === 'OrderWithdraw') {
        name = 'Order';
      } else {
        name = 'BetLog';
      }

      let routeData = this.$router.resolve({ name: name, query: { slug: type_and_slug.slug }});
      window.open(routeData.href, '_blank');
    },
  },
};
</script>

