<template>
  <a-modal :visible="show"
           :footer="null"
           :centered="true" 
           :width="'unset'"
           :title="$t(`ControlPanelPage.${uploadImg.title}`)" 
           @cancel="closeImgModal">
    <div class="upload-file-dialog">
      <p class="img-path">
        <span>{{$t('ControlPanelPage.img_path')}}: {{uploadedPath}}</span>
        <span class="rest" @click="deleteUploadedImg" v-if="uploadedPath"><a-icon type="rest" theme="filled" /></span>
      </p>
      <p class="img-textplaceholder highlight">
        <span>＊</span>
        <span>{{ $t('ControlPanelPage.img_format') }}：{{ uploadImg.imgFormat }}</span>
        <span>；</span>
        <span>{{ $t('ControlPanelPage.img_recommended_size') }}：{{ uploadImg.recommendedSize }}</span>
        <span>；</span>
        <span>{{ $t('ControlPanelPage.file_size') }}：{{`<=${uploadImg.maxSize} kb`}}</span>
        </p>
      <p class="img-textplaceholder">
        <span>＊{{ $t('ControlPanelPage.image') }}：</span>
          <a-upload
            :accept="imgTypes.join(',')"
            :file-list="fileList"
            :before-upload="beforeUpload"
            :showUploadList="false"
          >
            <a-button> <a-icon type="upload" /> {{ $t('global.action.select_file') }} </a-button>
          </a-upload>
        <span class="img-preview-text">{{filePath || $t('ControlPanelPage.file_no_selected') }}</span>
      </p>
      <div class="img-preview">
        <div class="loading" v-if="loading"><a-spin /></div>
        <div v-if="!imgPreview && !initialLoading" class="no-image preview-box">
          <img :src="require('images/no_image.png')" alt="no image">
          <p>{{ $t('ControlPanelPage.no_image') }}</p>
        </div>
        <div v-if="imgPreview && !initialLoading" class="thumbnail preview-box">
          <img :src="imgPreview" :alt="uploadImg.type">
        </div>
      </div>
      <div class="btn-group">
        <a-button type="primary" @click="upload" :disabled="fileList.length <= 0 || loading">{{ $t('global.action.upload_image') }}</a-button>
        <a-button type="button" class="add-btn" @click="cancelModification" :disabled="fileList.length <= 0 || loading">{{ $t('global.action.cancel_upload') }}</a-button>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { 
  getImage, 
// getOriginImage, 
// postImage, 
deleteImage, 
createImage 
} from '@/../api';

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export default {
  props: {
    show: Boolean,
    closeModal: Function,
    clearUploadData: Function,
    uploadImg: Object,
  },
  data() {
    return {
      imgPreview:'',
      rules: {},
      imgTypes:['image/png', 'image/jpeg'],
      fileList: [], //图片列表,
      filePath: '',
      uploadedPath: '',
      loading: false,
      initialLoading: true,
      images: [],
    };
  },
  async mounted(){
    this.loading = true;
    await this.getImg();
    this.loading = false;
    this.initialLoading = false;
  },
  methods: {
    async getImg(){
      const { merchant_provider_id, type } = this.uploadImg;
      try{
        const res = await getImage({ source_type: 'MerchantProvider', source_id: merchant_provider_id, name: type });
        const { images } = res.data;
        const imagesAry = Array.isArray(images) ? images : [];
        this.imgPreview = imagesAry[0].url;
        this.uploadedPath = imagesAry[0].url;
        this.images = imagesAry;
      }catch(err){
        console.log(err);
      }
    },
    covertFormat(val) {
      // console.log('checkFormat', val);
      let type = '';
      switch (val) {
          case "89504e47":
              type = "image/png";
              break;
          case "ffd8ffdb":
          case "ffd8ffe0":
          case "ffd8ffee":
          case "ffd8ffe1":
              type = "image/jpeg";
              break;
          default:
              type = "others"; // Or you can use the blob.type as fallback
              break;
      }
      return type;
    },
    checkFormat(file) {
      const self = this;
      let reader = new FileReader();

      reader.onerror = function(){
        reader.abort();
        const titleI18n = self.$t(`ControlPanelPage.${self.uploadImg.title}`);
        const failI18n = self.$t(`global.message.failed_to_update`,{ value: titleI18n });
        self.$message.error(failI18n);
      }

      reader.onloadend = function (e) {
        // console.log('result', e.target.result)
        let result = new Uint8Array(e.target.result);
        let header = '';
        for (var i = 0; i < result.length; i++) {
            header += result[i].toString(16);
        }

        const currentFormat = self.covertFormat(header);
        if(!self.imgTypes.includes(currentFormat)){
          self.$message.error(self.$t(`ControlPanelPage.message.error_image_type`));
        }else{
          self.filePath = file.name;
          self.loading = true;
          getBase64(file).then((res)=>{
            self.imgPreview = res;
            self.loading = false;
          })
          self.fileList = [file];
        }
        // console.log('real Format:' + self.type);
      }

      reader.readAsArrayBuffer(file.slice(0, 4));
    },
    beforeUpload(file) {
      if(file.size > this.uploadImg.maxSize * 1024){
        const titleI18n = this.$t(`ControlPanelPage.${this.uploadImg.title}`);
        const failI18n = this.$t(`global.message.failed_to_update`,{ value: titleI18n });
        const maxSizeI18n = this.$t(`ControlPanelPage.message.max_image_size`,{ value: this.uploadImg.maxSize });
        this.$message.error(`${failI18n}. ${maxSizeI18n}`);
        return false;
      }else{
        this.checkFormat(file);
        return false;
      }
    },
    cancelModification(){
      this.fileList = [];
      this.filePath = '';
      this.imgPreview = this.uploadedPath ? this.uploadedPath : '';
    },
    async upload(){
      const { merchant_provider_id, type } = this.uploadImg;
      const { images, fileList } = this;

      const formData = new FormData();
      const uploadTime = this.moment().format('YYYYMMDDHHmmss');
      // const updateImages = [];

      const originFile = fileList[0];
      const fileExtension = originFile.name.match(/\.([^.]+)$/)[1];
      const baseName = originFile.name.substr(0, originFile.name.lastIndexOf('.'));
      let newFileName = `${this.uploadImg.merchant_provider_id}_${uploadTime}_${baseName}.${fileExtension}`;
      //if(this.uploadImg.title === 'schedule') {
      //}

      const newFile = new File([originFile], newFileName, {
        type: originFile.type,
        lastModified: originFile.lastModified,
      })

      // updateImages.push({file: newFile, id:images[0] ? images[0].id : null, priority: 1});

      try{
        this.loading = true;
        // formData.append('images', updateImages);
        formData.append('images[][file]', newFile);
        formData.append('images[][id]', images[0] ? images[0].id : "");
        formData.append('images[][priority]', 1);
        formData.append('source_type', 'MerchantProvider');
        formData.append('source_id', merchant_provider_id);
        formData.append('name', type);
        const res = await createImage(formData);

        if(res) {
          this.$message.success(this.$t('global.message.success_to_change',{value: this.$t(`ControlPanelPage.${this.uploadImg.title}`)}));
          await this.getImg();
          this.fileList = [];
          this.filePath = '';
        }
      }catch(err){
        console.log(err);
        this.$message.error(this.$t('global.message.failed_to_update_with_reason',{value: this.$t(`ControlPanelPage.${this.uploadImg.title}`), reason: `${err.response.data.message}` }));
        // this.cancelModification();
      }finally{
        this.loading = false;
      }
    },
    deleteUploadedImg(){
      const { images } = this;
      const id = images[0].id;
      const self = this;
      this.$confirm({
        title: this.$t('ControlPanelPage.message.delete_image_placeholder',{ value: this.$t(`ControlPanelPage.${this.uploadImg.title}`) }),
        okText: this.$t('global.action.yes'),
        cancelText: this.$t('global.action.no'),
        async onOk() {
          //const { merchant_provider_id, type } = self.uploadImg;
          try{
            const res = await deleteImage({ id });
            if(res) {
              self.$message.success(self.$t('global.message.success_to_delete',{value: self.$t(`ControlPanelPage.${self.uploadImg.title}`)}));
              self.uploadedPath = '';
              self.images[0].id = '';
              if(self.fileList.length === 0) {
                self.imgPreview = '';
              }
            }
          }catch(err){
            self.$message.error(self.$t('global.message.failed_to_delete',{value: self.$t(`ControlPanelPage.${self.uploadImg.title}`) }));
            console.log(err);
          }
        },
        onCancel() {},
      });
    },
    closeImgModal(){
      this.closeModal();
      setTimeout(()=>{
        this.uploadedPath = '';
        this.cancelModification();
        this.clearUploadData();
        this.loading = false;
        this.initialLoading = true;
      },300)
    }
  },
  watch:{
    // uploadImg: {
    //   async handler(newValue, oldValue) {
    //     if(newValue.type) {
    //       this.loading = true;
    //       await this.getImg();
    //       this.loading = false;
    //       this.initialLoading = false;
    //     }
    //   },
    //   deep: true,
    //   immediate: false,
    // }
  },
};
</script>
