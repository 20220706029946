<template>
  <div>
    <a-spin :spinning="formLoading">
      <a-form-model :model="roleForm"
                    ref="roleForm"
                    :rules="rules"
                    layout="vertical">
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-model-item :label="$t('MerchantRolesPage.role_name')" prop="role_name">
              <a-input v-model="roleForm.role_name"/>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item :label="$t('MerchantRolesPage.color')" prop="color">
              <a-select v-model="roleForm.color">
                <a-select-option v-for="color in tag_colors" :value="color" :key="color">
                  <a-tag :color="color">{{color}}</a-tag>
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-divider class="checkbox-page-divider"/>
        <a-row  class="checkbox-page-min-title" :gutter="16">
          <a-col :sm="24" :md="6">
            <h5>{{ $t('MerchantRolesPage.can_use_pages') }}</h5>
          </a-col>
          <a-col :sm="24" :md="18">
            <a-checkbox class="checkbox" v-model="selectedAll" :indeterminate="indeterminate">
              {{ $t('global.common.all') }}
            </a-checkbox>
          </a-col>
        </a-row>

        <a-row class="checkbox-page" :gutter="16" v-for="(page, key) in page_list" :key="key">
          <a-col class="checkbox-page-group-title" :sm="24" :md="6">
            {{ $t(`global.menu.${key}`) }}
          </a-col>
          <a-col :sm="24" :md="18">
            <a-checkbox-group class="checkbox-page-group"
                              v-model="roleForm.permission_list[key]"
                              @change="onChangePermission(key)"
                              :data-group-name="key">
              <template v-for="value in page">
                <a-checkbox class="checkbox" :value="value" :key="value">
                  {{ $t(`MerchantRolesPage.${value}`) }}
                </a-checkbox>
              </template>
            </a-checkbox-group>
          </a-col>
        </a-row>

      </a-form-model>
    </a-spin>
    <div class="drawer-footer">
      <a-button type="primary" @click="roleFormSubmit(roleForm, formType)" :loading="formLoading">
        {{ $t(`global.action.${formType}`) }}
      </a-button>
    </div>
  </div>
</template>
<script>
import { createMerchantRole, updateMerchantRole, getDefaultPermissions } from '@/../api';

const blankPermission =  {
    ArenaModerator: [],
    ControlPanel: [],
    CurrentArena: [],
    Players: [],
    PlayerCredits: [],
    BetLog: [],
    GameResults: [],
    Order: [],
    Agents: [],
    Merchants: [],
    AgentShow: [],
  };
export default {
  props: {
    formType: {
      type: String,
      default: 'edit',
    },
    roleForm: {
      type: Object,
      default: {
        role_name: '',
        color: 'red',
        permission_list: Object.assign({}, blankPermission),
      },
    },
  },
  data() {
    return {
      id: 0,
      indeterminate: false,
      formLoading: false,
      selectedAll: false,
      tag_colors: ['red', 'pink', 'orange', 'green', 'cyan', 'blue', 'purple'],
      page_list: { // merchant all page
        ArenaModerator: ['read'],
        ControlPanel: ['read','arena_on_off', 'fight_control', 'lock_earning', 'announcement'],
        CurrentArena: ['read', 'lock_earning'],
        Players: ['read'],
        PlayerCredits: ['read'],
        BetLog: ['read'],
        GameResults: ['read'],
        Order: ['read'],
        Agents: ['read', 'update', 'create'],
        AgentShow: ['read', 'update'],
      },
      blankPermission, // // merchant all page 權限是空的
      rules: {
        role_name: [
          { required: true,
            message: this.$t('global.message.fill_input', {value: this.$t('MerchantRolesPage.role_name')}),
            trigger: 'blur'
          },
        ],
      }
    };
  },
  watch: {
    selectedAll(newValue){
      this.roleForm.permission_list =  Object.assign({}, newValue ? this.page_list : this.blankPermission);
      this.indeterminate = false;
    },
    roleForm(roleForm) {
      this.indeterminate = true;
    }
  },
  methods: {
    init(){
      getDefaultPermissions()
        .then((data)=>{
          this.page_list = data.data.permission_list;
        })
    },
    onChangePermission(key) {
      const page = this.roleForm.permission_list[key];
      if(page.includes('read') !== true && page.length >= 1) {
        this.roleForm.permission_list[key].push('read');
      }
      if ( this.roleForm.permission_list !== this.page_list) {
        this.indeterminate = true;
      }
    },
    roleFormSubmit(type) {
      this.$refs.roleForm.validate((valid) => {
        if (!valid) {
          if (this.formType == 'create') {
            this.$message.error(this.$t('global.message.failed_to_create', {value: this.$t('global.common.role')}));
          } else {
            this.$message.error(this.$t('global.message.failed_to_update', {value: this.$t('global.common.role')}));
          }
          return false;
        } else {
          this.formLoading = true;
          const { role_name, color, permission_list, id } = this.roleForm;
          if (this.formType == 'create') {
            createMerchantRole({ role_name, color, permission_list, id })
              .then((data) => {
                this.$message.success(this.$t('global.message.success_to_create', {value: this.$t('global.common.role')}));
                this.$emit('update-data', this.roleForm);
                this.formLoading = false;
              })
              .catch((err)=>{
                this.formLoading = false;
                this.$message.error(err.response.data.message)
              })
          } else {
            updateMerchantRole({ role_name, color, permission_list, id })
              .then((data) => {
                this.$message.success(this.$t('global.message.success_to_change', {value: this.$t('global.common.role')}));
                this.formLoading = false;
                this.$emit('update-data', this.roleForm);
              })
              .catch((err)=>{
                this.formLoading = false;
                this.$message.error(err.response.data.message)
              })
          }
        }
      });
    },
  },
  mounted() {
    this.init();
  },
};
</script>
