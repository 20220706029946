<template>
  <div class="frame game-results-page">
    <a-page-header
      :title="$t('global.menu.GGR')"
      @back="() => $router.go(-1)"
    >
    </a-page-header>
    <div class="page-content">
      <div class="search-bar">
        <a-form-model ref="searchForm" :model="form">
          <div class="search-group">
            <Datetime :input.sync="form.date"/>
            <div class="search-btns">
              <a-button type="primary" icon="search" :loading="searchLoading" @click="onSearch">{{ $t('global.action.search') }}</a-button>
            </div>
          </div>
        </a-form-model>
      </div>
      <div class="dashboard-cards" v-if="true">
        <div class="dashboard-card">
          <a-card>
            <a-card-meta :title="$t('global.common.total_bet_count')">
              <a-avatar
                slot="avatar"
                :src="require('images/bet_count.svg')"
              />
              <template slot="description">
                <div class="card-num">999.99</div>
              </template>
            </a-card-meta>
          </a-card>
        </div>
        <div class="dashboard-card">
          <a-card>
            <a-card-meta :title="$t('global.common.total_bet_amount')">
              <a-avatar
                slot="avatar"
                :src="require('images/rate.svg')"
              />
              <template slot="description">
                <div class="card-num">999.99</div>
              </template>
            </a-card-meta>
          </a-card>
        </div>
        <div class="dashboard-card">
          <a-card>
            <a-card-meta :title="$t('global.common.platform_profit')">
              <a-avatar
                slot="avatar"
                :src="require('images/bet.svg')"
              />
              <template slot="description">
                <div class="card-num">999.99</div>
              </template>
            </a-card-meta>
          </a-card>
        </div>
      </div>
      <div class="ant-card">
        <div class="ant-card-body">
          <a-table
            bordered
            size="small"
            :columns="columns"
            :data-source="data"
            :pagination="pagination"
            :loading="loading"
            rowKey="date"
            @change="handleTableChange"
          >
            <template v-for="(column,index) in columns">
              <span :slot="column.slots.title" :key="index">{{ $t(`global.common.${column.slots.title}`) }}</span>
            </template>
          </a-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Datetime from '@/../src/components/datetime_picker';

const columns = [
  {
    slots: { title: 'date' },
    title_name: 'date',
    dataIndex: 'date',
  },
  {
    slots: { title: 'bet_count' },
    title_name: 'bet_count',
    dataIndex: 'bet_count',
    align: 'right',
  },
  {
    slots: { title: 'bet_amount' },
    title_name: 'bet_amount',
    dataIndex: 'bet_amount',
    align: 'right',
  },
  {
    slots: { title: 'payout' },
    dataIndex: 'payout',
    align: 'right',
  },
  {
    slots: { title: 'platform_profit' },
    dataIndex: 'ggr',
    align: 'right',
  },
];

export default {
  components: {
    Datetime,
  },
  data() {
    return {
      data: [
        {
          date: '2021-09',
          bet_count: '20',
          bet_amount: '1000',
          rate: '100',
          ggr: '333',
          children: [
            {
              date: '2021-09-01',
              bet_count: '10',
              bet_amount: '500',
              rate: '50',
              ggr: '33',
            },
            {
              date: '2021-09-02',
              bet_count: '10',
              bet_amount: '500',
              rate: '50',
              ggr: '300',
            },
          ]
        },
      ],
      pagination: {},
      expand: false,
      loading: false,
      columns,
      form: {
        date: [new Date(), new Date()],
      },
      rules: {
      },
      searchLoading: false,
      results: [],
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    handleTableChange(pagination, filters, sorter) {
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
      this.fetch({
        results: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
      });
    },
    fetch(params = {}) {
      // this.loading = true;
      // getGameResult({
      //   results: 10,
      //   ...params,
      // }).then(({ data }) => {
      //   const pagination = { ...this.pagination };
      //   // Read total count from server
      //   // pagination.total = data.totalCount;
      //   console.log(data)
      //   pagination.total = data.gameResults.length;
      //   this.loading = false;
      //   this.results = data.gameResults;
      //   this.pagination = pagination;
      // });
    },
    onSearch() {
      this.$refs.searchForm.validate(valid => {
        if (valid) {
          this.searchLoading = true;
          setTimeout(() => {
            this.searchLoading = false;
          }, 800);
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm() {
      this.$refs.searchForm.resetFields();
    },
  },
};
</script>
