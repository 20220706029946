<template>
    <a-popover 
        overlayClassName="malay-detail-popover"
        placement="leftBottom" >
    <template slot="title">
        <span>{{ optionName }}</span>
    </template>
    <template slot="content">
        <table 
            class="malay-detail-popover-table">
            <tr>
                <td>{{ $t('PoolSettingPage.odds_refresh_frequency') }}</td>
                <td>{{ currentOption.duration_second }}</td>
            </tr>
            <tr>
                <td>{{ $t('global.common.rate') }}</td>
                <td>{{ currentOption.rake_percentage }}</td>
            </tr>
            <tr>
                <td>
                    <p>{{ $t('PoolSettingPage.risk_threshold') }}</p>
                    <p>{{ $t('PoolSettingPage.per_bet') }}(USD)</p>
                </td>
                <td>{{ currentOption.per_bet_risk_threshold }}</td>
            </tr>
            <tr>
                <td>
                    <p>{{ $t('PoolSettingPage.risk_threshold') }}</p>
                    <p>{{ $t('PoolSettingPage.per_round') }}(USD)</p>
                </td>
                <td>{{ currentOption.per_round_risk_threshold }}</td>
            </tr>
            <tr>
                <td>{{ $t('PoolSettingPage.last_call_percentage') }}</td>
                <td>{{ currentOption.last_call_percentage }}</td>
            </tr>
            <tr>
                <td>{{ $t('PoolSettingPage.close_bet_percentage') }}</td>
                <td>{{ currentOption.close_bet_percentage }}</td>
            </tr>
        </table>
    </template>
    <div class="show-icon">
        <a-icon type="eye" />
    </div>
    </a-popover>
  </template>
  <script>
  
  export default {
    props:{
      id: {
        type: Number,
        default: null
      },
      oddsProfile: {
        type: Array,
        default: ()=>([])
      }
    },
    components:{},
    data() {
      return {};
    },
    mounted() {},
    computed: {
        currentOption(){
            const optionItem = this.oddsProfile.find(profileItem=>profileItem.id === this.id);
            return optionItem;
        },
        optionName(){
            return this.currentOption ? this.currentOption.name : '-'
        }
    },
    watch: {},
    methods: {},
  };
  </script>
  