<template>
  <div class="new-agent">
    <a-form-model :layout="'vertical'" ref="editAdminAgent" :model="adminAgent" :rules="rules">

      <a-form-model-item :label="$t('global.common.merchant')" prop="merchant">
        <a-select v-model="adminAgent.merchant">
          <a-select-option v-for="merchant in merchants" :value="merchant.value" :key="merchant.value">
              {{ merchant.label }}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item :label="$t('global.common.change_password')" prop="password">
        <a-input-password v-model="adminAgent.password" :placeholder="$t('global.message.password_placeholder')" />
      </a-form-model-item>

      <a-form-model-item :label="$t('global.common.name')" prop="name">
        <a-input v-model="adminAgent.name" />
      </a-form-model-item>

      <a-form-model-item>
        <a-button type="primary" @click="adminAgentSubmit()" block>
          {{ $t('global.action.edit') }}
        </a-button>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>
<script>

export default {
  props: {
    adminAgent: Object,
    merchants: Array
  },
  data() {
    return {
      rules: {
        authority: [
          { required: true,
            message: this.$t('global.message.select_value', {value: this.$t('global.common.merchant')}),
            trigger: 'blur'
          },
        ],
      },
    };
  },
  methods: {
    adminAgentSubmit() {
      this.$refs.editAdminAgent.validate((valid) => {
        if (!valid) {
          this.$message.error(this.$t('global.message.failed_to_update', {value: this.$t('global.common.account')}));
          return false;
        } else {
          this.$emit('update-data', this.agentSubAccount);
          this.$message.success(this.$t('global.message.success_to_change', {value: this.$t('global.common.account')}));
        }
      });
    },
  },
};
</script>
