export const AUTH_TOKEN = 'authToken';
export const CURRENT_USER = 'currentUser';
export const BACK_URL = 'backURL';
export const PREVIOUS_URL = 'PREVIOUS_URL';
export const AUTH_ROLE = 'authRole';
export const IDENTITY = 'identity';

const storage = window.localStorage;

export default {
  setItem(name, object) {
    return storage.setItem(name, JSON.stringify(object));
  },
  getItem(name) {
    if (!storage.getItem(name) || storage.getItem(name) === 'undefined') {
      return;
    }
    return JSON.parse(storage.getItem(name));
  },
  clear() {
    return storage.clear();
  },
};
