import Vue from 'vue';
import VueI18n from 'vue-i18n';
import store from '../store';

import en from './lang/en';
import tw from './lang/tw';
import th from './lang/th'; // 泰語
import vn from './lang/vn'; // 越南語
import id from './lang/id'; // 印尼語
import my from './lang/my'; // 馬來語
import mm from './lang/mm'; // 緬甸語
import pt from './lang/pt'; // 西班牙語
import ind from './lang/in'; // 印度語
import ru from './lang/ru'; // 俄文
import kr from './lang/kr'; // 韓文

Vue.use(VueI18n);

const userLang = navigator.language;
let locale = userLang || 'en';

if (localStorage.getItem('footmark-lang')) {
  locale = localStorage.getItem('footmark-lang');
  store.commit('setLang', locale);
} else {
  store.commit('setLang', locale);
}

const i18n = new VueI18n({
  locale,
  fallbackLocale: 'en',
  formatFallbackMessages: true,
  silentFallbackWarn: true,
  messages: {
    en,
    tw,
    th, // 泰語
    vn, // 越南語
    id, // 印尼語
    my, // 馬來語
    mm, // 緬甸語
    pt, // 西班牙語
    ind, // 印度語
    ru, // 俄文
    kr, // 韓文
  },
});

export default i18n;
