<template>
  <a-modal 
    :visible="visible" 
    :title=" oddsForm.id ? $t('global.common.update_setting') : $t('global.common.create_setting')" 
    :footer="null" 
    :width="360"
    :afterClose="resetForm"
    @cancel="closeModal">
    <a-form-model :layout="'vertical'" ref="oddsForm" :model="oddsForm" :rules="rules">
      <a-row :gutter="[16,16]">
        <a-col :xs="24">
          <a-form-model-item :label="$t('global.common.name')" prop="name">
            <a-input v-model="oddsForm.name" />
          </a-form-model-item>

          <a-form-model-item :label="$t('PoolSettingPage.odds_refresh_frequency')" prop="duration_second">
            <a-input type="number" v-model="oddsForm.duration_second" @input="(e)=>{checkInput(e, 'duration_second', 1)}" @blur="(e)=>{checkBlur(e, 'duration_second', 1)}"/>
          </a-form-model-item>

          <a-form-model-item :label="`${$t('PoolSettingPage.per_round')} ${$t('PoolSettingPage.risk_threshold')}`" prop="per_round_risk_threshold">
            <a-input type="number" v-model="oddsForm.per_round_risk_threshold" @input="(e)=>{checkInput(e, 'per_round_risk_threshold', 0)}" @blur="(e)=>{checkBlur(e, 'per_round_risk_threshold', 0)}"/>
          </a-form-model-item>

          <a-form-model-item :label="`${$t('PoolSettingPage.per_bet')} ${$t('PoolSettingPage.risk_threshold')}`" prop="per_bet_risk_threshold">
            <a-input type="number" v-model="oddsForm.per_bet_risk_threshold" @input="(e)=>{checkInput(e, 'per_bet_risk_threshold', 0, oddsForm.per_round_risk_threshold)}" @blur="(e)=>{checkBlur(e, 'per_bet_risk_threshold', 0)}"/>
          </a-form-model-item>

          <a-form-model-item :label="$t('global.common.rate')" prop="rake_percentage">
            <a-row>
              <a-col :span="16">
                <a-slider v-model="oddsForm.rake_percentage" :min="rake_percentage.min" :max="rake_percentage.max" :step="rake_percentage.step" />
              </a-col>
              <a-col :span="8">
                <a-input-number
                  :value="oddsForm.rake_percentage"
                  :min="rake_percentage.min"
                  :max="rake_percentage.max"
                  :step="rake_percentage.step"
                  :formatter="value => `${value}%`"
                  :parser="(value)=>parserValue(value, rake_percentage.max)"
                  @change="(e)=>{handleChangeRate(e, 'rake_percentage')}"
                  style="marginLeft: 16px"
                />
              </a-col>
            </a-row>
          </a-form-model-item>

          <a-form-model-item :label="$t('PoolSettingPage.last_call_percentage')" prop="last_call_percentage">
            <a-row>
              <a-col :span="16">
                <a-slider v-model="oddsForm.last_call_percentage" :min="last_call_percentage.min" :max="last_call_percentage.max" :step="last_call_percentage.step" />
              </a-col>
              <a-col :span="8">
                <a-input-number
                  :value="oddsForm.last_call_percentage"
                  :min="last_call_percentage.min"
                  :max="last_call_percentage.max"
                  :step="last_call_percentage.step"
                  :formatter="value => `${value}%`"
                  :parser="(value)=>parserValue(value, last_call_percentage.max)"
                  @change="(e)=>{handleChangeRate(e, 'last_call_percentage')}"
                  style="marginLeft: 16px"
                />
              </a-col>
            </a-row>
          </a-form-model-item>

          <a-form-model-item :label="$t('PoolSettingPage.close_bet_percentage')" prop="close_bet_percentage">
            <a-row>
              <a-col :span="16">
                <a-slider v-model="oddsForm.close_bet_percentage" :min="close_bet_percentage.min" :max="close_bet_percentage.max" :step="close_bet_percentage.step" />
              </a-col>
              <a-col :span="8">
                <a-input-number
                  :value="oddsForm.close_bet_percentage"
                  :min="close_bet_percentage.min"
                  :max="close_bet_percentage.max"
                  :step="close_bet_percentage.step"
                  :formatter="value => `${value}%`"
                  :parser="(value)=>parserValue(value, close_bet_percentage.max)"
                  @change="(e)=>{handleChangeRate(e, 'close_bet_percentage')}"
                  style="marginLeft: 16px"
                />
              </a-col>
            </a-row>
          </a-form-model-item>

        </a-col>
      </a-row>
      <a-form-model-item>
        <a-button type="primary" @click="oddsFormSubmit()" :loading="submitLoading" block>
          {{ oddsForm.id ? $t('global.action.update') : $t('global.action.create') }}
        </a-button>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import { createMalayOdds, updateMalayOdds } from '@/../api';

export default {
  props:{
    settingFormData:{
      type: Object,
      default: {}
    },
    visible: {
      type: Boolean,
      default: false
    },
    fetchData: {
      type: Function,
      default: ()=>{}
    },
    closeModal: {
      type: Function,
      default: ()=>{}
    }
  },
  data() {
    const checkName = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('global.message.fill_input', {value: this.$t('global.common.name')})));
      } else {
        callback();
      }
    };
    const checkRate = (rule, value, callback) => {
      if (Number(value) > this.rake_percentage.max) {
        callback(new Error(this.$t('global.common.max', {value: Number(this.rake_percentage.max).toFixed(2)})));
      } else {
        callback();
      }
    };
    const checkCloseBetPercent = (rule, value, callback) => {
      if (Number(value) <= this.oddsForm.last_call_percentage) {
        callback(new Error(this.$t('global.message.A_greater_tham_B', {value_a: this.$t('PoolSettingPage.close_bet_percentage'), value_b: this.$t('PoolSettingPage.last_call_percentage')})));
      } else {
        callback();
      }
    };
    const checkPerRoundRiskThreshold = (rule, value, callback) => {
      if (Number(value) < this.oddsForm.per_bet_risk_threshold) {
        callback(new Error(this.$t('global.message.A_greater_tham_B', {
          value_a: `${this.$t('PoolSettingPage.per_round')} ${this.$t('PoolSettingPage.risk_threshold')}`, 
          value_b: `${this.$t('PoolSettingPage.per_bet')} ${this.$t('PoolSettingPage.risk_threshold')}`, 
        })));
      } else {
        callback();
      }
    };
    return {
      rake_percentage: {
        max: 15,
        min: 0,
        step: 0.01,
      },
      last_call_percentage:{
        max: 100,
        min: 0,
        step: 1,
      },
      close_bet_percentage:{
        max: 100,
        min: 0,
        step: 1,
      },
      oddsForm: {
        id: null,
        name: '',
        duration_second: 1,
        rake_percentage: 0,
        per_round_risk_threshold: 0,
        per_bet_risk_threshold: 0,
        last_call_percentage: 0,
        close_bet_percentage: 0,
      },
      rules: {
        name: [{required: true ,validator: checkName, trigger: 'submit' }],
        rake_percentage: [{ validator:checkRate, trigger: 'submit' }],
        close_bet_percentage: [{ validator:checkCloseBetPercent, trigger: 'submit' }],
        per_round_risk_threshold: [{ validator:checkPerRoundRiskThreshold, trigger: 'submit' }],
      },
      submitLoading: false,
    };
  },
  async mounted() {},
  computed: {},
  watch: {
    settingFormData: {
      handler(newValue){
        this.oddsForm.id = newValue.id;
        this.oddsForm.name = newValue.name;
        this.oddsForm.duration_second = newValue.duration_second;
        this.oddsForm.rake_percentage = parseFloat(newValue.rake_percentage);
        this.oddsForm.per_round_risk_threshold = parseInt(newValue.per_round_risk_threshold);
        this.oddsForm.per_bet_risk_threshold = parseInt(newValue.per_bet_risk_threshold);
        this.oddsForm.last_call_percentage = newValue.last_call_percentage;
        this.oddsForm.close_bet_percentage = newValue.close_bet_percentage;
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    showModal() {
      this.visible = true;
    },
    checkInput(e, index, defaultValue, maxValue){
      const value = Number(e.target.value);
      if(value){
        if(Number.isInteger(value)){
          if( typeof(maxValue) === 'number' && value > maxValue){
            this.oddsForm[index] = maxValue;
          }else{
            this.oddsForm[index] = value < defaultValue ? defaultValue : value;
          }
        }else{
          this.oddsForm[index] = Math.floor(value);
        }
      }
    },
    checkBlur(e, index, defaultValue){
      const value = Number(e.target.value);
      if(!value){
        this.oddsForm[index] = defaultValue;
      }
    },
    parserValue(value, max) {
      const replacePercentValue = value.replace('%', '');
      const newValue = /[^\d.]/g.test(replacePercentValue) ? replacePercentValue.replace(/[^\d.]/g,'') : replacePercentValue ;
      const numberValue = newValue.split('.');
      const integerValue = numberValue[0];
      const decimalValue = numberValue[1];
      if(decimalValue && decimalValue.length > 3){
        return `${integerValue}.${decimalValue.substring(0, 3)}`;
      }else if(Number(newValue) >= max){
        return String(max);
      }else {
        return newValue;
      }
    },
    handleChangeRate(value, index){
      const newValue = String(value).length > 5 ? Number(String(value).slice(0,5)) : Number(value);
      this.oddsForm[index] = newValue;
    },
    resetForm(){
      this.$refs.oddsForm.resetFields();
    },
    oddsFormSubmit() {
      if(this.submitLoading) return;
      this.$refs.oddsForm.validate((valid) => {
        if (valid) {
          this.submitLoading = true;
          // this.$message.error(this.$t('global.message.failed_to_create', {value: this.$t('global.common.setting')}));
          let hasError = false;
          const {id, ...form} = this.oddsForm;
          const malayOddsAction = id ? updateMalayOdds : createMalayOdds;
          malayOddsAction({ id , form }).then(({data})=>{
            const {code, message} = data;
            if(code === 'ERROR'){
              throw new Error(message)
            }else{
              const i18n = id ? 'global.message.success_to_edit' : 'global.message.success_to_create'
              this.$message.success(this.$t(i18n, {value: this.$t('global.common.setting')}));
            }
          }).catch((err)=>{
            const errMsg = err.response ? err.response.data.message : err.message;
            this.$message.error(errMsg);
            hasError = true;
          }).finally(()=>{
            this.submitLoading = false;
            this.closeModal();
            this.$refs.oddsForm.resetFields();
            if(!hasError){
              this.fetchData();
            }
          })
        }
      });
    },
  },
};
</script>
