<template>
  <div class="frame merchants-ip-page">
    <div class="page-breadcrumb" v-if="account">
      <a-breadcrumb>
        <a-breadcrumb-item><router-link :to="{name: 'Merchants'}">{{ $t('global.menu.Merchants') }}</router-link></a-breadcrumb-item>
        <a-breadcrumb-item>{{ account }}</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <a-page-header
      :title="$t('global.menu.MerchantIpList')"
      @back="() => $router.go(-1)"
    >
      <template slot="extra">
          <a-button class="add-btn" icon="plus" @click="createIp" v-if="actionBtn">
            {{ $t('MerchantIpPage.create_ip') }}
          </a-button>
      </template>
    </a-page-header>
    <div class="page-content">
      <div class="search-bar">
        <a-form-model ref="searchForm" :model="searchForm">
          <div class="search-group">
            <a-form-model-item ref="ip_search" :label="$t('global.common.ip')" prop="ip_search">
              <a-input
                v-model="searchForm.ip_search"
              />
            </a-form-model-item>
            <a-form-model-item ref="type_search" :label="$t('global.common.type')" prop="type_search">
              <a-select v-model="searchForm.type_search">
                <a-select-option v-for="listItem in typeList" :value="listItem.value" :key="listItem.value">
                  {{ listItem.label }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item ref="master_search" :label="$t('global.common.master')" prop="master_search">
              <a-select
                  v-model="searchForm.master_search"
                  :disabled="isMasterOptionDisabled"
                  show-search
                  :filter-option="filterOption"
              >
                <a-select-option value="">--</a-select-option>
                <a-select-option v-for="(master, i) in masterList" :value="master.id" :key="i">
                  {{ master.account }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item ref="agent_search" :label="$t('global.common.agent')" prop="agent_search">
              <a-select
                v-model="searchForm.agent_search"
                :disabled="isAgentOptionDisabled"
                show-search
                :filter-option="filterOption"
              >
                <a-select-option value="">--</a-select-option>
                <a-select-option v-for="(agent, i) in agentList" :value="agent.id" :key="i">
                    {{ agent.account }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item ref="description" :label="$t('global.common.description')" prop="description">
              <a-input
                v-model="searchForm.description"
              />
            </a-form-model-item>
          </div>
          <div class="search-group">
            <div class="search-btns">
              <a-button type="primary" icon="search" :loading="loading" @click="onSearch">{{ $t('global.action.search') }}</a-button>
              <a-button :disabled="loading" @click="resetForm">{{ $t('global.action.reset') }}</a-button>
            </div>
          </div>
        </a-form-model>
      </div>
      <div class="ant-card">
        <div class="ant-card-body">
          <a-table
            bordered
            size="small"
            :columns="columns"
            :row-key="data => data.id"
            :data-source="dataSource"
            :pagination="pagination"
            :loading="loading"
            @change="handleTableChange"
          >
            <template v-for="column in columns">
              <template :slot="column.slots.title">{{ $t(`global.common.${column.slots.title}`) }}</template>
            </template>
            <template slot="formatTimes" slot-scope="times">
              <div class="table-time">{{ times | formatTimes }}</div>
            </template>
            <template slot="formatString" slot-scope="string">
              <div>{{ string | formatString }}</div>
            </template>
            <template slot="action" slot-scope="id, row" v-if="actionBtn">
              <a-button type="primary" size="small" @click="editIp(row)">{{ $t('global.action.edit') }}</a-button>
              <a-button icon="delete" size="small" @click="deleteIp(row)"></a-button>
            </template>
          </a-table>
        </div>
      </div>
    </div>
    <IpForm
      :closeModal="closeModal"
      :formShow="formShow"
      :ipFormData="ipFormData"
      :formType="formType"
      :fetchData="fetchData"
      :pagination="pagination"
      :masterList="masterList"
      :agentList="agentList"
    />
  </div>
</template>

<script>
import { getManageIpList, deleteIpList, getMerchantRole, getMerchantCurrency, getMasterByMerchant, getAgentByMerchant} from '@/../api';
import IpForm from '@/../src/components/merchants/ip_form';
import { filterDuplicateData } from '@/../tools/common';

export default {
  components: {
    IpForm
  },
  data() {
    return {
      currencyList: [],
      currency: '',
      masterList: [],
      agentList: [],
      dataSource: [],
      columns: [
        {
          slots: { title: 'ip' },
          dataIndex: 'ip',
        },
        {
          slots: { title: 'type' },
          dataIndex: 'type',
        },
        {
          slots: { title: 'master' },
          dataIndex: 'master',
        },
        {
          slots: { title: 'agent' },
          dataIndex: 'agent',
        },
        {
          slots: { title: 'description' },
          dataIndex: 'description',
          scopedSlots: { customRender: 'formatString' },
          width: '100%'
        },
        {
          slots: { title: 'operator' },
          dataIndex: 'operator_account',
          scopedSlots: { customRender: 'formatString' },
        },
        {
          slots: { title: 'created_at' },
          dataIndex: 'created_at',
          scopedSlots: { customRender: 'formatTimes' },
        },
        {
          slots: { title: 'action' },
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
          width: 100
        },
      ],
      pagination: {
        pageSize: 10,
        current: 1,
        total: 0,
        showTotal: (total) => `Total ${total} items`, // 显示总数
      },
      loading: false,
      formShow: false,
      formType: '',
      ipFormData: {
        id: null,
        ip:'',
        description: '',
        type: '',
        master: '',
        agent: '',
      },
      account: '',
      actionBtn: false,
      searchForm: {
        ip_search: '',
        description: '',
        type_search: '',
        master_search: '',
        agent_search: '',
      },
      typeList: [{
        value: 'bo',
        label: 'BO'
      }, {
        value: 'api',
        label: 'API'
      }, {
        value: 'game',
        label: 'GAME'
      }, {
        value: 'block_game',
        label: 'BLOCK GAME'
      }, {
        value: 'block_provider',
        label: 'BLOCK PROVIDER'
      },{
        value: 'office',
        label: 'OFFICE'
      }]
    };
  },
  created(){
  },
  mounted() {
    getMerchantRole()
        .then((data)=>{
          this.actionBtn = data.data.role.MerchantIpList ? data.data.role.MerchantIpList.edit : false;
        })
    
    // this.fetchCurrencyList();
    this.fetchMasterByMerchant();
    this.fetchAgentByMerchant();
  },
  computed:{
    isAgentOptionDisabled(){
      return typeof(this.searchForm.master_search) == 'number' || this.searchForm.type_search == 'office'
    },
    isMasterOptionDisabled(){
      return typeof(this.searchForm.agent_search) == 'number' || this.searchForm.type_search == 'office'
    },
    searchFormType(){
      return this.searchForm.type_search;
    }
  },
  watch:{
    searchFormType(){
      this.searchForm.master_search = '';
      this.searchForm.agent_search = '';
    }
  },
  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    fetchData(params = {}) {
      this.loading = true;
      getManageIpList({
        ...params,
        ...this.searchForm
      }).then(({ data }) => {
        const pagination = { ...this.pagination };
        pagination.total = data.total;
        this.dataSource = data.ip_list;
        this.pagination = pagination;
      }).finally(()=>{
        this.loading = false;
      });
    },
    onSearch() {
      if(this.loading) return;
      const { pageSize } = this.pagination;
      this.pagination.current = 1;

      this.$refs.searchForm.validate(valid => {
        if (valid) {
          this.fetchData({       
            results: pageSize,
            page: 1
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm() {
      if(this.loading) return;
      this.$refs.searchForm.resetFields();
    },
    handleTableChange(pagination, filters, sorter) {
      const currentPage = this.pagination.pageSize !== pagination.pageSize ? 1 : pagination.current;
      this.pagination.current = currentPage;
      this.pagination.pageSize = pagination.pageSize;

      this.fetchData({
        results: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
      });
    },
    closeModal(){
      this.formShow = false;
    },
    createIp(){
      this.ipFormData.id = null;
      this.ipFormData.ip = '';
      this.ipFormData.description = '';
      this.ipFormData.type = '';
      this.ipFormData.master = '';
      this.ipFormData.agent = '';
      this.formShow = true;
      this.formType = 'create';
    },
    editIp(row) {
      this.ipFormData.id = row.id;
      this.ipFormData.ip = row.ip;
      this.ipFormData.description = row.description;
      this.ipFormData.type = row.type;
      this.ipFormData.master = row.master;
      this.ipFormData.agent = row.agent;
      this.formShow = true;
      this.formType = 'edit'
    },
    deleteIp(row) {
      const { id } = row;
      const self = this;
      const { pageSize } = this.pagination;
      this.$confirm({
        title: self.$t('global.message.delete', {value: row.ip}),
        okText: self.$t('global.action.yes'),
        cancelText: self.$t('global.action.no'),
        onOk() {
          deleteIpList({ip_record_id: id})
            .then((data) =>{
              self.$message.success(self.$t('global.message.success_to_delete', {value: row.ip}));
              self.fetchData({
                results: pageSize,
                page: 1
              });
            })
        },
        onCancel() {},
      });
    },
    fetchCurrencyList() {
      getMerchantCurrency().then((res)=>{
        const {currencies} = res.data;
        this.currencyList = currencies;
        if(currencies.length !== 0 && this.currencyRequired){
          let default_currency = this.currencyList.find(element => element === 'PHP');
          // 預設期望是 PHP 找不到的話隨便給第一個
          this.currency = default_currency ? default_currency : this.currencyList[0];
        }

        this.currencyisLoaded = true;
        if(this.currencyLoading){
          this.currencyLoading = false;
        }
      });
    },
    fetchMasterByMerchant() {
      getMasterByMerchant().then((data)=>{
        const masterList = filterDuplicateData(data.data.master,'id');
        this.masterList = masterList;
        if(masterList.length !== 0 && this.masterRequired){
          this.master = masterList[0].id;
        }
      })
    },
    fetchAgentByMerchant() {
      getAgentByMerchant().then((data)=>{
        this.agentList = filterDuplicateData(data.data.agent,'id');
      })
    },
  },
}
</script>
