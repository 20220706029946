<template>
  <MerchantAdmin :roles='roles'
                 :data='data'
                 :pagination='pagination'
                 :loading='loading'
                 :fetchData='fetchData'
                 :id='$route.params.id'
                 :account='account'
  />
</template>

<script>
import { getAdminMerchantAdmin, getMerchantAllRoles } from '@/../api';
import MerchantAdmin from '@/../src/components/merchants/merchant_admin';

export default {
  components: {
    MerchantAdmin
  },
  data() {
    return {
      roles: [],
      data: [],
      pagination: {
        pageSize: 10,
        current: 1,
        total: 0
      },
      loading: false,
      account: 'account',
      id: 0,
    };
  },
  created(){
  },
  mounted(){
    this.id = this.$route.params.id;
    this.fetchData();
    getMerchantAllRoles()
      .then((data)=>{
        this.roles = data.data.role;
      })
  },
  methods: {
    fetchData(params = {}) {
      this.loading = true;
      getAdminMerchantAdmin({
          id: this.$route.params.id,
          form:{
            results: this.pagination.pageSize,
            page: this.pagination.current,
            ...params,
          }
        }).then(({ data }) => {
          const pagination = { ...this.pagination };
          // Read total count from server
          // pagination.total = data.totalCount;
          pagination.total = data.total;
          this.data = data.sub_merchant;
          this.pagination = pagination;
        }).finally(()=>{
          this.loading = false;
        });
    },
  },
}
</script>
