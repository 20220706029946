<template>
  <div class="frame pool-setting-page">
    <a-page-header
      :title="$t('global.menu.PoolSetting')"
      @back="() => $router.go(-1)"
    >
    </a-page-header>
    <div class="page-content">
      <div class="search-bar">
        <a-form-model ref="searchForm" :model="form">
          <div class="search-group">
            <!-- <a-form-model-item ref="agent_account" :label="$t('AgentsPage.master_account')" prop="agent_account">
              <a-input
                v-model.trim="form.agent_account"
              />
            </a-form-model-item> -->
            <Currency :inputCurrency.sync="form.currency"
                      :inputMaster.sync="form.link_master_id"
                      :showCurrency="false"
                      :showLinkMaster="true"
                      :showMaster="false"
                      :showAgent="false"
                      :currencyRequired="true"/>

            <a-form-model-item ref="arena_no" :label="$t('global.common.arena')" prop="arena_no">
              <a-select
                  show-search
                  placeholder=""
                  :filter-option="filterOption"
                  v-model="form.arena_no"
              >
                <a-select-option v-for="(arena, i) in arenaList" :value="arena.id" :key="i">
                  {{ arena.arena_no }}({{ arena.id }})
                </a-select-option>
              </a-select>
            </a-form-model-item>
                      
            <a-form-model-item ref="odds_type" :label="$t('global.common.odds_type')" prop="odds_type">
              <a-select v-model="form.odds_type">
                <a-select-option v-for="odd in oddsTypeList" :value="odd.value" :key="odd.value">
                  {{ $t(`global.common.odds_type_list.${odd.label}`) }}
                </a-select-option>
              </a-select>
            </a-form-model-item>

            <a-form-model-item ref="status" :label="$t('global.common.status')" prop="status">
              <a-select v-model="form.status">
                <a-select-option v-for="status in statusList" :value="status.value" :key="status.value">
                  {{ $t(`global.common.active_status_list.${status.label}`) }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </div>
          <div class="search-group">
            <div class="search-btns">
              <a-button type="primary" icon="search" :loading="loading" @click="onSearch">{{ $t('global.action.search') }}</a-button>
              <a-button :disabled="loading" @click="resetForm">{{ $t('global.action.reset') }}</a-button>
            </div>
          </div>
        </a-form-model>
      </div>
      <div class="ant-card">
        <div class="ant-card-body">
          <div class="table-control">
            <a-popover trigger="click" placement="bottomRight">
              <template slot="content">
                <div class="table-filter-content">
                  <a-checkbox class="table-filter-check"
                    v-for="(item, index) in columns"
                    :key="item.dataIndex"
                    :defaultChecked="item.filterType"
                    :disabled="item.disabledFilter"
                    @click="columnsChange(index, item)"
                    :value="item.dataIndex">
                    {{ item.singleI18n ? $t(`PoolSettingPage.${item.slots.title}`) :$t(`global.common.${item.slots.title}`) }}
                  </a-checkbox>
                </div>
              </template>
              <a-button icon="filter"></a-button>
            </a-popover>
          </div>
          <a-table
            bordered
            size="small"
            :columns="columns.filter( item => item.filterType || item.filterType === undefined)"
            :data-source="pool_profile"
            :pagination="pagination"
            :loading="loading"
            :rowKey="(r, i)=> i"
            @change="handleTableChange"
          >
            <template v-for="column in columns">
              <template :slot="column.slots.title">{{ column.singleI18n ? $t(`PoolSettingPage.${column.slots.title}`) : $t(`global.common.${column.slots.title}`) }}</template>
            </template>
            <template slot="link_master" slot-scope="link_master, row">
              {{ row.name }}({{ row.master_account }})
            </template>
            <template slot="account" slot-scope="account, row">
              {{ account }} <span class="table-agent-level" :data-level="row.level" v-if="row.level === 'master'">{{ row.level }}</span>
            </template>
            <template slot="risk_odd" slot-scope="risk_odd, row">
              <span v-if="row.odds_type === 'rake'">
                {{ (risk_odd * 1).toFixed(2) }}
              </span>
              <span v-else>-</span>
            </template>
            <!-- <template slot="odds_type" slot-scope="odds_type">
              {{ odds_type }}
            </template> -->
            <template slot="active" slot-scope="active">
              <div class="table-status-item">
                <span class="status-icon" :data-status="active ? 'active' : 'close'"></span>
                <span class="status-text">{{ $t(`global.common.active_status_list.${active ? 'active' : 'close'}`) }}</span>
              </div>
            </template>

            <template slot="current_schedule" slot-scope="current_schedule, row">
              <a-button 
                v-if="row.odds_type === 'malay' && !isNaN(row.merchant_provider_id) && current_schedule && poolPremissions.viewSchedule"
                type="primary" 
                size="small" 
                @click="setDialogData({
                  id: current_schedule.id, 
                  editable: false, 
                  merchant_provider_id: row.merchant_provider_id, 
                  schedule_info: current_schedule.schedule_info,
                  effective_time: current_schedule.effective_time,
                  link_master: `${row.name}(${row.master_account})`,
                  current_schedule: [] })">
                {{ $t('PoolSettingPage.view') }}
              </a-button>
              <span v-else>-</span>
            </template>

            <template slot="new_schedule" slot-scope="new_schedule, row">
              <div v-if="row.odds_type === 'malay' && !isNaN(row.merchant_provider_id)">
                <p 
                  v-if="new_schedule"
                  class="new_schedule_time">{{ new_schedule.effective_time | formatTimesToUTC8 }}</p>
                <a-button 
                  v-if="poolPremissions.edit"
                  :class="[new_schedule && 'new_schedule_btn']"
                  type="primary" 
                  size="small" 
                  @click="setDialogData({
                    id: new_schedule ? new_schedule.id : null, 
                    editable: true, 
                    merchant_provider_id: row.merchant_provider_id, 
                    schedule_info: new_schedule ? new_schedule.schedule_info : [],
                    effective_time: new_schedule  ? new_schedule.effective_time : null,
                    link_master: `${row.name}(${row.master_account})`,
                    current_schedule: row.current_schedule })">
                  {{ new_schedule ? $t('global.action.edit') : $t('global.action.create') }}
                </a-button>
                <a-button 
                  v-if="new_schedule && poolPremissions.delete" 
                  size="small" 
                  icon="delete" 
                  class="new_schedule_btn"
                  @click="deleteSchedule(new_schedule.id, row.name, row.master_account)">
                </a-button>
                <span v-if="(!new_schedule && !poolPremissions.delete) || (!new_schedule && !poolPremissions.edit)">-</span>
              </div>
              <span v-else>-</span>
            </template>

            <template slot="log" slot-scope="id, row">
              <a-button 
                v-if="row.odds_type === 'malay' && poolPremissions.viewLog" 
                type="primary" 
                size="small" 
                @click="openChangeLogModal(row)">{{ $t('global.common.log') }}</a-button>
              <span v-else>-</span>
            </template>

            <!-- <template slot="formatTimes" slot-scope="times">
              <div class="table-time">{{ times | formatTimes }}</div>
            </template>
            <template slot="formatString" slot-scope="string">
              <div>{{ string | formatString }}</div>
            </template> -->
          </a-table>

          <PoolSettingDialog 
            :show="dialogVisible"
            :closeModal="closeDialog"
            :dialogData="dialogData"
            :fetchData="fetch"
            :oddsProfile="odds_profile"
          />

          <PoolSettingChangeLogDialog 
            :visible="changeLogDialog.visible"
            :id="changeLogDialog.id"
            :link_master="changeLogDialog.link_master"
            :clearLog="clearChangeLog"
            :closeLogModal="closeChangeLogModal"
            :oddsProfile="odds_profile"
            :poolPremissions="poolPremissions"
          />

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { 
  getArenaList,
  getPagePermissions, 
  getMerchantRole,
  getPoolList,
  getMalayOddsList,
  deletePoolSetting
} from '@/../api';
import Currency from '@/../src/components/currency_selection';
import PoolSettingDialog from '@/../src/components/pool/pool_setting_dialog';
import PoolSettingChangeLogDialog from '@/../src/components/pool/pool_setting_log_dialog';
import { filterDuplicateData } from '@/../tools/common';

const columns = [
  // {
  //   slots: { title: 'account' },
  //   dataIndex: 'master_account',
  //   scopedSlots: { customRender: 'account' },
  // },
  {
    slots: { title: 'link_master' },
    scopedSlots: { customRender: 'link_master' },
    // dataIndex: 'link_master_account',
    filterType: true,
    align: 'center',
  },
  {
    slots: { title: 'arena' },
    dataIndex: 'arena_no',
    align: 'center',
    filterType: true,
  },
  {
    slots: { title: 'risk_odd' },
    dataIndex: 'risk_odds',
    scopedSlots: { customRender: 'risk_odd' },
    align: 'center',
    filterType: true,
  },
  {
    slots: { title: 'odds_type' },
    dataIndex: 'odds_type',
    align: 'center',
    filterType: true,
  },
  {
    slots: { title: 'status' },
    dataIndex: 'enable',
    scopedSlots: { customRender: 'active' },
    align: 'center',
    filterType: true,
  },
  {
    slots: { title: 'current_schedule' },
    dataIndex: 'current_schedule',
    scopedSlots: { customRender: 'current_schedule' },
    align: 'center',
    filterType: true,
    singleI18n: true,
  },
  {
    slots: { title: 'new_schedule' },
    dataIndex: 'new_schedule',
    scopedSlots: { customRender: 'new_schedule' },
    align: 'center',
    filterType: true,
    singleI18n: true,
  },
  {
    slots: { title: 'log' },
    dataIndex: 'log',
    scopedSlots: { customRender: 'log'},
    align: 'center',
    // fixed: 'right',
    width: 70,
    filterType: true,
  },
];

export default {
  components: {
    Currency,
    PoolSettingDialog,
    PoolSettingChangeLogDialog
  },
  data() {
    return {
      data: [],
      updatePermission: false,
      pagination: {
        current: 1,
        pageSize: 10, // 默认每页显示数量
        showSizeChanger: true, // 显示可改变每页数量
        pageSizeOptions: ["10", "20", "30", "40"], // 每页数量选项
        showTotal: (total) => `Total ${total} items`, // 显示总数
        showSizeChange: (current, pageSize) => (this.pageSize = pageSize), // 改变每页数量时更新显示
        total: 0
      },
      columns,
      expand: false,
      loading: false,
      form: {
        currency: '',
        // agent_account: this.$route.query.agent,
        status: 1, //option: all, active, close
        link_master_id: null, //type: Number(id)
        odds_type: '', //option: rake, malay
        arena_no: '' //type: Number(id)
      },
      statusList: [
        {
          label: 'all',
          value: ''
        },
        {
          label: 'active',
          value: 1
        },
        {
          label: 'close',
          value: 0
        }
      ],
      oddsTypeList: [
        {
          label: 'rake',
          value: 0
        },
        {
          label: 'malay',
          value: 1
        }
      ],
      rules: {},
      arenaList: [],
      pool_profile: [],
      odds_profile: [],
      poolPremissions: {
        read: false,
        edit: false,
        viewSchedule: false,
        viewLog: false,
        delete: false,
      },
      dialogVisible: false,
      dialogData: {
        id: null,
        editable: false,
        merchant_provider_id: null,
        effective_time: null,
        schedule_info: [],
        link_master: '',
        current_schedule: []
      },
      changeLogDialog:{
        visible: false,
        id: null,
        link_master: ''
      }
    };
  },
  async mounted(){
    // this.onSearch();

    await getMerchantRole().then((res) => {
      this.poolPremissions.edit = res.data.role.PoolSetting?.edit ?? false;
      this.poolPremissions.delete = res.data.role.PoolSetting?.delete ?? false;
      this.poolPremissions.viewLog = res.data.role.PoolSetting?.view_log ?? false;
      this.poolPremissions.viewSchedule = res.data.role.PoolSetting?.view_schedule ?? false;
    });

    await getMerchantRole().then((data) => {
      this.displayRate = data.data.role.AgentSource?.display_rate;
    });

    await getArenaList().then((res)=>{
      this.arenaList = filterDuplicateData(res.data.arena,'id');
    })

  },
  watch: {},
  methods: {
    openDialog(){
      this.dialogVisible = true;
    },
    closeDialog(){
      this.dialogVisible = false;
    },
    columnsChange(index, item) {
      item.filterType = !item.filterType;
    },
    handleTableChange(pagination, filters, sorter) {
      const currentPage = this.pagination.pageSize !== pagination.pageSize ? 1 : pagination.current;
      this.pagination.current = currentPage;
      this.pagination.pageSize = pagination.pageSize;
      this.fetch({
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
      });
    },
    fetch(params = {}) {
      this.loading = true;
      const { link_master_id, odds_type, arena_no, status } = this.form;
      const { current, pageSize } = this.pagination;

      getPoolList({
        link_master_id,
        odds_type,
        arena_no,
        status,
        results: pageSize,
        page: current,
        ...params,
      }).then(({ data }) => {
        const pagination = { ...this.pagination };
        pagination.total = data.pool_profile.length;
        this.pool_profile = data.pool_profile;
        this.pagination = pagination;
      }).finally(()=>{
        this.loading = false;
      });
    },
    onSearch() {
      if(this.loading) return;
      this.$refs.searchForm.validate(valid => {
        if (valid) {
          getMalayOddsList({ form:{results: 1000, page: 1  }}).then(({ data }) => {
            this.odds_profile = data.odds_profile;
            this.fetch();
          }).catch((err)=>{
            this.$message.error(err.response.data.message, 3);
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm() {
      if(this.loading) return;
      this.$refs.searchForm.resetFields();
      this.form.link_master_id = null;
      // this.fetch({});
    },
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    setDialogData({id, editable, merchant_provider_id, schedule_info, effective_time, link_master, current_schedule}){
      this.dialogData.id = id;
      this.dialogData.editable = editable;
      this.dialogData.merchant_provider_id = merchant_provider_id;
      this.dialogData.schedule_info = schedule_info;
      this.dialogData.effective_time = effective_time;
      this.dialogData.link_master = link_master
      this.dialogData.current_schedule = current_schedule;
      this.openDialog();
    },
    deleteSchedule(id, name, account){
      const currentScheduleText = `${this.$t('PoolSettingPage.new_schedule')} - ${name}(${account})`;
      const self = this;
      this.$confirm({
        title: self.$t('global.message.delete', {value: currentScheduleText}),
        okText: self.$t('global.action.yes'),
        cancelText: self.$t('global.action.no'),
        onOk() {
          deletePoolSetting({id})
            .then(() =>{
              self.$message.success(self.$t('global.message.success_to_delete', {value: currentScheduleText}));
              self.fetch();
            }).catch((err)=>{
              this.$message.error(err.response.data.message, 3);
            })
        },
        onCancel() {},
      });
    },
    openChangeLogModal(row){
      this.changeLogDialog.visible = true;
      this.changeLogDialog.id = parseInt(row.merchant_provider_id);
      this.changeLogDialog.link_master = `${row.name}(${row.master_account})`;
    },
    closeChangeLogModal(){
      this.changeLogDialog.visible = false;
    },
    clearChangeLog(){
      this.changeLogDialog.id = null;
    }
  },
};
</script>
