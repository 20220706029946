<template>
  <a-radio-group v-model="periodValue" button-style="solid" class="search-times">
    <a-radio-button v-for="(time_key) in periods" :value="time_key" :key="time_key">{{ $t(`global.times.${time_key}`) }}
    </a-radio-button>
    <a-range-picker
      :value="formatDate"
      :showTime="preciseTime === 'time'"
      :disabled="periodValue !== 'custom'"
      :disabledTime="disabledTime"
      :disabledDate="disabledDate"
      @blur="blurCalendar"
      @change="onChangeDate"
      @calendarChange="onChangeCalendar"
      :style="{width: (preciseTime === 'time' ? '380px' : '350px') }"
      v-if="preciseTimeValue !== 'month'"
    />
      <a-range-picker
      :disabled="periodValue !== 'custom'"
      :value="formatDate"
      format="YYYY-MM"
      :mode="mode"
      @panelChange="onChangeMonth"
      v-else
    />
    <a-tooltip>
      <template slot="title">
        {{ $t('global.times.tooltip') }}
      </template>
      <a-icon type="question-circle" class="question-circle" />
    </a-tooltip>
  </a-radio-group>
</template>
<script>
import { isEqual } from 'lodash';

export default {
  props: {
    input: Array,
    periodDefault: {
      type: String,
      default: 'today',
    },
    preciseTime:{
      type: String,
      default: 'date' // param: month, date, time
    },
    customPeriods: {
      type: Array,
      default: () => ['today', 'yesterday', 'last_week', 'this_week', 'last_month', 'this_month', 'custom'],
    },
    limitTimeSearch: {
      type: Boolean,
      default: true
    }    
  },
  data() {
    return {
      periodValue: '',
      // periods: ['unset','today', 'yesterday', 'last_week', 'this_week', 'last_month', 'this_month', 'custom'],
      periods: ['today', 'yesterday', 'last_week', 'this_week', 'last_month', 'this_month', 'custom'],
      timer: null,
      preciseTimeValue: '',
      mode: ['month', 'month'],
      limitDate: null
    };
  },
  mounted() {
    if(this.periodDefault !== this.periodValue) {
      this.periodValue = this.periodDefault;
    }

    if(this.preciseTime !== this.preciseTimeValue) {
      this.preciseTimeValue = this.preciseTime;
    }

    if(this.customPeriods !== []) {
      this.periods = this.customPeriods;
    }
  },
  watch: {
    periodValue(newValue, oldValue){
      let formatTime;
      if(this.timer){
        this.clearTimer();
      }
      switch (newValue) {
        case 'unset':
          this.$emit('update:input', []);
          this.$emit('update:periodDefault', 'unset');
          break;
        case 'today':
          switch(this.preciseTimeValue){
            case 'time':
              formatTime = [this.moment().format('YYYY-MM-DD 00:00:00'), this.moment().format('YYYY-MM-DD 23:59:59')];
              break;
            default:
              formatTime = [this.moment().format('YYYY-MM-DD'), this.moment().format('YYYY-MM-DD')];
              break;
          }
          this.$emit('update:input', formatTime);
          this.$emit('update:periodDefault', 'today');
          break;
        case 'yesterday':
          switch(this.preciseTimeValue){
            case 'time':
              formatTime = [this.moment().add(-1, 'day').format('YYYY-MM-DD 00:00:00'), this.moment().add(-1, 'day').format('YYYY-MM-DD 23:59:59')];
              break;
            default:
              formatTime = [this.moment().add(-1, 'day').format('YYYY-MM-DD'), this.moment().add(-1, 'day').format('YYYY-MM-DD')];
              break;
          }
          this.$emit('update:input', formatTime);
          this.$emit('update:periodDefault', 'yesterday');
          break;
        case 'last_week':
          switch(this.preciseTimeValue){
            case 'time':
              formatTime = [this.moment().add(-1, 'week').startOf('isoWeek').format('YYYY-MM-DD 00:00:00'), this.moment().add(-1, 'week').endOf('isoWeek').format('YYYY-MM-DD 23:59:59')];
              break;
            default:
              formatTime = [this.moment().add(-1, 'week').startOf('isoWeek').format('YYYY-MM-DD'), this.moment().add(-1, 'week').endOf('isoWeek').format('YYYY-MM-DD')];
              break;
          }
          this.$emit('update:input', formatTime);
          this.$emit('update:periodDefault', 'last_week');
          break;
        case 'this_week':
          switch(this.preciseTimeValue){
            case 'time':
              formatTime = [this.moment().startOf('isoWeek').format('YYYY-MM-DD 00:00:00'), this.moment().endOf('isoWeek').format('YYYY-MM-DD 23:59:59')];
              break;
            default:
              formatTime = [this.moment().startOf('isoWeek').format('YYYY-MM-DD'), this.moment().endOf('isoWeek').format('YYYY-MM-DD')];
              break;
          }
          this.$emit('update:input', formatTime);
          this.$emit('update:periodDefault', 'this_week');
          break;
        case 'last_month':
          switch(this.preciseTimeValue){
            case 'month':
              formatTime = [this.moment().add(-1, 'month').startOf('month').format('YYYY-MM-DD'), this.moment().add(-1, 'month').endOf('month').format('YYYY-MM-DD')];
              break;
            case 'time':
              formatTime = [this.moment().add(-1, 'month').startOf('month').format('YYYY-MM-DD 00:00:00'), this.moment().add(-1, 'month').endOf('month').format('YYYY-MM-DD 23:59:59')];
              break;
            default:
              formatTime = [this.moment().add(-1, 'month').startOf('month').format('YYYY-MM-DD'), this.moment().add(-1, 'month').endOf('month').format('YYYY-MM-DD')];
              break;
          }
          this.$emit('update:input', formatTime);
          this.$emit('update:periodDefault', 'last_month');
          break;
        case 'this_month':
          switch(this.preciseTimeValue){
            case 'month':
              formatTime = [this.moment().startOf('month').format('YYYY-MM-DD'), this.moment().endOf('month').format('YYYY-MM-DD')];
              break;
            case 'time':
              formatTime = [this.moment().startOf('month').format('YYYY-MM-DD 00:00:00'), this.moment().endOf('month').format('YYYY-MM-DD 23:59:59')];
              break;
            default:
              formatTime = [this.moment().startOf('month').format('YYYY-MM-DD'), this.moment().endOf('month').format('YYYY-MM-DD')];
              break;
          }
          this.$emit('update:input', formatTime);
          this.$emit('update:periodDefault', 'this_month');
          break;
        default:
          this.$emit('update:periodDefault', 'custom');
          return;
      }
      this.timer = setInterval(() => { this.setNewTime() }, 1000);
    },
    preciseTimeValue(newValue, oldValue){
      if (newValue === oldValue) return;

      let formatTime;
      if(this.timer){
        this.clearTimer();
      }
      switch (this.periodValue) {
        case 'unset':
          this.$emit('update:input', []);
          this.$emit('update:periodDefault', 'unset');
          break;
        case 'today':
          switch(newValue){
            case 'time':
              formatTime = [this.moment().format('YYYY-MM-DD 00:00:00'), this.moment().format('YYYY-MM-DD 23:59:59')];
              break;
            default:
              formatTime = [this.moment().format('YYYY-MM-DD'), this.moment().format('YYYY-MM-DD')];
              break;
          }
          this.$emit('update:input', formatTime);
          this.$emit('update:periodDefault', 'today');
          break;
        case 'yesterday':
          switch(newValue){
            case 'time':
              formatTime = [this.moment().add(-1, 'day').format('YYYY-MM-DD 00:00:00'), this.moment().add(-1, 'day').format('YYYY-MM-DD 23:59:59')];
              break;
            default:
              formatTime = [this.moment().add(-1, 'day').format('YYYY-MM-DD'), this.moment().add(-1, 'day').format('YYYY-MM-DD')];
              break;
          }
          this.$emit('update:input', formatTime);
          this.$emit('update:periodDefault', 'yesterday');
          break;
        case 'last_week':
          switch(newValue){
            case 'time':
              formatTime = [this.moment().add(-1, 'week').startOf('isoWeek').format('YYYY-MM-DD 00:00:00'), this.moment().add(-1, 'week').endOf('isoWeek').format('YYYY-MM-DD 23:59:59')];
              break;
            default:
              formatTime = [this.moment().add(-1, 'week').startOf('isoWeek').format('YYYY-MM-DD'), this.moment().add(-1, 'week').endOf('isoWeek').format('YYYY-MM-DD')];
              break;
          }
          this.$emit('update:input', formatTime);
          this.$emit('update:periodDefault', 'last_week');
          break;
        case 'this_week':
          switch(newValue){
            case 'time':
              formatTime = [this.moment().startOf('isoWeek').format('YYYY-MM-DD 00:00:00'), this.moment().endOf('isoWeek').format('YYYY-MM-DD 23:59:59')];
              break;
            default:
              formatTime = [this.moment().startOf('isoWeek').format('YYYY-MM-DD'), this.moment().endOf('isoWeek').format('YYYY-MM-DD')];
              break;
          }
          this.$emit('update:input', formatTime);
          this.$emit('update:periodDefault', 'this_week');
          break;
        case 'last_month':
          switch(newValue){
            case 'month':
              formatTime = [this.moment().add(-1, 'month').startOf('month').format('YYYY-MM-DD'), this.moment().add(-1, 'month').endOf('month').format('YYYY-MM-DD')];
              break;
            case 'time':
              formatTime = [this.moment().add(-1, 'month').startOf('month').format('YYYY-MM-DD 00:00:00'), this.moment().add(-1, 'month').endOf('month').format('YYYY-MM-DD 23:59:59')];
              break;
            default:
              formatTime = [this.moment().add(-1, 'month').startOf('month').format('YYYY-MM-DD'), this.moment().add(-1, 'month').endOf('month').format('YYYY-MM-DD')];
              break;
          }
          this.$emit('update:input', formatTime);
          this.$emit('update:periodDefault', 'last_month');
          break;
        case 'this_month':
          switch(newValue){
            case 'month':
              formatTime = [this.moment().startOf('month').format('YYYY-MM-DD'), this.moment().endOf('month').format('YYYY-MM-DD')];
              break;
            case 'time':
              formatTime = [this.moment().startOf('month').format('YYYY-MM-DD 00:00:00'), this.moment().endOf('month').format('YYYY-MM-DD 23:59:59')];
              break;
            default:
              formatTime = [this.moment().startOf('month').format('YYYY-MM-DD'), this.moment().endOf('month').format('YYYY-MM-DD')];
              break;
          }
          this.$emit('update:input', formatTime);
          this.$emit('update:periodDefault', 'this_month');
          break;
        default:
          this.$emit('update:periodDefault', 'custom');
          return;
      }
      this.timer = setInterval(() => { this.setNewTime() }, 1000);
    },
    preciseTime(newValue, oldValue){
      if (newValue !== this.preciseTimeValue || newValue !== oldValue) {
        this.preciseTimeValue = newValue;
      }
    },
    periodDefault(newValue, oldValue) {
      if (newValue !== this.periodValue || newValue !== oldValue) {
        this.periodValue = newValue;
      }
    },
  },
  computed: {
    formatDate(){
      switch(this.preciseTimeValue){
        case 'month':
          return [this.monthFormat(this.input[0]), this.monthFormat(this.input[1])];
        case 'time':
          return [this.dateTimeFormat(this.limitDate ? this.limitDate : this.input[0]), this.dateTimeFormat(this.limitDate ? null : this.input[1])];
        default:
          return [this.dateFormat(this.limitDate ? this.limitDate : this.input[0]), this.dateFormat(this.limitDate ? null : this.input[1])];
      }
    },
  },
  methods: {
    dateTimeFormat(date) {
      return date ? this.moment(date, 'YYYY-MM-DD HH:mm:ss') : null;
    },
    dateFormat(date) {
      return date ? this.moment(date, 'YYYY-MM-DD') : null;
    },
    monthFormat(date) {
      return date ? this.moment(date, 'YYYY-MM') : null;
    },
    disabledDate (current) {
      if(!this.limitTimeSearch) {
        return current && (current > this.moment().endOf('day'));
      }else {
        if(this.limitDate) {
          return current && (current < this.moment(this.limitDate).startOf('day') || current > this.moment(this.limitDate).endOf('day').add(30,'days') || current > this.moment().endOf('day'));
        }else {
          return current && (current > this.moment().endOf('day') || current < this.moment().endOf('day').subtract(100,'days'));
        }
      }
    },
    disabledTime(current){
      if(this.preciseTimeValue === 'date'){
        return current && current > this.moment(this.input[0], 'YYYY-MM-DD HH:mm:ss');
      }
    },
    onChangeMonth(date, dateString){
      const currentMonth = [ this.moment(date[0]).startOf('month').format('YYYY-MM-DD'), this.moment(date[1]).endOf('month').format('YYYY-MM-DD') ];
      this.mode = [
        dateString[0] === 'date' ? 'month' : dateString[0],
        dateString[1] === 'date' ? 'month' : dateString[1],
      ];

      this.$emit('update:input', currentMonth);
    },
    onChangeDate(date, dateString) {
      this.$emit('update:input', dateString);
    },
    onChangeCalendar(date, dateString, info) {
      if(date[1]){
        if(this.limitDate) {
          this.limitDate = null;
          if(this.preciseTimeValue === 'time') {
            date[1] = this.moment(`${this.moment(date[1]).format('YYYY-MM-DD')} ${this.moment(this.input[1]).format('HH:mm:ss')}`);
          }
        }
      } else {
        this.limitDate = date[0];
      }
    },
    blurCalendar() {
      if(this.limitDate) {
        this.limitDate = null;
      }
    },
    clearTimer() {
      clearInterval(this.timer);
      this.timer = null;
    },
    setNewTime(){
      let newTime;
      switch(this.periodValue){
        case 'today':
          switch(this.preciseTimeValue){
            case 'time':
              newTime = [this.moment().format('YYYY-MM-DD 00:00:00'), this.moment().format('YYYY-MM-DD 23:59:59')];
              break;
            default:
              newTime = [this.moment().format('YYYY-MM-DD'), this.moment().format('YYYY-MM-DD')];
              break;
          }
          break;
        case 'yesterday':
          switch(this.preciseTimeValue){
            case 'time':
              newTime = [this.moment().add(-1, 'day').format('YYYY-MM-DD 00:00:00'), this.moment().add(-1, 'day').format('YYYY-MM-DD 23:59:59')];
              break;
            default:
              newTime = [this.moment().add(-1, 'day').format('YYYY-MM-DD'), this.moment().add(-1, 'day').format('YYYY-MM-DD')];
              break;
          }
          break;
        case 'last_week':
          switch(this.preciseTimeValue){
            case 'time':
              newTime = [this.moment().add(-1, 'week').startOf('isoWeek').format('YYYY-MM-DD 00:00:00'), this.moment().add(-1, 'week').endOf('isoWeek').format('YYYY-MM-DD 23:59:59')];
              break;
            default:
              newTime = [this.moment().add(-1, 'week').startOf('isoWeek').format('YYYY-MM-DD'), this.moment().add(-1, 'week').endOf('isoWeek').format('YYYY-MM-DD')];
              break;
          }
          break;
        case 'this_week':
          switch(this.preciseTimeValue){
            case 'time':
              newTime = [this.moment().startOf('isoWeek').format('YYYY-MM-DD 00:00:00'), this.moment().endOf('isoWeek').format('YYYY-MM-DD 23:59:59')];
              break;
            default:
              newTime = [this.moment().startOf('isoWeek').format('YYYY-MM-DD'), this.moment().endOf('isoWeek').format('YYYY-MM-DD')];
              break;
          }
          break;
        case 'last_month':
          switch(this.preciseTimeValue){
            case 'month':
              newTime = [this.moment().add(-1, 'month').startOf('month').format('YYYY-MM-DD'), this.moment().add(-1, 'month').endOf('month').format('YYYY-MM-DD')];
              break;
            case 'time':
              newTime = [this.moment().add(-1, 'month').startOf('month').format('YYYY-MM-DD 00:00:00'), this.moment().add(-1, 'month').endOf('month').format('YYYY-MM-DD 23:59:59')];
              break;
            default:
              newTime = [this.moment().add(-1, 'month').startOf('month').format('YYYY-MM-DD'), this.moment().add(-1, 'month').endOf('month').format('YYYY-MM-DD')];
              break;
          }
          break;
        case 'this_month':
          switch(this.preciseTimeValue){
            case 'month':
              newTime = [this.moment().startOf('month').format('YYYY-MM-DD'), this.moment().endOf('month').format('YYYY-MM-DD')];
              break;
            case 'time':
              newTime = [this.moment().startOf('month').format('YYYY-MM-DD 00:00:00'), this.moment().endOf('month').format('YYYY-MM-DD 23:59:59')];
              break;
            default:
              newTime = [this.moment().startOf('month').format('YYYY-MM-DD'), this.moment().endOf('month').format('YYYY-MM-DD')];
              break;
          }
          break;
        default:
          break;
      }
      if(!isEqual(this.input, newTime)){
        this.$emit('update:input', newTime)
      }
    }
  },
};
</script>
<style scoped lang="scss">
</style>
