<template>
  <a-layout id="components-layout-demo-fixed-sider" class="">
    <a-layout-sider v-model="collapsed" :collapsed-width="collapsed_width" :trigger="null" collapsible class="layout-menu">
      <div class="layout-logo" >
        <img v-if="logo" :src="require(`images/${logo}.png`)"/>
        <a-icon
          class="mobile-sidebar-close"
          type="close"
          @click="() => (collapsed = !collapsed)"
        />
      </div>
      <SideBar :collapsed.sync="collapsed" :toggleMenu="toggleMenu"/>
    </a-layout-sider>
    <a-layout :class="layoutML">
      <a-layout-header class="layout-header">
        <div class="layout-header-left">
          <div class="layout-mobile-logo" >
            <img v-if="logo" :src="require(`images/${logo}.png`)"/>
          </div>
          <a-icon
            class="sidebar-trigger"
            :type="collapsed ? 'menu-unfold' : 'menu-fold'"
            @click="() => (collapsed = !collapsed)"
          />
          <div class="users" v-if="role && (role === 'master' || role === 'agent' ) ">
            <img :src="require('images/people.svg')" alt="user">
            <span>{{ numeral(agent_online_users).format('0,0') }}</span>
          </div>
        </div>
        <div class="layout-header-right">
          <a-dropdown :trigger="['click']">
            <a class="ant-dropdown-link user" @click="e => e.preventDefault()">
              <a-icon type="user" class="user-icon"/>{{ user }}<a-icon type="down" class="down-icon" />
            </a>
            <a-menu slot="overlay">
              <a-menu-item key="1">
                <router-link :to="{ name: 'ChangePassword'}">{{ $t('global.menu.ChangePassword') }}</router-link>
              </a-menu-item>
              <a-menu-divider/>
              <a-menu-item @click="userLogout()">{{ $t('global.action.logout') }}</a-menu-item>
            </a-menu>
          </a-dropdown>
          <Lang :btnType="'web'" class="layout-header-lang" />
        </div>
      </a-layout-header>
      <a-layout-content>
        <router-view></router-view>
      </a-layout-content>
    </a-layout>
    <NetworkError v-if="networkErrorStatus" />
  </a-layout>
</template>
<script>
import numeral from 'numeral';
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
import storage, { AUTH_TOKEN } from '@/../storage';
import WebFooter from '@/../src/components/web_footer';
import SideBar from '@/../src/components/side_bar';
import Lang from '@/../src/components/lang';
import NetworkError from '@/../src/components/network_error';
import { setLogoFileName, decryptData } from '@/../tools/common';

let timer;

const clearTimer = () => {
  if (timer) {
    clearTimeout(timer);
    timer = undefined;
  }
};

export default {
  components: {
    WebFooter,
    SideBar,
    Lang,
    NetworkError,
  },
  data() {
    return {
      // auth_role: storage.getItem(IDENTITY), //取得當前角色
      collapsed: false,
      collapsed_width: 80,
      account: '',
      logo: '',
      cableActive: null,
      networkErrorStatus: false,
    };
  },
  channels: {
    AgentChannel: {
      connected() {
        // console.log('connected');
        this.cableActive = true;
      },
      rejected() {
        // console.log('rejected');
      },
      received(message) {
        // console.log('received', data);
        const transferMessage = typeof message === 'string' ? decryptData(message) : message;
        const {data, method} = transferMessage;
        
        if(method === 'agent_online_players_update') {
          this.setAgentOnlineUsers(data.agent_online_players);
        }
      },
      disconnected() {
        console.log('disconnected');
        // this.$cable.subscriptions.consumer.disconnect();
        // this.$cable.unsubscribe('CockfightChannel');
        const { ws_url, agent_channel, agent_sub_ch } = this.wsGroup;
        this.$cable.unsubscribe({ channel: agent_channel, agent_sub_ch, connectionUrl: ws_url });
        this.cableActive = false;
      },
    },
  },
  computed: {
    ...mapState(
      { ws_url: state=>state.auth.ws_url, 
      agent_channel: state=>state.auth.agent_channel, 
      agent_sub_ch: state=>state.auth.agent_sub_ch, 
      agent_online_users: state=>state.auth.agent_online_users, 
      role: state=>state.auth.role
      }),
    ...mapGetters({ user: 'auth/user' }),
    layoutML() {
      if(this.collapsed){
        return 'layout-ml-collapsed';
      }
      return 'layout-ml-open';
    },
    wsGroup(){
      const {ws_url, agent_channel, agent_sub_ch, role} = this;
      return {ws_url, agent_channel, agent_sub_ch, role}
    }
  },
  methods: {
    numeral,
    ...mapMutations({setAgentOnlineUsers: 'auth/setAgentOnlineUsers'}),
    ...mapActions({ goto: 'auth/goto', logout: 'auth/logout', currentManageProfile: 'auth/currentManageProfile' }),
    setLogoFileName,
    userLogout(){
      this.logout();
      this.goto({ name:'Login' });
      this.$message.success('Logout Success', 4);
    },
    toggleMenu(){
        this.collapsed = !(this.collapsed);
    },
    changeCollapsedWidth() {
      const windowW = window.innerWidth;
      if (windowW <= 768) {
        this.collapsed_width = 0;
      }else {
        this.collapsed_width = 80;
      }
    }
  },
  async created(){
    await this.currentManageProfile();
  },
  beforeMount() {
    window.addEventListener('resize', this.changeCollapsedWidth());
    // const windowW = window.innerWidth;
    // if (windowW <= 768) {
    //   this.collapsed_width = 0;
    // }
  },
  mounted() {
    this.logo = setLogoFileName();
  },
  destroyed(){
    window.removeEventListener('resize',this.changeCollapsedWidth());
  },
  watch:{
    wsGroup: {
      handler(newValue, oldValue) {
        const { ws_url, agent_channel, agent_sub_ch, role } = newValue;
        if(role === 'master' || role === 'agent'){
          const wsUrl = `${ws_url}?${storage.getItem(AUTH_TOKEN)}`;
          this.$cable.connection.connect(wsUrl);
          this.$cable.subscribe({ channel: agent_channel, sub_ch: agent_sub_ch, connectionUrl: ws_url });
        }
      },
      immediate: false,
      deep: true
    },
    cableActive: {
      async handler(newValue, oldValue) {
        if(newValue === true){
          if(timer){
            clearTimer();
          }
        }else if(newValue === false){
          timer = setTimeout(()=>{
            this.networkErrorStatus = true;
            this.$cable.connection.disconnect();
          }, 15000);
        }
      },
      immediate: false,
    },
  },
};
</script>
