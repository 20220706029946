<template>
    <a-modal v-model="visible" 
             :footer="null" 
             :closable="false" 
             :centered="true"
             :keyboard="false"
             :maskClosable="false"
             :bodyStyle="{'text-align': 'center', 'padding': '22px 0'}"
             :dialogStyle="{'max-width': '239px'}">
      <a-icon type="close-circle" theme="filled" class="modal-icon"/>
      <p class="network-error-title">{{$t('global.message.network_error_title')}}</p>
      <p>{{$t('global.message.network_error_info')}}</p>
    </a-modal>
</template>
<script>
export default {
  data() {
    return {
      visible: true,
    };
  },
  methods: {
    // showModal() {
    //   this.visible = true;
    // },
    // handleOk(e) {
    //   this.loading = true;
    //   setTimeout(() => {
    //     this.visible = false;
    //     this.loading = false;
    //   }, 3000);
    // },
    // handleCancel(e) {
    //   this.visible = false;
    // },
  },
};
</script>
