import { getMenuPermissions, getCurrentManageProfile } from '@/../api';
import { authLogin } from '@/../api';
import storage, { AUTH_TOKEN, CURRENT_USER, AUTH_ROLE, IDENTITY } from '@/../storage';
import router from 'router';

export const userlogin = ({ commit }, value) => new Promise((resolve, reject) => {
  authLogin(value)
    .then((data) => {
      if(data.data.game_link){
          window.location = data.data.game_link;
        // window.location.replace(data.data.game_link);
      } //Just for demo, let player can open an extra page to entry game page.
      else{
        storage.setItem(AUTH_TOKEN, data.data.token);
        storage.setItem(CURRENT_USER, data.data.account);
        storage.setItem(AUTH_ROLE, data.data.role);
        commit('setUser', { user: storage.getItem(CURRENT_USER), token: storage.getItem(AUTH_TOKEN), role: storage.getItem(AUTH_ROLE)}, { root: true });
        resolve();
      }
    })
    .catch((err) => {
      console.log(err)
      reject(err);
    });
});

export const loadSetting = ({ commit, dispatch }) => {
  if (storage.getItem(AUTH_TOKEN)) {
    console.log(`Action loadSetting: ${storage.getItem(AUTH_TOKEN)}`);

    commit('setUser', { user: storage.getItem(CURRENT_USER), token: storage.getItem(AUTH_TOKEN)});
  }
  else {
    dispatch('goto', 'Login', { root: true });
  }
}

export const logout = ({ commit, dispatch }) => {
  storage.clear();
  commit('setUser', { user: undefined, token: undefined });
  dispatch('goto', {name: 'Login'});
};

export const goto = (context, payload) => {
  const { name } = payload;
  router.push({ name }).catch(()=>{});
};

export const reload = () => {
  router.go(0);
};

export const currentManageProfile = async({commit, dispatch}) => {
  await getCurrentManageProfile()
    .then((data)=>{
      // this.current_manage = data.data.current_manage;
      commit('setCurrentProfile', data.data);
      storage.setItem(IDENTITY, data.data.identity.toLowerCase());
      // commit('setIdentity', data.data.identity.toLowerCase());
      commit('setTimeZone', data.data.current_manage.time_zone);
      // this.setTimeZone(data.data.current_manage.time_zone);
    }).catch(err=>{
      console.log(err);
      dispatch('logout');
    })
}