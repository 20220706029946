<template>
  <div class="frame">
    <div class="page-breadcrumb">
      <a-breadcrumb>
        <a-breadcrumb-item><router-link :to="{name: 'SingleModerator'}">{{ $t('global.menu.SingleModerator') }}</router-link></a-breadcrumb-item>
        <a-breadcrumb-item>
          <span>{{ $t('global.menu.ControlPanel') }}</span>
          <span v-if="master_name"> - {{master_name}}</span>
          <span v-if="master_name && master_account">(</span>
          <span v-if="master_account">{{master_account}}</span>
          <span v-if="master_name && master_account">)[</span>
          <span v-if="arena_no">{{arena_no}}({{arena_id}})</span>
          <span v-if="arena_no && arena_no">]</span>
        </a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <div class="control-panel-page">
      <div class="control-panel-content scroll">
        <div class="scroll-inner">
          <div class="control-panel-main">
            <div class="main-item">
              <h5 class="title">{{ $t('global.common.arena') }}</h5>
              <div class="status" v-if="arena_opt">{{ $t('ControlPanelPage.arena_on') }}</div>
              <div class="status" :class="{ 'is_off' :!arena_opt}" v-else>{{ $t('ControlPanelPage.arena_off') }}</div>
            </div>
            <div class="main-item" v-if="arena_opt">
              <h5 class="title">{{ $t('ControlPanelPage.start_time') }}</h5>
              <div class="status">{{ moment(arena_date).format('YYYY-MM-DD') }}</div>
            </div>
            <template v-if="controlPanelPermission.arena_on_off">
            <div class="control-panel-btns">
              <button class="control-panel-switch" data-arena-switch="close" @click="arenaSwitch('close')" v-if="arena_opt">
                <a-icon type="poweroff" />
                <span class="switch-text" >{{ $t('global.action.close_arena') }}</span>
              </button>
              <button class="control-panel-switch" data-arena-switch="open" @click="arenaSwitch('open')" v-else>
                <a-icon type="poweroff" />
                <span class="switch-text" >{{ $t('global.action.open_arena') }}</span>
              </button>
            </div>
            </template>
          </div>
          <div class="control-panel-video">
            <iframe v-if="iframeVideo" id="video-iframe" width="100%" allowfullscreen="true" name="ninjastreamers_player" border="0" scrolling="no" frameborder="0" marginheight="0" marginwidth="0" :src="iFrameVideoUrl"></iframe>
            <a-icon
              class="control-panel-button-reload button-reload"
              @click="reloadIframe"
              type="reload"
              :spin="isLoading"
            />
          </div>

          <!-- <template v-if="controlPanelPermission.announcement">
            <div class="control-announce">
              <div class="announce-header">
                <div class="announce-title"><a-icon type="notification" /> {{ $t('global.common.announcement') }}</div>
                <Announce :announce="announce" @edited="announceUpdate" />
              </div>
              <div class="announce-content" v-if="announce">{{ announce }}</div>
              <div class="announce-content no-content" v-else>{{ $t('ControlPanelPage.no_announce') }}</div>
            </div>
          </template> -->
          <!-- decision on hold btn -->
          <!-- <template v-if="controlPanelPermission.decision_on_hold">
            <div class="control-on-hold">
              <template v-if="!on_hold">
                <a-button type="primary" class="btn-on-hold" @click="onHold('hold')" >{{ $t('global.action.decision_on_hold') }}</a-button>
                <p>{{ $t('global.message.decision_on_hold') }}</p>
              </template>
              <template v-else>
                <a-button type="primary" class="" @click="onHold('cancel')">{{ $t('global.action.cancel_on_hold') }}</a-button>
                <p>{{ $t('global.message.cancel_on_hold') }}</p>
              </template>
            </div>
          </template> -->

          <!-- close bet btn -->
          <template v-if="controlPanelPermission.no_bets_allowed">
            <div class="control-close-bet">
              <template v-if="!close_bet">
                <a-button type="primary" class="btn-close-bet" @click="closeBet(1)" :loading="closeBetWaitingStatus">{{ $t('global.action.not_allow_bet') }}</a-button>
                <p>{{ $t('global.message.close_bet') }}</p>
              </template>
              <template v-else>
                <a-button type="primary" class="" @click="closeBet(0)" :loading="closeBetWaitingStatus">{{ $t('global.action.allow_bet') }}</a-button>
                <p>{{ $t('global.message.open_bet') }}</p>
              </template>
            </div>
          </template>

          <!-- mask video btn -->
          <template v-if="controlPanelPermission.mask_video">
            <div class="control-mask">
              <template v-if="!mask_video">
                <a-button type="primary" class="btn-mask" @click="maskVideo(1)" :loading="maskVideoWaitingStatus">{{ $t('global.action.mask_video') }}</a-button>
                <p>{{ $t('global.message.mask_video') }}</p>
              </template>
              <template v-else>
                <a-button type="primary" class="" @click="maskVideo(0)" :loading="maskVideoWaitingStatus">{{ $t('global.action.cancel_mask_video') }}</a-button>
                <p>{{ $t('global.message.cancel_mask_video') }}</p>
              </template>
            </div>
          </template>

          <!-- monitor video btn -->
          <!-- <template v-if="controlPanelPermission.monitor_video">
            <div class="control-monitor">
              <template v-if="!monitor_video">
                <a-button type="primary" class="btn-monitor" @click="monitorVideo" >{{ $t('global.action.monitor') }}</a-button>
                <p>{{ $t('global.message.monitor') }}</p>
              </template>
            </div>
          </template> -->

          <div class="control-upload" v-if="controlPanelPermission['arena_cover'] || controlPanelPermission['mask_file'] || controlPanelPermission['schedule']">
            <p>{{ $t('ControlPanelPage.file_upload') }}</p>
            <template v-for="(btn, index) in uploadBtn" >
              <a-button v-if="controlPanelPermission[btn.title]"
                        :key="index"
                        type="primary"
                        class="btn-upload"
                        @click="showUploadModal(btn.imgName, btn.title, btn.maxSize, btn.recommendedSize)">
                        {{ $t(`ControlPanelPage.${btn.title}`) }}
              </a-button>
            </template>
          </div>
        </div>
      </div>

      <div ref="fight_control" class="control-panel-fight">
        <div class="control-panel-fight-inner scroll"
             v-infinite-scroll="loadMoreData"
             :infinite-scroll-disabled="disableLoadMore"
             infinite-scroll-immediate-check="false"
             infinite-scroll-distance="10">
          <div ref="fight_control_items" class="fight-control-items scroll-inner">
            <div class="fight-control-item" v-for="(fight, index) in fights" :key="index">
              <Fight
                :pool_id="fight.pool_id"
                :fight_no="fight.fight_no"
                :open_bet="fight.open_bet"
                :opened="fight.opened"
                :result="fight.winner"
                :locked="fight.locked"
                :loading="fight.loading"
                :controlBtnLoading="fight.controlBtnLoading"
                :ready_to_last_call="fight.ready_to_last_call"
                :last_call="fight.last_call"
                :double_confirm="fight.double_confirm"
                :can_lock="controlPanelPermission.lock_earning"
                :can_fight_control="controlPanelPermission.fight_control"
                :canNextStep="canNextStep"
                :disableNextStep="disableNextStep"
                :main_pool_id="$route.query.main_pool_id"
                :showEditIcon="true"
                :game_type="gameType"
                :enableDraw="enable_draw"
                :toggleControlBtnLoading="toggleControlBtnLoading"
                :created_at="fight.created_at"
                @bettingOpen="bettingOpen"
                @bettingClose="bettingClose"
                @bettingReadyToLastCallClose="bettingReadyToLastCallClose"
                @checkResult="checkResult"
                @reDeclare="reDeclare"
                @lockEarning="lockEarning"
                @confirmBettingStatus="confirmBettingStatus"
                @update-fightId="updateFightId"
              />
            </div>
          </div>
          <div class="loading" v-if="this.loadCounts < this.total"><a-spin /></div>
        </div>
        <div class="control-panel-fight-hold" v-if="on_hold">
          {{ $t('global.common.arena_suspend') }}
        </div>
        <!-- <div class="fight-scroll-bottom" v-show="scrollBtn" @click="fightScrolltoBottom"><a-icon type="arrow-down" /></div> -->
      </div>

      <!-- <a-modal v-model="arena_open_modal" class="arena-open-modal" :title="$t('ControlPanelPage.open_arena_title')">
        <template slot="footer">
          <a-button key="back" @click="arena_open_modal = false">{{ $t('global.action.no') }}</a-button>
          <a-button key="submit" type="primary" @click="openArena">{{ $t('global.action.yes') }}</a-button>
        </template>
        <div class="arena_modal_form">
          <p class="input-title">{{ $t('ControlPanelPage.start_fight') }}</p>
          <a-input-number v-model="start_fight" :min="1" />
        </div>
      </a-modal> -->

      <UploadFile
        v-if="uploadImg.type && uploadImg.type !== 'Schedule'"
        :show="uploadModal"
        :closeModal="closeUploadModal"
        :clearUploadData="clearUploadData"
        :uploadImg="uploadImg"/>
      <UploadMultiFile
        v-else-if="uploadImg.type && uploadImg.type === 'Schedule'"
        :show="uploadModal"
        :closeModal="closeUploadModal"
        :clearUploadData="clearUploadData"
        :uploadImg="uploadImg"/>

      <ToggleArena
        :arena_info="arena_info"
        :arena_open_modal="arena_open_modal"
        :close_modal="() => { arena_open_modal = false; }"
        :openArena="openArena"
        :current_arena_index="current_arena_index"
        :changeCurrentArenaInfo="changeCurrentArenaInfo"
        :clearCurrentArenaInfo="clearCurrentArenaInfo"
        v-model="start_fight"/>

      <a-modal :width="350" v-model="result_modal" class="fight-result-modal" :data-result="gameType ? GAME_TYPE_SIDE[gameType][check_result] : ''">
        <template slot="footer">
          <a-button key="back" @click="result_modal = false">{{ $t('global.action.no') }}</a-button>
          <a-button key="submit" type="primary" @click="sentResult(check_fight, check_result)">{{ $t('global.action.yes') }}</a-button>
        </template>
        <div class="fight-result-img">
          <FightResultImg
            :result="check_result"
            :game_type="gameType" />
        </div>
        <div class="fight-result-title">{{ $t('global.common.declare_fight_winner') }}</div>
        <div class="fight-result-winner">{{ gameType ? $t(`global.fight_result.${GAME_TYPE_SIDE[gameType][check_result]}`) : '' | formatString }}</div>
      </a-modal>
    </div>
    <NetworkError v-if="networkErrorStatus" />
  </div>
</template>
<script>
import qs from 'query-string';
import { mapState } from 'vuex';
import { 
  getMerchantRole, 
  getPoolTodayfightHistory,
  setPoolDecisionOnHold,
  setPoolEnableBet,
  setPoolMaskVideo,
  setPoolCloseArena,
  setPoolOpenArena,
  setPoolDeclareWinner,
  setPoolReloadWinner,
  setPoolLock
  } from '@/../api';
import Fight from '@/../src/components/arena/fight_item';
import FightResultImg from '@/../src/components/arena/fight_result_img';
import Announce from '@/../src/components/edit_announce';
import ToggleArena from '@/../src/components/arena/toggle_arena';
import NetworkError from '@/../src/components/network_error';
import UploadFile from '@/../src/components/arena/upload_file';
import UploadMultiFile from '@/../src/components/arena/upload_multi_file';
import storage, { AUTH_TOKEN } from '@/../storage';
import { encryptData, decryptData } from '@/../tools/common';
import { GAME_TYPE_SIDE, getHostnameInfo } from '@/../tools/config';

let timer;

const clearTimer = () => {
  if (timer) {
    clearTimeout(timer);
    timer = undefined;
  }
};

export default {
  components: {
    Fight,
    Announce,
    ToggleArena,
    NetworkError,
    FightResultImg,
    UploadFile,
    UploadMultiFile,
  },
  data() {
    return {
      id: this.$route.params.id,
      GAME_TYPE_SIDE,
      arena_opt: false,
      arena_open_modal: false,
      arena_date: '',
      start_fight: '',
      road_map_id: '',
      arena_info: [],
      current_arena_index: null,
      on_hold: false,
      close_bet: false,
      mask_video: false,
      monitor_video: false,
      enable_draw: false,
      fights:[],
      result_modal: false,
      check_fight: '',
      check_result: '',
      iframeVideo: "",
      tempIframeVideo: "",
      isLoading: false,
      // scrollBtn: false,
      announce: '',
      channel: '',
      sub_ch: '',
      ws_url: '',
      networkErrorStatus: false,
      canNextStep: true,
      controlPanelPermission: {
        announcement: false,
        decision_on_hold: false,
        fight_control: false,
        lock_earning: false,
        read: false,
        no_bets_allowed: false,
        mask_video: false,
        monitor_video: false,
        arena_on_off: false,
      },
      cableActive: null, // Detect action cable. null is initial, other property is true or false.
      is_main_control: false,
      disableLoadMore: false,
      page: 1,
      initDataCount: 24,
      moreDataCount: 12,
      total: 0,
      master_name: '',
      master_account: '',
      arena_no: '',
      arena_id: '',
      uploadModal: false,
      uploadedImgPath: '',
      upLoadModalTitle: '',
      uploadImg: {
        title: '',
        maxSize: 0,
        imgFormat: 'png, jp(e)g',
        recommendedSize: '',
        merchant_provider_id: this.$route.query.merchant_provider_id,
        type: '',
      },
      uploadBtn: [
        // {
        //   imgName: 'ArenaCover',
        //   title: 'arena_cover',
        //   maxSize: 50,
        //   recommendedSize: 'w220px * h130px',
        // },
        {
          imgName: 'MaskFile',
          title: 'mask_file',
          maxSize: 120,
          recommendedSize: 'w646px * h363px',
        },
        {
          imgName: 'Schedule',
          title: 'schedule',
          maxSize: 150,
          recommendedSize: `w560px, ${this.$t('ControlPanelPage.below_px_value',{ value: 2300 })}`,
        },
      ],
      gameType: '',
      coolTime: 3,
      closeBetWaitingStatus: false,
      maskVideoWaitingStatus: false,
    };
  },
  channels: {
    CockfightChannel: {
      connected() {
        // console.log('connected');
        this.cableActive = true;
      },
      rejected() {
        // console.log('rejected');
      },
      received(message) {
        // console.log('received', message);
        const transferMessage = typeof message === 'string' ? decryptData(message) : message;
        const {data, method} = transferMessage;
        // var item = this.fights.find(i => i.fight_no === (data.data.fight_no));
          if(method === 'fight_status_update'){
            if(this.arena_opt){
              const item = this.fights.find(i => i.pool_id === (data.pool_id));
              if(item && item.fight_no !== data.fight_no){
                item.fight_no = data.fight_no;
              }
              switch(data.bo_status) {
              case 'initial':
                  if(item === undefined){
                    this.fights.unshift({
                      pool_id: data.pool_id,
                      fight_no: data.fight_no,
                      open_bet: false,
                      opened: false,
                      winner: '',
                      locked: 0,
                      sent_result: false,
                      loading: 6,
                      double_confirm: false,
                      last_call: false,
                      ready_to_last_call: false,
                      created_at: data.begin_at,
                    });
                  }
                break;
              case 'betting':
                  this.confirmBettingStatus(data.pool_id, false);
                  this.toggleControlBtnLoading(data.pool_id, false);
                  item.ready_to_last_call = true;
                  item.open_bet = true;
                break;
              case 'last_call':
                  this.confirmBettingStatus(data.pool_id, false);
                  this.toggleControlBtnLoading(data.pool_id, false);
                  item.ready_to_last_call = false;
                  item.last_call = true;
                break;
              case 'fighting':
                  this.confirmBettingStatus(data.pool_id, false);
                  this.toggleControlBtnLoading(data.pool_id, false);
                  item.opened = true,
                  item.open_bet = false;
                  item.last_call = false;
                break;
              case 'finished':
                  item.winner = data.winner;
                  item.sent_result = true;
                  // if(data.winner === 'cancel'){
                  //   item.locked = 2;
                  // }
                  if(item.locked === 0 && data.locking) {
                    item.locked = 1;
                  }
                break;
              case 'reloaded':
                  item.winner = data.winner;
                  // if(data.winner === 'cancel'){
                  //   item.locked = 2;
                  // }
                break;
              case 'earn_locked':
                  if(data.locking) {
                    item.locked = 2;
                  }
                break;
              case 'cancel':
                  if(item){
                    item.opened = true;
                    item.winner = data.winner;
                    item.sent_result = true;
                    item.locked = 2;
                  }
                break;
                default:
                    console.log(`out of ${item.status}`);
                  break;
              }
            }
          } else if(method === 'arena_announce'){
            this.announce = data.announcement;
          } else if(method === 'arena_status_update'){
            if(data.close_bet !== this.close_bet){
              this.close_bet = data.close_bet;
              if(!this.closeBetWaitingStatus){
                this.closeBetWaitingStatus = true;
                setTimeout(()=>{
                  this.closeBetWaitingStatus = false;
                }, this.coolTime * 1000);
              }
            }
            if(data.mask_video !== this.mask_video){
              this.mask_video = data.mask_video;
              if(!this.maskVideoWaitingStatus){
                this.maskVideoWaitingStatus = true;
                setTimeout(()=>{
                  this.maskVideoWaitingStatus = false;
                }, this.coolTime * 1000);
              }
            }
            this.on_hold = data.decision_on_hold;
            if(data.opened !== this.arena_opt){
              this.arena_opt = data.opened;
            }
            if(data.opened === false){
              this.fights = [];
            }
          }
      },
      disconnected() {
        console.log('disconnected');
        // this.$cable.subscriptions.consumer.disconnect();
        // this.$cable.unsubscribe('CockfightChannel');
        // this.$cable.unsubscribe({ channel: this.channel, sub_ch: this.sub_ch, connectionUrl: this.ws_url });
        this.cableActive = false;
      },
    },
  },
  async mounted() {
    await getMerchantRole()
      .then((data)=>{
        // console.log(data.data.role.ControlPanel)
        this.controlPanelPermission = {...this.controlPanelPermission, ...data.data.role.ControlPanel};
      })
    if(this.controlPanelPermission["read"] && this.$route.query.main_pool_id){
      await this.getFightHistory();
      const ws_url = `${this.ws_url}?${storage.getItem(AUTH_TOKEN)}`;
      this.$cable.connection.connect(ws_url);
      this.$cable.subscribe({ channel: this.channel, sub_ch: this.sub_ch, connectionUrl: ws_url });
      // this.$refs.fight_control.addEventListener('scroll', this.handleScrollBtn);
      // setTimeout(() => {
      //   this.handleScrollBtn();
      // }, 1000);
    }
  },
  // beforeDestroy(){
  //   if(timer){
  //     clearTimer();
  //   }
  //   if(client){
  //     clearClient();
  //   }
  // },
  computed: {
    ...mapState({ currentProfile: state => state.auth.currentProfile }),
    iFrameVideoUrl(){
      if(!this.$route.query.main_pool_id || !this.$route.query.merchant_provider_id || !this.arena_id || !this.currentProfile.id || !this.iframeVideo){
        return '';
      }
      const apiDomain = getHostnameInfo().api_url.split('//')[1].split('/api')[0];
      const token = storage.getItem(AUTH_TOKEN);
      const ws_url = this.ws_url;
      // this.$route.query.merchant_provider_id才是正確的arena_id（跟前台一樣）
      const arena_id = this.$route.query.merchant_provider_id;
      // this.arena_id才是database的provider_id
      const provider_id = this.arena_id;
      const user_id = this.currentProfile.id;
      const data = {token, url: apiDomain, provider_id, arena_id, ws_url, userId: user_id, isDebug: this.$route.query.dm};
      const encryptedData = encryptData(data);
      const url = qs.stringifyUrl({url: this.iframeVideo, query: {token: encryptedData}});
      return url;
    },
    // reversedFights(){
    //   const reversedFights = [...this.fights].reverse();
    //   return reversedFights;
    // },
    arenaName(){
      return `${this.arena_no}(${this.arena_id})`;
    },
    loadCounts(){
      return this.initDataCount + (this.page - 1) * this.moreDataCount;
    },
  },
  watch: {
    iframeVideo: {
      handler(newValue, oldValue) {
        if (newValue == "") {
          setTimeout(() => {
            this.iframeVideo = this.tempIframeVideo;
            this.tempIframeVideo = "";
            this.isLoading = false;
          },890);
        }
      },
    },
    canNextStep: {
      handler(newValue, oldValue) {
        if (newValue === false) {
          const waitTime = process.env.NODE_ENV === 'development' ? 1 : 1000;
          setTimeout(()=>{
            this.canNextStep = true;
          },waitTime)
        }
      },
    },
    arena_opt: {
      async handler(newValue, oldValue) {
        await this.getFightHistory();
      },
    },
    cableActive: {
      async handler(newValue, oldValue) {
        if(newValue === true){
          if(timer){
            clearTimer();
            await this.getFightHistory();
          }
        }else if(newValue === false){
          timer = setTimeout(()=>{
            this.networkErrorStatus = true;
            this.$cable.connection.disconnect();
          },15000);
        }
      },
      immediate: false,
    },
  },
  methods: {
    async getFightHistory(){
      const result = this.total > this.loadCounts || this.page === 1 ? this.loadCounts : this.total;
      const need_arena_info = this.page === 1 ? true : false;
      await getPoolTodayfightHistory({main_pool_id: this.$route.query.main_pool_id, result, need_arena_info})
        .then((res) =>{

          const { arena, pools = [], total_pools } = res.data;
          if(this.page === 1 && arena){
            this.master_account = arena.master_account;
            this.master_name = arena.master_name;
            this.ws_url = arena.ws_url;
            this.channel = arena.channel;
            this.sub_ch = arena.sub_ch;
            this.iframeVideo = arena.video_url;
            this.announce = arena.announcement;
            this.on_hold = arena.decision_on_hold;
            this.close_bet = arena.close_bet;
            this.mask_video = arena.mask_video;
            this.is_main_control = arena.is_main_control;
            this.arena_no = arena.arena_no;
            this.arena_id = arena.id;
            this.enable_draw = arena.enable_draw;
            this.gameType = arena.game_type;
            this.arena_info = arena.arena_info;
          }

          const mapPools = pools.map((pool) => {
            pool.controlBtnLoading = false;
            if(pool.status === 'cancel'){
              pool.sent_result = true;
              pool.result = 'cancel';
              pool.winner = 'cancel';
            }
            return pool;
          });
          if(mapPools.length !== 0){
            const lastRound = mapPools[0];

            if(this.page === 1){
              lastRound.double_confirm = false;
            }else {
              lastRound.double_confirm = this.fights[0].double_confirm;
            }

            if(lastRound.last_call){
              lastRound.ready_to_last_call = false;
            }else{
              lastRound.ready_to_last_call = true;
            }
            
            this.fights = mapPools;
            this.arena_date = arena ? arena.start_time : this.arena_date;
            this.arena_opt = true;
          }

          this.total = total_pools;
          this.disableLoadMore = total_pools <= result ? true : false;

        })
        .catch((err)=>{
          this.$message.error(err.response.data.message);
          // console.log(err);
        });
    },
    loadMoreData() {
      if(this.disableLoadMore) return;
      this.disableLoadMore = true;
      setTimeout(async()=>{
        this.page += 1;
        await this.getFightHistory();
      }, 1000)
    },
    arenaSwitch(type) {
      let self = this;
      if (type == 'open') {
        self.arena_open_modal = true;
      }else if (type == 'close') {
        this.$confirm({
          title: this.$t('ControlPanelPage.close_arena_title'),
          okText: this.$t('global.action.yes'),
          cancelText: this.$t('global.action.no'),
          onOk() {
            setPoolCloseArena({main_pool_id: self.$route.query.main_pool_id})
              .then((data) =>{
                self.arena_opt = false;
                // self.fights = [];
                // self.getFightHistory();
              })
              .catch((err)=>{
                this.$message.error(err.response.data.message);
                console.log(err);
              });
          },
          onCancel() {},
        });
      }
    },
    clearCurrentArenaInfo(){
      this.current_arena_index = null;
    },
    changeCurrentArenaInfo(index){
      this.current_arena_index = index;
      const currentArenaInfo = this.arena_info[index];
      this.arena_date = currentArenaInfo.time;
      this.start_fight = currentArenaInfo.fight_no;
      this.road_map_id = currentArenaInfo.road_map_id;
    },
    openArena() {
      // 確認、開起競技場遊戲
      if(this.start_fight) {
        const self = this;
        this.arena_open_modal = false;
        setPoolOpenArena({fight_no: this.start_fight, road_map_id: this.road_map_id, main_pool_id: this.$route.query.main_pool_id })
          .then(({ data }) => {
            self.arena_opt = true;
            // self.getFightHistory();
            // this.fights.push({
            //   pool_id: data.pool_id,
            //   fight_no: data.fight_no,
            //   open_bet: false,
            //   opened: false,
            //   result: '',
            //   locked: false,
            //   sent_result: false,
            // });
            // console.log(data)
          }).catch((err) => {
            this.$message.error(err.response.data.message);
            console.log(err);
          });
      }else {
        this.$message.warning(this.$t('ControlPanelPage.message.input_fight'));
      }
    },
    disableNextStep(){
      this.canNextStep = false;
    },
    toggleControlBtnLoading(id, status){
      const item = this.fights.find(i => i.pool_id === id);
      item.controlBtnLoading = status;

    },
    confirmBettingStatus(id, betting_status){
      const item = this.fights.find(i => i.pool_id === id);
      item.double_confirm = betting_status;
      this.disableNextStep();
    },
    bettingOpen(id) {
      // const item = this.fights.find(i => i.pool_id === id);
      // this.confirmBettingStatus(id, false);
    },
    bettingReadyToLastCallClose(id) {
      const item = this.fights.find(i => i.pool_id === id);
      this.confirmBettingStatus(id, false);
      item.ready_to_last_call = false;
      item.last_call = true;

    },
    bettingClose(id, check_one_side_bet) {
      // const item = this.fights.find(i => i.pool_id === id);
      // 單邊投注判斷
      // if(check_one_side_bet === true) {
      //   this.$message.warning(`賽局 ${id} 系統判斷為單邊投注或是無法成局，已自動取消此局`, 10);

      //   item.locked = true;
      //   this.result_modal = false;
      //   item.result = 'cancel'
      //   fightCancel({pool_id: id})
      //       .then(({data}) => {
      //         fightOpen({})
      //           .then(({ data }) => {
      //             // this.fights.push({
      //             //   pool_id: data.pool_id,
      //             //   fight_no: data.fight_no,
      //             //   open_bet: false,
      //             //   opened: false,
      //             //   result: '',
      //             //   locked: false,
      //             //   sent_result: false,
      //             // });
      //           }).catch((err) => {
      //             console.log(err)
      //           });
      //       })
      //       .catch((err)=>{
      //         console.log(err)
      //       })
      // } else {
      //   item.opened = true,
      //   item.open_bet = false;
      // }
      // this.confirmBettingStatus(id, false);
      // item.opened = true,
      // item.open_bet = false;
      // item.last_call = false;
    },
    checkResult(fight, result) {
      this.result_modal = true;
      this.check_fight = fight;
      this.check_result = result;
    },
    sentResult(id, result) {
      const item = this.fights.find(i => i.pool_id === id);
      this.result_modal = false;
      if (!item.sent_result) {
          setPoolDeclareWinner({ winner: result, main_pool_id: this.$route.query.main_pool_id, pool_id: id })
              .then(({ data }) => {
                  item.winner = result;
                  item.sent_result = true;

                  setPoolOpenArena({main_pool_id: this.$route.query.main_pool_id})
                      .then(({ data }) => {
                      }).catch(() => {
                      this.$message.error('Error Create New Round.');
                  });
              }).catch((err) => {
              this.$message.error(err.response.data.message);
              console.log(err);
          });

        // if(item.result === 'cancel'){
        //   fightCancel({pool_id: id, main_pool_id: this.$route.query.main_pool_id})
        //     .then(({data}) => {
        //       fightOpen({main_pool_id: this.$route.query.main_pool_id})
        //         .then(({ data }) => {
        //           // this.fights.push({
        //           //   pool_id: data.pool_id,
        //           //   fight_no: data.fight_no,
        //           //   open_bet: false,
        //           //   opened: false,
        //           //   result: '',
        //           //   locked: false,
        //           //   sent_result: false,
        //           // });
        //         }).catch((err) => {
        //           console.log(err)
        //         });
        //     })
        //     .catch((err)=>{
        //       console.log(err)
        //     })
        // } else {
        //   fightClose({ pool_id: id, winner: result, main_pool_id: this.$route.query.main_pool_id })
        //     .then(({ data }) => {
        //       fightOpen({main_pool_id: this.$route.query.main_pool_id})
        //         .then(({ data }) => {
        //           // this.fights.push({
        //           //   pool_id: data.pool_id,
        //           //   fight_no: data.fight_no,
        //           //   open_bet: false,
        //           //   opened: false,
        //           //   result: '',
        //           //   locked: false,
        //           //   sent_result: false,
        //           // });
        //         }).catch(() => {
        //         });
        //     }).catch((err) => {
        //       console.log(err)
        //     });
        // }
      } else {
        // if(result === 'cancel'){
        //   fightCancel({main_pool_id: this.$route.query.main_pool_id})
        //     .then(({ }) => {
        //       }).catch((err) => {
        //         console.log(err)
        //       });
        // }else{
          setPoolReloadWinner({ winner: result, main_pool_id: this.$route.query.main_pool_id , pool_id: id })
            .then(({ }) => {
              })
            .catch((err) => {
              this.$message.error(err.response.data.message);
              console.log(err)
            });
        }
      // };
    },
    reDeclare(id, fight_no) {
      let item = this.fights.find(i => i.pool_id === id);
      this.$confirm({
        title: this.$t('ControlPanelPage.re_declare_title'),
        content: this.$t('ControlPanelPage.re_declare_content', { id: fight_no }),
        okText: this.$t('global.action.yes'),
        cancelText: this.$t('global.action.no'),
        onOk() {
          item.winner = "";
        },
        onCancel() {},
      });
    },
    lockEarning(id, fight_no) {
      const self = this;
      let item = this.fights.find(i => i.pool_id === id);
      this.$confirm({
        title: this.$t('ControlPanelPage.lock_earing_title'),
        content: this.$t('ControlPanelPage.lock_earing_content', { id: fight_no }),
        okText: this.$t('global.action.yes'),
        cancelText: this.$t('global.action.no'),
        onOk() {
          item.locked = 1;
          setPoolLock({ main_pool_id:self.$route.query.main_pool_id, pool_id: id })
            .then(({ data }) => {
            }).catch(() => {
              this.$message.error(err.response.data.message);
              console.log(err)
            });
        },
        onCancel() {},
      });
    },
    // fightScrolltoBottom() {
    //   let controlItemWidth = this.$refs.fight_control_items.clientWidth;
    //   this.$refs.fight_control.scrollTo({top: controlItemWidth, behavior: "smooth"});
    // },
    // handleScrollBtn() {
    //   const controlScroll = this.$refs.fight_control.scrollTop;
    //   const controlHeight = this.$refs.fight_control.clientHeight;
    //   const controlItemHeight = this.$refs.fight_control_items.clientHeight;
    //   if ((controlItemHeight - controlHeight) > controlScroll){
    //     this.scrollBtn = true;
    //   }else {
    //     this.scrollBtn = false;
    //   }
    // },
    // announceUpdate(announce_input) {
    //   fightAnnounce({announce: announce_input, main_pool_id: this.$route.query.main_pool_id})
    //     .then((data) => {
    //       this.$message.success(this.$t('global.message.success_to_change', {value: this.$t('global.common.announcement')}));
    //       this.announce = announce_input;
    //     })
    //     .catch((err)=>{
    //       this.$message.error(this.$t('global.message.failed_to_update', {value: this.$t('global.common.announcement')}));
    //       console.log(err);
    //     });
    // },
    // onHold(type) {
    //   let self = this;
    //   if(type == 'hold') {
    //     this.$confirm({
    //       title: this.$t('global.action.decision_on_hold') + '?',
    //       content: "*" + this.$t('ControlPanelPage.message.affect_all_round', { value: this.arenaName }),
    //       okText: this.$t('global.action.yes'),
    //       cancelText: this.$t('global.action.no'),
    //       onOk() {
    //         setPoolDecisionOnHold({ hold: 1, main_pool_id: self.$route.query.main_pool_id })
    //           .then(() => {
    //             self.on_hold = true;
    //           })
    //           .catch((err)=>{
    //             this.$message.error(err.response.data.message);
    //             console.log(err);
    //           });
    //       },
    //       onCancel() {},
    //     });
    //   }else {
    //     this.$confirm({
    //       title: this.$t('global.action.cancel_on_hold') + '?',
    //       content: "*" + this.$t('ControlPanelPage.message.affect_all_round', { value: this.arenaName }),
    //       okText: this.$t('global.action.yes'),
    //       cancelText: this.$t('global.action.no'),
    //       onOk() {
    //         setPoolDecisionOnHold({ hold: 0, main_pool_id: self.$route.query.main_pool_id })
    //           .then(() => {
    //             self.on_hold = false;
    //           })
    //           .catch((err)=>{
    //             this.$message.error(err.response.data.message);
    //             console.log(err);
    //           });
    //       },
    //       onCancel() {},
    //     });
    //   }
    // },
    closeBet(value) {
      let self = this;
      if(value) {
        this.$confirm({
          title: this.$t('global.action.not_allow_bet') + '?',
          content: "*" + this.$t('ControlPanelPage.message.affect_all_round', { value: this.arenaName }),
          okText: this.$t('global.action.yes'),
          cancelText: this.$t('global.action.no'),
          onOk() {
            setPoolEnableBet({ close_bet: value, main_pool_id: self.$route.query.main_pool_id })
              .then(() => {})
              .catch((err)=>{
                if(err.response && err.response.data.code === 10423){
                  self.$message.error(self.$t('ControlPanelPage.message.operator_repeat_and_retry'));
                } else {
                  self.$message.error(self.$t('global.message.failed_to_update', {value: self.$t('global.common.close_bet')}));
                }
              });
          },
          onCancel() {},
        });
      }else {
        this.$confirm({
          title: this.$t('global.action.allow_bet') + '?',
          content: "*" + this.$t('ControlPanelPage.message.affect_all_round', { value: this.arenaName }),
          okText: this.$t('global.action.yes'),
          cancelText: this.$t('global.action.no'),
          onOk() {
            setPoolEnableBet({ close_bet: value, main_pool_id: self.$route.query.main_pool_id })
              .then(() => {})
              .catch((err)=>{
                if(err.response && err.response.data.code === 10423){
                  self.$message.error(self.$t('ControlPanelPage.message.operator_repeat_and_retry'));
                } else {
                  self.$message.error(self.$t('global.message.failed_to_update', {value: self.$t('global.common.close_bet')}));
                }
              });
          },
          onCancel() {},
        });
      }
    },
    maskVideo(value){
      let self = this;
      if(value) {
        this.$confirm({
          title: this.$t('global.action.mask_video') + '?',
          content: "*" + this.$t('ControlPanelPage.message.affect_all_round', { value: this.arenaName }),
          okText: this.$t('global.action.yes'),
          cancelText: this.$t('global.action.no'),
          onOk() {
            setPoolMaskVideo({ mask_video: value, main_pool_id: self.$route.query.main_pool_id })
                .then(() => {})
                .catch((err)=>{
                  if(err.response && err.response.data.code === 10423){
                    self.$message.error(self.$t('ControlPanelPage.message.operator_repeat_and_retry'));
                  } else {
                    self.$message.error(self.$t('global.message.failed_to_update', {value: self.$t('global.common.mask_video')}));
                  }
                });
          },
          onCancel() {},
        });
      }else {
        this.$confirm({
          title: this.$t('global.action.cancel_mask_video') + '?',
          content: "*" + this.$t('ControlPanelPage.message.affect_all_round', { value: this.arenaName }),
          okText: this.$t('global.action.yes'),
          cancelText: this.$t('global.action.no'),
          onOk() {
            setPoolMaskVideo({ mask_video: value, main_pool_id: self.$route.query.main_pool_id })
                .then(() => {})
                .catch((err)=>{
                  if(err.response && err.response.data.code === 10423){
                    self.$message.error(self.$t('ControlPanelPage.message.operator_repeat_and_retry'));
                  } else {
                    self.$message.error(self.$t('global.message.failed_to_update', {value: self.$t('global.common.mask_video')}));
                  }
                });
          },
          onCancel() {},
        });
      }
    },
    // monitorVideo(){
    //   let self = this;
    //   getMonitorVideo({ main_pool_id: self.$route.query.main_pool_id }).then((data)=>{
    //     this.game_link_url = data.data.game_link_url
    //     // 判断 game_link_url 是否為 null 或 undefined
    //     if (this.game_link_url) {
    //       window.open(this.game_link_url, '_blank');
    //     } else {
    //       console.error('error: game_link_url is nll or undefined');
    //     }
    //   });
    // },
    reloadIframe() {
      if( this.isLoading ){
        return;
      }else{
        this.isLoading = true;
        this.tempIframeVideo = this.iframeVideo;
        this.iframeVideo = "";
      }
    },
    updateFightId(fightForm) {
      this.fights.find((i) => {
        if(i.pool_id === (fightForm.pool_id)) {
          i.fight_no = fightForm.fight_no;
        }
      });
    },
    showUploadModal(imgPath, title, fileSize, recommendedSize) {
      this.uploadModal = true;
      this.uploadImg.type = imgPath;
      this.uploadImg.title = title;
      this.uploadImg.maxSize = fileSize;
      this.uploadImg.recommendedSize = recommendedSize;
    },
    closeUploadModal() {
      this.uploadModal = false;
    },
    clearUploadData(){
      this.uploadImg.type = '';
      this.uploadImg.title = '';
      this.uploadImg.maxSize = 0;
      this.uploadImg.recommendedSize = '';
    }
  },
};
</script>
