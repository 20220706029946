<template>
  <a-modal :visible="show"
           :footer="null"
           :centered="true"
           :title="$t(`ExchangeRatePage.tbd_confirm_rate`)"
           @cancel="closeConfirmModal">
    <div class="exchange-rate-dialog">
      <a-row :gutter="[0,16]">
        <a-col :xs="24">
          <a-col :xs="24" :sm="12">
            <div class="bold">
              <span>{{ $t('global.common.currency') }}:</span>
              <span>{{ exchangeRateInfo.currency }}</span>
            </div>
          </a-col>

          <a-col :xs="24" :sm="12">
            <div class="bold">
              <span>{{ $t('ExchangeRatePage.currency_name') }}:</span>
              <span>{{ exchangeRateInfo.currency_name }}</span>
            </div>
          </a-col>
        </a-col>

        <a-col :xs="24">
          <div class="bold">
            <span>{{ $t('ExchangeRatePage.last_approved_rate') }}:</span>
            <span>{{ exchangeRateInfo.last_approved_rate }}</span>
          </div>
        </a-col>

        <a-col :xs="24">
          <div>
            <span>{{ $t('ExchangeRatePage.today_rate') }}:</span>
            <a-input v-model="rateNum" class="rate-input"/>
            <span>({{ numeral(exchangeRateInfo.first_safe_rate).format('0,0[.][000]') }} ~ {{ numeral(exchangeRateInfo.last_safe_rate).format('0,0[.][000]') }})</span>
          </div>
        </a-col>

        <a-col :xs="24">
          <div class="btn-group">
            <a-button type="primary" @click="updateExchangeRate" block>
              {{ $t('global.action.ok') }}
            </a-button>
            <a-button type="primary" @click="closeConfirmModal" block>
              {{ $t('global.action.cancel') }}
            </a-button>
          </div>
        </a-col>
      </a-row>
    </div>
  </a-modal>
</template>

<script>
import { updateExchangeTbdConfirmRate } from '@/../api';
import numeral from 'numeral';

export default {
  props: {
    exchangeRateInfo: Object,
    show: Boolean,
    fetchData: Function,
    closeModal: Function
  },
  data() {
    return {
      rateNum: 0,
      rules: {}
    };
  },
  methods: {
    numeral,
    updateExchangeRate(){
      if(this.rateNum.toString().trim() === '') return this.$message.error(this.$t('global.message.fill_input',{value: this.$t('global.menu.ExchangeRate')}));
      if(isNaN(this.rateNum)) return this.$message.error(this.$t('global.message.must_be_number_value',{value: this.$t('global.menu.ExchangeRate')}));
      if(this.rateNum > this.exchangeRateInfo.last_safe_rate || this.rateNum < this.exchangeRateInfo.first_safe_rate) {
        return this.$message.error(this.$t('global.message.must_be_between_number',{value: this.$t('global.menu.ExchangeRate'), min: this.exchangeRateInfo.first_safe_rate, max: this.exchangeRateInfo.last_safe_rate}));
      }
      updateExchangeTbdConfirmRate({id: this.exchangeRateInfo.id, tbd_confirm_rate: this.rateNum})
        .then((data)=>{
          this.$message.success(this.$t('global.message.success_to_change',{value: this.$t('global.menu.ExchangeRate')}));
        }).catch((err)=>{
          this.$message.error(this.$t('global.message.failed_to_update',{value: this.$t('global.menu.ExchangeRate')}));
        }).finally(()=>{
          this.fetchData();
          this.closeModal();
        });
    },
    closeConfirmModal(){
      this.closeModal();
      setTimeout(()=>{
        this.rateNum = this.exchangeRateInfo.rate;
      },300)
    }
  },
  watch:{
    exchangeRateInfo:{
      immediate: true,
      deep: true,
      handler(newValue, oldValue){
        this.rateNum = newValue.rate;
      }
    }
  },
};
</script>
