<template>
  <div class="frame merchant-role-page">
    <div class="page-breadcrumb" v-if="account">
      <a-breadcrumb>
        <a-breadcrumb-item><router-link :to="{name: 'Merchants'}">{{ $t('global.menu.Merchants') }}</router-link></a-breadcrumb-item>
        <!-- <a-breadcrumb-item>{{ this.$route.params.id }}</a-breadcrumb-item> -->
        <a-breadcrumb-item>{{ account }}</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <a-page-header
      :title="$t('global.menu.MerchantRoles')"
      @back="() => $router.go(-1)"
    >
      <template slot="extra">
        <a-button class="add-btn" icon="plus" @click="showDrawer('create')"> {{ $t('MerchantRolesPage.create_role') }} </a-button>
      </template>
    </a-page-header>
    <div class="page-content">
      <div class="ant-card">
        <div class="ant-card-body">
          <a-table
            bordered
            size="small"
            :columns="columns"
            :row-key="data => data.id"
            :data-source="data"
            :pagination="pagination"
            :loading="loading"
            @change="handleTableChange"
          >
            <template v-for="column in columns">
              <template :slot="column.slots.title">{{ column.singleI18n ? $t(`MerchantRolesPage.${column.slots.title}`) : $t(`global.common.${column.slots.title}`) }}</template>
            </template>

            <template slot="role" slot-scope="id, row">
              <a-tag :color="row.color" style="font-size: 16px">{{ row.role_name }}</a-tag>
            </template>

            <template slot="permission_list" slot-scope="permission_list">
              <div class="table-min-width table-text-wrap" data-min-width="300px">
                {{ showPermissions(permission_list) }}
              </div>
            </template>

            <template slot="formatTimes" slot-scope="times">
              <div class="table-time">{{ times | formatTimes }}</div>
            </template>

            <template slot="action" slot-scope="id, row">
              <a-button type="primary" size="small" @click="showDrawer('edit', row)">{{ $t('global.action.edit') }}</a-button>
              <a-button icon="delete" size="small" @click="deleteRole(row)"></a-button>
            </template>

          </a-table>
        </div>
      </div>
    </div>

    <a-drawer
      :title="formTitle"
      :width="680"
      :visible="formShow"
      class="drawer-footer-model"
      @close="closeDrawer"
    >
      <RoleForm :formType="formType"
                :roleForm="roleForm"
                @update-data="updateRole"/>
    </a-drawer>
  </div>
</template>

<script>
import { deleteMerchantRole } from '@/../api';
import RoleForm from '@/../src/components/merchants/role_form';

const columns = [
  {
    slots: { title: 'id' },
    dataIndex: 'id',
    align: 'center',
  },
  {
    slots: { title: 'role' },
    dataIndex: 'role',
    scopedSlots: { customRender: 'role' },
    align: 'center',
  },
  {
    slots: { title: 'can_permission_pages' },
    dataIndex: 'permission_list',
    scopedSlots: { customRender: 'permission_list' },
    singleI18n: true,
  },
  {
    slots: { title: 'use_account' },
    dataIndex: 'use_account',
    align: 'center',
    singleI18n: true,
  },
  {
    slots: { title: 'updated_at' },
    dataIndex: 'update_at',
    scopedSlots: { customRender: 'formatTimes' },
  },
  {
    slots: { title: 'action' },
    dataIndex: 'action',
    scopedSlots: { customRender: 'action'},
    align: 'center',
    width: 120
  },
];

export default {
  components: {
    RoleForm,
  },
  props:{
    data: Array,
    pagination: Object,
    loading: Boolean,
    fetchData: Function,
    id: [String, Number],
    account: String,
  },
  data() {
    return {
      formShow: false,
      formTitle: this.$t('MerchantRolesPage.edit_role'),
      formType: 'edit',
      selectedAll: false,
      roleForm: {},
      // data: [
      //   {
      //     id: 1,
      //     role_name: 'Super',
      //     color: 'red',
      //     permission_list: {
      //       ArenaModerator: ['read'],
      //       ControlPanel: ['read','arena_on_off', 'fight_control', 'lock_earning', 'announcement', 'decision_on_hold'],
      //       CurrentArena: ['read', 'lock_earning', 'decision_on_hold'],
      //       Players: ['read'],
      //       PlayerCredits: ['read'],
      //       BetLog: ['read'],
      //       GameResults: ['read'],
      //       Order: ['read'],
      //       Agents: ['read', 'update', 'create'],
      //       AgentShow: ['read', 'update'],
      //     },
      //     use_account: 10,
      //     update_at: '21-09-22 20:22:22',
      //   },
      //   {
      //     id: 2,
      //     role_name: 'Super2',
      //     color: 'pink',
      //     permission_list: {
      //       ArenaModerator: ['read'],
      //       ControlPanel: ['read','arena_on_off', 'fight_control', 'lock_earning', 'announcement', 'decision_on_hold'],
      //       CurrentArena: ['read', 'lock_earning', 'decision_on_hold'],
      //     },
      //     use_account: 0,
      //     update_at: '21-09-22 20:22:22',
      //   },

      // ],
      expand: false,
      columns,
    };
  },
  methods: {
    showPermissions(list){
      const permissionList = [];
      Object.keys(list).forEach(listItem=>{
        if(list[listItem].includes('read')){
          permissionList.push(this.$t(`global.menu.${listItem}`));
        }
      })
      return permissionList.join(', ');
    },
    showDrawer(type, row) {
      if (type == 'create') {
        this.formTitle = this.$t('MerchantRolesPage.create_role');
        this.roleForm = {
          id: this.$route.params.id || 0,
          role_name: '',
          color: 'red',
          permission_list: {},
        };
      } else {
        this.formTitle = this.$t('MerchantRolesPage.edit_role');
        // 目前做切版暫時畫面，接上資料建議修改
        this.roleForm = {
          id: row.id,
          role_name: row.role_name,
          color: row.color,
          permission_list: Object.assign({}, row.permission_list),
        }
      }
      this.formType = type;
      this.formShow = true;
    },
    closeDrawer() {
      // 如果關閉修改沒送出，table資料不可更動
      this.formShow = false;
    },
    handleTableChange(pagination, filters, sorter) {
      const currentPage = this.pagination.pageSize !== pagination.pageSize ? 1 : pagination.current;
      this.pagination.current = currentPage;
      this.pagination.pageSize = pagination.pageSize;
      
      this.fetchData({
        results: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
      });
    },
    updateRole(form) {
      this.formShow = false;
      this.id = this.$route.params.id;
      const { id } = this;
      this.fetchData({id});
    },
    deleteRole(row) {
      const { id } = row;
      const self = this;
      const roleID = this.id;
      // 有帳號在使用該角色則不能刪除
      this.$confirm({
        title: self.$t('global.message.delete', {value: row.role_name}),
        okText: self.$t('global.action.yes'),
        cancelText: self.$t('global.action.no'),
        onOk() {
          if(row.use_account >= 1) {
            self.$message.error(self.$t('MerchantRolesPage.account_to_use'));
            return false;
          }
          deleteMerchantRole({id})
            .then((data) =>{
              self.$message.success(self.$t('global.message.success_to_delete', {value: row.role_name}));
              self.fetchData({roleID})
            })
        },
        onCancel() {},
      });
    }
  },
}
</script>
