<template>
  <div>
    <a-modal 
      :visible="visiblePoints" 
      :ok-text="$t('global.action.next')" 
      :cancel-text="$t('global.action.cancel')" 
      @cancel="inputHandleCancel"
      :footer="null"
      :width="400"
    >
      <template slot="title">
        {{ $t(`AgentCreditPointsPage.edit_points`) }}
        <span class="primary-font-color"> {{ this.changePointsData.account  }}</span>
      </template>
      <a-form-model :layout="'vertical'" ref="form" :model="form">
        <a-row :gutter="[16, 0]">
          <a-col :xs="24">
            <p>{{ $t(`global.common.current_points`) }}：<span class="primary-font-color">{{ this.changePointsData.balance | formatNumberWithPoint }}</span></p>
            <a-form-model-item :label="$t('AgentCreditPointsPage.change_type')">
              <a-radio-group :value="this.changePointsData.type" button-style='solid' >
                <a-radio-button value="increase" @click="operatorClick('increase')">
                  {{ $t(`AgentCreditPointsPage.increase_points`) }}
                </a-radio-button>
                <a-radio-button value="deduct" @click="operatorClick('deduct')">
                  {{ $t(`AgentCreditPointsPage.decrease_points`) }}
                </a-radio-button>
              </a-radio-group>
            </a-form-model-item>
            <a-form-model-item :label="$t(`AgentCreditPointsPage.edit_points`)">
              <a-input type="number" :disabled="operatorSwitch" :value="changePointsData.amount" @input="(e)=>{checkInput(e, 0, this.changePointsData.balance)}" @blur="(e)=>{checkBlur(e, 0)}" />
            </a-form-model-item>
          </a-col>
        </a-row>
        <div>
          <a-button key="submit" block :disabled="disabledConfirmButton" type="primary" @click="inputHandleOk">
            {{ $t(`global.common.confirm`) }}
          </a-button>
        </div>
      </a-form-model>
    </a-modal>
    <a-modal v-model="historyPointsCheckDialog" :footer="null" :width="360" :maskClosable="false" :closable="false"
    :keyboard="false" :centered="true" :afterClose="inputAmountLoadingFalse">
      <div>
        <a-row :gutter="[16, 0]">
          <a-col :xs="24">
            <p>{{ $t(`global.common.agent_account`) }}：<span class="primary-font-color">{{ changePointsData.account }}</span></p>
            <p>{{ $t(`global.common.current_points`) }}：<span class="primary-font-color">{{ changePointsData.balance | formatNumberWithPoint }}</span></p>
            <p>{{ $t(`AgentCreditPointsPage.points_after_change`) }}：<span class="primary-font-color">{{ changePointsData.after_points | formatNumberWithPoint }}</span></p>
          </a-col>
        </a-row>
        <div>
          <a-button class="add-btn" @click="pointsCheckCancel">
            {{ $t('global.action.cancel') }}
          </a-button> 
          <a-button type="primary" @click="pointsCheckOk" :loading="inputAmountLoading">
            {{ $t('global.common.confirm') }}
          </a-button>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
  export default {
    props: {
      inputVisiblePoints: {
        type: Boolean
      },
      inputHistoryPointsCheckDialog:{ 
        type: Boolean
      },
      inputForm: {
        type: Object
      },
      inputChangePointsData: {
        type: Object
      },
      inputOperatorSwitch: {
        type: Boolean
      },
      pointsCheckOk:{
        type: Function
      },
      pointsCheckCancel: {
        type: Function
      },
      inputHandleCancel: {
        type: Function
      },
      inputHandleOk: {
        type: Function
      },
      inputAmountLoading: {
        type: Boolean
      },
      inputAmountLoadingFalse: {
        type: Function
      }
    },
    data() {
      return {
        visiblePoints: false,
        historyPointsCheckDialog: false,
        form: {},
        changePointsData: {},
        operatorSwitch: false,
        disabledConfirmButton: true,
      };
    },
    methods: {
      // 上下分按鈕
      operatorClick(operator){
        // console.log("operatorClick");
        if (this.changePointsData.type !== operator) this.changePointsData.amount = 0;
        this.operatorSwitch = false;
        this.changePointsData.type = operator; // === 'increase' ? true : false;
        this.changePointsData.submit_button = false;
      },
      checkInput(event, defaultValue, maxValue){
        if(event.target.value === '') return;

        const type = this.changePointsData.type;
        const regex = /^(\d{1,9})(\.\d{0,2})?$/;
        const value = Number(event.target.value);
        if(regex.test(event.target.value)) {
          const maxNumber = Number(maxValue);
          if(type === 'deduct' && value > maxNumber){
            this.changePointsData.amount = maxNumber;
            event.target.value = maxNumber;
          }else{
            this.changePointsData.amount = value;
          }
        }
        else{
          if(Number(event.target.value) < 0) {
            event.target.value = '';
            this.changePointsData.amount = 0;
          }else{
            event.target.value = this.changePointsData.amount;
          }
        }
      },
      checkBlur(e, defaultValue){
        const value = Number(e.target.value);
        if(!value){
          this.changePointsData.amount = defaultValue;
        }
      },
    },
    watch: {
      inputChangePointsData: {
        handler(newValue, oldValue){
          this.changePointsData = newValue;
          this.disabledConfirmButton = this.changePointsData.amount <= 0;
        },
        deep: true,
        immediate: true
      },
      inputVisiblePoints: {
        handler(newValue, oldValue){
          this.visiblePoints = newValue;
        },
        deep: true,
        immediate: true
      },
      inputHistoryPointsCheckDialog: {
        handler(newValue, oldValue){
          this.historyPointsCheckDialog = newValue;
        },
      },
      inputForm: {
        handler(newValue, oldValue){
          this.form = newValue;
        },
        deep: true,
        immediate: true
      },
      inputOperatorSwitch: {
        handler(newValue, oldValue){
          this.operatorSwitch = newValue;
        },
        immediate: true
      },
    },  
  };
</script>
  