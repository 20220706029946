import Vue from 'vue';

// export const setUser = (states, value) => Vue.set(states, 'user', value);
export const setUser = (states, value) => {
  Vue.set(states, 'user', value.user);
  Vue.set(states, 'token', value.token);
  Vue.set(states, 'role', value.role);
}

export const setTimeZone = (states, value) => {
  states.timeZone = value;
}

export const setCurrentProfile = (states, value) => {
  states.currentProfile = value.current_manage;
  states.ws_url = value.ws_url;
  states.agent_channel = value.channel;
  states.agent_sub_ch = value.current_manage.id;
  states.agent_online_users = value.online_users;
  states.identity = typeof(value.identity) === 'string' ? value.identity.toLowerCase() : '';
  states.parentIdentity = typeof(value.parent_identity) === 'string' ? value.parent_identity.toLowerCase() : '';
  states.isMerchantAccount = value.is_merchant_account;
  states.role = states.identity === 'manager' ? states.parentIdentity : states.identity
}

export const setAgentOnlineUsers = (states,value) => {
  states.agent_online_users = value;
}

// export const setIdentity = (states, value) => {
//   states.identity = value;
// }