<template>
  <MerchantAdmin :roles='roles'
                 :data='data'
                 :pagination='pagination'
                 :loading='loading'
                 :fetchData='fetchData'
  />
</template>

<script>
import { getMerchantAdmin, getMerchantAllRoles } from '@/../api';
import MerchantAdmin from '@/../src/components/merchants/merchant_admin';

export default {
  components: {
    MerchantAdmin
  },
  data() {
    return {
      roles: [],
      data: [],
      pagination: {
        pageSize: 10,
        current: 1,
        total: 0
      },
      loading: false,
    };
  },
  created(){
    this.fetchData();
  },
  methods: {
    fetchData(params = {}) {
      this.loading = true;
      getMerchantAllRoles()
        .then(({ data }) => {
          this.roles = data.role;
          getMerchantAdmin({
            form: {
              results: this.pagination.pageSize,
              page: this.pagination.current,
              ...params,
            }
          }).then(({ data }) => {
            const pagination = { ...this.pagination };
            // Read total count from server
            // pagination.total = data.totalCount;
            pagination.total = data.total;
            this.data = data.sub_merchant;
            this.pagination = pagination;
          }).finally(()=>{
            this.loading = false;
          });
        })
    },
  },
}
</script>
