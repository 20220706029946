export default {
  lang: 'ไทย(泰文)',
  global: {
    game_type: {
      // cockfight: '',
      // spider: '',
      // color_game: '',
      // apple: '',
      // up_down: '',
    },
    fight_result: {
      meron: "เมรอน",
      wala: "วาลา",
      draw: "เสมอ",
      draw2: "เสมอ",
      cancel: "ยกเลิก",
      // meron: "",
      // wala: "",
      // draw: "",
      // white: '',
      // red3: '',
      // white3: '',
      // down: '',
      // up: '',
    },
    action: {
      yes: "ใช่",
      no: "ไม่",
      go: "ไป",
      search: "ค้นหา",
      reset: "รีเซ็ต",
      create: "สร้าง",
      logout: "ออกจากระบบ",
      submit: "ยืนยันส่งออก",
      edit: "แก้ไข",
      // risk_edit: '', 
      lock_earning: "จ่ายโบนัส",
      decision_on_hold: "หยุดต่อสู้ชั่วคราว",
      cancel_on_hold: "เริ่มต่อสู้",
      not_allow_bet: "ไม่อนุญาตให้เดิมพัน",
      allow_bet: "อนุญาตให้เดิมพัน",
      next: "ขั้นตอนต่อไป",
      prev: "ก่อนหน้า",
      step: "ขั้นตอน",
      ok: "ยืนยัน",
      collapse: "เก็บรวบรวม",
      mask_video: "หน้ากากวิดีโอ",
      cancel_mask_video: "ปิดหน้ากากวิดีโอ",
      // records: "",
      cancel: "ยกเลิก",
      export: "ส่งออก",
      // select_file: "",
      // upload_image: "",
      // cancel_upload: "",
      // update: '',
      // update_all: "",
      // upload_all_images: "",
      // cancel_update_all: "",
      refresh_fights: "รีเฟรชเวลาแข่งขัน",
      open_bet: "เปิดรับการเดิมพัน",
      close_bet: "ปิดการเดิมพัน",
      re_declare: "ประกาศผู้ชนะใหม่",
      locking: "กำลังจ่าย",
      open_arena: "เริ่มดำเนินการชนไก่",
      close_arena: "ปิดรอบทั้งหมดในสนามชนไก่",
      // refresh_cache: '',
      // cancel_all: '',
      delete: "ลบ",
      monitor: "ดูแล",
      // monitor_video: '',
      // retry: '',
      // refresh: '',
    },
    common: {
      account: "บัญชี",
      account_id: "หมายเลข",
      active_status_list: {
        all: "ทั้งหมด",
        active: "เปิด",
        close: "ปิด",
        // enable: '',
        // disable: ''
      },
      action: "ดำเนินการ",
      // add_settle_bet_info: '',
      agent: "ตัวแทน",
      agent_account: "บัญชีตัวแทน",
      agent_count: "จำนวนตัวแทน",
      arena: "สนาม",
      arena_suspend: "สนามชนไก่ระงับ",
      all: "ทั้งหมด",
      amount: "จำนวนเงิน",
      announcement: "ประกาศ",
      average_percentage: "การเดิมพันเฉลี่ย",
      bet_amount: "จำนวนเงินเดิมพัน",
      bet_count: "ปริมาณเดิมพัน",
      bet_type_list: {
        all: "ทั้งหมด",
        deposit: "ฝาก",
        withdraw: "ถอน",
        OrderDeposit: "ฝาก",
        OrderWithdraw: "ถอน",
        bet: "เดิมพัน",
        settle: "ชำระ",
        cancel: "ยกเลิก",
        resettle: "ชำระใหม่",
        fail: "ล้มเหลว",
        init: "อักษรย่อ"
      },
      // business_status: '',
      // calculation_base: '',
      // callback_url: '',
      change_password: "แก้ไขรหัสผ่าน",
      confirm: "ยืนยัน",
      confirm_password: "ยืนยันรหัสผ่าน",
      create_agent: "เพิ่มตัวแทน",
      // create_setting: '',
      created_at: "เวลาสร้าง",
      currency: "สกุลเงิน",
      // current_points: '',
      // custom_logo: '',
      // custom_logo_list: {
      //   odin: '',
      //   gleague: '',
      //   fiesta: '',
      //   fiestafruit: '',
      // },
      date: "วันที่",
      date_type: "เลือกเวลา",
      date_type_list: {
        BetAt: "เวลาเดิมพัน",
        GameDate: "วันที่แข่งขัน",
        SettleAt: "เวลาชำระ",
        OpenRound: "เวลาเริ่ม"
      },
      declare_fight_winner: "ประกาศผู้ชนะคือ",
      defaultLanguage: "ตั้งค่าภาษา",
      description: "คำอธิบาย",
      email: "อีเมล",
      fight_count: "จำนวนสนาม",
      fight_id: "ไอดีการต่อสู้",
      game_date: "วันที่แข่งขัน",
      // game_type: '',
      // ggr: '',
      ip: "IP",
      id: "หมายเลข",
      kill_rate: "เรท",
      language: "ภาษา",
      last_login: "เข้าสู่ระบบครั้งล่าสุด",
      // link_master: "",
      lock_earning: "จ่าย",
      log: "บันทึก",
      // main_arena: '',
      // main_pool: '',
      manager: "จัดการ",
      master: "ตัวแทนหลัก",
      max: "เดิมพันสูงสุด",
      merchant: "พ่อค้า",
      merchant_account: "ID ผู้ขาย",
      meron_wala: "วาลา เมรอน",
      min: "เดิมพันต่ำสุด",
      month: "รายเดือน",
      // multi_arena: "",
      multi_arena_status: {
        // open: "",
        // close: ""
      },
      name: "ชื่อ",
      odd: "อัตราต่อรอง",
      // odds_type: "",
      // odds_type_list: {
      //   rake: "",
      //   malay: "",
      //   static: ""
      // },
      operator: "ผู้ดำเนินการ",
      // options: '',
      // order_num: "",
      order_status_list: {
        init: "บิลที่ได้รับ",
        process: "กำลังดำเนินการ",
        success: "สำเร็จ",
        failed: "ล้มเหลว",
        pending: "รอดำเนินการ",
        fail: "ล้มเหลว",
        error: "ผิดพลาด",
      },
      password: "รหัสผ่าน",
      payout: "อัตราต่อรอง",
      platform_profit: "กำไร",
      player_account: "บัญชีผู้เล่น",
      // player_name: '',
      player_bet_limit: "ขีด จำกัด การเดิมพันเดี่ยวของผู้เล่น",
      player_count: "จำนวนผู้เล่น",
      player_round_limit: "ขีดจำกัดการเดิมพันรอบของผู้เล่น",
      // pool_status: '',
      profile: "ข้อมูลตัวแทน",
      provider_name: "ชื่อผู้ให้บริการ",
      rate: "อัตราค่าคอมมิชชั่น",
      rate_amount: "ยอดคอมมิชชั่น",
      // risk_odd: "",
      role: "บทบาท",
      // risk_control: '',
      // risk_type_list:{
      //   bet_limit: '',
      // no_login: '',
      // },
      round_id: "หมายเลขการต่อสู้",
      round_limit: "วงเงินเดิมพันต่อรอบ",
      // round_status: '',
      round_status_list: {
        idle: "ไม่ได้ใช้งาน",
        open: "เดิมพันได้",
        close: "ห้ามเดิมพัน",
        locked: "ชำระแล้ว",
        last_call: "เดิมพันล่าสุด",
        // arena_close: "",
        unsettled: 'ยังไม่เรียบร้อย',
        settled: 'เรียบร้อยแล้ว',
      },
      status: "สถานะ",
      // setting: '',
      settled_at: "เวลาชำระ",
      // settle_zero: '',
      side: "เดิมพันที่",
      // skin: '',
      // skin_list: {
      //   default: '',
      //   brown: '',
      //   white: '',
      //   black: '',
      //   green: '',
      //   dark_and_light: '',
      // },
      slug: "เลขขี่",
      theme: "ธีม",
      total: "ทั้งหมด",
      total_bet_amount: "ยอดเดิมพันทั้งหมด",
      total_bet_count: "จำนวนเดิมพันทั้งหมด",
      total_platform_profit: "กำไรทั้งหมด",
      total_rate_amount: "ยอดค่าคอมมิชชั่น",
      total_user_bet: "จำนวนคนเดิมพัน",
      total_valid_bet_amount: "การเดิมพันที่ถูกต้องทั้งหมด",
      type: "ประเภท",
      // update_setting: '',
      updated_at: "เวลาอัพเดท",
      usable_language: "ภาษาที่ใช้ได้",
      user: "ผู้ใช้",
      user_count: "จำนวนสมาชิกที่เดิมพัน",
      video_url: "URL แหล่งที่มาของวิดีโอ",
      // icp_video_url: "",
      valid_amount: "จำนวนเงินที่ถูกต้อง",
      winner: "ผล"
    },
    times: {
      unset: "ยังไม่ได้ตั้งค่า",
      today: "วันนี้",
      yesterday: "เมื่อวาน",
      this_week: "สัปดาห์นี้",
      last_week: "สัปดาห์ที่แล้ว",
      this_month: "เดือนนี้",
      last_month: "เดือนที่แล้ว",
      custom: "กำหนดเอง",
      // tooltip: ""
    },
    menu: {
      group: {
        player: "ผู้เล่น",
        record: "บันทึก",
        manager: "ผู้จัดการ",
        control: "แผงควบคุม",
        Agent: "ตัวแทน",
        MasterAgent: "ตัวแทนหลัก",
        ArenaModerator: "สนามชนไก่",
        // Pool: ''
      },
      // SingleModerator: "",
      // MultiModerator: "",
      ControlPanel: "แผงควบคุม",
      CurrentArena: "สนามปัจจุบัน",
      Players: "ผู้เล่น",
      PlayerCredits: "เครดิตผู้เล่น",
      BetLog: "บันทึกการเดิมพัน",
      GameResults: "ผลลัพธ์ของเกม",
      Order: "ผลลัพธ์การโอน",
      AgentList: "รายชื่อตัวแทน",
      // AgentSource: "",
      AdminAgents: "ตัวแทน",
      AgentShow: "ข้อมูลตัวแทน",
      AgentRoles: "สิทธิ์ของตัวแทน",
      AgentSubAccount: "แอดมิน",
      MasterAgentList: "รายชื่อตัวแทนหลัก",
      // MasterAgentSource: "",
      Merchants: "พ่อค้า",
      MerchantRoles: "บทบาทพ่อค้า",
      MerchantAdmin: "ผู้ดูแลระบบร้านค้า",
      MerchantIpList: "การตั้งค่าจำกัด IP",
      ChangePassword: "แก้ไขรหัสผ่าน",
      GGR: "GGR",
      Profile: "ข้อมูลส่วนบุคคล",
      VideoSource: "ที่มาของวิดีโอ",
      Transfer: "ตรวจสอบรายการทำธุรการ",
      MonthlyProfit: "สรุปกำไรประจำเดือน",
      // DailyBettingStatistic: "",
      // ExchangeRate: "",
      CreateAgent: "เพิ่มตัวแทน",
      // PoolSetting: '',
      // MalayOddsSetting: '',
      // AgentCreditPoints: '',
      // AgentPointsRecord: '',
    },
    message: {
      fill_input: "โปรดป้อน {value}",
      select_value: "โปรดเลือก {value}",
      must_be_at_4_characters: "{value} ต้องมีอย่างน้อย4ตัวอักษร",
      must_be_at_6_characters: "{value} ต้องมีอย่างน้อย6ตัวอักษร",
      must_be_between_characters: "{value}ต้องอยู่ระหว่าง{min} ถึง {max} ของอักขระ",
      // must_be_between_number: "",
      must_be_alphanumeric: "{value}ต้องเป็นตัวอักษรภาษาอังกฤษหรือตัวเลข",
      // must_be_number: "",
      // must_be_number_value: "",
      two_no_match: "เนื้อหาของอินพุตทั้งสองต่างกัน",
      failed_to_create: "{value}การสร้างล้มเหลว",
      failed_to_edit: "{value}การแก้ไขล้มเหลว",
      success_to_create: "{value} สร้างสำเร็จแล้ว",
      success_to_edit: "{value} แก้ไขสำเร็จแล้ว",
      success_to_change: "{value} เปลี่ยนสำเร็จแล้ว",
      failed_to_update: "{value} อัพเดทล้มเหลว",
      decision_on_hold: "คลิกปุ่มด้านบนสนามชนไก่จะระงับฟังก์ชั่นการเดิมพันและการโอนเงินทั้งหมด",
      cancel_on_hold: "คลิกปุ่มด้านบนเพื่อเริ่มสนามชนไก่",
      close_bet: "คลิกปุ่มด้านบน สนามชนไก่จะระงับฟังก์ชั่นการเดิมพัน",
      open_bet: "คลิกปุ่มด้านบน สนามชนไก่จะระงับฟังก์ชั่นการเดิมพัน",
      mask_video: "คลิกปุ่มด้านบน หน้าบ้านจะคลุมวิดีโอ",
      cancel_mask_video: "คลิกปุ่มด้านบน หน้าบ้านจะคลุมปิดวิดีโอ",
      delete: "ลบออก{value}",
      choose_arena: "การเลือกสนามชนไก่",
      enter_fight_number: "ใส่หมายเลขการแข่งขัน",
      select_date: "กรุณาเลือกวันที่",
      select_one: "{value} ต้องเลือกอย่างน้อยหนึ่งรายการ",
      browser_not_supported: "ไม่รองรับเบราว์เซอร์นี้ กรุณากดปุ่ม OK เพื่อเปิด google chrome หรือ safari",
      network_error_title: "ข้อผิดพลาดของเครือข่าย",
      network_error_info: "โปรดยืนยันเครือข่ายของคุณพร้อมรีเฟรชหน้านี้ใหม่",
      must_be_greater_than_zero: "จำนวนเงินต้องเป็นตัวเลขที่มากกว่าหรือเท่ากับ 0",
      must_be_greater_than_min: "จำนวนเงินสูงสุดต้องมากกว่าหรือเท่ากับจำนวนเงินขั้นต่ำ",
      // incorrect_ip: "",
      // criteria_be_selected: "",
      // success_to_delete: "",
      // failed_to_delete: "",
      // failed_to_delete_with_reason: "",
      account_placeholder: "กรุณากรอก 4-16 อักษรอังกฤษและตัวเลข",
      password_placeholder: "กรุณากรอก 6-20 อักษรอังกฤษ, ตัวเลข, สัญลักษณ์",
      confirm_password_placeholder: "กรุณาใส่รหัสผ่านเดิมอีกครั้ง",
      date_type_hint: "รายงานอิงตาม \"{dateType}\" และนับข้อมูลของเมื่อวานเวลา 10:00 น. (GMT+8) ทุกวัน ยกเว้นข้อมูลของวันนี้",
      please_create_role: "ก่อนสร้างผู้ดำเนินงาน โปรดสร้างหนึ่งบทบาทก่อน",
      // max_length: '',
      // whole_url: '',
      setting_player_round_max: "กำหนดวงเงินเดิมพันขั้นต่ำและสูงสุดของผู้เล่นทุกรอบ",
      setting_player_bet_max: "ตั้งค่าการเดิมพันสูงสุดเดี่ยวของผู้เล่น",
      // A_greater_tham_B: '',
      // reset_data: ''
      // monitor: "",
      // failed_to_monitor: '',
      // failed_to_fetch: '',
      // not_found: '',
    }
  },
  AgentsPage: {
    master_account: "ไอดีตัวแทนหลัก",
    languageList: "รายการภาษา",
    create_master_agent: "เพิ่มตัวแทนหลัก",
    rate: "อัตราค่าคอมมิชชั่น",
    rate_note: "หากอัตราค่าคอมมิชชันของตัวแทนดาวน์ไลน์สูงกว่าอัตราค่าคอมมิชชันของตัวแทนอัพไลน์ ระบบจะลดอัตราค่าคอมมิชชันใหม่โดยอัตโนมัติ",
    max_with_value: "ค่าสูงสุด: {value}",
    abbr: "เขียนย่อ",
    abbr_placeholder: "กรุณากรอก 1-6 อักษรอังกฤษและตัวเลข",
    edit_agent: "แก้ไขตัวแทน"
  },
  AgentSubAccountPage: {
    authority: "สิทธิ์",
    authority_list: {
      read: "อ่าน",
      editor: "แก้ไข"
    },
    create_sub_account: "เพิ่มบัญชีแอดมิน"
  },
  BetLogPage: {
    win_lose: "แพ้ชนะ",
    bet_return: "คืนเงินเดิมพัน",
    is_settled: "ชำระแล้วใช่ไหม",
    device: "อุปกรณ์",
    amount_type: "ประเภทจำนวนเงิน",
    amount_range: "ช่วงจำนวนเงิน"
  },
  ChangePasswordPage: {
    current_password: "รหัสผ่านปัจจุบัน",
    current_password_placeholder: "กรุณาใส่รหัสผ่านปัจจุบัน",
    new_password: "รหัสผ่านใหม่"
  },
  ArenaModeratorPage: {
    video_rate: "แหล่งที่มาของวิดีโอคอมมิชชั่น",
    // online_users: ""
  },
  ControlPanelPage: {
    arena_on: "เปิด",
    arena_off: "ปิด",
    no_announce: "ไม่มีประกาศ กรุณากด \"แก้ไข\" เพื่ออัพเดท",
    edit_announce: "แก้ไขประกาศ",
    open_arena_title: "เปิดสนามชนไก่?",
    close_arena_title: "คุณแน่ใจหรือว่าต้องการปิดสนามชนไก่ของวันนี้ (การแข่งทั้งหมดจะจบลง)?",
    start_fight: "เริ่มหมายเลขต่อสู้",
    start_time: "เวลาเริ่มต้น",
    open_betting: "เปิดรับเดิมพัน ?",
    close_betting: "ปิดการเดิมพัน ?",
    re_declare_title: "ประกาศผู้ชนะใหม่?",
    re_declare_content: "ประกาศผู้ชนะใหม่ หมายเลขต่อสู้{id} ผู้ชนะคือ",
    lock_earing_title: "จ่าย?",
    lock_earing_content: "จ่าย หมายเลขต่อสู้{id}",
    click_again_to: "คลิกอีกครั้ง",
    // file_upload: "",
    // arena_cover: "",
    // mask_file: "",
    // schedule: "",
    // edit_provider_url: "",
    // img_path: "",
    // below_px_value: "",
    // img_format: "",
    // img_recommended_size: "",
    // file_size: "",
    // image: "",
    // file_no_selected: "",
    // no_image: "",
    // edit_video_url: "",
    // pool_fight_number: '',
    // round_master: '',
    message: {
      input_fight: "กรุณาใส่หมายเลขต่อสู้เริ่มต้น",
      // max_image_size: "",
      // error_image_type: "",
      // delete_image_placeholder: "",
      // success_to_earn_locked: "",
      // fail_to_earn_locked: "",
      // success_to_cancel_bet: "",
      // fail_to_cancel_bet: "",
      // affect_all_round: "",
      // operator_repeat_and_retry: ""
    }
  },
  CurrentArenaPage: {
    count: "ปริมาณ",
    unsettlement: "ยังไม่ชำระ",
    fight_action: "ดำเนินการการแข่งขัน"
  },
  GameResultsPage: {
    open_bet_at: "เวลาเริ่มต้นเก็บบิลเดิมพัน",
    close_bet_at: "เวลาสิ้นสุดเก็บบิลเดิมพัน",
    declare_at: "เวลาประกาศผล",
    reload_at: "เวลารีเซ็ต",
    cancel_at: "เวลายกเลิก",
    total_rate: "ค่าคอมมิชชั่นทั้งหมด",
    total_meron: "เมรอน-เดิมพันทั้งหมด",
    total_wala: "วาลา-เดิมพันทั้งหมด",
    total_draw: "เสมอ-ทั้งหมด",
    status_list: {
      initial: "อักษรย่อ",
      betting: "อยู่ระหว่างเดิมพัน",
      fighting: "อยู่ระหว่างแข่งขัน",
      finished: "อยู่ระหว่างชำระ",
      reloaded: "ตัดสินใหม่",
      earn_locked: "จ่าย",
      last_call: "เดิมพันล่าสุด",
      cancel: "ยกเลิก"
    },
    time: "เวลา",
    // reason: "",
    cancel_reasons: {
      // '3000': "",
      // '3001': "",
      // canceled_manual: "",
      // canceled_ws: ""
    },
    open_round: 'เวลาเริ่ม',
  },
  LoginPage: {
    log_in: "เข้าสู่ระบบ",
    otp: "OTP",
    message: {
      success: "เข้าสู่ระบบสำเร็จ",
      failed: "การป้อนบัญชีหรือรหัสผ่านล้มเหลว"
    }
  },
  MerchantsPage: {
    admin: "ผู้ดูแลระบบ",
    role: "บทบาท",
    create_merchant: "สร้างผู้ค้า",
    name: "ชื่อร้านค้า",
    message: {
      // master_agent_only_select_one: ""
    }
  },
  MerchantRolesPage: {
    // current_arena: '',
    can_permission_pages: "รายการอนุญาต",
    use_account: "ใช้บัญชี",
    create_role: "สร้างบทบาท",
    edit_role: "แก้ไขบทบาท",
    role_name: "ชื่อบทบาท",
    color: "สี",
    can_use_pages: "หน้าที่เข้าถึงได้",
    arena_on_off: "เปิด/ปิดสนามชนไก่",
    fight_control: "การควบคุมการต่อสู้",
    lock_earning: "จ่าย",
    announcement: "ประกาศ",
    decision_on_hold: "หยุดการแข่งขัน",
    monitor: "ดูแล",
    no_bets_allowed: "ไม่อนุญาตให้เดิมพัน",
    mask_video: "ปิดการมองเห็น",
    read: "อ่าน",
    update: "อัพเดท",
    create: "สร้างสรรค์",
    delete: "ลบ",
    edit: "แก้ไข้",
    dashboard_card: "แสดงจำนวนเงินทั้งหมด",
    // confirm: "",
    // approve: "",
    // arena_cover: "",
    // mask_file: "",
    // schedule: "",
    export: "ส่งออก",
    // edit_provider_url: "",
    // display_rate: "",
    // view_schedule: '',
    // view_log: '',
    account_to_use: "ยังมีบัญชีให้ใช้บทบาทนี้",
    // risk_edit: '',
    monitor_video: "ดูแลวิดีโอ",
  },
  MerchantAdminPage: {
    edit_manager: "แก้ไขผู้ดำเนินการ",
    create_manager: "สร้างบัญชีผู้ดำเนินการ"
  },
  MerchantIpPage: {
    // master_agent: "",
    create_ip: "Ipใหม่"
  },
  DailyBettingStatisticPage: {
    // yesterday_percentage: "",
    // last_week_percentage: "",
    player_count: "จำนวนสมาชิกที่เดิมพัน",
    // ave_per_fight_percentage: "",
    // last_week_ave_per_fight_percentage: ""
  },
  OrderPage: {
    after_balance: "ยอดคงเหลือหลังโอน"
  },
  PlayersPage: {
    balance: "ยอดคงเหลือ",
    fee: "ค่าธรรมเนียม",
    current_sign_in_ip: "IP ที่ใช้เข้าสู่ระบบ",
    total_deposit: "ยอดฝาก",
    total_withdraw: "ยอดถอน",
    total_win_lose: "ยอดชนะแพ้"
  },
  PlayerCreditsPage: {
    id: "หมายเลขการทำธุรการ",
    type: {
      deposit: "ฝาก",
      withdraw: "ถอน",
      settle: "ชนะ",
      cancel: "ยกเลิก",
      bet: "เดิมพัน"
    },
    before_amount: "จำนวนเงินก่อนดำเนินการ",
    after_amount: "จำนวนเงินหลังดำเนินการ"
  },
  ProfilePage: {
    // setting_round_draw_max: "กำหนดจำนวนเงินเดิมพันสูงสุดที่รับได้ในแต่ละรอบ",
    setting_round_draw_max_part1: "กำหนดจำนวนเงินเดิม",
    setting_round_draw_max_part2: "วงเงินเดิมพันสูงสุด",
    player_limit: "วงเงินเดิมพันสูงสุดของผู้เล่น",
    setting_player_max: "ตั้งค่าเดิมพันสูงสุดและต่ำสุดที่ผู้เล่นจะเดิมพันในแต่ละรอบ",
    basic_info_title: "ข้อมูลส่วนบุคคล",
    basic_info_text: "ข้อมูลพื้นฐานเกี่ยวกับตัวแทนของคุณ",
    // integration_info: '',
    // auth: '',
    // secret: '',
    // api_doc_url: '',
    // api_url: '',
    // video_traffic: '',
    // remain_video_data: '',
    // update_video_data: '',
    // alarm_quota: '',
    // set_notification_quota_insufficient_points: '',
    // update_alarm_quota: '',
    // disabled_change_status: '',
    // change_only_agent_closed: '',
    // please_turn_off_agent_status: '',
    // please_decrease_points_to_zero: '',
    // warning_no_enough_point: '',
  },
  VideoSourcePage: {
    source: "แหล่งที่มาของวิดีโอ",
    agent_source: "แหล่งที่มาของวิดีโอตัวแทนหลัก",
    accessable_merchants: "ร้านค้าที่สามารถเข้าถึงได้"
  },
  TransferPage: {
    slug: "หมายเลขการทำธุรการ",
    data: "ข้อมูล",
    res: "ตอบ",
    round_id: "รอบ",
    setting_master_id: "ตั้งค่า",
    num: "หมายเลข",
    resend: "ส่งใหม่",
    bet_num: "หมายเลข",
    resend_note: "หมายเหตุ",
    resend_mark: "ทำเครื่องหมาย",
    message: {
      success_to_resend: "การส่งซ้ำสำเร็จ No:{value}",
      fail_to_resend: "การส่งซ้ำล้มเหลว No:{value}",
      success_to_remark: "หมายเหตุสำเร็จ No:{value}",
      fail_to_remark: "หมายเหตุล้มเหลว No:{value}"
    }
  },
  ExchangeRatePage: {
    // currency: "",
    // currency_name: "",
    // last_approved_rate: "",
    // today_rate: "",
    // ref_rate: "",
    // ref_rate_time: "",
    // tbd_confirm_rate: "",
    // tbd_modify_rate: "",
    // record: "",
    // current_time: "",
    // last_approved_time: "",
    // base_currency: "",
    // fetch_exchange_rate: "",
    // approve_rate: "",
    // rate_warning: "",
    // log: "",
    // confirmed_by: "",
    // confirmed_time: "",
    // approved_by: "",
    // approved_time: "",
    // exchange_rate: ""
  },
  CreateAgentPage: {
    // parent_agent: '',
    // environment: '',
    // staging: '',
    // production: '',
    // brand: '',
    // trade_mode: '',
    // transfer_wallet: '',
    // single_wallet: '',
    // options: '',
    // level_sub_agent_with_value: '',
    // delete_agent_with_value: '',
    // add_agent_with_value: '',
    // agent_info: '',
    message: {
      // select_master_first: '',
      // agent_same_master: '',
      // please_refresh_cache: '',
      // log_saved_time: '',
      // success_refresh_cache: '',
      // fail_refresh_cache: '',
    }
  },
  PoolSettingPage: {
    // pool_name: '',
    // odds_refresh_frequency: '',
    // last_call_percentage: '',
    // close_bet_percentage: '',
    // per_bet: '',
    // per_round: '',
    // risk_threshold: '',
    // current_schedule: '',
    // new_schedule: '',
    // update_schedule: '',
    // schedule: '',
    // view: '',
    // start_time: '',
    // end_time: '',
    // pool_configuration_options: '',
    // expect_date: '',
    // expect_time: '',
    // add_new_pool_configuration: '',
    // exceed_time: '',
    // effective_time: '',
    // import_current_schedule: '',
  },
  AgentCreditPointsPage: {
    // parent_agent: '',
    // points: '',
    // edit_points: '',
    // edited_record: '',
    // increase_points: '',
    // decrease_points: '',
    // group_decrease: '',
    // points_before_change: '',
    // points_after_change: '',
    // agent_increase_points: '',
    // agent_decrease_points: '',
    // last_edited_time: '',
    // edited_time: '',
    // edited_account: '',
    // change_type: '',
    // change_type_list: {
    //   order_settle: '',
    //   order_cancel: '',
    //   order_return: '',
    //   order_bet: '',
    //   increase: '',
    //   deduct: '',
    // },
    // has_selected_value: '',
    // confirm_agent_is_closed: '',
    // confirm_all_agents_are_closed: ''
  },
}
