<template>
  <div class="new-agent">
    <a-button class="add-btn" icon="user-add" @click="showModal">
      {{ $t('global.common.create_agent') }}
    </a-button>
    <a-modal v-model="visible" :title="$t('global.common.create_agent')" :footer="null" :width="720">
      <a-form-model :layout="'vertical'" ref="newAgent" :model="newAgent" :rules="rules">
        <a-row :gutter="[16,16]">
          <a-col :xs="24" :sm="12">
            <a-form-model-item :label="$t('global.common.account')" prop="account">
              <a-input v-model="newAgent.account" :placeholder="$t('global.message.account_placeholder')" autocomplete="off" />
            </a-form-model-item>
            <a-form-model-item has-feedback :label="$t('global.common.password')" prop="password">
              <a-input v-model="newAgent.password" type="password" :placeholder="$t('global.message.password_placeholder')" autocomplete="off" />
            </a-form-model-item>
            <a-form-model-item has-feedback :label="$t('global.common.confirm_password')" prop="confirm_password">
              <a-input v-model="newAgent.confirm_password" type="password" :placeholder="$t('global.message.confirm_password_placeholder')" autocomplete="off" />
            </a-form-model-item>
            <a-form-model-item :label="$t('global.common.email')" prop="email">
              <a-input v-model="newAgent.email" />
            </a-form-model-item>
          </a-col>
          <a-col :xs="24" :sm="12">
            <a-form-model-item :label="$t('global.common.name')" prop="name">
              <a-input v-model="newAgent.name" />
            </a-form-model-item>
            <a-form-model-item :label="$t('global.common.theme')" prop="theme">
              <a-select v-model="newAgent.theme">
                <a-select-option v-for="theme in themes" :key="theme" :value="theme">
                  {{ theme }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item :label="$t('AgentsPage.abbr')" prop="abbr">
              <a-input v-model="newAgent.abbr" :placeholder="$t('AgentsPage.abbr_placeholder')"/>
            </a-form-model-item>
            <a-form-model-item :label="$t('AgentsPage.rate')" prop="rate" v-if="is_merchant_account">
              <a-row>
                <a-col :span="16">
                  <a-slider v-model="newAgent.rate" :min="rate_min" :max="rate_max" :step="0.1" />
                </a-col>
                <a-col :span="8">
                  <a-input-number
                    :value="newAgent.rate"
                    :min="rate_min"
                    :max="rate_max"
                    :step="0.1"
                    :formatter="value => `${value}%`"
                    :parser="parserValue"
                    @change="handleChangeRate"
                    style="marginLeft: 16px"
                  />
                </a-col>
              </a-row>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-form-model-item>
          <a-button type="primary" :loading="createLoading" @click="newAgentSubmit(newAgent)" block>
            {{ $t('global.action.create') }}
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
import { agentCreate, getClientTheme, getCurrentManageRate } from '@/../api';

export default {
  props:{
    is_merchant_account: Boolean,
  },
  data() {
    const checkAccount = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('global.message.fill_input', {value: this.$t('global.common.account')})));
      }else if (value.length < 4) {
        callback(new Error(this.$t('global.message.must_be_at_4_characters', {value: this.$t('global.common.account')})));
      } else if (!/^[a-zA-Z0-9_\.]*$/.test(value)) {
        callback(new Error(this.$t('global.message.must_be_alphanumeric', {value: this.$t('global.common.account')})));
      } else {
        callback();
      }
    };
    const checkPassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('global.message.fill_input', {value: this.$t('global.common.password')})));
      }else if (value.length < 4) {
        callback(new Error(this.$t('global.message.must_be_at_4_characters', {value: this.$t('global.common.password')})));
      } else {
        callback();
      }
    };
    const confirmPassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('global.message.fill_input', {value: this.$t('global.common.confirm_password')})));
      }else if (value !== this.newAgent.password) {
        callback(new Error(this.$t('global.message.two_no_match')));
      } else {
        callback();
      }
    };
    const checkName = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('global.message.fill_input', {value: this.$t('global.common.name')})));
      } else {
        callback();
      }
    };
    const checkRate = (rule, value, callback) => {
      if (Number(value) > this.rate_max) {
        callback(new Error(this.$t('AgentsPage.max', {value: Number(this.rate_max).toFixed(1)})));
      } else {
        callback();
      }
    };
    const checkAbbr = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('global.message.fill_input', {value: this.$t('AgentsPage.abbr')})));
      }else if(value.length > 6){
        callback(new Error(this.$t('global.message.must_be_between_characters', {value: this.$t('AgentsPage.abbr'), min:1, max:6})));
      } 
      else {
        callback();
      }
    };
    return {
      createLoading: false,
      visible: false,
      themes: [],
      currencys: [],
      rate_max: 0, // This agent max rate
      rate_min: 0, // This agent max rate
      newAgent: {
        account: '',
        password: '',
        confirm_password: '',
        email: '',
        name: '',
        theme: '',
        rate: 0,
        abbr: '',
      },
      rules: {
        account: [{ required: true, validator: checkAccount, trigger: 'change' }],
        password: [{ required: true, validator: checkPassword, trigger: 'change' }],
        confirm_password: [{ required: true, validator: confirmPassword, trigger: 'change' }],
        email: [{ required: true, trigger: 'change' }],
        name: [{ required: true,validator: checkName, trigger: 'change' }],
        theme: [{ required: true, trigger: 'change' }],
        rate: [{ validator:checkRate, trigger: 'change' }],
        abbr: [{ required: true ,validator:checkAbbr, trigger: 'change' }],
      },
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init(){
      getClientTheme()
        .then((data)=>{
          this.themes = data.data.themes;
        })
      getCurrentManageRate()
        .then((data) => {
          const { odds_mode, rate } = data.data;
          if(odds_mode !== 'rake'){
            this.rate_min = Number(rate) * 100;
            this.rate_max = 100;
          }else{
            this.rate_min = 0;
            this.rate_max = Number(rate) * 100;
          }
      })
    },
    showModal() {
      this.visible = true;
    },
    parserValue(value) {
      const replacePercentValue = value.replace('%', '');
      const newValue = /[^\d.]/g.test(replacePercentValue) ? replacePercentValue.replace(/[^\d.]/g,'') : replacePercentValue ;
      const numberValue = newValue.split('.');
      const integerValue = numberValue[0];
      const decimalValue = numberValue[1];
      if(decimalValue && decimalValue.length > 3){
        return `${integerValue}.${decimalValue.substring(0, 3)}`;
      }else if(Number(newValue) >= this.rate_max){
        return String(this.rate_max);
      }else {
        return newValue;
      }
    },
    handleChangeRate(value){
      const newValue = String(value).length > 5 ? Number(String(value).slice(0,5)) : Number(value);
      this.newAgent.rate = newValue;
    },
    newAgentSubmit() {
      if(this.createLoading) return;
      this.$refs.newAgent.validate((valid) => {
        if (!valid) {
          this.$message.error(this.$t('global.message.failed_to_create', {value: this.$t('global.common.account')}));
          return false;
          } else {
          const { account, password, name, theme, rate, email, abbr } = this.newAgent;
          const ratePercentage = rate / 100;
          this.createLoading = true;
          agentCreate({ account, password, name, client_theme: theme, fee: ratePercentage, email, abbr })
            .then((data) => {
              this.$message.success(this.$t('global.message.success_to_create', {value: this.$t('global.common.account')}));
              this.visible = false;
              this.$refs.newAgent.resetFields();
              this.$emit("created");
            })
            .catch((err) => {
              this.$message.error(err.response.data.message);
            });
        }
      });
    },
  },
};
</script>
