<template>
  <div class="new-sub-account">
    <a-form-model :layout="'vertical'" ref="agentSubAccount" :model="agentSubAccount" :rules="rules">
      <a-form-model-item :label="$t('global.common.change_password')" prop="password">
        <a-input-password v-model="agentSubAccount.password" :placeholder="$t('global.message.password_placeholder')" />
      </a-form-model-item>

      <a-form-model-item :label="$t('global.common.name')" prop="name">
        <a-input v-model="agentSubAccount.name" />
      </a-form-model-item>

      <a-form-model-item :label="$t('AgentSubAccountPage.authority')" prop="authority">
        <a-select v-model="agentSubAccount.authority">
          <a-select-option v-for="authority in authoritys" :value="authority.id" :key="authority.id">
            <a-tag :color="authority.color">
              {{ $t(`AgentSubAccountPage.authority_list.${authority.name}`) }}
            </a-tag>
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item>
        <a-button type="primary" @click="agentSubAccountSubmit(agentSubAccount)" block>
          {{ $t('global.action.edit') }}
        </a-button>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>
<script>
import { 
  getAgentAllRoles, 
  updateAgentManager, 
  // getAgentManagerList 
  } from '@/../api';

export default {
  props: {
    agentSubAccount: Object,
    closeForm: Function,
    fetchData: Function
  },
  data() {
    const checkPassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('global.message.fill_input', {value: this.$t('global.common.password')})));
      }else if (value.length < 6) {
        callback(new Error(this.$t('global.message.must_be_at_6_characters', {value: this.$t('global.common.password')})));
      } else {
        callback();
      }
    };
    const checkName = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('global.message.fill_input', {value: this.$t('global.common.name')})));
      } else {
        callback();
      }
    };
    return {
      authoritys: [
        // {
        //   id: 1,
        //   name: 'read',
        //   color: 'blue',
        // },
        // {
        //   id: 2,
        //   name: 'editor',
        //   color: 'pink',
        // },
      ],
      rules: {
        password: [{ validator: checkPassword, trigger: 'change' }],
        name: [{ validator: checkName, trigger: 'change' }],
        authority: [
          { required: true,
            message: this.$t('global.message.select_value', {value: this.$t('AgentSubAccountPage.authority')}),
            trigger: 'blur'
          },
        ],
      },
    };
  },
  mounted() {
    getAgentAllRoles()
      .then((data)=>{
        this.authoritys = data.data.role;
      })
  },
  methods: {
    agentSubAccountSubmit() {
      const { password, name, authority, id } = this.agentSubAccount
      this.$refs.agentSubAccount.validate((valid) => {
        if (!valid) {
          this.$message.error(this.$t('global.message.failed_to_update', {value: this.$t('global.common.account')}));
          return false;
        } else {
          updateAgentManager({name, role_id: authority, id, password})
            .then((data)=>{
              this.$message.success(this.$t('global.message.success_to_change', {value: this.$t('global.common.account')}));
              this.fetchData();
            })
            .catch((err)=>{
              this.$message.error(err.response.data.message)
            }).finally(()=>{
              this.closeForm();
            })
        }
      });
    },
  },
};
</script>
