<template>
  <a-modal
    :visible="formShow"
    :footer="null"
    :width="450"
    @cancel="closeModal"
    :afterClose="resetForm"
  >
    <template slot="title">
      {{ $t("global.action.edit") }}
      <span class="primary-font-color"> {{ betLimit.account }}</span>
    </template>

    <a-form-model
      :layout="'vertical'"
      ref="betLimitForm"
      :model="roundBetLimit"
      :rules="rules"
      class="player-betting-limit-dialog"
    >
      <a-form-model-item>
        <a-checkbox v-model="no_login">
          {{ $t("global.common.risk_type_list.no_login") }}
        </a-checkbox>
      </a-form-model-item>

      <div class="limit-group">
        <a-form-model-item>
          <a-checkbox :checked="checked" @change="handleChange">
            {{ $t("global.common.risk_type_list.bet_limit") }}
          </a-checkbox>
        </a-form-model-item>

        <a-form-model-item class="limit-item">
          <p class="limit-item-title">
            {{ $t("global.common.player_round_limit") }}
          </p>
          <p class="limit-item-sub-title">
            {{ $t("global.message.setting_player_round_max") }}
          </p>
          <table>
            <thead>
              <tr>
                <!-- <td></td>
                    <td>{{ $t('global.common.min') }}</td> -->
                <td></td>
                <td>{{ $t("global.common.max") }}</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <span :class="getCurrentSide('meron')">{{
                    $t(`global.fight_result.${getCurrentSide("meron")}`)
                  }}</span>
                  <span> / </span>
                  <span :class="getCurrentSide('wala')">{{
                    $t(`global.fight_result.${getCurrentSide("wala")}`)
                  }}</span>
                </td>
                <!-- <td><a-input-number v-model="roundBetLimit.meron_wala.min" /></td>
                    <td>-</td> -->
                <td>
                  <a-input-number
                    :disabled="!checked"
                    v-model="roundBetLimit.meron_wala.max"
                  />
                </td>
              </tr>
              <tr>
                <template v-if="game_type === 'apple'">
                  <td>
                    <span :class="getCurrentSide('red3')">{{
                      $t(`global.fight_result.${getCurrentSide("red3")}`)
                    }}</span>
                    <span> / </span>
                    <span :class="getCurrentSide('white3')">{{
                      $t(`global.fight_result.${getCurrentSide("white3")}`)
                    }}</span>
                  </td>
                </template>
                <template v-else>
                  <td :class="getCurrentSide('draw')">
                    {{ $t(`global.fight_result.${getCurrentSide("draw")}`) }}
                  </td>
                </template>

                <!-- <td><a-input-number v-model="roundBetLimit.draw.min" /></td>
                  <td>-</td> -->
                <td>
                  <a-input-number
                    :disabled="!checked"
                    v-model="roundBetLimit.draw.max"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </a-form-model-item>

        <a-form-model-item class="limit-item">
          <p class="limit-item-title">
            {{ $t("global.common.player_bet_limit") }}
          </p>
          <p class="limit-item-sub-title">
            {{ $t("global.message.setting_player_bet_max") }}
          </p>
          <table>
            <thead>
              <tr>
                <td></td>
                <td>{{ $t("global.common.min") }}</td>
                <td></td>
                <td>{{ $t("global.common.max") }}</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <span :class="getCurrentSide('meron')">{{
                    $t(`global.fight_result.${getCurrentSide("meron")}`)
                  }}</span>
                  <span> / </span>
                  <span :class="getCurrentSide('wala')">{{
                    $t(`global.fight_result.${getCurrentSide("wala")}`)
                  }}</span>
                </td>
                <td>
                  <a-input-number
                    :disabled="!checked"
                    v-model="roundBetLimit.meron_wala.min"
                  />
                </td>
                <td>-</td>
                <td>
                  <a-input-number
                    :disabled="!checked"
                    v-model="roundBetLimit.meron_wala.per_bet"
                  />
                </td>
              </tr>
              <tr>
                <template v-if="game_type === 'apple'">
                  <td>
                    <span :class="getCurrentSide('red3')">{{
                      $t(`global.fight_result.${getCurrentSide("red3")}`)
                    }}</span>
                    <span> / </span>
                    <span :class="getCurrentSide('white3')">{{
                      $t(`global.fight_result.${getCurrentSide("white3")}`)
                    }}</span>
                  </td>
                </template>
                <template v-else>
                  <td :class="getCurrentSide('draw')">
                    {{ $t(`global.fight_result.${getCurrentSide("draw")}`) }}
                  </td>
                </template>
                <td>
                  <a-input-number
                    :disabled="!checked"
                    v-model="roundBetLimit.draw.min"
                  />
                </td>
                <td>-</td>
                <td>
                  <a-input-number
                    :disabled="!checked"
                    v-model="roundBetLimit.draw.per_bet"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </a-form-model-item>
      </div>

      <a-form-model-item>
        <a-button type="primary" class="submit-btn" @click="submitForm" block>
          {{ $t("global.action.submit") }}
        </a-button>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import { GAME_TYPE_SIDE } from "@/../tools/config";
import { updatePlayerBetLimit } from "@/../api";

export default {
  props: {
    closeModal: Function,
    formShow: Boolean,
    betLimit: Object,
    game_type: {
      type: String,
      default: "",
    },
    refreshCurrentPlayerLimit: Function,
  },
  data() {
    return {
      GAME_TYPE_SIDE,
      checked: false,
      no_login: false,
      roundBetLimit: {
        meron_wala: {
          per_bet: 0,
          max: 0,
          min: 0,
        },
        draw: {
          per_bet: 0,
          max: 0,
          min: 0,
        },
      },
      rules: {},
    };
  },
  watch: {
    formShow: {
      handler(newValue) {
        if (newValue) {
          this.checked = this.betLimit.is_reduce_limit || false;
          this.no_login = this.betLimit.no_login || false; 
          this.$set(this, "roundBetLimit", {
            meron_wala: {
              per_bet: this.betLimit.meron_wala.per_bet,
              max: this.betLimit.meron_wala.per_round.max,
              min: this.betLimit.meron_wala.per_round.min,
            },
            draw: {
              per_bet: this.betLimit.draw.per_bet,
              max: this.betLimit.draw.per_round.max,
              min: this.betLimit.draw.per_round.min,
            },
          });
        }
      },
      deep: true,
    },
  },
  methods: {
    getCurrentSide(side) {
      const game_type = this.game_type || "cockfight";
      return this.GAME_TYPE_SIDE[game_type][side];
    },
    editLimit() {
      this.updateLimit(this.roundBetLimit, this.closeModal);
    },
    handleChange(e) {
      const isChecked = e.target.checked;
      this.checked = isChecked;
    },
    submitForm() {
      const betLimit = {
        id: this.betLimit.id,
        is_reduce_limit: this.checked,
        no_login: this.no_login,
        meron_wala: {
          per_bet: this.roundBetLimit.meron_wala.per_bet,
          per_round: {
            min: this.roundBetLimit.meron_wala.min,
            max: this.roundBetLimit.meron_wala.max,
          },
        },
        draw: {
          per_bet: this.roundBetLimit.draw.per_bet,
          per_round: {
            min: this.roundBetLimit.draw.min,
            max: this.roundBetLimit.draw.max,
          },
        },
      };
      updatePlayerBetLimit({ betLimit })
        .then((res) => {
          this.$message.success(
            this.$t("global.message.success_to_change", {
              value: this.betLimit.account,
            })
          );
          this.refreshCurrentPlayerLimit(res.data, this.betLimit.id);
          this.closeModal();
        })
        .catch(() => {
          this.$message.error(this.$t("global.message.failed_to_update"));
        });
    },
    resetForm() {
      this.checked = false;
      this.no_login = false;
      this.$set(this, "roundBetLimit", {
        meron_wala: {
          per_bet: 0,
          max: 0,
          min: 0,
        },
        draw: {
          per_bet: 0,
          max: 0,
          min: 0,
        },
      });
    },
  },
};
</script>
