<template>
  <a-modal :visible="recordFormShow"
           :footer="null"
           :centered="true"
           :width="'unset'"
           :title="$t(`ExchangeRatePage.log`)" 
           @cancel="closeModal">
    <div class="exchange-rate-dialog">
      <a-row :gutter="[0, 16]">
        <a-col :xs="24">
          <a-col :xs="24" :sm="8">
            <div class="bold">
              <span>{{ $t('global.common.currency') }}:</span>
              <span>{{ exchangeRateInfo.currency }}</span>
            </div>
          </a-col>

          <a-col :xs="24" :sm="8">
            <div class="bold">
              <span>{{ $t('ExchangeRatePage.currency_name') }}:</span>
              <span>{{ exchangeRateInfo.currency_name }}</span>
            </div>
          </a-col>
        </a-col>
      </a-row>

      <a-table
          bordered
          :columns="columns"
          :data-source="data"
          :row-key="data => data.id"
          :loading="loading"
          :pagination="pagination"
          @change="handleTableChange"
      >
        <template v-for="(column,index) in columns">
          <span :slot="column.slots.title" :key="index">{{ $t(`ExchangeRatePage.${column.slots.title}`) }}</span>
        </template>

        <template slot="exchange_rate" slot-scope="exchange_rate">
          <div>{{ numeral(exchange_rate).format('0,0[.][000]') }}</div>
        </template>

        <template slot="formatString" slot-scope="string">
          <div>{{ string | formatString }}</div>
        </template>

        <template slot="formatTimes" slot-scope="times">
          <div class="table-time">{{ times | formatTimesToUTC8 }}</div>
        </template>
      </a-table>
    </div>
  </a-modal>
</template>

<script>
import { getExchangeRateLog } from '@/../api';
import numeral from 'numeral';

const columns = [
  {
    slots: { title: 'confirmed_by' },
    dataIndex: 'confirmed_by',
    scopedSlots: { customRender: 'formatString'},
  },
  {
    slots: { title: 'confirmed_time' },
    dataIndex: 'confirmed_time',
    scopedSlots: { customRender: 'formatTimes' },
  },
  {
    slots: { title: 'approved_by' },
    dataIndex: 'approved_by',
  },
  {
    slots: { title: 'approved_time' },
    dataIndex: 'approved_time',
    scopedSlots: { customRender: 'formatTimes' },
  },
  {
    slots: { title: 'exchange_rate' },
    dataIndex: 'exchange_rate',
    scopedSlots: { customRender: 'exchange_rate'},
  },
]

export default {
  props: {
    recordFormShow: Boolean,
    closeModal: Function,
    exchangeRateInfo: Object
  },
  data() {
    return {
      data: [],
      loading: false,
      columns,
      pagination: {
        pageSize: 10,
        current: 1,
        total: 0,
        showTotal: (total) => `Total ${total} items`, // 显示总数
      },
    };
  },
  methods: {
    numeral,
    fetchData(){
      this.loading = true;

      getExchangeRateLog({
        id: this.exchangeRateInfo.id,
        page_size: this.pagination.pageSize,
        page: this.pagination.current
        })
        .then(({ data }) => {
          this.data = data.log;
          this.pagination.total = data.total || data.log.length;
        }).finally(()=>{
          this.loading = false;
        });
    },
    handleTableChange(pagination, filters, sorter) {
      const currentPage = this.pagination.pageSize !== pagination.pageSize ? 1 : pagination.current;
      this.pagination.current = currentPage;
      this.pagination.pageSize = pagination.pageSize;
      this.fetchData();
    },
  },
  watch:{
    exchangeRateInfo:{
      immediate: true,
      deep: true,
      handler(newValue, oldValue){
        if(newValue.id){
          this.pagination.current = 1;
          // this.fetchData();
        }
      }
    },
    recordFormShow:{
      immediate: true,
      handler(newValue, oldValue){
        if(newValue){
          this.fetchData();
        }
      }
    },
  }
};
</script>
