<template>
  <fragment>
    <a-form-model-item ref="currency" :label="$t('global.common.currency')" prop="currency" v-if="showCurrency">
      <a-select
          show-search
          v-model="currency"
          @dropdownVisibleChange="currencyShow"
      >
        <template #notFoundContent v-if="currencyLoading">
          <a-spin size="small" />
        </template>
        <a-select-option v-for="(currency, i) in currencyList" :value="currency" :key="i">
          {{ currency }}
        </a-select-option>
      </a-select>
    </a-form-model-item>

    <a-form-model-item ref="master" :label="$t('global.common.master')" prop="master" v-if="showMaster">
      <a-select
          show-search
          :mode="multipleMaster ? 'multiple' : 'default'"
          :showArrow="true"
          :filter-option="filterOption"
          v-model="master"
      >
        <a-select-option v-for="(master, i) in masterList" :value="master.id" :key="i">
          {{ master.account }}
        </a-select-option>
      </a-select>
    </a-form-model-item>
    <a-form-model-item ref="agent" :label="$t('global.common.agent')" prop="agent" v-if="showAgent">
      <a-select
        show-search
        :mode="multipleAgent ? 'multiple' : 'default'"
        :showArrow="true"
        :filter-option="filterOption"
        v-model="agent"
      >
        <template #notFoundContent v-if="fetchingAgentList">
          <div class="spin-center">
            <a-spin size="small" />
          </div>
        </template>
        <a-select-option v-for="(agent, i) in agentList" :value="agent.id" :key="i">
            {{ agent.account }}
        </a-select-option>
      </a-select>
    </a-form-model-item>
    <a-form-model-item ref="link_master" :label="$t('global.common.link_master')" prop="link_master" v-if="showLinkMaster">
      <a-select
          show-search
          :filter-option="filterOption"
          v-model="master"
      >
        <a-select-option v-for="(master, i) in masterList" :value="master.id" :key="i">
          {{ master.account }}
        </a-select-option>
      </a-select>
    </a-form-model-item>
  </fragment>
</template>
<script>
import { isEqual } from 'lodash';
import { getMerchantCurrency, getMasterByCurrency, getAgentByCurrency, getAgentByMaster, getManageDescendantList, getLinkMasterByCurrency } from '@/../api';
import storage, { IDENTITY } from '@/../storage';
import { mapState } from 'vuex';
import { filterDuplicateData } from '@/../tools/common';

let timer;

const clearTimer = () => {
  if (timer) {
    clearTimeout(timer);
    timer = undefined;
  }
};

export default {
  props: {
    inputCurrency: {
      type: String,
    },
    inputMaster: {
      type: [Number, Array],
    },
    inputAgent: {
      type: [Number, Array],
    },
    showCurrency: {
      type: Boolean,
      default: true
    },
    showMaster: {
      type: Boolean,
      default: true
    },
    showAgent: {
      type: Boolean,
      default: true
    },
    currencyRequired: {
      type: Boolean,
      default: false,
    },
    masterRequired: {
      type: Boolean,
      default: false
    },
    showLinkMaster: {
      type: Boolean,
      default: false,
    },
    multipleMaster: {
      type: Boolean,
      default: false,
    },
    multipleAgent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currencyLoading: false,
      currencyisLoaded: false,
      currencyList: [],
      currency: '',
      masterList: [],
      master: this.initValueFormat(this.multipleMaster),
      agent: this.initValueFormat(this.multipleAgent),
      agentList: [
        // {
        //   account: 'hank_agent',
        //   id: 99999
        // },
        // {
        //   account: 'hank_agent2',
        //   id: 99998
        // }
      ],
      fetchingAgentList: false,
      prefix: ''
    };
  },
  computed: {
    ...mapState({currentProfile: state => state.auth.currentProfile}),
    currencyGroup(){
      const { currency, master, agent, masterRequired } = this;
      return {
        currency,
        master,
        agent,
        masterRequired
      }
    },
    currencySetting() {
      const { inputCurrency, prefix, currencyList } = this;
      return {
        inputCurrency,
        prefix,
        currencyList,
      }
    }
  },
  mounted() {
    if(!this.currencyRequired && this.showAgent){
      this.fetchAgentList();
    }
  },
  methods: {
    initValueFormat(multiple){
      return multiple ? [] : '';
    },
    judgeNotEmptyValueFormat( multiple, value ){
      //value's type : '' / [];
      if(multiple){
        return Array.isArray(value) && value.length !== 0;
      } else {
        return value !== '';
      }
    },
    currencyShow(){
      if(!this.currencyisLoaded){
        this.currencyLoading = true;
        this.fetchCurrencyList();
      }
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    fetchCurrencyList() {
      getMerchantCurrency().then((res)=>{
        const {currencies} = res.data;
        this.currencyList = filterDuplicateData(currencies,'currency');
        this.currencyisLoaded = true;
        if(this.currencyLoading){
          this.currencyLoading = false;
        }
      });
    },
    fetchMasterByCurrency(currency) {
      getMasterByCurrency({ currency: currency }).then((data)=>{
        const masterList = data.data.master;
        this.masterList = filterDuplicateData(masterList,'id');
        if(masterList.length !== 0 && this.masterRequired){
          this.master = this.multipleMaster ? [this.masterList[0].id] : this.masterList[0].id;
        }
      })
    },
    fetchAgentByCurrency(currency) {
      getAgentByCurrency({ currency: currency }).then((data)=>{
        this.agentList = filterDuplicateData(data.data.agent,'id');
      })
    },
    fetchAgentByMaster(master) {
      getAgentByMaster({ master: master }).then((data)=>{
        this.agentList = filterDuplicateData(data.data.agent,'id');
      })
      .finally(()=>{
        if(this.fetchingAgentList){
          this.fetchingAgentList = false;
        }
      })
    },
    fetchAgentList() {
      getManageDescendantList().then((data) => {
        this.agentList = filterDuplicateData(data.data.agents,'id');
      });
    },
    fetchLinkMasterByCurrency(currency) {
      getLinkMasterByCurrency({ currency: currency }).then((data)=>{
        this.masterList = filterDuplicateData(data.data.master,'id');
      })
    },
  },
  watch:{
    currentProfile: {
      handler(newValue, oldValue){
        if (Object.keys(newValue).length !== 0) {
          if((storage.getItem(IDENTITY) === 'master' || storage.getItem(IDENTITY) === 'agent') && this.currencyRequired){
            this.currency = newValue.currency;
          }
          if(storage.getItem(IDENTITY) === 'master' && !this.showMaster){
            this.master = this.multipleMaster ? [newValue.id] : newValue.id
          }
          if(storage.getItem(IDENTITY) === 'agent' && !this.showAgent){
            this.agent = this.multipleAgent ? [newValue.id] : newValue.id;
          }

          this.prefix = newValue.prefix;

          if(this.currencyRequired && storage.getItem(IDENTITY) === 'merchant'){
            this.fetchCurrencyList();
          }
        }
      },
      deep: true,
      immediate: true
    },
    inputAgent(newValue, oldValue) {
      if (newValue === null) {
        this.agent = this.initValueFormat(this.multipleAgent);
      }
    },
    inputMaster(newValue, oldValue) {
      if (newValue === null) {
        this.master = this.initValueFormat(this.multipleMaster);
      }
    },
    currencySetting: {
      handler(newValue,oldValue){
        if(newValue.inputCurrency === '' && newValue.currencyList.length > 0){
          const setExpectedCurrency = () => {
            switch(newValue.prefix){
              case 'ds88':
                return 'PHP';
              case 'ws168':
                return 'KVND';
              default:
                return newValue.currencyList[0];
            }
          }

          const expectedCurrency = setExpectedCurrency();
          const defaultCurrency = newValue.currencyList.find(element => element === expectedCurrency);
          const currentCurrency = defaultCurrency ? defaultCurrency : newValue.currencyList[0];
          if(this.currency !== currentCurrency){
            this.currency = currentCurrency;
          }else{
            this.$emit('update:inputCurrency', currentCurrency);
          }
        }
      },
      deep: true
    },
    currencyGroup: {
      handler(newValue, oldValue) {

        if(!this.currencyRequired && oldValue === undefined) return;

        //如果currency不變 && master不變才會觸發
        if(!oldValue || isEqual(oldValue.currency, newValue.currency) && isEqual(oldValue.master, newValue.master)){
          if(this.judgeNotEmptyValueFormat(this.multipleAgent, newValue.agent)) {
            this.$emit('update:inputAgent', newValue.agent);
          }else{
            this.$emit('update:inputAgent', null);
          }
        }

        //master變動才會觸發
        if(!oldValue || !isEqual(oldValue.master, newValue.master)){
          this.$emit('update:inputAgent', null);
          if(this.judgeNotEmptyValueFormat(this.multipleMaster, newValue.master)){
            this.$emit('update:inputMaster', newValue.master);
            if(this.showAgent) {
              const masterValue = Array.isArray(newValue.master) ? newValue.master : [newValue.master];

              if(this.multipleMaster){
                this.agentList = [];
                this.fetchingAgentList = true;
                if(timer){
                  clearTimer();
                }
                timer = setTimeout(()=>{
                  clearTimer();
                  this.fetchAgentByMaster(masterValue);
                }, 1000);
              }else{
                this.fetchAgentByMaster(masterValue);
              }

            }
          } else {
            this.$emit('update:inputMaster', null);
          }
        }

        //如果currency變動
        if(!oldValue || !isEqual(oldValue.currency, newValue.currency)){
          if(newValue.currency !== ''){
            this.$emit('update:inputCurrency', newValue.currency);
            if(storage.getItem(IDENTITY) === 'merchant' && !newValue.masterRequired){
              this.$emit('update:inputMaster', null);
            }
            if(this.currencyRequired && storage.getItem(IDENTITY) === 'merchant'){
              if(this.showMaster){
                this.fetchMasterByCurrency(newValue.currency);
              }
              if(this.showAgent && !newValue.masterRequired){
                this.fetchAgentByCurrency(newValue.currency);
              }
              if(this.showLinkMaster){
                this.fetchLinkMasterByCurrency(newValue.currency);
              }
            }

            if(storage.getItem(IDENTITY) === 'master' && this.showMaster){
              this.fetchMasterByCurrency(newValue.currency);
              if(this.showAgent){
                this.fetchAgentByCurrency(newValue.currency);
              }
            }

            if(storage.getItem(IDENTITY) === 'agent' && this.showAgent){
              this.fetchAgentByCurrency(newValue.currency);
            }
          }
        }else{
          if(storage.getItem(IDENTITY) === 'merchant') {
            if(newValue.masterRequired) {
              if(!this.judgeNotEmptyValueFormat(this.multipleMaster, newValue.master) && this.masterList.length !== 0) {
                this.master = this.multipleMaster ? [this.masterList[0].id] : this.masterList[0].id;
              }
            }else {
              if(!this.judgeNotEmptyValueFormat(this.multipleMaster, newValue.master) && this.judgeNotEmptyValueFormat(this.multipleMaster, oldValue.master) && this.showAgent) {
                this.fetchAgentByCurrency(newValue.currency);
              }
              if(oldValue.masterRequired) {
                this.$emit('update:inputMaster', null);
              }
            }
          }
        }
      },
      deep: true,
      immediate: true
    },
  }
};
</script>
