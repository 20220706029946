export default {
  lang: 'Bahasa Melayu(馬來文)',
  global: {
    game_type: {
      // cockfight: '',
      // spider: '',
      // color_game: '',
      // apple: '',
      // up_down: '',
    },
    fight_result: {
      meron: "Meron",
      wala: "Wala",
      draw: "Lukis",
      draw2: "Lukis",
      cancel: "Batal",
      red: "Merah",
      blue: "Biru",
      gold: "Emas",
      // white: '',
      // red3: '',
      // white3: '',
      // down: '',
      // up: '',
    },
    action: {
      yes: "Ya",
      no: "Tidak",
      go: "Pergi",
      search: "Cari",
      reset: "Tetapkan semula",
      create: "Buat",
      logout: "Log keluar",
      submit: "Hantar",
      edit: "Edit",
      // risk_edit: '',
      lock_earning: "Kunci Perolehan",
      decision_on_hold: "Keputusan Ditahan",
      cancel_on_hold: "Batal ditahan",
      // not_allow_bet: "",
      // allow_bet: "",
      next: "Langkah seterusnya",
      prev: "Sebelumnya",
      step: "langkah",
      // ok: "",
      // collapse: "",
      // mask_video: "",
      // cancel_mask_video: "",
      // records: "",
      cancel: "Batal",
      export: "Eksport",
      // select_file: "",
      // upload_image: "",
      // cancel_upload: "",
      // update: '',
      // update_all: "",
      // upload_all_images: "",
      // cancel_update_all: "",
      // refresh_fights: "",
      open_bet: "Buka",
      close_bet: "Tutup",
      re_declare: "RE-Menyatakan",
      // locking: "",
      open_arena: "Buka Arena",
      close_arena: "Tutup semua pusingan di bawah Pool",
      // refresh_cache: '',
      // cancel_all: '',
      delete: "padam",
      monitor: "Pantau",
      // monitor_video: '',
      // retry: '',
      // refresh: '',
    },
    common: {
      account: "Akaun",
      account_id: "nombor siri",
      active_status_list: {
        all: "Semua",
        active: "Hidupkan",
        close: "penutupan",
        // enable: '',
        // disable: ''
      },
      action: "Action",
      // add_settle_bet_info: '',
      agent: "Ejen",
      // agent_account: "",
      agent_count: "Kira Pemain",
      // arena: "",
      arena_suspend: "Arena Menangguhkan",
      all: "Semua",
      amount: "Jumlah",
      announcement: "Pengumuman",
      // average_percentage: "",
      // bet_amount: "",
      // bet_count: "",
      bet_type_list: {
        all: "Semua",
        // deposit: "",
        withdraw: "Tarik balik",
        // OrderDeposit: "",
        OrderWithdraw: "Tarik balik",
        // bet: "",
        // settle: "",
        // cancel: "",
        // resettle: "",
        fail: "Gagal",
        // init: ""
      },
      // business_status: '',
      // calculation_base: '',
      // callback_url: '',
      change_password: "Tukar kata laluan",
      // confirm: "",
      confirm_password: "Sahkan kata laluan",
      create_agent: "Buat Ejen",
      // create_setting: '',
      // created_at: "",
      // currency: "",
      // current_points: '',
      // custom_logo: '',
      // custom_logo_list: {
      //   odin: '',
      //   gleague: '',
      //   fiesta: '',
      //   fiestafruit: '',
      // },
      date: "Tarikh",
      // date_type: "",
      date_type_list: {
        // BetAt: "",
        // GameDate: "",
        // SettleAt: "",
        // OpenRound: ""
      },
      declare_fight_winner: "Nyatakan Pemenang Pertarungan",
      // defaultLanguage: "",
      description: "Penerangan",
      // email: "",
      // fight_count: "",
      fight_id: "Fight ID",
      // game_date: "",
      // game_type: '',
      // ggr: '',
      // ip: "",
      id: "nombor siri",
      // kill_rate: "",
      language: "Bahasae",
      last_login: "Log masuk terakhir",
      // link_master: "",
      lock_earning: "Kunci Perolehan",
      // log: "",
      // main_arena: '',
      // main_pool: '',
      manager: "pengurus",
      // master: "",
      max: "Taruhan tertinggi",
      merchant: "Saudagar",
      merchant_account: "ID Pedagang",
      // meron_wala: "",
      min: "Pertaruhan minimum",
      // month: "",
      // multi_arena: "",
      multi_arena_status: {
        // open: "",
        // close: ""
      },
      name: "Akaun proksi",
      odd: "Aneh",
      // odds_type: "",
      // odds_type_list: {
      //   rake: "",
      //   malay: "",
      //   static: ""
      // },
      // operator: "",
      // options: '',
      // order_num: "",
      order_status_list: {
        init: "Di dalamnya",
        // process: "",
        success: "Kejayaan",
        failed: "Gagal",
        // pending: "",
        fail: "Gagal",
        // error: ""
      },
      password: "Kata Laluan",
      payout: "Pembayaran",
      // platform_profit: "",
      player_account: "Akaun Pemain",
      // player_name: '',
      player_bet_limit: "Pemain setiap Had Pertaruhan",
      player_count: "nama",
      player_round_limit: "Had Pertaruhan Pusingan Pemain",
      // pool_status: '',
      profile: "Maklumat Ejen",
      // provider_name: "",
      rate: "Kadar Komisen",
      // rate_amount: "",
      // risk_odd: "",
      role: "Peranan",
      // risk_control: '',
      // risk_type_list:{
      //   bet_limit: '',
      // no_login: '',
      // },
      round_id: "Berjuang #",
      round_limit: "Had pertaruhan setiap pusingan",
      // round_status: '',
      round_status_list: {
        idle: "Menganggur",
        open: "Buka",
        close: "Tutup",
        locked: "Terkunci",
        // last_call: "",
        // arena_close: "",
        unsettled: 'Belum Selesai',
        settled: 'Selesai',
      },
      status: "keadaan",
      // setting: '',
      // settled_at: "",
      // settle_zero: '',
      // side: "",
      // skin: '',
      // skin_list: {
      //   default: '',
      //   brown: '',
      //   white: '',
      //   black: '',
      //   green: '',
      //   dark_and_light: '',
      // },
      slug: "Slug",
      theme: "tema",
      // total: "",
      total_bet_amount: "Jumlah Pertaruhan",
      total_bet_count: "Jumlah Pertaruhan",
      // total_platform_profit: "",
      // total_rate_amount: "",
      // total_user_bet: "",
      // total_valid_bet_amount: "",
      type: "Jenis",
      // update_setting: '',
      // updated_at: "",
      // usable_language: "",
      // user: "",
      // user_count: "",
      // video_url: "",
      // icp_video_url: "",
      valid_amount: "Jumlah Sah",
      winner: "Pemenang"
    },
    times: {
      unset: "Tidak ditetapkan",
      today: "Hari ini",
      yesterday: "Semalam",
      this_week: "Minggu ini",
      last_week: "Minggu lepas",
      this_month: "Bulan ini",
      last_month: "Bulan lepas",
      custom: "Adat",
      // tooltip: ""
    },
    menu: {
      group: {
        player: "Pemain",
        record: "Rekod",
        manager: "Pengurus",
        control: "Panel kawalan",
        Agent: "Ejen",
        // MasterAgent: "",
        ArenaModerator: "Padang sabung ayam",
        // Pool: ''
      },
      // SingleModerator: "",
      // MultiModerator: "",
      ControlPanel: "Panel kawalan",
      CurrentArena: "Arena Semasa",
      Players: "Pemain",
      PlayerCredits: "Kredit Pemain",
      BetLog: "Log Pertaruhan",
      GameResults: "Hasil Permainan",
      Order: "Pesanan",
      // AgentList: "",
      // AgentSource: "",
      AdminAgents: "Ejen",
      // AgentShow: "",
      // AgentRoles: "",
      AgentSubAccount: "pengurus",
      // MasterAgentList: "",
      // MasterAgentSource: "",
      Merchants: "Pedagang",
      MerchantRoles: "Peranan saudagar",
      // MerchantAdmin: "",
      // MerchantIpList: "",
      ChangePassword: "Tukar kata laluan",
      GGR: "GGR",
      Profile: "maklumat peribadi",
      // VideoSource: "",
      // Transfer: "",
      // MonthlyProfit: "",
      // DailyBettingStatistic: "",
      // ExchangeRate: "",
      CreateAgent: "Buat Ejen",
      // PoolSetting: '',
      // MalayOddsSetting: '',
      // AgentCreditPoints: '',
      // AgentPointsRecord: '',
    },
    message: {
      fill_input: "Sila masukkan {value}.",
      select_value: "Sila pilih {value}",
      must_be_at_4_characters: "{value} sekurang-kurangnya 4 aksara",
      must_be_at_6_characters: "{value} sekurang-kurangnya 6 aksara",
      // must_be_between_characters: "",
      // must_be_between_number: "",
      must_be_alphanumeric: "{value} mestilah abjad angka",
      // must_be_number: "",
      // must_be_number_value: "",
      two_no_match: "Dua input tidak sepadan!",
      failed_to_create: "Gagal membuat {value}",
      // failed_to_edit: "",
      success_to_create: "Berjaya membuat {value}",
      // success_to_edit: "",
      success_to_change: "{value} berjaya diubah suai",
      failed_to_update: "{value} gagal dikemas kini",
      decision_on_hold: "Setelah diklik, Arena akan ditangguhkan.",
      cancel_on_hold: "Setelah diklik, Arena akan bermula.",
      // close_bet: "",
      // open_bet: "",
      // mask_video: "",
      // cancel_mask_video: "",
      delete: "Padamkan {value}",
      choose_arena: "Pilih Arena sabung ayam",
      enter_fight_number: "Masukkan bilangan perlawanan",
      select_date: "Sila pilih tarikh",
      // select_one: "",
      // browser_not_supported: "",
      // network_error_title: "",
      // network_error_info: "",
      // must_be_greater_than_zero: "",
      // must_be_greater_than_min: "",
      // incorrect_ip: "",
      // criteria_be_selected: "",
      // success_to_delete: "",
      // failed_to_delete: "",
      // failed_to_delete_with_reason: "",
      account_placeholder: "4-16 aksara, hanya abjad angka.",
      password_placeholder: "6-20 watak",
      confirm_password_placeholder: "Masukkan kata laluan yang sama sekali lagi",
      // date_type_hint: "",
      please_create_role: "Sebelum membuat pentadbir, sila cipta peranan",
      // max_length: '',
      // whole_url: '',
      setting_player_round_max: "Tetapkan Amaun minimum dan maksimum had pertaruhan satu pusingan pemain.",
      setting_player_bet_max: "Tetapkan Amaun maksimum bagi setiap had pertaruhan pemain.",
      // A_greater_tham_B: '',
      // reset_data: ''
      // monitor: "",
      // failed_to_monitor: '',
      // failed_to_fetch: '',
      // not_found: '',
    }
  },
  AgentsPage: {
    // master_account: "",
    // languageList: "",
    create_master_agent: "Buat Ejen Sarjana",
    rate: "Kadar Komisen",
    rate_note: "Jika Kadar Komisen ejen downline lebih tinggi daripada Kadar Komisen ejen atasan, sistem akan secara automatik menurunkannya kepada Kadar Komisen baharu.",
    max_with_value: "Taruhan tertinggi: {value}",
    // abbr: "",
    // abbr_placeholder: "",
    edit_agent: "Sunting ejen"
  },
  AgentSubAccountPage: {
    authority: "kewibawaan",
    authority_list: {
      read: "membaca",
      editor: "penyunting"
    },
    create_sub_account: "Tambah pengurus"
  },
  BetLogPage: {
    win_lose: "Menang kalah",
    bet_return: "Pulangan Pertaruhan",
    is_settled: "Sudah Selesai",
    // device: "",
    // amount_type: "",
    // amount_range: ""
  },
  ChangePasswordPage: {
    current_password: "Kata Laluan Semasa",
    current_password_placeholder: "Sila masukkan kata laluan semasa",
    new_password: "Kata laluan baharu"
  },
  ArenaModeratorPage: {
    // video_rate: "",
    // online_users: ""
  },
  ControlPanelPage: {
    arena_on: "HIDUP",
    arena_off: "MATI",
    no_announce: "Tanpa Pengumuman, sila klik 'edit' untuk mengemas kini",
    edit_announce: "Edit Pengumuman",
    open_arena_title: "Buka Arena?",
    close_arena_title: "Adakah anda pasti menutup Arena?",
    start_fight: "Mula Berjuang #",
    start_time: "Masa mula",
    open_betting: "Pertaruhan Terbuka?",
    close_betting: "Tutup Pertaruhan?",
    re_declare_title: "Tentukan semula?",
    re_declare_content: "Nyatakan semula pemenang Fight # {id}",
    lock_earing_title: "pembayaran?",
    lock_earing_content: "pembayaran bergaduh # {id}",
    // click_again_to: "",
    // file_upload: "",
    // arena_cover: "",
    // mask_file: "",
    // schedule: "",
    // edit_provider_url: "",
    // img_path: "",
    // below_px_value: "",
    // img_format: "",
    // img_recommended_size: "",
    // file_size: "",
    // image: "",
    // file_no_selected: "",
    // no_image: "",
    // edit_video_url: "",
    // pool_fight_number: '',
    // round_master: '',
    message: {
      input_fight: "Sila masukkan nombor Fight",
      // max_image_size: "",
      // error_image_type: "",
      // delete_image_placeholder: "",
      // success_to_earn_locked: "",
      // fail_to_earn_locked: "",
      // success_to_cancel_bet: "",
      // fail_to_cancel_bet: "",
      // affect_all_round: "",
      // operator_repeat_and_retry: ""
    }
  },
  CurrentArenaPage: {
    count: "Kira",
    unsettlement: "Ketidakselesaan",
    // fight_action: ""
  },
  GameResultsPage: {
    open_bet_at: "Pertaruhan terbuka",
    close_bet_at: "Tutup pertaruhan",
    declare_at: "Menyatakan pada",
    reload_at: "Muat semula pada",
    cancel_at: "Batal pada",
    total_rate: "Jumlah keseluruhan",
    total_meron: "Jumlah Meron",
    total_wala: "Jumlah Wala",
    total_draw: "Jumlah Cabutan",
    status_list: {
      // initial: "",
      // betting: "",
      // fighting: "",
      // finished: "",
      // reloaded: "",
      // earn_locked: "",
      // last_call: "",
      // cancel: ""
    },
    // time: "",
    // reason: "",
    cancel_reasons: {
      // '3000': "",
      // '3001': "",
      // canceled_manual: "",
      // canceled_ws: ""
    },
    // open_round: "",
  },
  LoginPage: {
    log_in: "Log masuk",
    // otp: "",
    message: {
      success: "Kejayaan Masuk",
      failed: "Gagal memasukkan akaun atau kata laluan."
    }
  },
  MerchantsPage: {
    admin: "pentadbir",
    role: "Peranan",
    create_merchant: "Buat Pedagang",
    name: "Nama Pedagang",
    message: {
      // master_agent_only_select_one: ""
    },
  },
  MerchantRolesPage: {
    // current_arena: '',
    can_permission_pages: "Senarai kebenaran",
    use_account: "Gunakan akaun",
    create_role: "Cipta peranan",
    edit_role: "Sunting Peranan",
    role_name: "Nama Peranan",
    color: "warna",
    can_use_pages: "Halaman Boleh Akses",
    arena_on_off: "Padang sabung ayam on/off",
    fight_control: "kawalan permainan",
    lock_earning: "pembayaran",
    announcement: "Pengumuman",
    decision_on_hold: "Hentikan permainan",
    monitor: "pantauan",
    // no_bets_allowed: "",
    // mask_video: "",
    read: "membaca",
    update: "kemas kini",
    create: "cipta",
    delete: "padam",
    edit: "suntingan",
    // dashboard_card: "",
    // confirm: "",
    // approve: "",
    // arena_cover: "",
    // mask_file: "",
    // schedule: "",
    export: "Eksport",
    // edit_provider_url: "",
    // display_rate: "",
    // view_schedule: '',
    // view_log: '',
    account_to_use: "Masih terdapat akaun yang menggunakan peranan ini",
    // risk_edit: '',
    monitor_video: "Pantau video",
  },
  MerchantAdminPage: {
    edit_manager: "Edit Pengurus",
    create_manager: "mencipta Pengurus"
  },
  MerchantIpPage: {
    // master_agent: "",
    // create_ip: ""
  },
  DailyBettingStatisticPage: {
    // yesterday_percentage: "",
    // last_week_percentage: "",
    // player_count: "",
    // ave_per_fight_percentage: "",
    // last_week_ave_per_fight_percentage: ""
  },
  OrderPage: {
    // after_balance: ""
  },
  PlayersPage: {
    balance: "Seimbang",
    fee: "Bayaran",
    current_sign_in_ip: "IP Log Masuk Semasa",
    total_deposit: "Jumlah Deposit",
    total_withdraw: "Pengeluaran Keseluruhan",
    total_win_lose: "Jumlah Menang Kalah"
  },
  PlayerCreditsPage: {
    id: "ID",
    type: {
      deposit: "Deposit",
      withdraw: "Tarik balik",
      // settle: "",
      cancel: "BatalBet",
      bet: "Pertaruhan"
    },
    before_amount: "Sebelum Jumlah",
    after_amount: "Selepas Jumlah"
  },
  ProfilePage: {
    // setting_round_draw_max: "Tetapkan jumlah pertaruhan maksimum yang boleh diterima untuk setiap pusingan",
    setting_round_draw_max_part1: "Tetapkan jumlah pertaruhan maksimum yang",
    setting_round_draw_max_part2: "boleh diterima untuk setiap pusingan",
    player_limit: "Had pertaruhan pemain",
    setting_player_max: "Tetapkan julat jumlah yang setiap pemain boleh bertaruh dalam setiap pusingan",
    basic_info_title: "Maklumat peribadi",
    basic_info_text: "Cipta maklumat peribadi anda",
    // integration_info: '',
    // auth: '',
    // secret: '',
    // api_doc_url: '',
    // api_url: '',
    // video_traffic: '',
    // remain_video_data: '',
    // update_video_data: '',
    // alarm_quota: '',
    // set_notification_quota_insufficient_points: '',
    // update_alarm_quota: '',
    // disabled_change_status: '',
    // change_only_agent_closed: '',
    // please_turn_off_agent_status: '',
    // please_decrease_points_to_zero: '',
    // warning_no_enough_point: '',
  },
  VideoSourcePage: {
    // source: "",
    // agent_source: "",
    // accessable_merchants: ""
  },
  TransferPage: {
    // slug: "",
    // data: "",
    // res: "",
    // round_id: "",
    // setting_master_id: "",
    // num: "",
    // resend: "",
    // bet_num: "",
    // resend_note: "",
    // resend_mark: "",
    message: {
      // success_to_resend: "",
      // fail_to_resend: "",
      // success_to_remark: "",
      // fail_to_remark: ""
    }
  },
  ExchangeRatePage: {
    // currency: "",
    // currency_name: "",
    // last_approved_rate: "",
    // today_rate: "",
    // ref_rate: "",
    // ref_rate_time: "",
    // tbd_confirm_rate: "",
    // tbd_modify_rate: "",
    // record: "",
    // current_time: "",
    // last_approved_time: "",
    // base_currency: "",
    // fetch_exchange_rate: "",
    // approve_rate: "",
    // rate_warning: "",
    // log: "",
    // confirmed_by: "",
    // confirmed_time: "",
    // approved_by: "",
    // approved_time: "",
    // exchange_rate: ""
  },
  CreateAgentPage: {
    // parent_agent: '',
    // environment: '',
    // staging: '',
    // production: '',
    // brand: '',
    // trade_mode: '',
    // transfer_wallet: '',
    // single_wallet: '',
    // options: '',
    // level_sub_agent_with_value: '',
    // delete_agent_with_value: '',
    // add_agent_with_value: '',
    // agent_info: '',
    message: {
      // select_master_first: '',
      // agent_same_master: '',
      // please_refresh_cache: '',
      // log_saved_time: '',
      // success_refresh_cache: '',
      // fail_refresh_cache: '',
    }
  },
  PoolSettingPage: {
    // pool_name: '',
    // odds_refresh_frequency: '',
    // last_call_percentage: '',
    // close_bet_percentage: '',
    // per_bet: '',
    // per_round: '',
    // risk_threshold: '',
    // current_schedule: '',
    // new_schedule: '',
    // update_schedule: '',
    // schedule: '',
    // view: '',
    // start_time: '',
    // end_time: '',
    // pool_configuration_options: '',
    // expect_date: '',
    // expect_time: '',
    // add_new_pool_configuration: '',
    // exceed_time: '',
    // effective_time: '',
    // import_current_schedule: '',
  },
  AgentCreditPointsPage: {
    // parent_agent: '',
    // points: '',
    // edit_points: '',
    // edited_record: '',
    // increase_points: '',
    // decrease_points: '',
    // group_decrease: '',
    // points_before_change: '',
    // points_after_change: '',
    // agent_increase_points: '',
    // agent_decrease_points: '',
    // last_edited_time: '',
    // edited_time: '',
    // edited_account: '',
    // change_type: '',
    // change_type_list: {
    //   order_settle: '',
    //   order_cancel: '',
    //   order_return: '',
    //   order_bet: '',
    //   increase: '',
    //   deduct: '',
    // },
    // has_selected_value: '',
    // confirm_agent_is_closed: '',
    // confirm_all_agents_are_closed: ''
  },
}
