<template>
  <span>{{ current_time }}</span>
</template>

<script>

let timer;

export default {
  data() {
    return {
      current_time: this.moment().utcOffset("+08:00").format('YYYY-MM-DD HH:mm:ss'),
    };
  },
  async mounted() {
    timer = setInterval(()=>{
      this.current_time = this.moment().utcOffset("+08:00").format('YYYY-MM-DD HH:mm:ss')
    },1000)
  },
  destroyed(){
    clearInterval(timer);
    timer = undefined;
  },
  methods: {},
  watch:{}
};
</script>
