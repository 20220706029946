<template>
    <a-modal :visible="arena_open_modal" 
             class="arena-open-modal" 
             :title="$t('ControlPanelPage.open_arena_title')" 
             @cancel="cancelModal"
             :destroyOnClose="true">
        <div class="step-container">
            <div class="step-info">
                <p class="step-index">{{ $t('global.action.step') }} {{ !selectedDate? 1 : 2 }}</p>
                <p class="step-value">{{ !selectedDate? $t('global.message.choose_arena') : $t('global.message.enter_fight_number') }}</p>
            </div>
            <div class="arena-info" v-if="selectedDate">
                <p class="arena-title">{{ $t('global.common.arena') }}</p>
                <p class="arena-time">{{ arena_info[current_arena_index].time }}</p>
                <p class="arena-fight-no">{{ $t('global.common.fight_id') }} <span class="number">{{arena_info[current_arena_index].fight_no}}</span></p>
            </div>
        </div>
        <div :class="[ 'arena_modal_form',{ 'select-date': !selectedDate } ]">
            <template v-if="!selectedDate">
                <div v-for="( item, index ) in arena_info" 
                     :key="index" 
                     :class="[ 'date-item', {'active':current_arena_index === index} ]"
                     @click="changeCurrentArenaInfo(index)">
                    <a-tag color="red" class="today-tag" v-if="item.today">{{ $t('global.times.today') }}</a-tag>
                    <p class="date">{{ item.time }}</p>
                    <p class="fight">{{ $t('global.common.fight_id') }} <span class="fight-no">{{ item.fight_no }}</span></p>
                </div>
            </template>
            <template v-else>
                <p class="input-title">{{ $t('ControlPanelPage.start_fight') }}</p>
                <a-input-number :value="value" @change="onChange" :min="1" />
            </template>
        </div>
        <template slot="footer">
            <template v-if="!selectedDate">
                <a-button key="next" @click="nextStep">{{ $t('global.action.next') }}</a-button>
            </template>
            <div class="selected-date-footer" v-else>
                <a-button key="back" @click="prevStep">{{ $t('global.action.prev') }}</a-button>
                <a-button key="submit" type="primary" @click="submitOpenArena">{{ $t('global.action.submit') }}</a-button>
            </div>
        </template>
      </a-modal>
</template>

<script>
export default {
    props:{
        arena_open_modal: Boolean,
        close_modal: Function,
        openArena: Function,
        value: [String,Number], //start_fight's value
        arena_info: Array,
        current_arena_index: Number,
        changeCurrentArenaInfo: Function,
        clearCurrentArenaInfo: Function
    },
    data() {
        return {
            selectedDate: false,
        }
    },
    watch: {
        arena_open_modal:{
            handler(newValue, oldValue){
                if(newValue === false){
                    setTimeout(()=>{
                        this.clearCurrentArenaInfo();
                        this.selectedDate = false;
                    },300)
                }
            }
        }
    },
    methods:{
        onChange(value){
            this.$emit('input', value);
        },
        nextStep(){
            if(this.current_arena_index === null){
                this.$message.error(this.$t('global.message.select_date'));
            }else{
                this.selectedDate = true;
            }
        },
        prevStep(){
            this.selectedDate = false;
        },
        cancelModal(){
            this.close_modal();
        },
        submitOpenArena(){
            this.openArena();
        }
    },
}
</script>