<template>
  <div class="frame bet-log-page">
    <a-page-header
      :title="$t('global.menu.BetLog')"
      @back="() => $router.go(-1)"
    >
    </a-page-header>
    <div class="page-content">
      <div class="search-bar">
        <a-form-model ref="searchForm" :model="form" :rules="rules">
          <div class="search-group">
            <Currency
              :inputCurrency.sync="form.currency"
              :inputMaster.sync="form.master"
              :inputAgent.sync="form.agent"
              :showCurrency="
                auth_role === 'master' || auth_role === 'agent' ? false : true
              "
              :showMaster="
                auth_role === 'master' || auth_role === 'agent' ? false : true
              "
              :currencyRequired="true"
              :masterRequired="masterRequired"
              :multipleMaster="true"
              :multipleAgent="true"
            />
            <a-form-model-item
              ref="account"
              :label="$t('global.common.account')"
              prop="account"
            >
              <a-input v-model="form.account" />
            </a-form-model-item>

            <a-form-model-item
              ref="arena_no"
              :label="$t('global.common.arena')"
              prop="arena_no"
            >
              <a-select
                mode="multiple"
                show-search
                :show-arrow="true"
                :filter-option="filterOption"
                v-model="form.arena_no"
              >
                <a-select-option
                  v-for="(arena, i) in arenaList"
                  :value="arena.id"
                  :key="i"
                >
                  {{ arena.arena_no }}({{ arena.id }})
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item
              ref="slug"
              :label="$t('global.common.order_num')"
              prop="slug"
            >
              <a-input v-model="form.slug" />
            </a-form-model-item>
            <a-form-model-item
              ref="round_id"
              :label="$t('global.common.round_id')"
              prop="round_id"
            >
              <a-input v-model="form.round_id" />
            </a-form-model-item>
            <a-form-model-item
              ref="fight_id"
              :label="$t('global.common.fight_id')"
              prop="fight_id"
            >
              <a-input v-model="form.fight_id" />
            </a-form-model-item>
            <a-form-model-item
              ref="status"
              :label="$t('global.common.status')"
              prop="status"
            >
              <a-select
                mode="multiple"
                :size="size"
                placeholder=""
                :default-value="[]"
                :show-arrow="true"
                v-model="form.status"
              >
                <a-select-option
                  v-for="typeItem in betLogType"
                  :value="typeItem.value"
                  :key="typeItem.value"
                >
                  {{ $t(`global.common.bet_type_list.${typeItem.label}`) }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <!-- 盤口類型(rake/malay_odds/static) -->
            <a-form-model-item
              ref="odds_type"
              :label="$t('global.common.odds_type')"
              prop="odds_type"
            >
              <a-select
                mode="multiple"
                :size="size"
                placeholder=""
                :default-value="[]"
                :show-arrow="true"
                v-model="form.odds_type"
              >
                <a-select-option
                  v-for="typeItem in oddsType"
                  :value="typeItem.value"
                  :key="typeItem.value"
                >
                  {{ $t(`global.common.odds_type_list.${typeItem.label}`) }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </div>
          <div class="search-group">
            <a-form-model-item
              ref="game_type"
              :label="$t('global.common.game_type')"
              prop="game_type"
            >
              <a-select v-model="form.game_type" mode="multiple">
                <a-select-option
                  v-for="game_type in activeGameType"
                  :value="game_type.value"
                  :key="game_type.value"
                >
                  {{ showGameTypeI18n(game_type) }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item
              ref="side"
              :label="$t('global.common.side')"
              prop="side"
            >
              <a-select v-model="form.side">
                <a-select-option
                  v-for="side in betLogSide"
                  :value="side.value"
                  :key="side.value"
                >
                  {{ side.label }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item
              ref="amount_type"
              :label="$t('BetLogPage.amount_type')"
              prop="amount_type"
            >
              <a-select v-model="form.amount_type">
                <a-select-option
                  v-for="typeItem in amountType"
                  :value="typeItem.value"
                  :key="typeItem.value"
                >
                  {{
                    typeItem.singleI18n
                      ? $t(`BetLogPage.${typeItem.label}`)
                      : $t(`global.common.${typeItem.label}`)
                  }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item
              ref="amount_range"
              :label="$t('BetLogPage.amount_range')"
              prop="amount_range"
            >
              <a-row type="flex" justify="space-between">
                <a-col>
                  <a-input-number
                    style="min-width: 80px"
                    v-model="form.amount_range[0]"
                    placeholder="min"
                    :precision="0"
                  />
                </a-col>
                <a-col>-</a-col>
                <a-col>
                  <a-input-number
                    style="min-width: 80px"
                    v-model="form.amount_range[1]"
                    placeholder="max"
                    :precision="0"
                  />
                </a-col>
              </a-row>
            </a-form-model-item>
            <a-form-model-item
              ref="ip"
              :label="$t('global.common.ip')"
              prop="ip"
            >
              <a-input v-model="form.ip" />
            </a-form-model-item>
          </div>
          <div class="search-group-no-wrap">
            <a-form-model-item
              ref="date_type"
              :label="$t('global.common.date_type')"
              prop="date_type"
            >
              <a-select v-model="form.date_type">
                <a-select-option
                  v-for="typeItem in dateTypes"
                  :value="typeItem"
                  :key="typeItem"
                  >{{
                    $t(`global.common.date_type_list.${typeItem}`)
                  }}</a-select-option
                >
              </a-select>
            </a-form-model-item>
            <Datetime
              :input.sync="form.date"
              :periodDefault.sync="periodDefault"
              :preciseTime="form.preciseTime"
            />
          </div>
          <div class="ant-row ant-row-end">
            <a-button
              type="primary"
              icon="search"
              :loading="loading"
              @click="onSearch"
              >{{ $t("global.action.search") }}</a-button
            >
            <a-button :disabled="loading" @click="resetForm">{{
              $t("global.action.reset")
            }}</a-button>
          </div>
        </a-form-model>
      </div>
      <div class="dashboard-cards" v-if="dashboard_card_show">
        <div class="dashboard-card">
          <a-card>
            <a-card-meta :title="$t('global.common.total_user_bet')">
              <a-avatar
                shape="square"
                slot="avatar"
                :src="require('images/BetUsers.svg')"
              />
              <template slot="description">
                <div class="card-num">
                  {{ total_bet_user_count | formatNumber }}
                </div>
              </template>
            </a-card-meta>
          </a-card>
        </div>
        <div class="dashboard-card">
          <a-card>
            <a-card-meta :title="$t('global.common.total_bet_count')">
              <a-avatar
                shape="square"
                slot="avatar"
                :src="require('images/BetRecords.svg')"
              />
              <template slot="description">
                <div class="card-num">{{ total_bet_count | formatNumber }}</div>
              </template>
            </a-card-meta>
          </a-card>
        </div>
        <div class="dashboard-card">
          <a-card>
            <a-card-meta :title="$t('global.common.total_bet_amount')">
              <a-avatar
                shape="square"
                slot="avatar"
                :src="require('images/betAmount.svg')"
              />
              <template slot="description">
                <div class="card-num">
                  {{ total_bet_amount | formatNumber }}
                </div>
              </template>
            </a-card-meta>
          </a-card>
        </div>
        <div class="dashboard-card">
          <a-card>
            <a-card-meta :title="$t('global.common.total_valid_bet_amount')">
              <a-avatar
                shape="square"
                slot="avatar"
                :src="require('images/ValidBet.svg')"
              />
              <template slot="description">
                <div class="card-num">
                  {{ total_bet_valid_amount | formatNumber }}
                </div>
              </template>
            </a-card-meta>
          </a-card>
        </div>
        <div class="dashboard-card">
          <a-card>
            <a-card-meta :title="$t('global.common.total_platform_profit')">
              <a-avatar
                shape="square"
                slot="avatar"
                :src="require('images/PlatformProfit.svg')"
              />
              <template slot="description">
                <div class="card-num">
                  {{ total_platform_profit | formatNumberWithPoint }}
                </div>
              </template>
            </a-card-meta>
          </a-card>
        </div>
        <div class="dashboard-card" v-if="is_merchant_account">
          <a-card>
            <a-card-meta :title="$t('global.common.total_rate_amount')">
              <a-avatar
                shape="square"
                slot="avatar"
                :src="require('images/commission.svg')"
              />
              <template slot="description">
                <div class="card-num">
                  {{ total_rate_amount | formatNumberWithPoint }}
                </div>
              </template>
            </a-card-meta>
          </a-card>
        </div>
      </div>
      <div class="ant-card">
        <div class="ant-card-body">
          <div class="table-control">
            <a-popover trigger="click" placement="bottomRight">
              <template slot="content">
                <div class="table-filter-content">
                  <a-checkbox
                    class="table-filter-check"
                    v-for="(item, index) in columns"
                    :key="item.dataIndex"
                    :defaultChecked="item.filterType"
                    :disabled="item.disabledFilter"
                    @click="columnsChange(index, item)"
                    :value="item.dataIndex"
                  >
                    {{
                      item.singleI18n
                        ? $t(`BetLogPage.${item.slots.title}`)
                        : $t(`global.common.${item.slots.title}`)
                    }}
                  </a-checkbox>
                </div>
              </template>
              <a-button icon="filter"></a-button>
            </a-popover>
          </div>
          <a-table
            bordered
            size="small"
            :columns="
              columns.filter(
                (item) => item.filterType || item.filterType === undefined
              )
            "
            :data-source="betLogs"
            :pagination="pagination"
            :loading="loading"
            :row-key="(betLogs) => betLogs.id[0]"
            @change="handleTableChange"
          >
            <template v-for="column in columns">
              <span :slot="column.slots.title">
                <div v-if="column.slots.title === 'fight_id'">
                  {{ $t(`global.common.game_date`) }}
                </div>

                <div v-if="column.slots.title === 'round_id'">
                  {{ $t(`global.common.arena`) }}
                </div>
                {{
                  column.singleI18n
                    ? $t(`BetLogPage.${column.slots.title}`)
                    : $t(`global.common.${column.slots.title}`)
                }}
              </span>
            </template>
            <template slot="player_account" slot-scope="player_account">
              <span class="table-link" @click="gotoPlayer(player_account)">{{
                player_account
              }}</span>
            </template>
            <template slot="odds_type" slot-scope="odds_type">
              <span>{{ odds_type }}</span>
            </template>
            <template slot="round_id" slot-scope="round_id, row">
              <div>
                {{ row["arena_no"] }}
              </div>
              {{ round_id }}
            </template>

            <template slot="fight_id" slot-scope="fight_id, row">
              <div>
                {{ row["game_day"] | formatDayToUTC8 }}
              </div>
              {{ fight_id }}
            </template>
            <template slot="status" slot-scope="status, row">
              {{ status }}
              <a-tooltip
                placement="top"
                v-if="status === 'cancel' || status === 'fail'"
              >
                <template slot="title">
                  <span>{{ row.cancel_message }}</span>
                </template>
                <a-icon type="exclamation-circle" />
              </a-tooltip>
            </template>
            <template slot="settled" slot-scope="settled">
              <div
                class="table-status"
                data-status="success"
                v-if="settled === 'true'"
              >
                <a-icon type="check-circle" theme="filled" />
              </div>
            </template>
            <template slot="game_type" slot-scope="game_type">
              {{ $t(`global.game_type.${game_type}`) }}
            </template>
            <template slot="side" slot-scope="side, row">
              <div
                class="table-side"
                :data-side="currentGameTypeSide(row.game_type, side)"
              >
                {{
                  currentGameTypeSideI18n(row.game_type, side) | formatString
                }}
              </div>
            </template>
            <template slot="rate" slot-scope="rate"> {{ rate }}% </template>
            <template slot="winner" slot-scope="winner, row">
              <div
                class="table-winner"
                :data-side="currentGameTypeSide(row.game_type, winner)"
              >
                {{
                  currentGameTypeSideI18n(row.game_type, winner) | formatString
                }}
              </div>
            </template>
            <template slot="formatTimes" slot-scope="times">
              <div class="table-time">{{ times | formatTimes }}</div>
            </template>
            <template slot="formatString" slot-scope="string">
              <div>{{ string | formatString }}</div>
            </template>
            <template slot="formatNumberWithPoint" slot-scope="amount">
              <div>{{ amount | formatNumberWithPoint }}</div>
            </template>
          </a-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getBetLog,
  getDashboardCard,
  //  getManageDescendantList,
  getArenaList,
  getMerchantRole,
} from "@/../api";
import Datetime from "@/../src/components/datetime_picker";
import Currency from "@/../src/components/currency_selection";
import storage, { IDENTITY } from "@/../storage";
import { GAME_TYPE_SIDE } from "@/../tools/config";
import { filterDuplicateData } from "@/../tools/common";

const agentColumns = [
  {
    slots: { title: "order_num" },
    dataIndex: "slug",
    filterType: true,
  },
  {
    slots: { title: "round_id" },
    dataIndex: "round_id",
    scopedSlots: { customRender: "round_id" },
    filterType: true,
    disabledFilter: true,
  },
  {
    slots: { title: "fight_id" },
    dataIndex: "fight_id",
    scopedSlots: { customRender: "fight_id" },
    width: 120,
    filterType: true,
  },
  {
    slots: { title: "player_account" },
    dataIndex: "player_account",
    scopedSlots: { customRender: "player_account" },
    filterType: true,
  },
  {
    slots: { title: "agent" },
    dataIndex: "agent",
    // scopedSlots: { customRender: 'agent' },
    filterType: true,
  },
  {
    slots: { title: "game_type" },
    dataIndex: "game_type",
    scopedSlots: { customRender: "game_type" },
    align: "center",
  },
  {
    slots: { title: "odds_type" },
    dataIndex: "odds_type",
    scopedSlots: { customRender: "odds_type" },
    filterType: true,
    align: "center",
  },
  {
    slots: { title: "side" },
    dataIndex: "side",
    align: "center",
    scopedSlots: { customRender: "side" },
    filterType: true,
  },
  {
    slots: { title: "bet_amount" },
    dataIndex: "bet_amount",
    align: "right",
    scopedSlots: { customRender: "formatNumberWithPoint" },
    filterType: true,
  },
  {
    slots: { title: "bet_return" },
    dataIndex: "bet_return",
    align: "right",
    scopedSlots: { customRender: "formatNumberWithPoint" },
    filterType: true,
    singleI18n: true,
  },
  {
    slots: { title: "win_lose" },
    dataIndex: "win_or_loss",
    align: "right",
    scopedSlots: { customRender: "formatNumberWithPoint" },
    filterType: true,
    singleI18n: true,
  },
  {
    slots: { title: "odd" },
    dataIndex: "odd",
    align: "right",
    scopedSlots: { customRender: "formatNumberWithPoint" },
    filterType: true,
  },
  {
    slots: { title: "winner" },
    dataIndex: "winner",
    align: "center",
    scopedSlots: { customRender: "winner" },
    filterType: true,
  },
  {
    slots: { title: "created_at" },
    dataIndex: "created_at",
    scopedSlots: { customRender: "formatTimes" },
    filterType: true,
  },
  {
    slots: { title: "settled_at" },
    dataIndex: "settled_at",
    scopedSlots: { customRender: "formatTimes" },
    filterType: true,
  },
  {
    slots: { title: "status" },
    dataIndex: "status",
    scopedSlots: { customRender: "status" },
    filterType: true,
  },
  {
    slots: { title: "valid_amount" },
    dataIndex: "valid_amount",
    align: "right",
    scopedSlots: { customRender: "formatNumberWithPoint" },
    filterType: true,
  },
  {
    slots: { title: "is_settled" },
    dataIndex: "settled",
    scopedSlots: { customRender: "settled" },
    align: "center",
    filterType: true,
    singleI18n: true,
  },
  {
    slots: { title: "ip" },
    dataIndex: "ip",
    filterType: true,
  },
  {
    slots: { title: "device" },
    dataIndex: "device_info",
    filterType: true,
    singleI18n: true,
  },
];

const merchantColumns = [
  {
    slots: { title: "order_num" },
    dataIndex: "slug",
    filterType: true,
  },
  {
    slots: { title: "round_id" },
    dataIndex: "round_id",
    scopedSlots: { customRender: "round_id" },
    filterType: true,
    disabledFilter: true,
  },
  {
    slots: { title: "fight_id" },
    dataIndex: "fight_id",
    scopedSlots: { customRender: "fight_id" },
    width: 120,
    filterType: true,
  },
  {
    slots: { title: "player_account" },
    dataIndex: "player_account",
    scopedSlots: { customRender: "player_account" },
    filterType: true,
  },
  {
    slots: { title: "agent" },
    dataIndex: "agent",
    // scopedSlots: { customRender: 'agent' },
    filterType: true,
  },
  {
    slots: { title: "game_type" },
    dataIndex: "game_type",
    scopedSlots: { customRender: "game_type" },
    align: "center",
  },
  {
    slots: { title: "odds_type" },
    dataIndex: "odds_type",
    scopedSlots: { customRender: "odds_type" },
    filterType: true,
    align: "center",
  },
  {
    slots: { title: "side" },
    dataIndex: "side",
    align: "center",
    scopedSlots: { customRender: "side" },
    filterType: true,
  },
  {
    slots: { title: "bet_amount" },
    dataIndex: "bet_amount",
    align: "right",
    scopedSlots: { customRender: "formatNumberWithPoint" },
    filterType: true,
  },
  {
    slots: { title: "bet_return" },
    dataIndex: "bet_return",
    align: "right",
    scopedSlots: { customRender: "formatNumberWithPoint" },
    filterType: true,
    singleI18n: true,
  },
  {
    slots: { title: "win_lose" },
    dataIndex: "win_or_loss",
    align: "right",
    scopedSlots: { customRender: "formatNumberWithPoint" },
    filterType: true,
    singleI18n: true,
  },
  {
    slots: { title: "odd" },
    dataIndex: "odd",
    align: "right",
    scopedSlots: { customRender: "formatNumberWithPoint" },
    filterType: true,
  },
  {
    slots: { title: "winner" },
    dataIndex: "winner",
    align: "center",
    scopedSlots: { customRender: "winner" },
    filterType: true,
  },
  {
    slots: { title: "created_at" },
    dataIndex: "created_at",
    scopedSlots: { customRender: "formatTimes" },
    filterType: true,
  },
  {
    slots: { title: "settled_at" },
    dataIndex: "settled_at",
    scopedSlots: { customRender: "formatTimes" },
    filterType: true,
  },
  {
    slots: { title: "status" },
    dataIndex: "status",
    scopedSlots: { customRender: "status" },
    filterType: true,
  },
  {
    slots: { title: "platform_profit" },
    dataIndex: "platform_profit",
    align: "right",
    scopedSlots: { customRender: "formatNumberWithPoint" },
    filterType: true,
  },
  {
    slots: { title: "valid_amount" },
    dataIndex: "valid_amount",
    align: "right",
    scopedSlots: { customRender: "formatNumberWithPoint" },
    filterType: true,
  },
  {
    slots: { title: "rate" },
    dataIndex: "rate_percent",
    scopedSlots: { customRender: "rate" },
    align: "right",
    filterType: true,
  },
  {
    slots: { title: "is_settled" },
    dataIndex: "settled",
    scopedSlots: { customRender: "settled" },
    align: "center",
    filterType: true,
    singleI18n: true,
  },
  {
    slots: { title: "rate_amount" },
    dataIndex: "rate_amount",
    align: "right",
    scopedSlots: { customRender: "formatNumberWithPoint" },
    filterType: true,
  },
  {
    slots: { title: "ip" },
    dataIndex: "ip",
    filterType: true,
  },
  {
    slots: { title: "device" },
    dataIndex: "device_info",
    filterType: true,
    singleI18n: true,
  },
];
export default {
  components: {
    Datetime,
    Currency,
  },
  data() {
    const checkAmountType = (rule, value, callback) => {
      if (this.form.amount_type) {
        switch (this.form.amount_type) {
          case "bet_amount":
          case "bet_return":
            callback(this.checkAmountWithBetAmount());
            break;
          case "win_or_loss":
            callback(this.checkAmountTypeWithWinLoss());
            break;
          default:
            callback();
        }
      } else {
        callback();
      }
    };

    return {
      auth_role: storage.getItem(IDENTITY), //取得當前角色
      data: [],
      pagination: {
        current: 1,
        pageSize: 10, // 默认每页显示数量
        showSizeChanger: true, // 显示可改变每页数量
        pageSizeOptions: ["10", "20", "50", "100"], // 每页数量选项
        showTotal: (total) => `Total ${total} items`, // 显示总数
        showSizeChange: (current, pageSize) => (this.pageSize = pageSize), // 改变每页数量时更新显示
        total: 0,
      },
      expand: false,
      loading: false,
      GAME_TYPE_SIDE,
      form: {
        date: [new Date(), new Date()],
        slug: this.$route.query.slug || "",
        account: "",
        round_id: "",
        fight_id: "",
        status: [],
        odds_type: [],
        agent: null,
        date_type: "SettleAt",
        arena_no: [],
        preciseTime: "time", // 是否啟用 hh:mm:ss
        currency: "",
        master: null,
        side: "",
        game_type: [],
        amount_type: "",
        amount_range: ["", ""],
        ip: "",
      },
      periodDefault: "today",
      rules: {
        amount_range: [{ validator: checkAmountType, trigger: "submit" }],
        fight_id: [
          {
            pattern: new RegExp(/^[0-9]+$/),
            message: () => this.$t("global.message.must_be_number"),
            trigger: "submit",
          },
        ],
        round_id: [
          {
            pattern: new RegExp(/^[0-9]+$/),
            message: () => this.$t("global.message.must_be_number"),
            trigger: "submit",
          },
        ],
        master: [{ required: false }],
        ip: [
          {
            pattern: new RegExp(
              /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.|$)){4}$/
            ),
            message: () => this.$t("global.message.incorrect_ip"),
            trigger: "submit",
          },
        ],
      },
      betLogs: [],
      betLogType: [
        {
          value: "init",
          label: "init",
        },
        {
          value: "beted",
          label: "bet",
        },
        {
          value: "settled",
          label: "settle",
        },
        {
          value: "resettle",
          label: "resettle",
        },
        {
          value: "cancel",
          label: "cancel",
        },
        {
          value: "fail",
          label: "fail",
        },
      ],
      // gameTypes: [
      //   {
      //     value: 'cockfight',
      //     label: 'cockfight'
      //   },
      //   {
      //     value: 'spider',
      //     label: 'spider'
      //   },
      //   {
      //     value: 'color_game',
      //     label: 'color_game'
      //   },
      //   {
      //     value: 'apple',
      //     label: 'apple'
      //   },
      //   {
      //     value: 'up_down',
      //     label: 'up_down'
      //   },
      // ],
      oddsType: [
        {
          value: "0",
          label: "rake",
        },
        {
          value: "1",
          label: "malay",
        },
        {
          value: "2",
          label: "static",
        },
      ],
      amountType: [
        {
          value: "bet_amount",
          label: "amount",
        },
        {
          value: "bet_return",
          label: "bet_return",
          singleI18n: true,
        },
        {
          value: "win_or_loss",
          label: "win_lose",
          singleI18n: true,
        },
      ],
      total_bet_amount: 0,
      total_bet_count: 0,
      total_bet_user_count: 0,
      total_bet_valid_amount: 0,
      total_platform_profit: 0,
      total_rate_amount: 0,
      size: "default",
      dateTypes: ["GameDate", "BetAt", "SettleAt"],
      arenaList: [],
      gameTypeList: [],
      dashboard_card_show: false,
      is_merchant_account: false,
      checkCurrency: "",
    };
  },
  mounted() {
    getMerchantRole().then((data) => {
      this.dashboard_card_show = data.data.role["BetLog"]
        ? data.data.role["BetLog"].dashboard_card
        : false;
      this.is_merchant_account = data.data.is_merchant_account;
    });
    this.periodDefault = "today";
    if (this.$route.query.slug) {
      setTimeout(() => {
        this.onSearch();
      }, 100);
    }
    // this.fetchArenaList();
  },
  computed: {
    columns() {
      return this.is_merchant_account ? merchantColumns : agentColumns;
    },
    masterRequired() {
      const { auth_role, form, periodDefault } = this;
      const yesterday = this.moment().subtract(1, "days").format("YYYY-MM-DD");
      const beforeYesterday = this.moment(form.date[0], "YYYY-MM-DD").isBefore(
        yesterday
      );
      const requiredPeriod = [
        "last_week",
        "this_week",
        "last_month",
        "this_month",
      ];
      return auth_role === "merchant" &&
        (beforeYesterday || requiredPeriod.includes(periodDefault))
        ? true
        : false;
    },
    currency() {
      return this.form.currency;
    },
    activeGameType() {
      //把arena list裡重複的game type移除
      const onlyOneTypeAry = [];

      this.arenaList.forEach((listItem) => {
        if (!onlyOneTypeAry.includes(listItem.game_type)) {
          onlyOneTypeAry.push(listItem.game_type);
        }
      });

      //回傳arena list裡目前有的game type 跟活動標籤
      return this.gameTypeList.filter(
        (typeItem) =>
          onlyOneTypeAry.includes(typeItem.value) || typeItem.is_event
      );
    },
    //用在side的下拉式選班
    betLogSide() {
      // 如果沒有可選擇的game type，則回傳空陣列
      if (this.activeGameType.length === 0) return [];
      // 如果已選了多個game type
      if (this.formGameType.length > 1) {
        const side = {};

        //藉由已選擇game type去取得各個遊戲種類的 side value
        this.formGameType.forEach((gameTypeItem) => {
          const itemKeys = GAME_TYPE_SIDE[gameTypeItem];
          // 如果沒有side則跳過(篩選掉event的狀況)
          if (itemKeys) {
            for (let keyItem in itemKeys) {
              //如果是 continue 則跳過
              if (keyItem === "cancel") continue;

              //如果還沒生成陣列，則生成陣列
              if (side[keyItem] === undefined) {
                side[keyItem] = [];
              }

              //如果已有存在同樣的值，則不進到陣列
              if (!side[keyItem].includes(itemKeys[keyItem])) {
                side[keyItem].push(itemKeys[keyItem]);
              }
            }
          }
        });

        // 如果沒有side則回傳空陣列
        if (Object.keys(side).length === 0) {
          return [];
        } else {
          const sideOptions = [];
          for (let sideItem in side) {
            const labels = side[sideItem];

            labels.forEach((labelItem, index) => {
              //取得各個項目的i18n
              labels[index] = this.$t(`global.fight_result.${labelItem}`);
            });

            //主文字
            let label = labels[0];

            //如果項目大於一個，在顯示上會有類似備註的文字
            if (labels.length > 1) {
              label += `(${labels.slice(1, labels.length).join("/")})`;
            }

            const option = {
              label: label,
              value: sideItem,
            };

            sideOptions.push(option);
          }

          return sideOptions;
        }
      } else {
        //當前已選的game_type只有一項
        const currentType = this.formGameType[0];
        //取得config裡的遊戲種類裡面的side
        const currentSide = GAME_TYPE_SIDE[currentType];

        // 如果沒有side則回傳空陣列
        if (!currentSide) {
          return [];
        } else {
          const sideOptions = [];
          for (let sideKey in currentSide) {
            //如果是cancel則跳過
            if (sideKey === "cancel") continue;
            sideOptions.push({
              label: this.$t(`global.fight_result.${currentSide[sideKey]}`),
              value: sideKey,
            });
          }

          return sideOptions;
        }
      }
    },
    formGameType() {
      return this.form.game_type;
    },
  },
  watch: {
    masterRequired: {
      handler(newValue) {
        if (newValue) {
          this.rules.master = [{ required: true, trigger: "blur" }];
        } else {
          this.rules.master = [{ required: false }];
        }
      },
      immediate: true,
      deep: true,
    },
    currency: {
      handler(newValue, oldValue) {
        if (newValue !== "") {
          if (newValue !== this.checkCurrency) {
            this.checkCurrency = newValue;
            this.form.arena_no = [];
            this.arenaList = [];
            this.fetchArenaList(newValue);
          }
        }
      },
    },
    formGameType: {
      handler(newValue) {
        this.form.side = "";
        if (newValue.length === 0 && this.activeGameType.length > 0) {
          this.form.game_type = [this.activeGameType[0].value];
        }
      },
    },
    activeGameType: {
      handler(newValue, oldValue) {
        if (newValue.length > 0 && oldValue.length === 0) {
          this.form.game_type = [newValue[0].value];
        }
      },
    },
  },
  methods: {
    handleTableChange(pagination, filters, sorter) {
      const currentPage =
        this.pagination.pageSize !== pagination.pageSize
          ? 1
          : pagination.current;
      this.pagination.current = currentPage;
      this.pagination.pageSize = pagination.pageSize;

      const {
        date,
        account,
        slug,
        round_id,
        fight_id,
        status,
        odds_type,
        agent,
        date_type,
        arena_no,
        preciseTime,
        currency,
        master,
        side,
        amount_type,
        amount_range,
        game_type,
        ip,
      } = this.form;
      this.loading = true;
      this.fetchBetLog({
        results: pagination.pageSize,
        page: currentPage,
        date,
        account,
        slug,
        round_id,
        fight_id,
        status,
        odds_type,
        agent,
        arena_no,
        date_type,
        preciseTime,
        currency,
        master,
        side,
        amount_type,
        amount_range,
        game_type,
        ip,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
      });
    },
    async fetch(params = {}) {
      if (this.loading) return;
      this.loading = true;
      await getDashboardCard({
        type: "BetLog",
        ...params,
      })
        .then(({ data }) => {
          this.pagination.total = data.result.total || 0;
          this.total_bet_amount = data.result.total_bet_amount;
          this.total_bet_count = data.result.total_bet_count;
          this.total_bet_user_count = data.result.total_bet_user_count;
          this.total_bet_valid_amount = data.result.total_bet_valid_amount;
          this.total_platform_profit = data.result.total_platform_profit;
          this.total_rate_amount = data.result.total_rate_amount;
        })
        .catch((err) => {
          this.loading = false;
          var msg = this.$t(`global.message.${err.response.data.message}`);
          this.$message.error(msg);
        });

      this.fetchBetLog({ ...params });
    },
    async fetchBetLog(params = {}) {
      await getBetLog({
        ...params,
      })
        .then(({ data }) => {
          this.betLogs = data.bet_log;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onSearch() {
      if (this.loading) return;

      this.$refs.searchForm.validate((valid) => {
        if (valid) {
          const { pageSize } = this.pagination;
          this.pagination.current = 1;
          const {
            date,
            account,
            slug,
            round_id,
            fight_id,
            status,
            odds_type,
            agent,
            date_type,
            arena_no,
            preciseTime,
            currency,
            master,
            side,
            amount_type,
            amount_range,
            game_type,
            ip,
          } = this.form;
          const only_show_total = this.dashboard_card_show;
          this.fetch({
            date,
            account,
            slug,
            round_id,
            fight_id,
            status,
            odds_type,
            agent,
            side,
            amount_type,
            amount_range,
            results: pageSize,
            page: 1,
            date_type,
            arena_no,
            game_type,
            only_total: only_show_total,
            preciseTime,
            currency,
            master,
            ip,
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm() {
      if (this.loading) return;
      this.$refs.searchForm.resetFields();
      this.form.agent = null;
      this.form.master = null;
      this.form.slug = "";
      this.periodDefault = "today";
      // setTimeout(() => {
      //   this.onSearch();
      // }, 100);
    },
    columnsChange(index, item) {
      item.filterType = !item.filterType;
    },
    gotoPlayer(player) {
      let routeData = this.$router.resolve({
        name: "Players",
        query: { player: player },
      });
      window.open(routeData.href, "_blank");
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    fetchArenaList(currency) {
      getArenaList({ currency }).then((data) => {
        this.arenaList = filterDuplicateData(data.data.arena, "id");
        this.gameTypeList = data.data.game_type_list;
      });
    },
    // handleChangeNum(value){
    //   var num = parseInt(value, 10)
    // },
    checkAmountWithBetAmount() {
      if (
        typeof this.form.amount_range[0] !== "number" ||
        typeof this.form.amount_range[1] !== "number"
      ) {
        return new Error(
          this.$t("global.message.fill_input", {
            value: this.$t("global.common.amount"),
          })
        );
      }

      if (this.form.amount_range[0] < 0 || this.form.amount_range[1] < 0) {
        return new Error(this.$t("global.message.must_be_greater_than_zero"));
      }

      if (this.form.amount_range[0] > this.form.amount_range[1]) {
        return new Error(this.$t("global.message.must_be_greater_than_min"));
      }
    },
    checkAmountTypeWithWinLoss() {
      if (
        typeof this.form.amount_range[0] !== "number" ||
        typeof this.form.amount_range[1] !== "number"
      ) {
        return new Error(
          this.$t("global.message.fill_input", {
            value: this.$t("global.common.amount"),
          })
        );
      }

      if (this.form.amount_range[0] > this.form.amount_range[1]) {
        return new Error(this.$t("global.message.must_be_greater_than_min"));
      }
    },
    currentGameTypeSide(game_type, side) {
      return this.GAME_TYPE_SIDE[game_type]
        ? this.GAME_TYPE_SIDE[game_type][side]
        : "";
    },
    currentGameTypeSideI18n(game_type, side) {
      return this.GAME_TYPE_SIDE[game_type] && side
        ? this.$t(`global.fight_result.${this.GAME_TYPE_SIDE[game_type][side]}`)
        : "";
    },
    showGameTypeI18n(gameTypeItem) {
      return gameTypeItem.is_event
        ? gameTypeItem.name
        : this.$t(`global.game_type.${gameTypeItem.name}`);
    },
  },
};
</script>

