<template>
  <a-menu theme="dark" :selectedKeys="selectedKeys" :openKeys="openKeys" @openChange="openKeysChange" mode="inline">
    <template v-if="isAgent">
      <a-menu-item class="role" title="Profile" v-if="collapsed === true" @click="routerTo('Profile')">
          <a-avatar
            class="icon"
            :src="require('images/agent_icon.svg')"
          />
      </a-menu-item>
      <a-menu-item class="role active" @click="routerTo('Profile')" v-else>
        <a-avatar
          class="icon"
          :src="require('images/agent_icon.svg')"
        />
        <p class="username">{{ currentProfile.name }}</p>
        <div class="btn-container">
          <div class="btn">{{ $t(`global.menu.Profile`) }}</div>
        </div>
      </a-menu-item>
    </template>
    <template v-for="menu in menus">
      <template v-if="menu.group">
        <template v-if="showList(menu.group)">
          <a-sub-menu :key="menu.name">
            <span slot="title">
              <a-icon :type="menu.icon" />
              <span>
                <template v-if="isMerchant && menu.name.includes('Agent')">{{ $t(`global.menu.group.Master${menu.name}`) }}</template>
                <template v-else>{{ $t(`global.menu.group.${menu.name}`) }}</template>
              </span>
            </span>
            <template v-for="menuItem in menu.group">
              <a-menu-item @click="routerTo(menuItem)" v-if="showList(menuItem)" :key="menuItem">
                <template v-if="isMerchant && menu.name.includes('Agent') && menuItem.includes('Agent') && noShowMasterI18n(menuItem)">{{ $t(`global.menu.Master${menuItem}`) }}</template>
                <template v-else>{{ $t(`global.menu.${menuItem}`) }}</template>
                <!-- {{ $t(`global.menu.${menuItem}`) }} -->
              </a-menu-item>
            </template>
          </a-sub-menu>
        </template>
      </template>
      <template v-else>
        <a-menu-item :key="menu.name" @click="routerTo(menu.name)" v-if="showList(menu.name)">
          <a-icon :type="menu.icon" />
          <span>
              <template v-if="isMerchant && menu.name.includes('Agent')">{{ $t(`global.menu.Master${menu.name}`) }}</template>
              <template v-else>{{ $t(`global.menu.${menu.name}`) }}</template>
          </span>
        </a-menu-item>
      </template>
    </template>
  </a-menu>
</template>
<script>
import { getMenuPermissions } from '@/../api';
import { mapState } from 'vuex';

// default Role permission
// const admin_permission_list = {
//   'ArenaModerator': true,
//   'ControlPanel': true,
//   'CurrentArena': true,
//   'Players': true,
//   'PlayerCredits': true,
//   'BetLog': true,
//   'GameResults': true,
//   'Transfer': true,
//   'Order': true,
//   'GGR': true,
//   'Agents': true,
//   'Merchants': true,
//   'VideoSource': true,
// }
// const merchant_permission_list = {
//   'ArenaModerator': true,
//   'ControlPanel': true,
//   'CurrentArena': true,
//   'Players': true,
//   'PlayerCredits': true,
//   'BetLog': true,
//   'GameResults': true,
//   'Transfer': true,
//   'Order': true,
//   'Agents': true,
//   'MerchantRoles': true,
//   'MerchantAdmin': true,
// }
// const agent_permission_list = {
//   'Players': true,
//   'PlayerCredits': true,
//   'BetLog': true,
//   'GameResults': true,
//   'Order': true,
//   'Agents': true,
//   'AgentSubAccount': true,
//   'AgentRoles': true,
// }

export default {
  props: {
    collapsed: Boolean,
    toggleMenu: Function
  },
  data() {
    return {
      permission_list: {},
      windowSize: 0,
      selectedKeys: [],
      openKeys:[],
      menus: [
        {name: 'VideoSource', icon: 'video-camera'},
        // {name: 'ArenaModerator', icon: 'control'},
        {name: 'ArenaModerator', icon: 'desktop', group:[
          'SingleModerator','MultiModerator'
        ]},
        {name: 'Pool', icon: 'control', group:[
          'PoolSetting', 'MalayOddsSetting'
        ]},
        {name: 'player', icon: 'team', group: [
          'Players','PlayerCredits', 'Order',
        ]},
        {name: 'record', icon: 'bar-chart', group: [
          'BetLog', 'GameResults', 'GGR', 'Transfer', 'DailyBettingStatistic'
        ]},
        {name: 'Agent', icon: 'apartment',group: [
          'AgentList','AgentSource', 'CreateAgent', 'AgentCreditPoints', 'AgentPointsRecord'
        ]},
        {name: 'AdminAgents', icon: 'apartment'},
        {name: 'Merchants', icon: 'shop'},
        {name: 'AgentSubAccount', icon: 'setting'},
        {name: 'manager', icon: 'setting', group: [
          'MerchantRoles', 'MerchantAdmin', 'MerchantIpList', 'ExchangeRate'
        ]},
      ],
    };
  },
  computed: {
    ...mapState({ currentProfile: state => state.auth.currentProfile, 
                  identity: state => state.auth.identity,
                  parentIdentity: state => state.auth.parentIdentity}),
    isMerchant(){
      const isMerchantIdentity = this.identity === 'merchant' || (this.identity === 'manager' && this.parentIdentity === 'merchant');
      return isMerchantIdentity;
    },
    isAgent(){
      const isAgentIdentity = this.identity === 'agent' || (this.identity === 'manager' && this.parentIdentity === 'agent');
      const isMasterIdentity = this.identity === 'master' || (this.identity === 'manager' && this.parentIdentity === 'master');
      return isAgentIdentity || isMasterIdentity;
    }
  },
  methods: {
    // ...mapMutations({setTimeZone:"auth/setTimeZone"}),
    init(){
      getMenuPermissions()
        .then((data)=>{
          this.permission_list = data.data.menus;
          const enableMenus = Object.keys(data.data.menus).filter(menuItem=> data.data.menus[menuItem] );
          if(enableMenus.includes(this.$route.name)){

            const currentMenu = this.menus.find((menuItem)=>{
              if(Array.isArray(menuItem.group)){
                return menuItem.group.includes(this.$route.name)
              }
            })
            if(currentMenu){
              this.openKeys = [currentMenu.name];
            }            

            if(this.$route.name === 'ControlPanel'){
              this.selectedKeys = ['SingleModerator'];
            }else if(this.$route.name === 'CurrentArena'){
              this.selectedKeys = ['MultiModerator'];
            }else{
              this.selectedKeys = [this.$route.name]; 
            }

          }
        })
    },
    routerTo(name) {
      if(name === this.selectedKeys[0]) return;
      if (this.windowSize <= 768) {
        // this.$emit('update:collapsed', true);
        this.toggleMenu();
      }
      this.selectedKeys = [name];
      this.$router.push({
        name: name,
      }).catch(()=>{});
    },
    showList(value) {
      const menuShow = Object.keys(this.permission_list);
      if (Array.isArray(value)) {
        return value.some(i => menuShow.includes(i));
      }
      return menuShow.includes(value);
    },
    openKeysChange(openKeys){
      this.openKeys = openKeys;
    },
    noShowMasterI18n(menu){
      const excludeMenu = ['CreateAgent','AgentCreditPoints','AgentPointsRecord'];
      return !excludeMenu.includes(menu);
    }
  },
  mounted() {
    this.windowSize = window.innerWidth;
    this.init();
  },
};
</script>
