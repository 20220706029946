<template>
  <a-modal 
    class="pool-setting-dialog"
    :visible="show" 
    :width="'unset'"
    :footer="null" 
    :afterClose="resetForm"
    @cancel="closeModal">

    <template slot="title">
      <template v-if="!dialogData.editable">{{ $t('PoolSettingPage.schedule') }}</template>
      <template v-else>{{ settingForm.id ? $t('PoolSettingPage.update_schedule') : $t('PoolSettingPage.new_schedule') }}</template>

      <span> - </span>
      <span class="primary-font-color">{{ dialogData.link_master }}</span>

      <span v-if="dialogData.log_no"> - {{ dialogData.log_no }}</span>

    </template>

    <div 
      v-if="!dialogData.editable"
      class="effective-time">
      <span>{{ $t('PoolSettingPage.effective_time') }}：</span>
      <span>{{ dialogData.effective_time | formatTimesToUTC8 }}</span>
    </div>

    <!-- style 無法寫在css，full calendar會無法取得正確高度 -->
    <div class="pool-setting-dialog-content">
      <div 
        v-if="show"
        class="calendar-container"
        :style="{height: '600px', width: '300px'}"
        >
        <FullCalendar ref="calendarEl" :options="calendarOptions" />
      </div>
      <div class="schedule-options-container">
        <div>
          <a-button 
            v-if="dialogData.editable && dialogData.current_schedule"
            type="primary"
            @click="importCurrentSchedule" 
            block>
            {{ $t('PoolSettingPage.import_current_schedule') }}
          </a-button>
        </div>
        <div class="schedule-options-main-content">
          <div class="schedule-options-card-group-container">
            <div class="schedule-options-card-group">
            <div
              v-for="(setting, index) in form.poolSettings"
              :class="['schedule-option-card']"
              :key="index">
              <table>
                <tr>
                  <td>
                    <span>{{ $t('PoolSettingPage.start_time') }}</span>
                  </td>
                  <td>
                    <a-time-picker 
                      v-if="dialogData.editable"
                      :class="['card-selection', 
                                showAllowClear(index) ? 'show-suffix-icon' : 'hide-suffix-icon',
                                showAllowClear(index) && dialogData.editable && 'ant-input']"
                      v-model="setting.startTime"
                      :defaultOpenValue="defaultOpenValue"
                      :allowClear="showAllowClear(index)"
                      :disabled="!showAllowClear(index) || !dialogData.editable"
                      :disabledHours="() => disabledStartHours(setting.endTime, index)"
                      :disabledMinutes="() => disabledStartPicker()"
                      :disabledSeconds="() => disabledStartPicker()"
                      :hideDisabledOptions="true"
                      @change="changeTime"
                      />
                      <span
                        class="non-editable-text"
                        v-else>{{ moment(setting.startTime).format('HH:mm:ss') }}</span>
                  </td>
                  <td>
                    <div 
                      v-if="form.poolSettings.length > 1 && dialogData.editable"
                      class="delete-icon" 
                      @click="deletePoolItem(index)">
                      <a-icon type="delete" />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>{{ $t('PoolSettingPage.end_time') }}</span>
                  </td>
                  <td>
                    <a-time-picker
                      v-if="dialogData.editable"
                      :class="['card-selection', 
                                showAllowClear(index) ? 'show-suffix-icon' : 'hide-suffix-icon',
                                showAllowClear(index) && setting.startTime && dialogData.editable && 'ant-input']"
                      v-model="setting.endTime"
                      :defaultOpenValue="defaultOpenValue"
                      :allowClear="showAllowClear(index)"
                      :disabled="!showAllowClear(index) || !setting.startTime || !dialogData.editable"
                      :disabledHours="() => disabledEndHours(setting.startTime, index)"
                      :disabledMinutes="() => disabledEndPicker()"
                      :disabledSeconds="() => disabledEndPicker()"
                      :hideDisabledOptions="true"/>
                      <span
                        class="non-editable-text"
                        v-else>{{ moment(setting.endTime).format('HH:mm:ss') }}</span>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>           
                    <span>{{ $t('PoolSettingPage.pool_configuration_options') }}</span>
                  </td>
                  <td>
                    <a-select 
                      v-if="dialogData.editable"
                      show-search
                      :filter-option="filterOption"
                      :disabled="!dialogData.editable || oddsProfile.length === 0"
                      v-model="setting.settingOption"
                      class="card-selection">
                      <a-select-option
                        v-for="option in oddsProfile"
                        :key="option.id"
                        :value="option.id">
                        {{ option.name }}
                      </a-select-option>
                    </a-select>
                    <span
                      class="non-editable-text"
                      v-else>{{ showOptionName(setting.settingOption) }}</span>
                  </td>
                  <td>
                    <MalayDetailPopover 
                      :id="setting.settingOption"
                      :oddsProfile="oddsProfile"/>
                  </td>
                </tr>
              </table>

            </div>
            </div>
          </div>
          <div 
            v-if="!isFullTime && dialogData.editable">
            <a-button 
              class="add-btn"
              @click="addPool" 
              :disabled="allowAddPool"
              block>
              <!-- {{ oddsForm.id ? $t('global.action.update') : $t('global.action.create') }} -->
              {{ $t('PoolSettingPage.add_new_pool_configuration') }}
            </a-button>
          </div>
        </div>
      </div>
      <div class="submit-form-container" v-if="dialogData.editable">
        <div class="submit-card">
            <table>
              <tr>
                <td>
                  <span> {{ $t('PoolSettingPage.expect_date') }} </span>
                </td>
                <td>
                  <a-date-picker 
                    :class="['card-selection']"
                    :disabledDate="disabledDate"
                    v-model="form.expert.date"
                    />
                </td>
              </tr>
              <tr>
                <td>
                  <span>{{ $t('PoolSettingPage.expect_time') }}</span>
                </td>
                <td>
                  <a-time-picker
                    :class="['card-selection', form.expert.date && 'ant-input']"
                    :disabled="!form.expert.date"
                    :defaultOpenValue="defaultOpenValue"
                    :minuteStep="10"
                    :disabledHours="() => disabledExpertHours()"
                    :disabledMinutes="(selectedHour) => disabledExpertMinutes(selectedHour)"
                    :disabledSeconds="() => disabledStartPicker()"
                    :hideDisabledOptions="true"
                    v-model="form.expert.time"/>
                </td>
              </tr>
            </table>

        </div>
        <div class="submit-btn-group">
          <a-button class="add-btn" @click="confirmResetForm">
            {{ $t('global.action.reset') }}
          </a-button>

          <a-button 
            type="primary" 
            @click="settingFormSubmit" 
            :disabled="!isFullTime || !form.expert.date || !form.expert.time"
            :loading="submitLoading">
            {{ $t('global.action.submit') }}
          </a-button>
        </div>
      </div>
    </div>
  </a-modal>
</template>
<script>
import moment from 'moment';
import { cloneDeep, isEqual } from 'lodash';
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { createPoolSetting, updatePoolSetting } from '@/../api';
import MalayDetailPopover from './malay_detail_popover.vue';

export default {
  props:{
    closeModal: {
      type: Function,
      default: () => {}
    },
    settingForm: {
      type: Object,
      default: () => ({})
    },
    show: {
      type: Boolean,
      default: false,
    },
    dialogData: {
      type: Object,
      default: () => ({})
      // {
      //   attr: id,
      //         editable,
      //         merchant_provider_id,
      //         schedule_info,
      //         effective_time,
      //         link_master,
      //         log_no,
      //         current_schedule
      // }
    },
    fetchData: {
      type: Function,
      default: () => {}
    },
    oddsProfile: {
      type: Array,
      default: ()=>([])
    }
  },
  components:{
    FullCalendar,
    MalayDetailPopover
  },
  data() {
    const checkName = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('global.message.fill_input', {value: this.$t('global.common.name')})));
      } else {
        callback();
      }
    };
    const checkRate = (rule, value, callback) => {
      if (Number(value) > this.rake_percentage.max) {
        callback(new Error(this.$t('global.common.max', {value: Number(this.rake_percentage.max).toFixed(2)})));
      } else {
        callback();
      }
    };
    const checkCloseBetPercent = (rule, value, callback) => {
      if (Number(value) <= this.oddsForm.last_call_percentage) {
        callback(new Error(this.$t('global.message.A_greater_tham_B', {value_a: this.$t('PoolSettingPage.close_bet_percentage'), value_b: this.$t('PoolSettingPage.last_call_percentage')})));
      } else {
        callback();
      }
    };
    const checkPerRoundRiskThreshold = (rule, value, callback) => {
      if (Number(value) < this.oddsForm.per_bet_risk_threshold) {
        callback(new Error(this.$t('global.message.A_greater_tham_B', {
          value_a: `${this.$t('PoolSettingPage.per_round')} ${this.$t('PoolSettingPage.risk_threshold')}`, 
          value_b: `${this.$t('PoolSettingPage.per_bet')} ${this.$t('PoolSettingPage.risk_threshold')}`, 
        })));
      } else {
        callback();
      }
    };
    return {
      rules: {
        name: [{required: true ,validator: checkName, trigger: 'submit' }],
        rake_percentage: [{ validator:checkRate, trigger: 'submit' }],
        close_bet_percentage: [{ validator:checkCloseBetPercent, trigger: 'submit' }],
        per_round_risk_threshold: [{ validator:checkPerRoundRiskThreshold, trigger: 'submit' }],
      },
      submitLoading: false,
      isMounted: false,
      maxHour: 24,
      form: {
        poolSettings: [],
        expert: {
          date: null,
          time: null,
        }
      },
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin],
        headerToolbar: false,
        aspectRatio: 0.5, //最小似乎只能設到0.5，實測0.5以下無作用
        expandRows: true,
        // headerToolbar: {
        //     left: 'prev,next today',
        //     center: 'title',
        //     right: 'dayGridMonth,timeGridWeek,timeGridDay'
        // },
        // buttonText: { //各按钮的显示文本信息
        //     today: '今天',
        //     month: '月',
        //     week: '周',
        //     day: '日',
        // },
        // locale: 'en-GB', //语言环境
        initialView: 'timeGridDay',
        // initialEvents: [], // alternatively, use the `events` setting to fetch from a feed
        events: [
          // {//日程事件
          //   id: 1,
          //   title: '会议',
          //   // start: '2024-01-22 00:01',
          //   // end: '2024-01-22 01:00'
          //   startTime: '00:00:00',
          //   endTime: '11:59:59',
          // },
          // {
          //   id: 2,
          //   title: '会议1',
          //   // start: '2024-01-22 10:00',
          //   // end: '2024-01-22 11:00',
          //   startTime: '12:00:00',
          //   endTime: '12:59:59'
          // }
        ],
        slotEventOverlap: false, //Boolean，預設為true，用來顯示事件間是否重疊排列
        allDaySlot: false, //Boolean，預設為true，在TimeGrid模式下，是否顯示all day欄位
        dayHeaders: false,//Boolean，預設為true，在TimeGrid模式下，用來顯示星期幾
        slotDuration: '01:00:00', //String，預設為'00:30:00'，用來顯示列表的時間單位區間
        scrollTime: '00:00:00', //String，預設為'06:00:00'，用來顯示第一次載入的時間捲軸位置(或切換tab時會觸發)
        scrollTimeReset: false, //Boolean，預設為true，是否啟用切換tab時會觸發scrollTime
        slotLabelFormat: { //標籤格式
          hour12: false, //使用12小時制
          hour: '2-digit', //使用00:00
          minute: '2-digit', //使用00
        },
        eventTimeFormat: {
          // hour12: false, //使用12小時制
          hour: '2-digit', //使用00:00
          minute: '2-digit', //使用00
          hourCycle: 'h23' //用來避免 00:00:00顯示成24:00:00
        },
        slotLabelContent:(info) => {
          return info.text.replace('24', '00'); //標籤的第一欄位會是24:00:00，將24轉成00
        },
        // editable: true,
        // selectable: true,
        selectMirror: true,
        // dayMaxEvents: true,
        // weekends: true,
        // eventDurationEditable: true,
        // select: this.handleDateSelect,//拖拽选中事件
        // eventClick: this.handleEventClick,//日程事件点击事件
        // eventsSet: this.handleEvents,//设置日程事件
        // eventDrop: (info) => {
        //     console.log(info);
        // },
        // eventResize: (info) => {
        //     console.log(info, '&lt;&lt;&lt;&lt;&lt;调整日程');
        // },
      },
    };
  },
  async mounted() {
    this.isMounted = true;
  },
  computed: {
    defaultOpenValue(){
      return moment(moment().format('YYYY-MM-DD'));
    },
    allowAddPool(){
      const lastSetting = this.form.poolSettings[this.form.poolSettings.length - 1];
      return this.oddsProfile.length > 0 && lastSetting && lastSetting.startTime && lastSetting.endTime ? false : true;
    },
    poolSettings(){
      return this.form.poolSettings;
    },
    isFullTime(){
      const oneDaySecond = 24 * 60 * 60;
      const reduceSettingsSecond = this.form.poolSettings.reduce((accumulator, currentValue)=>{
        if(!currentValue.startTime || !currentValue.endTime){
          return accumulator;
        }else{
          const currentStartTimeSecond = moment(currentValue.startTime).unix();
          const currentEndTimeSecond = moment(currentValue.endTime).unix() + 1;
          return accumulator + (currentEndTimeSecond - currentStartTimeSecond);
        }
      }, 0)

      return reduceSettingsSecond === oneDaySecond;
    },
    firstSelectOption(){
      return this.oddsProfile.length > 0 ? this.oddsProfile[0].id : null;
    }
  },
  watch: {
    poolSettings:{
      handler(newValue){
        const cloneSettings = cloneDeep(newValue);
        const lastSetting = cloneSettings[cloneSettings.length - 1];

        if(!lastSetting.startTime || !lastSetting.endTime){
          cloneSettings.splice(cloneSettings.length - 1, 1);
        }

        const events = cloneSettings.map(settingItem=>{
          const currentOption = this.oddsProfile.find((option)=>option.id === settingItem.settingOption);
          return {
            startTime: moment(settingItem.startTime).format('HH:mm:ss'),
            endTime: moment(settingItem.endTime).format('HH:mm:ss'),
            title: currentOption ? currentOption.name : '',
          }
        })

        if(!isEqual(events, this.calendarOptions.events)){
          this.$set(this.calendarOptions, 'events', events);
        }

      },
      deep: true
    },
    show: {
      handler(newValue){
        if(newValue){
          if(this.dialogData.schedule_info.length === 0){
            this.$set(this.form, 'poolSettings',[{
              startTime: null,
              endTime: null,
              settingOption: this.firstSelectOption
            }])
          }else{
            const poolSettings = this.dialogData.schedule_info.map(settingItem=>{
              const today = moment().format('YYYY-MM-DD');
              return {
                startTime: moment(`${today} ${settingItem.start_time}`),
                endTime: moment(`${today} ${settingItem.end_time}`),
                settingOption: settingItem.odds_profile
              }
            })

            this.$set(this.form, 'poolSettings', poolSettings)

          }

          if(this.dialogData.effective_time){
            const currentTime = moment(this.dialogData.effective_time);
            this.form.expert.date = currentTime;
            this.form.expert.time = currentTime;
          }

        }
      }
    }
  },
  methods: {
    disabledStartPicker(){
      const arr = Array.from({ length: 59 }, (v, i) => i + 1);
      return arr;
    },
    disabledHours(index){

      const reduceSettings = this.form.poolSettings.reduce((accumulator, currentValue, currentIndex)=>{
        //如果開始時間為空或結束時間為空或當時正在選擇的選項，則不進行處理
        if(!currentValue.startTime || !currentValue.endTime || currentIndex === index){
          return accumulator;
        }else{
          //開始時間
          const currentStartTime = moment(currentValue.startTime).hours();
          //結束時間
          const currentEndTime = moment(currentValue.endTime).hours();
          //兩者相減的時間區間（需大於最小值所以需要+1）
          const timeSubtract = currentEndTime - currentStartTime + 1;
          //與之前的陣列組合
          return accumulator.concat(Array.from({ length: timeSubtract }, (v, i)=> i + currentStartTime));
        }
      }, [])

      return reduceSettings.sort((a, b)=> a - b);
    },
    disabledStartHours(endTime, index){
      const disabledHours = this.disabledHours(index);
      //如果同項的endTime有值
      if(endTime){
        //當前選擇的結束時間
        const currentEndHour = moment(endTime).hours() + 1;
        //禁止選結束時間之後的時間區間
        const disabledStartHourArr = Array.from({ length: this.maxHour - currentEndHour }, (v, i)=> i + currentEndHour);
        //不允許選擇時間的降冪排序
        const descDisableHours = disabledHours.sort((a, b)=> b - a);
        //不允許選擇時間的最小值（因為要大於最小值，所以找到後需要+1)
        const disabledMinHour = descDisableHours.find(hour=>hour < currentEndHour) + 1;
        //禁止選不允許選擇時間的最小值之前的時間區間
        const disabledLowerHourArr = Array.from({ length: disabledMinHour }, (v, i)=> i);

        //總禁止的時間區間為組合兩個陣列
        return disabledStartHourArr.concat(disabledLowerHourArr);
      }else{
        return disabledHours;
      }
    },
    disabledEndHours(startTime, index){
      //已被禁止的時間
      const disabledHours = this.disabledHours(index);
      //當前選擇的開始時間
      const currentStartHour = moment(startTime).hours();
      //禁止選目前選擇的開始時間之前列表
      const disabledStartHourArr = Array.from({ length: currentStartHour }, (v, i)=> i);
      //如果已被禁止的時間為空陣列
      if(disabledHours.length === 0){
        //回傳禁止選目前選擇的開始時間之前列表
        return disabledStartHourArr;
      }else{
        //不允許選擇的時間最小值
        const disableMinEndHour = disabledHours.find(hour=>hour > currentStartHour);

        if(!disableMinEndHour){
          return disabledStartHourArr;
        }else{
          //產生不允許選擇的時間最小值到最大時間的區間列表
          const disabledEndHourArr = Array.from({ length: this.maxHour - disableMinEndHour }, (v, i)=> i + disableMinEndHour);
          //將兩個不允許選擇的時間區間做結合（即這個允許選擇區間外的時間皆被禁止）
          return disabledStartHourArr.concat(disabledEndHourArr);
        }
      }
    },
    disabledEndPicker(){
      const arr = Array.from({ length: 59 }, (v, i) => i);
      return arr;
    },
    showAllowClear(index){
      return index === this.form.poolSettings.length - 1;
    },
    changeTime(time, timeString){
      // console.log(time);
      // console.log(timeString);
    },
    deletePoolItem(index){
      this.form.poolSettings.splice(index, 1);
    },
    addPool(){
      this.form.poolSettings.push(
        {
          startTime: null,
          endTime: null,
          settingOption: this.firstSelectOption
        })
    },
    disabledDate(current){
      return current < moment().startOf('day');
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    disabledExpertHours(){
      const today = moment().startOf('day');
      const expertDate = moment(this.form.expert.date).startOf('day');
      if(today.isSame(expertDate)){
        const currentHour = moment().hour();
        return Array.from({ length: currentHour }, (v, i)=>i);
      }else{
        return [];
      }
    },
    disabledExpertMinutes(selectedHour){
      const today = moment().startOf('day');
      const expertDate = moment(this.form.expert.date).startOf('day');
      const currentHour = moment().hour();
      const isSameHour = selectedHour === null || selectedHour === currentHour ? true : false;
      if(today.isSame(expertDate) && isSameHour){
        const currentMinute = moment().minute();
        return Array.from({ length: currentMinute }, (v, i)=>i);
      }else{
        return []
      }
    },
    resetForm(){
      this.$set(this.calendarOptions, 'events', []);
      this.$set(this.form, 'poolSettings', 
      [
        {
          startTime: null,
          endTime: null,
          settingOption: this.firstSelectOption
        }
      ]);
      this.form.expert.date = null;
      this.form.expert.time = null;
    },
    confirmResetForm(){
      const self = this;
      this.$confirm({
        title: self.$t('global.message.reset_data'),
        okText: self.$t('global.action.yes'),
        cancelText: self.$t('global.action.no'),
        onOk() {
          self.resetForm();
        },
        onCancel() {},
      });
    },
    settingFormSubmit() {
      if(this.submitLoading) return;

      const effectiveTime = moment(`${moment(this.form.expert.date).format('YYYY-MM-DD')} ${moment(this.form.expert.time).format('HH:mm:ss')}`);
      const now = moment();

      if(moment(effectiveTime).isBefore(now)){
        return this.$message.error(this.$t('PoolSettingPage.exceed_time'), 3);
      }

      this.submitLoading = true;
      let hasError = false;

      const effectiveTimeUtcFormat = effectiveTime.utc().format('YYYY-MM-DD HH:mm:ss');

      const id = this.dialogData.id;
      const form = {
        merchant_provider_id: parseInt(this.dialogData.merchant_provider_id),
        effective_time: `${effectiveTimeUtcFormat} +0`,
        schedule_info: this.form.poolSettings.sort((a, b)=> moment(a.startTime).unix() - moment(b.startTime).unix()).map(settingItem => {
          return {
            odds_profile: settingItem.settingOption,
            start_time: moment(settingItem.startTime).format('HH:mm:ss'),
            end_time: moment(settingItem.endTime).format('HH:mm:ss')
          }
        })
      }
      const poolAction = id ? updatePoolSetting : createPoolSetting;
      poolAction({ id , form }).then(({data})=>{
        const {code, message} = data;
        if(code === 'ERROR'){
          throw new Error(message)
        }else{
          const i18n = id ? 'global.message.success_to_edit' : 'global.message.success_to_create'
          this.$message.success(this.$t(i18n, {value: this.$t('global.common.setting')}));
        }
      }).catch((err)=>{
        const errMsg = err.response ? err.response.data.message : err.message;
        this.$message.error(errMsg);
        hasError = true;
      }).finally(()=>{
        this.submitLoading = false;
        this.closeModal();
        if(!hasError){
          this.fetchData();
        }
      })
    },
    showOptionName(id){
      const optionItem = this.oddsProfile.find(profileItem=>profileItem.id === id)
      return optionItem ? optionItem.name : '-' ;
    },
    importCurrentSchedule(){
      const self = this;
      const title = `${this.$t('PoolSettingPage.import_current_schedule')}?`
      this.$confirm({
        title: title,
        okText: self.$t('global.action.yes'),
        cancelText: self.$t('global.action.no'),
        onOk() {
          const poolSettings = self.dialogData.current_schedule.schedule_info.map(settingItem=>{
            const today = moment().format('YYYY-MM-DD');
            return {
              startTime: moment(`${today} ${settingItem.start_time}`),
              endTime: moment(`${today} ${settingItem.end_time}`),
              settingOption: settingItem.odds_profile
            }
          })

          self.$set(self.form, 'poolSettings', poolSettings);
        },
        onCancel() {},
      });
    }
  },
};
</script>
