<template>
    <div class="result-btn-group">
        <template v-if="appleResultBtn">
            <div class="group-item group-item-2">
                <button :disabled="!can_fight_control" class="result-btn" data-result-btn="meron" @click="checkResult('meron')">{{ $t(`global.fight_result.${GAME_TYPE_SIDE[game_type]['meron']}`) }}</button>
                <button :disabled="!can_fight_control" class="result-btn" data-result-btn="meron" @click="checkResult('red3')">{{ $t(`global.fight_result.${GAME_TYPE_SIDE[game_type]['red3']}`) }}</button>
            </div>
            <div class="group-item group-item-2">
                <button :disabled="!can_fight_control" class="result-btn" data-result-btn="white" @click="checkResult('wala')">{{ $t(`global.fight_result.${GAME_TYPE_SIDE[game_type]['wala']}`) }}</button>
                <button :disabled="!can_fight_control" class="result-btn" data-result-btn="white" @click="checkResult('white3')">{{ $t(`global.fight_result.${GAME_TYPE_SIDE[game_type]['white3']}`) }}</button>
            </div>
            <div class="group-item group-item-1">
                <button :disabled="!can_fight_control" class="result-btn" data-result-btn="cancel" @click="checkResult('cancel')">{{ $t(`global.fight_result.${GAME_TYPE_SIDE[game_type]['cancel']}`) }}</button>
            </div>
        </template>
        <template v-else>
            <div class="group-item group-item-1">
                <template v-if="showResultBtn">
                  <button :disabled="!can_fight_control" class="result-btn" data-result-btn="meron" @click="checkResult('meron')">{{ $t(`global.fight_result.${GAME_TYPE_SIDE[game_type]['meron']}`) }}</button>
                  <button :disabled="!can_fight_control"
                          class="result-btn"
                          :data-result-btn="GAME_TYPE_SIDE[game_type]['wala']"
                          @click="checkResult('wala')">
                    {{ $t(`global.fight_result.${GAME_TYPE_SIDE[game_type]['wala']}`) }}
                  </button>
                  <button v-if="enableDraw" :disabled="!can_fight_control" class="result-btn" :data-result-btn="GAME_TYPE_SIDE[game_type]['draw']" @click="checkResult('draw')">{{ $t(`global.fight_result.${GAME_TYPE_SIDE[game_type]['draw']}`) }}</button>
                </template>
                <button :disabled="!can_fight_control" class="result-btn" data-result-btn="cancel" @click="checkResult('cancel')">{{ $t(`global.fight_result.${GAME_TYPE_SIDE[game_type]['cancel']}`) }}</button>
            </div>
        </template>
    </div>
  </template>
  <script>
  import { GAME_TYPE_SIDE } from '@/../tools/config';
  
  export default {
    props: {
      game_type: String,
      can_fight_control: {
        type: Boolean,
        default: false,
      },
      enableDraw: {
        type: Boolean,
        default: false,
      }
    },
    data() {
      return {
        GAME_TYPE_SIDE
      };
    },
    mounted() {},
    computed: {
      showResultBtn() {
        return this.game_type !== 'up_down'
      },
      appleResultBtn() {
        return this.game_type === 'apple'
      } 
    },
    methods: {
        checkResult(result){
            this.$emit('checkResult', result)
        }
    }
  };
  </script>
  