<template>
    <a-modal 
      :visible="visible" 
      :footer="null" 
      :width="'60%'"
      :afterClose="clearLog"
      @cancel="closeLogModal"
      class="malay-odds-setting-page"
    >

      <template slot="title">
        <span>{{ $t('global.common.log') }}</span>
        <span> - </span>
        <span class="primary-font-color">{{ link_master }}</span>
      </template>
        
        <a-table
            bordered
            size="small"
            :columns="columns"
            :row-key="data => data.id"
            :data-source="data"
            :loading="logLoading"
            :pagination="pagination"
            @change="handleTableChange"
        >
            <div 
              v-for="column in columns" 
              :slot="column.slots.title" 
              :key="column.slots.title"
              class="title">
              <span v-if="column.slots.title !== 'per_bet_risk_threshold' && column.slots.title !== 'per_round_risk_threshold'">
                {{ column.singleI18n ? $t(`PoolSettingPage.${column.slots.title}`) :$t(`global.common.${column.slots.title}`) }}
              </span>
              <div v-else>
                <p>{{ $t('PoolSettingPage.risk_threshold') }}</p>
                <p>{{ column.slots.title === 'per_bet_risk_threshold' ? $t('PoolSettingPage.per_bet') : $t('PoolSettingPage.per_round') }} (USD)</p>
              </div>
            </div>
            
            <template slot="action" slot-scope="action">
              <div>{{ $t(`global.action.${action}`) }}</div>
            </template>

            <template slot="formatTimes" slot-scope="times">
              <div class="table-time">{{ times | formatTimesToUTC8 }}</div>
            </template>

            <template slot="schedule" slot-scope="schedule, row">
              <a-button 
                v-if="schedule.length > 0 && poolPremissions.viewSchedule"
                type="primary" 
                size="small" 
                @click="setDialogData({
                  id: null, 
                  editable: false, 
                  merchant_provider_id: null, 
                  schedule_info: schedule,
                  effective_time: row.effective_time,
                  link_master: link_master,
                  log_no: row.log_no
                })">
                {{ $t('PoolSettingPage.view') }}
              </a-button>
              <span v-else>-</span>
            </template>
        </a-table>


        <PoolSettingDialog 
          :show="dialogVisible"
          :closeModal="closeDialog"
          :dialogData="dialogData"
          :oddsProfile="oddsProfile"
        />

    </a-modal>
  </template>
  <script>
import { getPoolChangeLogById } from '@/../api';
import PoolSettingDialog from '@/../src/components/pool/pool_setting_dialog';


const columns = [
  {
    slots: { title: 'account_id' },
    dataIndex: 'log_no',
    filterType: true,
    align: 'center',
    width: 70
  },
  {
    slots: { title: 'user' },
    dataIndex: 'username',
    filterType: true,
    align: 'center',
  },
  {
    slots: { title: 'type' },
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' },
    filterType: true,
    align: 'center',
  },
  {
    slots: { title: 'created_at' },
    dataIndex: 'created_at',
    scopedSlots: { customRender: 'formatTimes' },
    filterType: true,
    align: 'right'
  },
  {
    slots: { title: 'effective_time' },
    dataIndex: 'effective_time',
    scopedSlots: { customRender: 'formatTimes' },
    filterType: true,
    singleI18n: true,
    align: 'right'
  },
  {
    slots: { title: 'schedule' },
    dataIndex: 'schedule_info',
    scopedSlots: { customRender: 'schedule' },
    filterType: true,
    singleI18n: true,
    align: 'center'
  },
];
  
  export default {
    props:{
        visible: {
          type: Boolean,
          default: false
        },
        id: {
          type: Number,
          default: null
        },
        link_master: {
          type: String,
          default: ''
        },
        clearLog: {
          type: Function,
          default: ()=>{}
        },
        closeLogModal: {
          type: Function,
          default:()=>{}
        },
        oddsProfile: {
          type: Array,
          default:()=>([])
        },
        poolPremissions: {
          type: Object,
          default: ()=>({})
        }
    },
    components: {
      PoolSettingDialog,
    },
    data() {
      return {
        columns,
        data: [],
        pagination: {
          current: 1,
          pageSize: 5, // 默认每页显示数量
          // showSizeChanger: true, // 显示可改变每页数量
          // pageSizeOptions: ["5", "10", "20", "50", "100"], // 每页数量选项
          showTotal: (total) => `Total ${total} items`, // 显示总数
          // showSizeChange: (current, pageSize) => (this.pageSize = pageSize), // 改变每页数量时更新显示
          total: 0
        },
        logLoading: false,
        currentId: null,
        dialogData: {
          id: null,
          editable: false,
          merchant_provider_id: null,
          effective_time: null,
          schedule_info: [],
          link_master: '',
          log_no: null
        },
        dialogVisible: false,
      };
    },
    async mounted() {},
    methods: {
        openDialog(){
          this.dialogVisible = true;
        },
        closeDialog(){
          this.dialogVisible = false;
        },
        setDialogData({id, editable, merchant_provider_id, schedule_info, effective_time, link_master, log_no}){
          this.dialogData.id = id;
          this.dialogData.editable = editable;
          this.dialogData.merchant_provider_id = merchant_provider_id;
          this.dialogData.schedule_info = schedule_info;
          this.dialogData.effective_time = effective_time;
          this.dialogData.link_master = link_master;
          this.dialogData.log_no = log_no;
          this.openDialog();
        },
        handleTableChange(pagination, filters, sorter) {
            const currentPage = this.pagination.pageSize !== pagination.pageSize ? 1 : pagination.current;
            this.pagination.current = currentPage;
            this.pagination.pageSize = pagination.pageSize;

            this.fetch({
                results: pagination.pageSize,
                page: currentPage,
                sortField: sorter.field,
                sortOrder: sorter.order,
                ...filters,
            });
        },
        fetch(form = {}) {
            this.logLoading = true;
            getPoolChangeLogById({id: this.currentId, form}).then(({ data }) => {
                const pagination = { ...this.pagination };
                const result = [];

                data.logs.forEach(logItem=>{
                  const {action, auditable_id, created_at, username, audited_changes = {}, id} = logItem;

                  if(audited_changes && audited_changes.delete_at) return false;

                  let currentEffectiveTime;
                  let currentScheduleInfo;
                  if(!audited_changes){
                    currentEffectiveTime = null;
                    currentScheduleInfo = []
                  }else{
                    const { effective_time, schedule_info } = audited_changes;

                    currentEffectiveTime = 
                      !effective_time 
                        ? null  
                        : Array.isArray(effective_time) 
                          ? effective_time[effective_time.length - 1] 
                          : effective_time;
                    currentScheduleInfo = 
                      !schedule_info 
                        ? [] 
                        : Array.isArray(schedule_info[schedule_info.length - 1]) 
                          ? schedule_info[schedule_info.length - 1] 
                          : schedule_info;
                  }

                  const dataItem = {
                    id,
                    action,
                    log_no: auditable_id,
                    created_at,
                    username,
                    effective_time: currentEffectiveTime,
                    schedule_info: currentScheduleInfo
                  }

                  result.push(dataItem)

                })

                pagination.total = result.length;
                this.data = result;
                this.pagination = pagination;
            }).catch((err)=>{
                this.$message.error(err.response.data.message, 3);
            }).finally(()=>{
                this.logLoading = false;
            });
        },
    },
    computed:{},
    watch:{
        id:{
            handler(newValue){
              this.currentId = newValue;
            },
            immediate: true
        },
        currentId:{
            handler(newValue){
                if(newValue){
                    const { pageSize } = this.pagination;
                    this.pagination.current = 1;
                    this.fetch({results: pageSize, page: 1});
                }
            }
        }
    }
  };
  </script>
  
  
  