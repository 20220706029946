<template>
  <a-modal :visible="formShow" :title="`${dialogAction} ${$t('global.common.usable_language')}`" :footer="null" :width="450" @cancel="closeModal('languageList')">
    <a-form-model :layout="'vertical'" ref="language" :model="languageForm">
      <a-row  class="checkbox-page-min-title">
        <a-col :sm="12">
          <h5>{{ $t('global.common.usable_language') }}</h5>
        </a-col>
        <a-col :sm="12">
          <a-checkbox class="checkbox checkbox-gap" :checked="selectedAll" :indeterminate="indeterminate" @change="onCheckAllChange">
            {{ $t('global.common.all') }}
          </a-checkbox>
        </a-col>
      </a-row>

      <a-row class="checkbox-page" v-for="ListItem in languageOptions" :key="ListItem.abbreviation">
        <a-col class="checkbox-page-group-title" :sm="12">
          <span><img class="icon" :src="require(`images/flag/${ListItem.abbreviation}.svg`)" :alt="`${ListItem.abbreviation} icon`"></span>
          <span>{{ ListItem.name }}</span>
        </a-col>
        <a-col :sm="12">
          <a-checkbox-group class="checkbox-page-group"
                            @change="onChange(ListItem.id)"
                            :value='languageForm.languages'
                            >
              <a-checkbox class="checkbox checkbox-gap" :value="ListItem.id"></a-checkbox>
          </a-checkbox-group>
        </a-col>
      </a-row>

      <a-form-model-item class="checkbox-page-btn">
        <a-button type="primary" @click="languageSubmit()" block>
          {{ dialogAction }}
        </a-button>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import { 
  // getCurrentUserLanguagesId, 
  updateLanguage, 
  // getUserLanguagesById 
  } from '@/../api';
import { iconList } from '@/../tools/config';

export default {
  props:{
    formShow: Boolean,
    dialogAction: String,
    closeModal: Function,
    languageList: Array,
    getAgentProfile: Function,
    updateLanguageList: Function,
    usableLanguageList: Array
  },
  data() {
    return {
      iconList,
      indeterminate: true,
      selectedAll: false,
      languageForm: {
        // 丟 id
        languages: [],
      },
      languageOptions:[],
      allLanguages: [],
    };
  },
  mounted() {
    // this.init();
  },
  methods: {
    // init() {
    //   if(this.$route.params.id !== undefined){
    //     getUserLanguagesById({id: this.$route.params.id})
    //       .then((data)=>{
    //         this.languageForm.languages = data.data.languages
    //       })
    //   } else {
    //     getCurrentUserLanguagesId()
    //       .then((data)=>{
    //         this.languageForm.languages = data.data.languages
    //       })
    //   }
    // },
    onChange(value) {
      this.languageForm.languages.indexOf(value) > -1
      ? this.languageForm.languages.splice(this.languageForm.languages.indexOf(value),1)
      : this.languageForm.languages.push(value);
    },
    onCheckAllChange(e) {
      this.languageForm.languages = e.target.checked ? this.allLanguages : [];
      this.indeterminate = !(e.target.checked);
      this.selectedAll = e.target.checked;
    },
    languageSubmit() {
      this.$refs.language.validate((valid) => {
        if (!valid) {
          this.$message.error(this.$t('global.message.failed_to_edit', {value: this.$t('global.common.language')}));
          return false;
          } else {
            if(this.languageForm.languages.length === 0) {
              return this.$message.error(this.$t('global.message.select_one', {value: this.$t('global.common.language')}));
            }
            if(this.languageForm.languages === undefined) {
              this.$message.error(this.$t('global.message.failed_to_edit', {value: this.$t('global.common.language')}));
              return false;
            }

            updateLanguage({language: this.languageForm.languages, id: this.$route.params.id})
              .then((data)=>{
                this.$message.success(this.$t('global.message.success_to_change', {value: this.$t('global.common.language')}));
                this.closeModal('languageList');
                this.updateLanguageList();
                this.getAgentProfile();
              })
              .catch((err) => {
                this.$message.error(err.response.data.message);
              });
        }
      });
    },
  },
  watch: {
    languageList:{
      handler(newValue, oldValue){
        this.languageOptions = newValue;
        this.allLanguages = newValue.map(valueItem => valueItem.id);
      },
      deep: true,
      immediate: true
    },
    usableLanguageList:{
      handler(newValue, oldValue){
        const languages = [];
        newValue.forEach(usableLanguage=>{
          languages.push(usableLanguage.id)
        })
        this.languageForm.languages = languages;
      },
      deep: true,
      immediate: true
    },
    // languageForm: {
    //   handler(newValue, oldValue){
    //     const { languages } = newValue;
    //     this.indeterminate = languages.length < this.languageOptions.length;
    //     this.selectedAll = languages.length === this.languageOptions.length;
    //   },
    //   deep: true,
    //   immediate: true
    // }
  },
  updated(){
    this.indeterminate = this.languageForm.languages.length < this.languageOptions.length;
    this.selectedAll = this.languageForm.languages.length === this.languageOptions.length;
  }
};
</script>


