<template>
  <div class="edit-video-url">
    <a-button type="primary" icon="edit" @click="showModal">
      <template v-if="showTitle">{{ $t('global.action.edit') }}</template>
    </a-button>
    <a-modal v-model="visible" :title="$t('ControlPanelPage.edit_video_url')" :footer="null" :width="380">
      <a-form-model-item prop="content">
        <a-textarea v-model="video_url_input" :auto-size="{ minRows: 3, maxRows: 6 }" />
      </a-form-model-item>
      <a-form-model-item>
        <a-button type="primary" @click="editVideoUrl()" block>
          {{ $t('global.action.edit') }}
        </a-button>
      </a-form-model-item>
    </a-modal>
  </div>
</template>
<script>

export default {
  props: {
    video_url: String,
    showTitle: {
      type: Boolean,
      default: true
    } 
  },
  data() {
    return {
      visible: false,
      video_url_input: '',
    };
  },
  methods: {
    showModal() {
      this.visible = true;
    },
    editVideoUrl() {
      if (this.video_url_input !== this.video_url) {
        this.visible = false;
        this.$emit("edited", this.video_url_input);
      }
    },
  },
  watch: {
    video_url: {
      handler(newValue, oldValue) {
        this.video_url_input = newValue;
      },
    },
  }
};
</script>
