<template>
  <a-modal :visible="show"
           :footer="null"
           :centered="true"
           :width="550"
           :title="$t(`ExchangeRatePage.approve_rate`)" 
           @cancel="closeModal">
    <div class="exchange-rate-dialog">
      <a-table
          bordered
          size="small"
          :columns="columns"
          :data-source="data"
          :row-key="data => data.id"
          :loading="loading"
          :pagination="false"
      >
        <template v-for="(column,index) in columns">
          <span :slot="column.slots.title" :key="index">{{ column.singleI18n ? $t(`ExchangeRatePage.${column.slots.title}`) : $t(`global.common.${column.slots.title}`) }}</span>
        </template>

        <template slot="rate" slot-scope="rate">
          <div>{{ numeral(rate).format('0,0[.][000]') }}</div>
        </template>
      </a-table>
      <p class="warning">{{ $t('ExchangeRatePage.rate_warning') }}</p>
      <div class="btn-group">
        <a-button type="primary" @click="approveRate" block>
          {{ $t('global.action.ok') }}
        </a-button>
        <a-button type="primary" @click="closeModal" block>
          {{ $t('global.action.cancel') }}
        </a-button>
      </div>
    </div>
  </a-modal>
</template>

<script>
import numeral from 'numeral';

const columns = [
  {
    slots: { title: 'currency' },
    dataIndex: 'currency',
  },
  {
    slots: { title: 'currency_name' },
    dataIndex: 'currency_name',
    singleI18n: true,
  },
  {
    slots: { title: 'last_approved_rate' },
    dataIndex: 'last_approved_rate',
    scopedSlots: { customRender: 'rate' },
    align: 'center',
    singleI18n: true,
  },
  {
    slots: { title: 'approve_rate' },
    dataIndex: 'tbd_confirm_rate',
    scopedSlots: { customRender: 'rate' },
    align: 'center',
    singleI18n: true,
  },
];

export default {
  props: {
    show: Boolean,
    closeModal: Function,
    data: Array,
    approveRate: Function
  },
  data() {
    return {
      loading: false,
      columns,
    };
  },
  methods: {
    numeral,
  },
  watch:{}
};
</script>
