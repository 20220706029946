<template>
  <div class="fight-control-item-inner">
    <div class="fight-control-item-header">
      <div class="fight-item-number">
        <span class="title">{{ $t('global.common.fight_id') }}</span>
        <span class="text">{{ now_fight.fight_no }}</span>
      </div>
      <div class="fight-item-status" :data-fight-status="roundStatus">
        <span class="title">{{ $t('global.common.status') }}</span>
        <span class="text">{{ roundStatus === '' ? '' : $t(`global.common.round_status_list.${roundStatus}`) }}</span>
      </div>
    </div>
    <div class="fight-control-item-body">
      <div class="control-item-group">
        <div class="control-item-inner settle-item-inner">
          <a-button 
            @click="retryRoundLock"
            :loading="retryLoading" 
            :disabled="disabledClick" 
            class="btn control-item-btn settle-item-btn ant-btn">
            {{ $t('global.action.retry') }}
          </a-button>
          <a-button 
            @click="cancelRound"
            :loading="cancelLoading" 
            :disabled="disabledClick" 
            class="btn control-item-btn settle-item-btn ant-btn">
            {{ $t('global.action.cancel') }}
          </a-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { postRetryRoundLock, postRoundCancel } from '@/../api';

export default {
  props: {
    now_fight: {
      type: Object,
      default: () => ({}),
    },
    currentPoolId: [String, Number],
    updateRoundLocked: {
      type: Function,
      default: () => {},
    }
  },
  data() {
    return {
      retryLoading: false,
      cancelLoading: false,
    };
  },
  mounted() {
  },
  // watch: {
  //   now_fight: {
  //     handler(newValue){
  //       console.log(newValue)
  //     }
  //   }
  // },
  computed: {
    roundStatus() {
      if (this.now_fight.round_locked === undefined) return '';
      return this.now_fight.round_locked ? 'settled' : 'unsettled';
    },
    disabledClick() {
      // return false
      return this.now_fight.round_locked || this.now_fight.round_locked === undefined || this.retryLoading || this.cancelLoading || this.now_fight.pool_status !== 'earn_locked';
    }
  },
  methods: {
    retryRoundLock() {
      this.retryLoading = true;
      postRetryRoundLock({round_id: this.now_fight.round_id, main_pool_id: this.currentPoolId})
        .then((res) => {
          if(res.data.code === 'OK'){
            this.$message.success(this.$t('ControlPanelPage.message.success_to_earn_locked', { value: this.now_fight.round_id }));
            this.updateRoundLocked({ round_locked: true });
          }else{
            throw new Error(res.data.message);
          }
        })
        .catch((err) => {
          const errorMessage = err.response ? err.response.data.message : err.message;
          this.$message.error(this.$t('ControlPanelPage.message.fail_to_earn_locked', { value: this.now_fight.round_id, reason: errorMessage }));
        })
        .finally(() => {
          this.retryLoading = false;
        })
    },
    cancelRound() {
      this.cancelLoading = true;
      postRoundCancel({round_id: this.now_fight.round_id, main_pool_id: this.currentPoolId})
        .then((res) => {
          if(res.data.code === 'OK'){
            this.$message.success(this.$t('ControlPanelPage.message.success_to_cancel_bet', { value: this.now_fight.round_id }));
            this.updateRoundLocked({ round_locked: true, winner: 'cancel' });
          }else{
            throw new Error(res.data.message);
          }
        })
        .catch((err) => {
          const errorMessage = err.response ? err.response.data.message : err.message;
          this.$message.error(this.$t('ControlPanelPage.message.fail_to_cancel_bet', { value: this.now_fight.round_id, reason:errorMessage }));
        })
        .finally(() => {
          this.cancelLoading = false;
        })
    }
  }
};
</script>
