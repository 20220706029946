<template>
  <div class="frame change-password-page">
    <a-page-header
      :title="$t('global.menu.ChangePassword')"
      @back="() => $router.go(-1)"
    >
    </a-page-header>
    <div class="page-content">
      <a-row>
        <a-col :md="24" :lg="16" :xl="8">
          <a-form-model :layout="'vertical'" ref="changePassword" :model="changePassword" :rules="rules">
            <a-form-model-item :label="$t('ChangePasswordPage.current_password')" prop="current_password">
              <a-input-password v-model="changePassword.current_password" :placeholder="$t('ChangePasswordPage.current_password_placeholder')" autocomplete="off" size="large"/>
            </a-form-model-item>
            <a-form-model-item has-feedback :label="$t('ChangePasswordPage.new_password')" prop="new_password">
              <a-input-password v-model="changePassword.new_password" type="password" :placeholder="$t('global.common.password_placeholder')" autocomplete="off" size="large"/>
            </a-form-model-item>

            <a-form-model-item has-feedback :label="$t('global.common.confirm_password')" prop="confirm_password">
              <a-input-password v-model="changePassword.confirm_password" type="password" :placeholder="$t('global.message.confirm_password_placeholder')" autocomplete="off" size="large"/>
            </a-form-model-item>
            <a-form-model-item>
              <a-button type="primary" size="large" @click="changePasswordSumit(changePassword)" block>
                {{ $t('global.action.submit') }}
              </a-button>
            </a-form-model-item>
          </a-form-model>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import { updatePassword } from '@/../api';

export default {
  data() {
    const currentPassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('global.message.fill_input', {value: this.$t('ChangePasswordPage.current_password')})));
      } else {
        callback();
      }
    };
    const checkPassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('global.message.fill_input', {value: this.$t('ChangePasswordPage.new_password')})));
      }else if (value.length < 6) {
        callback(new Error(this.$t('global.message.must_be_at_6_characters', {value: this.$t('ChangePasswordPage.new_password')})));
      } else {
        callback();
      }
    };
    const confirmPassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('global.message.fill_input', {value: this.$t('global.common.confirm_password')})));
      }else if (value !== this.changePassword.new_password) {
        callback(new Error(this.$t('global.message.two_no_match')));
      } else {
        callback();
      }
    };
    return {
      visible: false,
      changePassword: {
        current_password: '',
        new_password: '',
        confirm_password: '',
      },
      rules: {
        current_password: [{ validator: currentPassword, trigger: 'change' }],
        new_password: [{ validator: checkPassword, trigger: 'change' }],
        confirm_password: [{ validator: confirmPassword, trigger: 'change' }],
      },
    };
  },
  methods: {
    showModal() {
      this.visible = true;
    },
    changePasswordSumit() {
      this.$refs.changePassword.validate((valid) => {
        if (!valid) {
          // this.$message.error('error message');
          return false;
          } else {
            const { current_password, new_password, confirm_password } = this.changePassword;
            updatePassword({ current_password, new_password, confirm_password })
              .then((data)=>{
                this.$message.success(this.$t('global.message.success_to_change', {value: this.$t('global.common.password')}));
                this.visible = false;
                this.$refs.changePassword.resetFields();
              })
              .catch((err)=>{
                this.$message.error(err.response.data.message)
              })
        }
      });
    },
  },
};
</script>
