<template>
  <div class="frame bet-log-page">
    <a-page-header
      :title="$t('global.menu.Order')"
      @back="() => $router.go(-1)"
    >
    </a-page-header>
    <div class="page-content">
      <div class="search-bar">
        <a-form-model ref="searchForm" :model="form" :rules="rules">
          <div class="search-group">
            <Currency :inputCurrency.sync="form.currency"
                      :inputMaster.sync="form.master"
                      :inputAgent.sync="form.agent"
                      :showCurrency="false"
                      :showMaster="false"/>
            <a-form-model-item ref="account" :label="$t('global.common.account')" prop="account">
              <a-input
                v-model="form.account"
              />
            </a-form-model-item>
            <a-form-model-item ref="slug" :label="$t('global.common.slug')" prop="slug">
              <a-input
                v-model="form.slug"
              />
            </a-form-model-item>
            <a-form-model-item ref="type" :label="$t('global.common.type')" prop="type">
              <a-select v-model="form.type">
                <a-select-option v-for="typeItem in OrderTypes" :value="typeItem.value" :key="typeItem.value">{{ typeItem.label === 'all' ? $t(`global.common.${typeItem.label}`) : $t(`global.common.bet_type_list.${typeItem.label}`) }}</a-select-option>
              </a-select>
            </a-form-model-item>
            <!--
            <a-form-model-item :label="$t('global.merchant.id')" prop="merchant">
              <a-select v-model="form.merchant" placeholder="please select merchant">
                <a-select-option value="shanghai">
                  Merchant 1
                </a-select-option>
                <a-select-option value="beijing">
                  Merchant 2
                </a-select-option>
              </a-select>
            </a-form-model-item>-->
          </div>
          <div class="search-group">
            <Datetime :input.sync="form.date"
                      :periodDefault.sync="periodDefault"/>
            <div class="search-btns">
              <a-button type="primary" icon="search" :loading="loading" @click="onSearch">{{ $t('global.action.search') }}</a-button>
              <a-button :disabled="loading" @click="resetForm">{{ $t('global.action.reset') }}</a-button>
            </div>
          </div>
        </a-form-model>
      </div>
      <div class="ant-card">
        <div class="ant-card-body">
          <div class="table-control">
            <a-popover trigger="click" placement="bottomRight">
              <template slot="content">
                <div class="table-filter-content">
                  <a-checkbox class="table-filter-check"
                              v-for="(item, index) in columns"
                              :key="item.dataIndex"
                              :defaultChecked="item.filterType"
                              :disabled="item.disabledFilter"
                              @click="columnsChange(index, item)"
                              :value="item.dataIndex">
                    {{ item.singleI18n ? $t(`OrderPage.${item.slots.title}`) : $t(`global.common.${item.slots.title}`) }}
                  </a-checkbox>
                </div>
              </template>
              <a-button icon="filter"></a-button>
            </a-popover>
          </div>
          <a-table
            bordered
            size="small"
            :columns="columns.filter( item => item.filterType || item.filterType === undefined)"
            :data-source="orders"
            :pagination="pagination"
            :loading="loading"
            :row-key="orders => orders.id"
            @change="handleTableChange"
          >
            <template v-for="column in columns">
              <span :slot="column.slots.title" :key='column.slots.title'>{{ column.singleI18n ? $t(`OrderPage.${column.slots.title}`) : $t(`global.common.${column.slots.title}`) }}</span>
            </template>
            <template slot="type" slot-scope="type">
              <span>{{ $t(`global.common.bet_type_list.${type}`) }}</span>
            </template>
            <template slot="status" slot-scope="status">
              <div class="table-status-item">
                <span class="status-icon" :data-status="status"></span>
                <span class="status-text">{{ $t(`global.common.order_status_list.${status}`) }}</span>
              </div>
            </template>
            <template slot="formatTimes" slot-scope="times">
              <div class="table-time">{{ times | formatTimes }}</div>
            </template>
            <template slot="formatNumberWithPoint" slot-scope="amount">
              <div>{{ amount | formatNumberWithPoint }}</div>
            </template>
          </a-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getOrders } from '@/../api';
import Datetime from '@/../src/components/datetime_picker';
import Currency from '@/../src/components/currency_selection';

const columns = [
  {
    slots: { title: 'slug' },
    dataIndex: 'slug',
    filterType: true,
    disabledFilter: true,
  },
  {
    slots: { title: 'account' },
    dataIndex: 'player_account',
    filterType: true,
  },
  {
    slots: { title: 'merchant_account' },
    dataIndex: 'merchant_account',
    filterType: true,
  },
  {
    slots: { title: 'agent_account' },
    dataIndex: 'agent_account',
    filterType: true,
  },
  {
    slots: { title: 'type' },
    dataIndex: 'type',
    align: 'center',
    scopedSlots: { customRender: 'type' },
    customCell:(text) => {
      return {
        class: 'source-type type-'+ text.type,
      };
    },
    filterType: true,
  },
  {
    slots: { title: 'status' },
    dataIndex: 'status',
    align: 'right',
    scopedSlots: { customRender: 'status' },
    filterType: true,
  },
  {
    slots: { title: 'amount' },
    dataIndex: 'amount',
    align: 'right',
    scopedSlots: { customRender: 'formatNumberWithPoint' },
    filterType: true,
  },
    {
    slots: { title: 'after_balance' },
    dataIndex: 'after_balance',
    align: 'right',
    scopedSlots: { customRender: 'formatNumberWithPoint' },
    filterType: true,
    singleI18n: true,
  },
  {
    slots: { title: 'created_at' },
    dataIndex: 'created_at',
    scopedSlots: { customRender: 'formatTimes' },
    filterType: true,
  },
  {
    slots: { title: 'updated_at' },
    dataIndex: 'updated_at',
    scopedSlots: { customRender: 'formatTimes' },
    filterType: true,
  },
  {
    slots: { title: 'settled_at' },
    dataIndex: 'settled_at',
    scopedSlots: { customRender: 'formatTimes' },
    filterType: true,
  },
];

export default {
  components: {
    Datetime,
    Currency,
  },
  data() {
    return {
      data: [],
      pagination: {
        current: 1,
        pageSize: 10, // 默认每页显示数量
        showSizeChanger: true, // 显示可改变每页数量
        pageSizeOptions: ["10", "20", "30", "40"], // 每页数量选项
        showTotal: (total) => `Total ${total} items`, // 显示总数
        showSizeChange: (current, pageSize) => (this.pageSize = pageSize), // 改变每页数量时更新显示
        total: 0
      },
      expand: false,
      loading: false,
      columns,
      form: {
        date: [new Date(), new Date()],
        slug: this.$route.query.slug || '',
        type: 'All',
        agent: null,
        currency: '',
        master: null,
      },
      OrderTypes: [
        {
          value: 'All',
          label: 'all'
        },
        {
          value: 'deposit',
          label: 'deposit'
        },
        {
          value: 'withdraw',
          label: 'withdraw'
        },
      ],
      periodDefault: 'today',
      orders: [],
      rules: {
        agent: [{ required: true, trigger: 'submit' }],
      },
    };
  },
  mounted() {
    // if (this.$route.query.slug) {
    //   this.periodDefault = 'unset';
    //   setTimeout(() => {
    //     this.onSearch();
    //   }, 100);
    // }
    // this.onSearch();
  },
  methods: {
    handleTableChange(pagination, filters, sorter) {
      const currentPage = this.pagination.pageSize !== pagination.pageSize ? 1 : pagination.current;
      this.pagination.current = currentPage;
      this.pagination.pageSize = pagination.pageSize;

      const { date, account, slug, type, agent } = this.form;
      this.fetch({
        results: pagination.pageSize,
        page: currentPage,
        date: date,
        account: account,
        slug: slug,
        type: type,
        agent: agent,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
      });
    },
    fetch(params = {}) {
      this.loading = true;
      getOrders({
        ...params,
      }).then(({ data }) => {
        const pagination = { ...this.pagination };
        // Read total count from server
        // pagination.total = data.totalCount;
        pagination.total = data.total;
        // pagination.total = 200;
        this.orders = data.orders;
        this.pagination = pagination;
      }).finally(()=>{
        this.loading = false;
      });
    },
    onSearch() {
      if(this.loading) return;
      const { current, pageSize } = this.pagination;
      const { date, account, slug, type, agent } = this.form;
      this.$refs.searchForm.validate(valid => {
        if (valid) {
          this.fetch({ date, account, slug, type, results: pageSize, page: current, agent });
        } else {
          // console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm() {
      if(this.loading) return;
      this.$refs.searchForm.resetFields();
      // this.form.slug = '';
      this.periodDefault = 'today';
      // this.onSearch();
      // setTimeout(() => {
      //   this.onSearch();
      // }, 100);
    },
    columnsChange(index, item) {
      item.filterType = !item.filterType;
    },
  },
};
</script>
