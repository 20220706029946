<template>
  <a-modal 
    v-model="showResultDialog" 
    :title="$t('CreateAgentPage.agent_info')"
    :footer="null" 
    :width="720"
    :maskClosable="false"
    :closable="false"
    :keyboard="false"
    :afterClose="clearCreateResult"
    class="create-agent-page">
      <p v-if="showResultPlaceholder" class="create-agent-result create-agent-wilder">
        {{ $t('global.message.success_to_create', { value: $t('global.common.agent') }) }} 
        {{ $t('CreateAgentPage.message.please_refresh_cache')}}
      </p>
      <div class="create-agent-result create-agent-wilder">
        <p v-for="(itemData, index) in filterData" :key="index">{{ itemData }}</p>
      </div>
        <div class="create-agent-footer">
        <a-button class="add-btn" @click="closeResultDialog">
          {{ $t('global.common.active_status_list.close') }}
        </a-button>
      </div>
  </a-modal>
</template>
<script>

export default {
  props:{
    showResultDialog: Boolean,
    closeResultDialog: Function,
    data: Array,
    clearCreateResult: Function,
    showResultPlaceholder: Boolean,
  },
  data() {
    return {};
  },
  async mounted() {},
  methods: {},
  computed:{
    filterData(){
      return this.data.slice(0, this.data.length -1);
    }
  },
  watch:{}
};
</script>
