<template>
  <div class="new-master-agent">
    <a-button class="add-btn" icon="user-add" @click="showModal">
      {{ $t('AgentsPage.create_master_agent') }}
    </a-button>
    <a-modal v-model="visible" :title="$t('AgentsPage.create_master_agent')" :footer="null" :width="720">
      <a-form-model :layout="'vertical'" ref="newAgent" :model="newAgent" :rules="rules">
        <a-row :gutter="[16,16]">
          <a-col :xs="24" :sm="12">
            <a-form-model-item :label="$t('global.common.account')" prop="account">
              <a-input v-model="newAgent.account" :placeholder="$t('global.message.account_placeholder')" autocomplete="off" />
            </a-form-model-item>
            <a-form-model-item has-feedback :label="$t('global.common.password')" prop="password">
              <a-input v-model="newAgent.password" type="password" :placeholder="$t('global.message.password_placeholder')" autocomplete="off" />
            </a-form-model-item>
            <a-form-model-item has-feedback :label="$t('global.common.confirm_password')" prop="confirm_password">
              <a-input v-model="newAgent.confirm_password" type="password" :placeholder="$t('global.message.confirm_password_placeholder')" autocomplete="off" />
            </a-form-model-item>
            <a-form-model-item :label="$t('global.common.email')" prop="email">
              <a-input v-model="newAgent.email" />
            </a-form-model-item>
            <a-form-model-item :label="$t('AgentsPage.abbr')" prop="abbr">
              <a-input v-model="newAgent.abbr" :placeholder="$t('AgentsPage.abbr_placeholder')"/>
            </a-form-model-item>
          </a-col>
          <a-col :xs="24" :sm="12">
            <a-form-model-item :label="$t('global.common.name')" prop="name">
              <a-input v-model="newAgent.name" />
            </a-form-model-item>
            <a-form-model-item :label="$t('global.common.theme')" prop="theme">
              <a-select v-model="newAgent.theme">
                <a-select-option v-for="themeOption in themeOptions" :key="themeOption" :value="themeOption">
                  {{ themeOption }}
                </a-select-option>
              </a-select>
            </a-form-model-item>

            <a-form-model-item :label="$t('global.common.skin')" prop="skin" class="create-agent-form-item">
              <a-select v-model="newAgent.skin">
                <a-select-option v-for="skinOption in skinOptions" :key="skinOption" :value="skinOption">
                  {{ $t(`global.common.skin_list.${skinOption}`) }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <!-- 目前需求是要隱藏該欄位 -->
            <!-- <a-form-model-item :label="$t('AgentsPage.rate')" prop="rate">
              <a-row>
                <a-col :span="16">
                  <a-slider v-model="newAgent.rate" :min="rate_min" :max="rate_max" :step="0.1" />
                </a-col>
                <a-col :span="8">
                  <a-input-number
                    :value="newAgent.rate"
                    :min="rate_min"
                    :max="rate_max"
                    :step="0.1"
                    :formatter="value => `${value}%`"
                    :parser="parserValue"
                    @change="handleChangeRate"
                    style="marginLeft: 16px"
                  />
                </a-col>
              </a-row>
            </a-form-model-item> -->
            <a-form-model-item :label="$t('global.common.currency')" prop="currency">
              <a-select v-model="newAgent.currency">
                <a-select-option v-for="currency in currencys" :value="currency" :key="currency">
                  {{ currency }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-form-model-item>
          <a-button type="primary" @click="newAgentSubmit(newAgent)" :loading="createLoading" block>
            {{ $t('global.action.create') }}
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
import { agentCreate, getClientTheme, getCurrentManageRate, getClientCurrency, } from '@/../api';

export default {
  data() {
    const checkAccount = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('global.message.fill_input', {value: this.$t('global.common.account')})));
      }else if (value.length < 4) {
        callback(new Error(this.$t('global.message.must_be_at_4_characters', {value: this.$t('global.common.account')})));
      } else if (!/^[a-zA-Z0-9_\.]*$/.test(value)) {
        callback(new Error(this.$t('global.message.must_be_alphanumeric', {value: this.$t('global.common.account')})));
      } else {
        callback();
      }
    };
    const checkPassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('global.message.fill_input', {value: this.$t('global.common.password')})));
      }else if (value.length < 6) {
        callback(new Error(this.$t('global.message.must_be_at_6_characters', {value: this.$t('global.common.password')})));
      } else {
        callback();
      }
    };
    const confirmPassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('global.message.fill_input', {value: this.$t('global.common.confirm_password')})));
      }else if (value !== this.newAgent.password) {
        callback(new Error(this.$t('global.message.two_no_match')));
      } else {
        callback();
      }
    };
    const checkName = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('global.message.fill_input', {value: this.$t('global.common.name')})));
      } else {
        callback();
      }
    };
    const checkRate = (rule, value, callback) => {
      if (Number(value) > this.rate_max) {
        callback(new Error(this.$t('global.common.max', {value: Number(this.rate_max).toFixed(1)})));
      } else {
        callback();
      }
    };
    const checkAbbr = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('global.message.fill_input', {value: this.$t('AgentsPage.abbr')})));
      }else if(value.length > 6){
        callback(new Error(this.$t('global.message.must_be_between_characters', {value: this.$t('AgentsPage.abbr'), min:1, max:6})));
      } 
      else {
        callback();
      }
    };
    return {
      visible: false,
      themes: [],
      currencys: [],
      rate_max: 0, // This agent max rate
      rate_min: 0,
      theme_list: [
        {
          name: 'default_theme',
          skin_list:['black', 'brown', 'white', 'dark_and_light']
        },
        {
          name: 'custom_theme',
          skin_list:['white', 'black']
        },
      ],
      newAgent: {
        account: '',
        password: '',
        confirm_password: '',
        email: '',
        name: '',
        theme: '',
        skin: '',
        rate: 0,
        currency: '',
        abbr: '',
      },
      rules: {
        account: [{ required: true, validator: checkAccount, trigger: 'submit' }],
        password: [{ required: true, validator: checkPassword, trigger: 'submit' }],
        confirm_password: [{ required: true, validator: confirmPassword, trigger: 'submit' }],
        name: [{required: true ,validator: checkName, trigger: 'submit' }],
        theme: [{ required: true, trigger: 'submit' }],
        skin: [{ required: true, trigger: 'submit' }],
        currency: [{ required: true, trigger: 'submit' }],
        rate: [{ validator:checkRate, trigger: 'submit' }],
        abbr: [{ required: true ,validator:checkAbbr, trigger: 'submit' }],
        email: [{ required: true, trigger: 'submit' }],
      },
      createLoading: false,
    };
  },
  async mounted() {
    await this.init();
    if(this.theme_list.length !== 0) {
      this.newAgent.theme = this.theme_list[0].name;
    }
  },
  computed: {
    themeOptions(){
      return this.theme_list.map(themeListItem=>themeListItem.name);
    },
    formTheme() {
      return this.newAgent.theme;
    },
    skinOptions() {
      const currentThemeOption = this.theme_list.find(themeItem=>themeItem.name === this.formTheme);
      return currentThemeOption ? currentThemeOption.skin_list : [];
    },
  },
  watch: {
    formTheme: {
      handler(newValue){
        if(newValue !== ''){
          const currentTheme = this.theme_list.find(themeItem=>themeItem.name === newValue);
          if(currentTheme){
            this.newAgent.skin = currentTheme.skin_list[0];
          }
        }else{
          if(this.theme_list.length !== 0) {
            this.newAgent.theme = this.theme_list[0].name;
          }
        }
      }
    }
  },
  methods: {
    async init(){
      await getClientTheme()
        .then((data)=>{
          // this.theme_list = res.data.themes;
        })
      await getCurrentManageRate()
        .then((data) => {
          const { odds_mode, rate } = data.data;
          if(odds_mode !== 'rake'){
            this.rate_min = Number(rate) * 100;
            this.rate_max = 100;
          }else{
            this.rate_min = 0;
            this.rate_max = Number(rate) * 100;
          }
      })
      await getClientCurrency()
        .then((data)=>{
          this.currencys = data.data.currencies;
        })
    },
    showModal() {
      this.visible = false;
    },
    parserValue(value) {
      const replacePercentValue = value.replace('%', '');
      const newValue = /[^\d.]/g.test(replacePercentValue) ? replacePercentValue.replace(/[^\d.]/g,'') : replacePercentValue ;
      const numberValue = newValue.split('.');
      const integerValue = numberValue[0];
      const decimalValue = numberValue[1];
      if(decimalValue && decimalValue.length > 3){
        return `${integerValue}.${decimalValue.substring(0, 3)}`;
      }else if(Number(newValue) >= this.rate_max){
        return String(this.rate_max);
      }else {
        return newValue;
      }
    },
    handleChangeRate(value){
      const newValue = String(value).length > 5 ? Number(String(value).slice(0,5)) : Number(value);
      this.newAgent.rate = newValue;
    },
    newAgentSubmit() {
      if(this.createLoading) return;
      this.$refs.newAgent.validate((valid) => {
        if (!valid) {
          this.$message.error(this.$t('global.message.failed_to_create', {value: this.$t('global.common.account')}));
          return false;
          } else {
          const { account, password, name, theme, rate, email, currency, abbr , skin} = this.newAgent;
          const ratePercentage = rate / 100;
          this.createLoading = true;
          agentCreate({ account, password, name, client_theme: theme, fee: ratePercentage, email, currency, abbr, skin_option: skin })
            .then((data) => {
              this.createLoading = false;
              this.$message.success(this.$t('global.message.success_to_create', {value: this.$t('global.common.account')}));
              this.visible = false;
              this.$refs.newAgent.resetFields();
              this.$emit("crated");
            })
            .catch((err) => {
              this.$message.error(err.response.data.message);
            });
        }
      });
    },
  },
};
</script>
