<template>
  <a-modal v-model="showConfirmDialog" :footer="null" :width="720" :maskClosable="false" :closable="false"
    :keyboard="false" class="create-agent-page">
    <div>
      <a-row :gutter="[16, 0]" class="create-agent-content">
        <a-col :xs="24" :sm="12" class="create-agent-col">
          <p>{{ $t('global.common.master') }}：<span class="primary-font-color">{{ data.master }}</span></p>
          <p>{{ $t('CreateAgentPage.parent_agent') }}：<span class="primary-font-color">{{ data.parentAgent }}</span></p>
          <p>{{ $t('CreateAgentPage.environment') }}：<span class="primary-font-color">{{ currentEnv }}</span></p>
          <p>{{ $t('CreateAgentPage.trade_mode') }}：<span class="primary-font-color">{{ currentMode }}</span></p>
          <p v-if="data.callbackUrl">{{ $t('global.common.callback_url') }}：<span class="primary-font-color">{{
            data.callbackUrl }}</span></p>
          <p>{{ $t('CreateAgentPage.brand') }}：<span class="primary-font-color">{{ currentBrand }}</span></p>
          <div class="multiple-options">
            <p>{{ $t('global.common.language') }}：</p>
            <template v-for="languageItem in language_list">
              <p :key="languageItem.id" v-if="data.languages.includes(languageItem.id)">
                <img :src="require(`images/flag/${languageItem.abbreviation}.svg`)"
                  :alt="`${languageItem.abbreviation} icon`">
                <span>{{ languageItem.name }}</span>
              </p>
            </template>
          </div>
          <p>{{ $t('global.common.theme') }}：<span class="primary-font-color">{{ currentTheme }}</span></p>
          <p>{{ $t('global.common.skin') }}：<span class="primary-font-color">{{ currentSkin }}</span></p>
          <p>{{ $t('global.common.custom_logo') }}：<span class="primary-font-color">{{ currentLogo }}</span></p>
          <div v-if="data.settledZero.length > 0 || data.multiArena.length > 0 || data.addSettleBetInfo.length > 0 || data.agentCreditPoints.length > 0"  class="multiple-options">
            <p>{{ $t('global.common.options') }}：</p>
            <p v-if="data.settledZero.length > 0">
              <a-icon type="check" />
              <span>{{ $t('global.common.settle_zero') }}</span>
            </p>
            <p v-if="data.multiArena.length > 0">
              <a-icon type="check" />
              <span>{{ $t('global.common.multi_arena') }}</span>
            </p>
            <p v-if="data.addSettleBetInfo.length > 0">
              <a-icon type="check" />
              <span>{{ $t('global.common.add_settle_bet_info') }}</span>
            </p>
            <p v-if="data.agentCreditPoints.length > 0">
              <a-icon type="check" />
              <span>{{ $t('global.menu.AgentCreditPoints') }} - {{ $t(`global.common.${data.basisCalculationType}`) }}</span>
            </p>
          </div>
        </a-col>
        <a-col :xs="24" :sm="12" class="create-agent-col">
          <p class="create-agent-col-title">{{ $t('global.common.agent') }}：</p>
          <div class="create-agent-sub-content">
            <div v-for="(agentItem, index) in data.agents" :key="index" class="create-agent-form-item-group">
              <p>{{ $t('CreateAgentPage.level_sub_agent_with_value', { value: index + 1 }) }}</p>
              <p>{{ $t('global.common.account') }}：<span class="primary-font-color">{{ agentItem.account }}</span></p>
              <p>{{ $t('global.common.name') }}：<span class="primary-font-color">{{ agentItem.name }}</span></p>
            </div>
          </div>
        </a-col>
      </a-row>
      <div class="create-agent-footer">
        <a-button class="add-btn" @click="closeConfirmDialog">
          {{ $t('global.action.cancel') }}
        </a-button>

        <a-button type="primary" @click="newAgentSubmit('generate')" :loading="createBtnLoading">
          {{ $t('global.action.create') }}
        </a-button>
      </div>
    </div>
  </a-modal>
</template>
<script>
import { iconList } from '@/../tools/config';

export default {
  props: {
    showConfirmDialog: Boolean,
    closeConfirmDialog: Function,
    data: Object,
    language_list: Array,
    website_type_list: Array,
    brand_list: Array,
    trade_mode_list: Array,
    newAgentSubmit: Function,
    createBtnLoading: Boolean,
    theme_list: Array
  },
  data() {
    return {
      iconList,
    };
  },
  async mounted() { },
  methods: {},
  computed: {
    currentEnv() {
      const current = this.website_type_list.find(typeListItem => typeListItem.value === this.data.env);
      return current ? this.$t(`CreateAgentPage.${current.label}`) : '';
    },
    currentBrand() {
      const current = this.brand_list.find(brandListItem => brandListItem.value === this.data.brand);
      return current ? current.label : '';
    },
    currentMode() {
      const current = this.trade_mode_list.find(tradeListItem => tradeListItem.value === this.data.tradeMode);
      return current ? this.$t(`CreateAgentPage.${current.label}`) : '';
    },
    currentTheme() {
      return this.data.theme;
    },
    currentSkin() {
      return this.$t(`global.common.skin_list.${this.data.skin}`);
    },
    currentLogo() {
      if(this.data.custom_logo === ""){ return null }
      return this.$t(`global.common.custom_logo_list.${this.data.custom_logo}`);
    }
  },
  watch: {}
};
</script>
