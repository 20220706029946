<template>
    <div class="draw-limit">
        <div class="draw-limit-header">
            <div class="draw-limit-title">
                <p>{{ $t('global.common.round_limit') }}</p>
                <a-button v-if="data.editable && updatePermission" type="primary" icon="edit" @click="showModal">
                    {{ $t('global.action.edit') }}
                </a-button>
            </div>
            <div class="draw-limit-subtitle">{{ $t('ProfilePage.setting_round_total_max') }}</div>
        </div>
        <div class="draw-limit-content">
          <template v-if="data.game_type === 'apple'">
            <p>
              <span :class="getCurrentSide('red3')">{{ $t(`global.fight_result.${getCurrentSide('red3')}`) }}</span>
              <span> / </span>
              <span :class="getCurrentSide('white3')">{{ $t(`global.fight_result.${getCurrentSide('white3')}`) }}</span>
            </p>
          </template>
          <template v-else>
            <p :class="getCurrentSide('draw')">{{ $t(`global.fight_result.${getCurrentSide('draw')}`) }}</p>
          </template>
          <p class="draw-number">{{ maxDraw | formatNumber }}</p>
        </div>
        <a-modal v-model="visible" :title="$t('global.common.round_limit')" :footer="null"  class="draw-limit-dialog" width='380px'>
            <div class="placeholder">
              {{ $t('ProfilePage.setting_round_draw_max_part1') }}
              <template v-if="data.game_type === 'apple'">
                <span :class="getCurrentSide('red3')">{{ $t(`global.fight_result.${getCurrentSide('red3')}`) }}</span>
                <span> / </span>
                <span :class="getCurrentSide('white3')">{{ $t(`global.fight_result.${getCurrentSide('white3')}`) }}</span>
              </template>
              <template v-else>
                <span :class="getCurrentSide('draw')">{{ $t(`global.fight_result.${getCurrentSide('draw')}`) }}</span>
              </template>
              {{$t('ProfilePage.setting_round_draw_max_part2')}}</div>
            <a-form-model-item prop="content">
                <a-input-number v-model="drawLimit" />
            </a-form-model-item>
            <a-form-model-item>
                <a-button type="primary" @click="editLimit" block>
                {{ $t('global.action.edit') }}
                </a-button>
            </a-form-model-item>
        </a-modal>
    </div>
</template>
<script>
import { GAME_TYPE_SIDE } from '@/../tools/config';

export default {
  props: {
    maxDraw: Number,
    updateLimit: Function,
    data: Object,
    updatePermission: Boolean
  },
  data() {
    return {
      GAME_TYPE_SIDE,
      visible: false,
      drawLimit: 0,
    };
  },
  methods: {
    showModal() {
      this.visible = true;
    },
    closeModal(){
      this.visible = false;
    },
    editLimit() {
      this.updateLimit('max_draw',this.drawLimit, this.closeModal);
    },
    getCurrentSide(side){
      return this.GAME_TYPE_SIDE[this.data.game_type][side];
    },
  },
  watch:{
    maxDraw:{
      handler(newValue, oldValue){
        if(newValue){
          this.drawLimit = newValue;
        }
      },
      immediate: true
    }
  },
};
</script>
