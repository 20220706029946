<template>
  <div class="frame create-agent-page">
    <a-page-header :title="$t('global.menu.CreateAgent')" @back="() => $router.go(-1)">
      <template slot="extra">
        <a-button v-if="!disabledCacheBtn" type="primary" icon="reload" @click="refreshCache">{{
          $t('global.action.refresh_cache') }}</a-button>
        <a-button type="primary" icon="table" @click="activeLogDialog">{{ $t('global.common.log') }}</a-button>
      </template>
    </a-page-header>
    <div class="page-container">
      <div class="page-content">
        <a-form-model :layout="'vertical'" ref="form" :model="form" :rules="rules">
          <a-row :gutter="[16, 0]" class="create-agent-content">
            <a-col :xs="24" :sm="12" class="create-agent-col">

              <a-form-model-item :label="$t('global.common.master')" prop="master">
                <a-select v-model="form.master" :showSearch='true'
                  :placeholder="$t('global.message.select_value', { value: $t('global.common.master') })">
                  <a-select-option v-for="masterItem in master_list" :key="masterItem.id" :value="masterItem.account">
                    {{ masterItem.account }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>

              <a-form-model-item :label="$t('CreateAgentPage.parent_agent')" prop="sameAsMaster">
                <a-checkbox-group v-model="form.sameAsMaster" :disabled="!form.master">
                  <a-checkbox :value="1">{{ $t('CreateAgentPage.message.agent_same_master') }}</a-checkbox>
                </a-checkbox-group>
              </a-form-model-item>

              <a-form-model-item prop="parentAgent" class="sub-form-item">
                <a-select v-model="form.parentAgent" :disabled="!form.master || form.sameAsMaster.length > 0"
                  :placeholder="form.master ? $t('global.message.select_value', { value: $t('global.common.agent') }) : $t('CreateAgentPage.message.select_master_first')"
                  :showSearch='true'>
                  <a-select-option v-for="agentItem in agent_list" :key="agentItem.id" :value="agentItem.account">
                    {{ agentItem.account }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>

              <a-form-model-item :label="$t('CreateAgentPage.environment')" prop="env">
                <a-radio-group v-model="form.env" :disabled="true">
                  <a-radio v-for=" typeListItem in website_type_list" :key="typeListItem.value"
                    :value="typeListItem.value">{{ $t(`CreateAgentPage.${typeListItem.label}`) }}</a-radio>
                </a-radio-group>
              </a-form-model-item>

              <a-form-model-item :label="$t('CreateAgentPage.trade_mode')" prop="tradeMode">
                <a-radio-group v-model="form.tradeMode">
                  <a-radio v-for="tradeListItem in trade_mode_list" :key="tradeListItem.value"
                    :value="tradeListItem.value">{{ $t(`CreateAgentPage.${tradeListItem.label}`) }}</a-radio>
                </a-radio-group>
              </a-form-model-item>

              <a-form-model-item :label="$t('global.common.callback_url')" prop="callbackUrl"
                v-if="form.tradeMode === 'transfer'">
                <a-input v-model.trim="form.callbackUrl" placeholder="https://..." />
              </a-form-model-item>

              <a-form-model-item :label="$t('CreateAgentPage.brand')" prop="brand">
                <a-radio-group v-model="form.brand" :disabled="true">
                  <a-radio v-for=" brandListItem in brand_list" :key="brandListItem.value" :value="brandListItem.value">{{
                    brandListItem.label }}</a-radio>
                </a-radio-group>
              </a-form-model-item>

              <a-form-model-item :label="$t('global.common.language')" prop="languages" class="create-agent-form-item">
                <a-select mode="multiple" :showArrow="true" v-model="form.languages"
                  dropdownClassName="create-agent-select"
                  :placeholder="$t('global.message.select_one', { value: $t('global.common.language') })">
                  <a-select-option 
                    class="language-item" 
                    v-for="languageItem in language_list"
                    :key="languageItem.id" 
                    :value="languageItem.id">
                    <img :src="require(`images/flag/${languageItem.abbreviation}.svg`)"
                      :alt="`${languageItem.abbreviation} icon`">
                    <span>{{ languageItem.name }}</span>
                  </a-select-option>
                </a-select>
              </a-form-model-item>

              <a-form-model-item :label="$t('global.common.theme')" prop="theme">
                <a-select v-model="form.theme">
                  <a-select-option v-for="themeOption in themeOptions" :key="themeOption" :value="themeOption">
                    {{ themeOption }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>

              <a-form-model-item :label="$t('global.common.skin')" prop="skin" class="create-agent-form-item">
                <a-select v-model="form.skin">
                  <a-select-option v-for="skinOption in skinOptions" :key="skinOption" :value="skinOption">
                    {{ $t(`global.common.skin_list.${skinOption}`) }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>

              <a-form-model-item :label="$t('global.common.custom_logo')" prop="custom_logo">
                <a-select v-model="form.custom_logo">
                  <a-select-option v-for="logoOption in logoOptions" :key="logoOption" :value="logoOption">
                    {{ logoOption }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>

              <div class="ant-form-item-label">{{ $t('global.common.options') }}</div>
              <a-row :gutter="[8, 8]">
                <a-col :xs="24">
                  <a-form-model-item prop="settledZero">
                    <a-checkbox-group v-model="form.settledZero" :disabled="form.tradeMode === 'credit'">
                      <a-checkbox :value="1">{{ $t('global.common.settle_zero') }}</a-checkbox>
                    </a-checkbox-group>
                  </a-form-model-item>
                </a-col>
              </a-row>
              <a-row :gutter="[8, 8]">
                <a-col :xs="24">
                  <a-form-model-item prop="multiArena">
                    <a-checkbox-group v-model="form.multiArena">
                      <a-checkbox :value="1">{{ $t('global.common.multi_arena') }}</a-checkbox>
                    </a-checkbox-group>
                  </a-form-model-item>
                </a-col>
              </a-row>
              <a-row :gutter="[8, 8]">
                <a-col :xs="24">
                  <a-form-model-item prop="addSettleBetInfo">
                    <a-checkbox-group v-model="form.addSettleBetInfo">
                      <a-checkbox :value="1">{{ $t('global.common.add_settle_bet_info') }}</a-checkbox>
                    </a-checkbox-group>
                  </a-form-model-item>
                </a-col>
              </a-row>
              <a-row :gutter="[8, 8]">
                <a-col :xs="24">
                  <a-form-model-item prop="agentCreditPoints">
                    <a-checkbox-group v-model="form.agentCreditPoints">
                      <a-checkbox :value="1">{{ $t('global.menu.AgentCreditPoints') }}</a-checkbox>
                    </a-checkbox-group>
                  </a-form-model-item>
                </a-col>
              </a-row>

              <a-form-model-item v-if="form.agentCreditPoints.length > 0" :label="$t('global.common.calculation_base')" prop="basisCalculationType" class="create-agent-form-option-item">
                <a-select v-model="form.basisCalculationType">
                  <a-select-option v-for="BasicCalculationItem in filterBasicCalculationList" :key="BasicCalculationItem.value" :value="BasicCalculationItem.value">
                    {{ $t(`global.common.${BasicCalculationItem.label}`) }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>

            </a-col>
            <a-col :xs="24" :sm="12" class="create-agent-col">

              <a-form-model-item :label="$t('global.common.agent')" prop="agents">
                <div v-for="(agentItem, index) in form.agents" :key="index" class="create-agent-form-item-group">
                  <p>{{ $t('CreateAgentPage.level_sub_agent_with_value', { value: index + 1 }) }}</p>
                  <a-form-model-item :label="$t('global.common.account')" :prop="`agents[${index}].account`"
                    :rules="rules.account">
                    <a-input v-model.trim="agentItem.account" />
                  </a-form-model-item>
                  <a-form-model-item :label="$t('global.common.name')" :prop="`agents[${index}].name`" :rules="rules.name"
                    class="sub-form-item">
                    <a-input v-model.trim="agentItem.name" />
                  </a-form-model-item>
                </div>
              </a-form-model-item>

              <div class="create-agent-sub-content">
                <a-row :gutter="[8, 0]">
                  <a-col :xs="12">
                    <a-button class="add-btn" @click="deleteSubAgent" :disabled="form.agents.length <= 1" block>
                      {{ $t('CreateAgentPage.delete_agent_with_value', { value: form.agents.length }) }}
                    </a-button>
                  </a-col>

                  <a-col :xs="12">
                    <a-button type="primary" @click="addSubAgent" block>
                      {{ $t('CreateAgentPage.add_agent_with_value', { value: form.agents.length + 1 }) }}
                    </a-button>
                  </a-col>
                </a-row>
              </div>

            </a-col>
          </a-row>
          <div class="create-agent-footer">
            <a-button class="add-btn" @click="resetForm">
              {{ $t('global.action.reset') }}
            </a-button>

            <a-button type="primary" @click="newAgentSubmit('check')" :loading="submitBtnLoading">
              {{ $t('global.action.submit') }}
            </a-button>
          </div>
        </a-form-model>
      </div>
    </div>

    <ConfirmCreateAgentDialog :showConfirmDialog="showConfirmDialog" :closeConfirmDialog="closeConfirmDialog" :data="form"
      :language_list="language_list" :website_type_list="website_type_list" :brand_list="brand_list"
      :trade_mode_list="trade_mode_list" :newAgentSubmit="newAgentSubmit" :createBtnLoading="createBtnLoading"
      :theme_list="theme_list" />

    <ResultCreateAgentDialog :showResultDialog="showResultDialog" :closeResultDialog="closeResultDialog"
      :data="createResult" :clearCreateResult="clearCreateResult" :showResultPlaceholder="showResultPlaceholder" />

    <LogCreateAgentDialog :showLogDialog="showLogDialog" :closeLogDialog="closeLogDialog" :propData="log"
      :activeResultDialog="activeResultDialog" :setCreateResult="setCreateResult" :clearLog="clearLog"
      :logLoading="logLoading" />

  </div>
</template>

<script>
import {
  getMasterByMerchant,
  getAgentByMaster,
  getLanguages,
  //  getPagePermissions, 
  generate_agent,
  refresh_agent_cache,
  show_agent_cache_list,
  getClientTheme,
  // getPrepaidBaseMode
} from '@/../api';
import { mapState } from 'vuex';
import { iconList, getHostnameInfo } from '@/../tools/config';
import ConfirmCreateAgentDialog from '@/../src/components/agents/confirm_create_agent_dialog';
import ResultCreateAgentDialog from '@/../src/components/agents/result_create_agent_dialog';
import LogCreateAgentDialog from '@/../src/components/agents/log_create_agent_dialog';
import { filterDuplicateData } from '@/../tools/common';

export default {
  components: {
    ConfirmCreateAgentDialog,
    ResultCreateAgentDialog,
    LogCreateAgentDialog,
  },
  data() {
    const checkUrl = (rule, value, callback) => {
      try {
        new URL(value);
        callback();
      } catch (err) {
        callback(new Error(this.$t('global.message.whole_url')));
      }
    };

    const checkSameAsMaster = (rule, value, callback) => {
      callback();
    };

    const checkAccount = (rule, value, callback) => {
      if (value) {
        if (value.length > 255) {
          callback(new Error(this.$t('global.message.max_length', { value: this.$t('global.common.account'), length: 255 })));
        } else if (!/^[a-zA-Z0-9_]*$/.test(value)) {
          callback(new Error(this.$t('global.message.must_be_alphanumeric', { value: this.$t('global.common.account') })));
        } else {
          callback();
        }
      } else {
        callback(new Error('account is required'));
      }
    };

    const checkName = (rule, value, callback) => {
      if (value) {
        if (value.length > 255) {
          callback(new Error(this.$t('global.message.max_length', { value: this.$t('global.common.name'), length: 255 })));
        } else {
          callback();
        }
      } else {
        callback(new Error('name is required'));
      }
    };

    return {
      iconList,
      initialLoading: true,
      showConfirmDialog: false,
      showResultDialog: false,
      showLogDialog: false,
      showResultPlaceholder: false,
      disabledCacheBtn: true,
      logLoading: false,
      master_list: [],
      agent_list: [],
      website_type_list: [
        {
          label: 'staging',
          value: '測試站'
        },
        {
          label: 'production',
          value: '正式站'
        }
      ],
      brand: '',
      env: '',
      brand_list: [
        {
          label: 'DS88',
          value: 'ds88'
        },
        {
          label: 'WS168',
          value: 'ws168'
        },
        {
          label: 'GLEAGUE',
          value: 'gleague'
        },
        {
          label: 'FIESTA',
          value: 'fiesta'
        },
        {
          label: 'FIESTA_FRUIT',
          value: 'fiestafruit'
        },
        {
          label: 'Gemini',
          value: 'gemini'
        },
        {
          label: 'Marble',
          value: 'marble'
        }
      ],
      trade_mode_list: [
        {
          label: 'transfer_wallet',
          value: 'credit' //轉帳錢包後端value
        },
        {
          label: 'single_wallet',
          value: 'transfer' //單一錢包後端value
        }
      ],
      language_list: [],
      theme_list: [],
      form: {
        master: undefined,
        parentAgent: undefined,
        sameAsMaster: [],
        env: '',
        brand: '',
        tradeMode: 'credit',
        callbackUrl: '',
        isOdin: [],
        settledZero: [],
        multiArena: [],
        addSettleBetInfo: [],
        languages: [],
        agentCreditPoints: [],
        basisCalculationType: '',
        theme: '',
        skin: '',
        custom_logo: '',
        agents: [{
          account: '',
          name: ''
        }],
      },
      rules: {
        master: [{ required: true, trigger: 'submit' }],
        sameAsMaster: [{ required: true, validator: checkSameAsMaster, trigger: 'submit' }],
        parentAgent: [{ required: true, trigger: 'submit' }],
        env: [{ required: true, trigger: 'submit' }],
        brand: [{ required: true, trigger: 'submit' }],
        tradeMode: [{ required: true, trigger: 'submit' }],
        callbackUrl: [{ required: true, validator: checkUrl, trigger: 'submit' }],
        languages: [{ required: true, trigger: 'submit' }],
        account: [{ required: true, validator: checkAccount, trigger: 'submit' }],
        name: [{ required: true, validator: checkName, trigger: 'submit' }],
        skin: [{ required: true, trigger: 'submit' }],
        theme: [{ required: true, trigger: 'submit' }],
      },
      submitBtnLoading: false,
      createBtnLoading: false,
      createResult: [],
      log: [],
      enableSelectBasisCalculationType: false,
      defaultBasicCalculationList: [
        {
          label: 'ggr',
          value: 'ggr'
        },
        {
          label: 'valid_amount',
          value: 'valid_amount'
        }
      ]
    };
  },
  async mounted() {
    this.getEnv();
    await this.getList();
    await this.getMaster();
    await this.getLanguageList();
    await this.getThemes();

    if(this.theme_list.length !== 0) {
      this.form.theme = this.theme_list[0].name;
    }

    this.initialLoading = false;
  },
  methods: {
    async getMaster() {
      try {
        const res = await getMasterByMerchant();
        const { master } = res.data;
        this.master_list = filterDuplicateData(master,'id');
      } catch (err) {
        this.$message.error(err.response.data.message);
      }
    },
    async getLanguageList() {
      try {
        const res = await getLanguages();
        const { languages } = res.data;
        this.language_list = filterDuplicateData(languages,'id');
      } catch (err) {
        this.$message.error(err.response.data.message);
      }
    },
    async getThemes() {
      getClientTheme()
        .then((res)=>{
          this.theme_list = filterDuplicateData(res.data.themes,'theme');
        })
    },
    getEnv() {
      const hostnameInfo = getHostnameInfo();
      this.env = hostnameInfo.env;
      this.form.env = this.env;
    },
    addSubAgent() {
      this.form.agents.push({ account: '', name: '' });
    },
    deleteSubAgent() {
      if (this.form.agents.length > 1) {
        this.form.agents.pop();
      }
    },
    resetForm() {
      this.form = {
        master: undefined,
        parentAgent: undefined,
        sameAsMaster: [],
        env: this.env,
        brand: this.brand,
        tradeMode: 'credit',
        callbackUrl: '',
        settledZero: [],
        multiArena: [],
        addSettleBetInfo: [],
        languages: [],
        agentCreditPoints: [],
        basisCalculationType: '',
        agents: [{
          account: '',
          name: ''
        }],
        skin: '',
        custom_logo: '',
        theme: '',
      }
    },
    activeConfirmDialog() {
      this.showConfirmDialog = true;
    },
    closeConfirmDialog() {
      this.showConfirmDialog = false;
    },
    setCreateResult(result) {
      this.createResult = result;
    },
    clearCreateResult() {
      this.createResult = [];
    },
    activeResultDialog() {
      this.showResultDialog = true;
    },
    closeResultDialog() {
      this.showResultDialog = false;
      if (this.showResultPlaceholder) {
        this.showResultPlaceholder = false;
      }
    },
    activeLogDialog() {
      this.showLogDialog = true;
    },
    closeLogDialog() {
      this.showLogDialog = false;
    },
    setLog(result) {
      this.log = result;
    },
    clearLog() {
      this.log = [];
    },
    newAgentSubmit(action) {
      if (this.submitBtnLoading || this.createBtnLoading) return;
      this.$refs.form.validate(async (valid) => {
        if (valid) {

          if (action === 'check') {
            this.submitbtnLoading = true;
          } else {
            this.createBtnLoading = true;
          }

          const { master, parentAgent, sameAsMaster, env, brand, tradeMode, callbackUrl, settledZero, multiArena, addSettleBetInfo, languages, agents, skin, theme, custom_logo, agentCreditPoints, basisCalculationType } = this.form;

          const up_account = {
            up_account: sameAsMaster.length > 0 ? master : parentAgent,
            type: sameAsMaster.length > 0 ? "Master" : "Agent"
          }

          const trade_mode = {
            trade_mode: tradeMode,
          };

          if (tradeMode === 'transfer') {
            trade_mode["swt_callback_url"] = callbackUrl;
          }

          const settle_zero = settledZero.length > 0 ? true : false;
          const multi_arena = multiArena.length > 0 ? true : false;
          const add_settle_bet_info = addSettleBetInfo.length > 0 ? true : false;
          const prepaid_base_mode_status = agentCreditPoints.length > 0 ? true : false;
          const prepaid_base_mode = agentCreditPoints.length > 0 ? basisCalculationType : null;

          const lang = languages.sort(function (a, b) { return a - b; });

          const form = {
            up_account,
            env_desc: env,
            trade_mode,
            settle_zero,
            multi_arena,
            add_settle_bet_info,
            lang,
            theme,
            theme_color: skin,
            custom_logo,
            brand,
            agents,
            action_type: action,
            prepaid_base_mode_status,
            prepaid_base_mode
          }

          try {
            const res = await generate_agent(form);
            const { msg, result, refresh_btn } = res.data;
            if (result === 'OK') {
              if (action === 'check') {
                this.activeConfirmDialog();
              } else {
                if (refresh_btn !== undefined) {
                  this.disabledCacheBtn = refresh_btn;
                }
                this.showResultPlaceholder = true;
                this.closeConfirmDialog();
                this.setCreateResult(msg);
                this.activeResultDialog();
                this.resetForm();
              }
            } else {
              this.$message.error(msg);
            }
          } catch (err) {
            this.$message.error(err.response.data.response);
          } finally {
            this.submitBtnLoading = false;
            this.createBtnLoading = false;
          }
        }
      });
    },
    async refreshCache() {
      try {
        const res = await refresh_agent_cache();
        const { refresh_btn, result } = res.data;
        this.disabledCacheBtn = refresh_btn;

        if (result === 'OK') {
          this.$message.success(this.$t('CreateAgentPage.message.success_refresh_cache'));
        } else {
          this.$message.error(this.$t('CreateAgentPage.message.fail_refresh_cache'));
        }
      } catch (err) {
        this.$message.error(err.response.data.message);
      }
    },
    async getList() {
      this.logLoading = true;
      try {
        const res = await show_agent_cache_list();
        const { msg, refresh_btn } = res.data;
        if (this.initialLoading) {
          this.disabledCacheBtn = refresh_btn;
        }
        this.setLog(msg);
      } catch (err) {
        this.$message.error(err.response.data.message);
      } finally {
        this.logLoading = false;
      }
    }
  },
  computed: {
    ...mapState({ currentProfile: state => state.auth.currentProfile }),
    masterId() {
      const { master } = this.form;
      return master;
    },
    parentAgentId() {
      const { parentAgent } = this.form;
      return parentAgent;
    },
    formMasterSetting() {
      const { sameAsMaster, master } = this.form;
      return { sameAsMaster, master };
    },
    themeOptions(){
      return this.theme_list.map(themeListItem=>themeListItem.name);
    },
    logoOptions(){
      return ["odin", "fiesta", "ufc"];
    },
    formTheme() {
      return this.form.theme;
    },
    formTradeMode() {
      return this.form.tradeMode;
    },
    skinOptions() {
      const currentThemeOption = this.theme_list.find(themeItem=>themeItem.name === this.formTheme);
      return currentThemeOption ? currentThemeOption.skin_list : [];
    },
    filterBasicCalculationList() {
      const prefix = this.currentProfile.prefix;
      switch(prefix){
        case 'ds88':
          return this.defaultBasicCalculationList.filter(basicCalculationItem=>basicCalculationItem.value === 'valid_amount');
        case 'ws168':
        case 'gemini':
        case 'marble':
          return this.defaultBasicCalculationList.filter(basicCalculationItem=>basicCalculationItem.value === 'ggr');
        default:
          return this.defaultBasicCalculationList;
      }
    }
  },
  watch: {
    masterId: {
      async handler(newValue, oldValue) {
        if (newValue) {
          if (this.form.parentAgent) {
            this.form.parentAgent = undefined;
          }
          try {
            const currentMaster = this.master_list.find(masterItem => masterItem.account === newValue);
            if (currentMaster) {
              const currentId = currentMaster.id;
              const res = await getAgentByMaster({ master: [currentId] });
              const { agent } = res.data;
              this.agent_list = filterDuplicateData(agent,'id');
            }
          } catch (err) {
            this.$message.error(err.response.data.message);
          }
        }
      },
      immediate: true,
    },
    parentAgentId: {
      async handler(newValue) {
        if (newValue) {
          this.form.basisCalculationType = this.filterBasicCalculationList[0].value;
          this.enableSelectBasisCalculationType = true;
        }else {
          this.form.basisCalculationType = '';
          this.enableSelectBasisCalculationType = false;
          this.form.agentCreditPoints = [];
        }
      },
    },
    currentProfile: {
      handler(newValue, oldValue) {
        if (newValue) {
          const { prefix } = newValue;
          this.brand = typeof prefix === 'string' ? prefix.toLowerCase() : '';
          this.form.brand = this.brand;
        }
      },
      immediate: true,
      deep: true
    },
    formMasterSetting: {
      handler(newValue, oldValue) {
        if (newValue.sameAsMaster.length !== 0) {
          this.$set(this.form, "parentAgent", newValue.master);
        } else {
          if (this.form.parentAgent) {
            this.$set(this.form, "parentAgent", undefined);
          }
        }
      },
      deep: true
    },
    showLogDialog: {
      async handler(newValue, oldValue) {
        if (newValue) {
          if (this.log.length === 0) {
            await this.getList();
          }
        }
      }
    },
    formTheme: {
      handler(newValue){
        if(newValue !== ''){
          const currentTheme = this.theme_list.find(themeItem=>themeItem.name === newValue);
          if(currentTheme){
            this.form.skin = currentTheme.skin_list[0];
          }
        }else{
          if(this.theme_list.length !== 0) {
            this.form.theme = this.theme_list[0].name;
          }
        }
      }
    },
    formTradeMode: {
      handler(newValue){
        if(newValue === 'credit'){
          this.$set(this.form, "settledZero", []);
        }
      }
    }
  }
};
</script>
