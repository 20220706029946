<template>
  <div class="new-sub-account">
    <a-button class="add-btn" icon="user-add" @click="showModal">
      {{ $t('AgentSubAccountPage.create_sub_account') }}
    </a-button>
    <a-modal v-model="visible" :title="$t('AgentSubAccountPage.create_sub_account')" :footer="null" :width="380" :destroyOnClose="true">
      <a-form-model :layout="'vertical'" ref="newSubAccount" :model="newSubAccount" :rules="rules">
        <a-form-model-item :label="$t('global.common.account')" prop="account">
          <a-input v-model="newSubAccount.account" :placeholder="$t('global.message.account_placeholder')" autocomplete="off" />
        </a-form-model-item>
        <a-form-model-item has-feedback :label="$t('global.common.password')" prop="password">
          <a-input-password v-model="newSubAccount.password" type="password" :placeholder="$t('global.message.password_placeholder')" autocomplete="off" />
        </a-form-model-item>

        <a-form-model-item :label="$t('global.common.name')" prop="name">
          <a-input v-model="newSubAccount.name" />
        </a-form-model-item>

        <a-form-model-item :label="$t('AgentSubAccountPage.authority')" prop="authority">
          <a-select v-model="newSubAccount.authority">
            <a-select-option v-for="authority in authoritys" :value="authority.id" :key="authority.id">
              <a-tag :color="authority.color">
                {{ $t(`AgentSubAccountPage.authority_list.${authority.name}`) }}
              </a-tag>
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item>
          <a-button type="primary" @click="newSubAccountSubmit(newSubAccount)" block>
            {{ $t('global.action.create') }}
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
import { 
  getAgentAllRoles, 
  createAgentManager, 
  // getAgentManagerList 
  } from '@/../api';

export default {
    props: {
    fetchData: Function
  },
  data() {
    const checkAccount = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('global.message.fill_input', {value: this.$t('global.common.account')})));
      }else if (value.length < 4) {
        callback(new Error(this.$t('global.message.must_be_at_4_characters', {value: this.$t('global.common.account')})));
      } else if (!/^[a-zA-Z0-9_\.]*$/.test(value)) {
        callback(new Error(this.$t('global.message.must_be_alphanumeric', {value: this.$t('global.common.account')})));
      } else {
        callback();
      }
    };
    const checkPassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('global.message.fill_input', {value: this.$t('global.common.password')})));
      }else if (value.length < 6) {
        callback(new Error(this.$t('global.message.must_be_at_6_characters', {value: this.$t('global.common.password')})));
      } else {
        callback();
      }
    };
    const checkName = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('global.message.fill_input', {value: this.$t('global.common.name')})));
      } else {
        callback();
      }
    };
    return {
      visible: false,
      authoritys: [
        // {
        //   id: 1,
        //   name: 'read',
        //   color: 'blue',
        // },
        // {
        //   id: 2,
        //   name: 'editor',
        //   color: 'pink',
        // },
      ],
      newSubAccount: {
        account: '',
        password: '',
        name: '',
        authority: null,
      },
      rules: {
        account: [{ validator: checkAccount, trigger: 'change' }],
        password: [{ validator: checkPassword, trigger: 'change' }],
        name: [
          { required: true,
            trigger: 'submit',
            message: this.$t('global.message.fill_input', {value: this.$t('MerchantAdminPage.new.name')}),
          },
        ],
        authority: [
          { required: true,
            message: this.$t('global.message.select_value', {value: this.$t('AgentSubAccountPage.authority')}),
            trigger: 'blur'
          },
        ],
      },
    };
  },
  watch:{
    visible: {
      handler(newValue, oldValue){
        if(newValue){
          this.init();
        }else{
          this.resetForm();
        }
      }
    }
  },
  methods: {
    init(){
      getAgentAllRoles()
        .then((res)=>{
          const { role } = res.data;
          this.authoritys = role;
          if(role.length !== 0){
            this.newSubAccount.authority = role[0].id;
          }
        })
    },
    showModal() {
      this.visible = true;
    },
    resetForm() {
      this.newSubAccount = {
        account: '',
        password: '',
        name: '',
        authority: null,
      }
    },
    newSubAccountSubmit() {
      this.$refs.newSubAccount.validate((valid) => {
        if (!valid) {
          this.$message.error(this.$t('global.message.failed_to_create', {value: this.$t('global.common.account')}));
          return false;
        } else {
          const { account, authority, name, password } = this.newSubAccount;
          createAgentManager({account, role_id: authority, name, password})
            .then((data)=>{
              if (data.data.code == 'OK') {
                this.$message.success(this.$t('global.message.success_to_create', {value: data.data.message}));
              } else {
                this.$message.error(this.$t('global.message.failed_to_create', {value: data.data.message.account[0]}));
              }
            this.fetchData();
            }).catch((err)=>{
              this.$message.error(this.$t('global.message.failed_to_create', {value: this.$t('global.common.account')}));
            }).finally(()=>{
              this.visible = false;
            })
        }
      });
    },
  },
};
</script>
