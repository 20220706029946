<template>
  <div class="fight-items-inner" 
      :class=" {'active-fight-items-inner':currentTableIndex === tableIndex} " 
      @click="setRoundAndInfo">
    <div class="fight-item" data-right-content="fight">{{ data.round_id | formatString }}</div>
    <div class="fight-item" data-right-content="fight_no">{{ data.pool_id }} - {{ data.fight_no }}</div>
    <div class="fight-item" data-right-content="link_master">
      <p>
        <span>{{ data.master_name | formatString }}({{ data.master_account | formatString }})</span>
        <!-- <span v-if="data.main_arena"><a-icon type="crown" theme="filled" class="main-arena-icon" /></span> -->
      </p>
    </div>
    <div class="fight-item" data-right-content="winner" :data-result="GAME_TYPE_SIDE[gameType][data.winner]">{{ data.winner ? $t(`global.fight_result.${GAME_TYPE_SIDE[gameType][data.winner]}`) : '' | formatString }}</div>
    <div class="fight-item" data-right-content="status">
      <a-tag class="status-item" :data-status="poolStatus">{{ $t(`global.common.round_status_list.${poolStatus}`) }}</a-tag>
    </div>
    <div class="fight-item" data-right-content="status">
      <a-tag class="status-item" :data-status="roundStatus">{{ $t(`global.common.round_status_list.${roundStatus}`) }}</a-tag>
    </div>
  </div>
</template>
<script>
import { GAME_TYPE_SIDE } from '@/../tools/config';

export default {
  props: {
    data: Object,
    gameType: String,
    currentTableIndex: [Number, String],
    tableIndex: [Number, String],
    setCurrentRoundAndInfo: Function
  },
  data() {
    return {
      GAME_TYPE_SIDE
    };
  },
  mounted(){
  },
  computed: {
    poolStatus() {
      switch(this.data.pool_status) {
        case 'initial':
          return 'idle';
        case 'betting':
          return 'open';
        case 'last_call':
          return 'last_call';
        case 'fighting':
          return 'close';
        case 'finished':
          return 'unsettled';
        case 'earn_locked':
          return 'settled';
        default:
          return '';
      }
    },
    roundStatus() {
      return this.data.round_locked ? 'settled' : 'unsettled';
    }
  },
  methods: {
    setRoundAndInfo() {
      this.setCurrentRoundAndInfo(this.tableIndex, this.data);
    }
  }
};
</script>
