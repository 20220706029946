<template>
  <div class="frame arena-moderator-page">
    <a-page-header
      :title="$t('global.menu.SingleModerator')"
      @back="() => $router.go(-1)"
    >
    </a-page-header>
    <div class="page-content">
      <a-row :gutter="[16, 16]">
        <a-col
          class="gutter-row"
          :md="12"
          :lg="8"
          v-for="arena in arenas"
          :key="arena.id"
        >
          <div class="gutter-box">
            <a-card
              class="arena-moderator-card"
              :class="arena.game_type"
            >
            <template slot="title">
              <div class="card-head">
                <p>{{ $t('global.common.main_pool') }}：{{ arena.name }}</p>
                <!-- <div class="users" v-if="!isNaN(arena.online_users)">
                  <img :src="require('images/people.svg')" alt="user">
                  <span>{{ numeral(arena.online_users).format('0,0') }}</span>
                </div> -->
              </div>
            </template>
            <div class="arena-list">
              <div class="arena-list-title">
                <p>{{$t('global.common.arena')}}
                  <span>:</span>
                </p>
              </div>
              <div class="arena-list-provide">
                <div
                  v-for="provider in arena.providers"
                  :key="provider.id"
                >
                  <p>{{ provider.arena_no}}({{ provider.id }})
                    <a-icon class="provide-icon" type="desktop" v-if="provider.id === arena.main_provider" />
                  </p>
                </div>
              </div>
            </div>
            <div class="arena-moderator-status">
              <div
                class="arena-status"
                :class="[ arena.active ? 'status-on' : 'status-off' ]"
              >
                {{ arena.active ? 'on' : 'off' }}
              </div>
            </div>
              <!-- <div class="arena-moderator-detail">
                <table class="arena-card-table">
                  <tr>
                    <th>{{ $t("ArenaModeratorPage.video_rate") }}</th>
                    <td>
                      <span class="rate">{{ (arena.rate * 100).toFixed(2) }}</span>
                      <span>%</span>
                    </td>
                  </tr>
                  <tr>
                    <th>{{ $t("global.common.video_url") }}</th>
                    <td>
                      <div class="arena-url">
                        <span>{{ arena.video_url }}</span>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="arena.arena_no">
                    <th>{{ $t("global.common.arena") }}</th>
                    <td>
                      <div class="arena-no">
                        <span>{{ arena.arena_no }}({{ arena.arena_id }})</span>
                      </div>
                    </td>
                  </tr>
                </table>
              </div> -->
              <template slot="actions" class="ant-card-actions">
                <template v-if="controlBtn.ControlPanel">
                  <router-link
                    :to="{ name: 'ControlPanel' , query: { main_pool_id: arena.id, merchant_provider_id: arena.main_mp_id }}"
                    class="important-btn arena-card-action"
                    >{{ $t("global.menu.ControlPanel") }}
                  </router-link>
                </template>
              </template>
            </a-card>
          </div>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import numeral from 'numeral';
import { mapState } from 'vuex';
import {
  getArenaModerator,
  getMerchantRole,
} from "@/../api";

const arenas = [
  //  {
  //    "id": 1,
  //   "name": "cockfight1",
  //   "pool_name": "cockfight1",
  //   "main_mp_id": 8,
  //   "active": true,
  //   "visible": true,
  //   "created_at": "2024-06-05T15:40:29.744+08:00",
  //   "updated_at": "2024-06-05T15:40:29.744+08:00",
  //   "merchant_id": 101,
  //   "mask_video": false,
  //   "is_close_bet": false,
  //   "telegram_switch": false,
  //   "telegram_switch_info": null,
  //    "game_type": "cockfight",
  //   "providers": [
  //       {
  //           "id": 21,
  //           "arena_no": "SABONG21",
  //           "video_url": "https://media.streama0.live/liveapp/wm/abcd/360.html?123",
  //           "game_type": "cockfight"
  //       },
  //       {
  //           "id": 23,
  //           "arena_no": "SABONG21",
  //           "video_url": "https://www.fiestavid0.xyz/static/live/fRu1t2/540.html?9c13b50a8eb300",
  //           "game_type": "cockfight"
  //       }
  //   ]
  //  },
  //  {

  //   "id": 2,
  //   "name": "cockfight2",
  //   "pool_name": "cockfight2",
  //   "main_mp_id": 8,
  //   "active": true,
  //   "visible": true,
  //   "created_at": "2024-06-05T15:40:29.744+08:00",
  //   "updated_at": "2024-06-05T15:40:29.744+08:00",
  //   "merchant_id": 101,
  //   "mask_video": false,
  //   "is_close_bet": false,
  //   "telegram_switch": false,
  //   "telegram_switch_info": null,
  //   "game_type": "cockfight",
  //   "providers": [
  //       {
  //           "id": 24,
  //           "arena_no": "SABONG24",
  //           "video_url": "https://media.streama0.live/liveapp/wm/abcd/360.html?123",
  //           "game_type": "cockfight"
  //       },
  //       {
  //           "id": 27,
  //           "arena_no": "SABONG27",
  //           "video_url": "https://www.fiestavid0.xyz/static/live/fRu1t2/540.html?9c13b50a8eb300",
  //           "game_type": "cockfight"
  //       }
  //   ]
  //  },
];


export default {
  data() {
    return {
      arenas,
      editor: [],
      controlBtn: {
        ControlPanel: false,
        CurrentArena: false,
      },
      networkErrorStatus: false,
    };
  },
  async mounted() {
    await getMerchantRole().then((data) => {
      this.controlBtn.ControlPanel = data.data.role.ControlPanel?.read;
    });
    await this.init();
  },
  computed: {
    ...mapState({currentProfile: state => state.auth.currentProfile}),
  },
  methods: {
    numeral,
    async init() {
      await getArenaModerator()
        .then((res) => {
          const{ main_pools } = res.data;
          this.arenas = main_pools;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
