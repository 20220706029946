<template>
    <div class="info-content">
      <div class="content-row">
        <p class="row-index">{{$t('global.common.name')}}</p>
        <p class="row-value">{{ data.name | formatString }}</p>
      </div>
      <div class="content-row">
        <p class="row-index">{{$t('global.common.account')}}</p>
        <p class="row-value">{{ data.account | formatString }}</p>
      </div>
      <div class="content-row">
        <p class="row-index">{{$t('global.common.email')}}</p>
        <p class="row-value">{{ data.email | formatString }}</p>
      </div>
      <!-- 目前需求是要隱藏該欄位 -->
      <!-- <div class="content-row" v-if="data.is_merchant_account">
        <p class="row-index">{{$t('global.common.rate')}}</p>
        <p class="row-value">{{ numeral(data.rate).multiply(100).value() }}</p>
      </div> -->
      <div class="content-row">
        <p class="row-index">{{$t('global.common.currency')}}</p>
        <p class="row-value">{{ data.currency | formatString }}</p>
      </div>
      <div class="content-row">
        <p class="row-index">{{$t('global.common.theme')}}</p>
        <p class="row-value">{{ data.theme | formatString }}</p>
      </div>
      <div class="content-row">
        <p class="row-index">{{$t('global.common.skin')}}</p>
        <p class="row-value">{{ data.theme_color | formatString }}</p>
      </div>
      <div class="content-row language">
        <p class="row-index">
          <span>{{$t('global.common.usable_language')}}</span>
          <a-button v-if="data.editable && updatePermission" type="primary" icon="edit" @click="showEdit('languageList','Edit')">
            {{ $t('global.action.edit') }}
          </a-button>
        </p>
        <div class="row-value language-list-wrap">
          <div class="language-value language-list">
            <div v-for="languageItem in usableLanguageList" :key="languageItem.id">
              <img v-if="iconList.includes(languageItem.abbreviation)"  :src="require(`images/flag/${languageItem.abbreviation}.svg`)" :alt="`${languageItem.abbreviation} icon`">
            </div>
            <!-- <span>{{ data.language }}</span> -->
            <div class="language-list-modal">
              <p v-for="(listItem_outer, outerIndex) in newLanguageList" :key="`outer ${outerIndex}`">
                <span v-for="(listItem_inner, innerIndex) in listItem_outer" :key="`inner ${innerIndex}`">
                  <img v-if="iconList.includes(listItem_inner.abbreviation)"  :src="require(`images/flag/${listItem_inner.abbreviation}.svg`)" :alt="`${listItem_inner.abbreviation} icon`">
                  {{listItem_inner.name}}
                  {{ listItem_outer.length !== 1 ? ", " : "" }}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="content-row language">
        <p class="row-index">
          <span>{{$t('global.common.defaultLanguage')}}</span>
          <a-button v-if="data.editable && updatePermission" type="primary" icon="edit" @click="showEdit('language','Edit')">
            {{ $t('global.action.edit') }}
          </a-button>
        </p>
        <div class="row-value">
          <p class="language-value">
            <span v-if="currentLanguage.abbreviation"><img :src="require(`images/flag/${currentLanguage.abbreviation}.svg`)" :alt="`${data.language} icon`"></span>
            <span>{{ data.language | formatString }}</span>
          </p>
        </div>
      </div>

      <div class="content-row">
        <p class="row-index">{{$t('global.common.business_status')}}</p>
        <p class="row-value status">
          <span class="status-light" :class="data.status === 'active' ? 'success' : 'fail' "></span>
          <span>{{ $t(`global.common.active_status_list.${data.status}`) }}</span>
        </p>
      </div>

      <div class="content-row">
        <p class="row-index">{{$t('global.common.multi_arena')}}</p>
        <p class="row-value multi_arena">
          <span class="status-light" :class="data.multi_arena === true ? 'success' : 'fail' "></span>
          <span>{{ $t(`global.common.multi_arena_status.${data.multi_arena === true ? 'open' : 'close'}`) }}</span>
        </p>
      </div>

      <div class="content-row">
        <p class="row-index">{{$t('global.common.add_settle_bet_info')}}</p>
        <p class="row-value add_settle_bet_info">
          <span class="status-light" :class="data.add_settle_bet_info === true ? 'success' : 'fail' "></span>
          <span>{{ $t(`global.common.multi_arena_status.${data.add_settle_bet_info === true ? 'open' : 'close'}`) }}</span>
        </p>
      </div>

      <div class="content-row" v-if="!data.is_master_agent">
        <p class="row-index">{{$t('global.menu.AgentCreditPoints')}}</p>
        <p class="row-value agent_credit_points">
          <template v-if="data.prepaid_fund_active">
            <span class="status-light success" />
            <span>{{ $t(`global.common.active_status_list.active`) }} - {{ $t(`global.common.${data.prepaid_base_mode}`) }}</span>
          </template>
          <template v-else>
            <span class="status-light fail" />
            <span>{{ $t(`global.common.active_status_list.close`) }}</span>
          </template>
        </p>
      </div>

      <div class="content-row">
        <p class="row-index">{{$t('global.common.created_at')}}</p>
        <p class="row-value">{{ data.created_at | formatTimes }}</p>
      </div>
      <template>
        <AgentEditLanguage :formShow='languageFormShow'
                           :dialogAction='dialogAction'
                           :closeModal='closeLanguageModal'
                           :currentLanguage='currentLanguage'
                           :getAgentProfile='getAgentProfile'
                           :usableLanguageList ='usableLanguageList'
                           :updateLanguageList ='init'
                           />
      </template>
      <template>
        <AgentEditLanguageList :formShow='usableLanguagesFormShow'
                               :dialogAction='dialogAction'
                               :closeModal='closeLanguageModal'
                               :getAgentProfile='getAgentProfile'
                               :languageList='languageList'
                               :updateLanguageList ='init'
                               :usableLanguageList ='usableLanguageList'
                           />
      </template>
    </div>
</template>
<script>
import numeral from 'numeral';
import AgentEditLanguage from '@/../src/components/agents/AgentEditLanguage';
import AgentEditLanguageList from '@/../src/components/agents/AgentEditLanguageList';
import { getLanguages, getCurrentUserLanguagesList, getUserLanguagesList } from '@/../api';
import { iconList } from '@/../tools/config';
import { filterDuplicateData } from '@/../tools/common';

export default {
  props: {
    data: Object,
    getAgentProfile: Function,
    updatePermission: Boolean
  },
  components: {
    AgentEditLanguage,
    AgentEditLanguageList
  },
  data() {
    return {
      currentLanguage: {
        id: null,
        abbreviation: '',
        name: ''
      },
      languageList: [],
      languageFormShow: false,
      usableLanguagesFormShow: false,
      dialogAction: '',
      iconList,
      usableLanguageList: [],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    numeral,
    showEdit(type,dialogAction) {
      type === 'language'
        ? this.languageFormShow = true
        : this.usableLanguagesFormShow = true;
      this.dialogAction = dialogAction;
    },
    closeLanguageModal(type) {
      type === 'language'
        ? this.languageFormShow = false
        : this.usableLanguagesFormShow = false;
    },
    init(){
      getLanguages()
        .then((data)=>{
          this.languageList = filterDuplicateData(data.data.languages,'id');
        })

      if(this.$route.params.id !== undefined){
        getUserLanguagesList({id: this.$route.params.id})
          .then((data)=>{
            this.usableLanguageList = filterDuplicateData(data.data.languages,'id');
          })
      } else {
        getCurrentUserLanguagesList()
          .then((data)=>{
            this.usableLanguageList = filterDuplicateData(data.data.languages,'id');
          })
      }
    },
  },
  computed: {
    combinedData(){
      return{ data: this.data, languageList: this.languageList }
    },
    newLanguageList(){
      const tempArray = [];
      const maxIndex = Math.floor(this.usableLanguageList.length / 2);
      for(let i=0; i<= maxIndex; i++){
        tempArray[i] = [];
      }
      this.usableLanguageList.forEach((listItem, index) =>{
        const currentArrIndex = Math.floor(index / 2);
        tempArray[currentArrIndex].push(listItem)
      })
      return tempArray;
    }
  },
  watch: {
    combinedData: {
      handler(newValue, oldValue){
        if(newValue){
          const { data, languageList } = newValue;
          if(Object.keys(data).length === 0 || languageList.length === 0) return;
          const { language } = data;
          if(language) {
            const currentLanguage = languageList.find((languageItem) => languageItem.abbreviation === language);
            this.currentLanguage = currentLanguage;
          } else {
            this.currentLanguage = {
              id: null,
              abbreviation: '',
              name: ''
            }
          }
        }
      },
      deep: true
    }
  },
};
</script>
