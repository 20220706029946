<template>
  <AgentSubAccount :authoritys='authoritys'
                   :data='data'
                   :pagination='pagination'
                   :loading='loading'
                   :fetchData='fetchData'
                   :id='$route.params.id'/>
</template>

<script>
import AgentSubAccount from '@/../src/components/agents/agent_sub_account';

export default {
  components: {
    AgentSubAccount,
  },
  data() {
    return {
      authoritys: [
        {
          id: 1,
          name: 'read',
          color: 'blue',
        },
        {
          id: 2,
          name: 'editor',
          color: 'pink',
        },
      ],
      data: [
        {
          id: 1,
          account: 'Account',
          name: 'Name',
          authority: 1,
          last_login: '2021-08-11 10:23:01',
        },
        {
          id: 2,
          account: 'jjjjj1111',
          name: 'Name',
          authority: 2,
          last_login: '2021-08-11 10:23:01',
        },
      ],
      pagination: {},
      loading: false,
    };
  },
  created(){
    this.fetchData();
  },
  mounted(){
    // console.log(this.$route.params.id)
  },
  methods: {
    fetchData(params = {}) {
      // this.loading = true;
    },
  },
};
</script>
