<template>
  <a-dropdown :trigger="['click']">
    <a-button icon="global" @click="e => e.preventDefault()"> <span class="lang-btn-text">{{ $t('lang') }}</span></a-button>
    <a-menu slot="overlay" class="lang-switch">
      <a-menu-item v-for="(locale, index) in Object.keys($i18n.messages)"
          :key="index"
          :class="{ active: $i18n.locale == locale }"
          @click="setLang(locale)">
        <span class="lang-inner-item">
          {{$i18n.messages[locale].lang}}
        </span>
      </a-menu-item>
    </a-menu>
  </a-dropdown>
</template>

<script>

export default {
  props: {
    btnType: String,
  },
  data() {
    return {
    };
  },
  methods: {
    setLang(value) {
      this.$store.commit('setLang', value);
      this.$i18n.locale = value;
      localStorage.setItem('footmark-lang', value);
    },
  },
};
</script>
