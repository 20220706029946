<template>
  <div class="edit-announcement">
    <a-button type="primary" icon="edit" @click="showModal">
      <template v-if="showTitle">{{ $t('global.action.edit') }}</template>
    </a-button>
    <a-modal v-model="visible" :title="$t('ControlPanelPage.edit_announce')" :footer="null" :width="380">
      <a-form-model-item prop="content">
        <a-textarea v-model="announce_input" :auto-size="{ minRows: 3, maxRows: 6 }" />
      </a-form-model-item>
      <a-form-model-item>
        <a-button type="primary" @click="editAnnounce(announce_input)" block :disabled="announce_input === announce">
          {{ $t('global.action.edit') }}
        </a-button>
      </a-form-model-item>
    </a-modal>
  </div>
</template>
<script>

export default {
  props: {
    announce: String,
    showTitle: {
      type: Boolean,
      default: true
    } 
  },
  data() {
    return {
      visible: false,
      announce_input: '',
    };
  },
  methods: {
    showModal() {
      this.visible = true;
    },
    editAnnounce(announce_input) {
      this.visible = false;
      this.$emit("edited", announce_input);
    },
  },
  watch: {
    announce: {
      handler(newValue, oldValue) {
        this.announce_input = newValue;
      },
    },
  }
};
</script>
