<template>
  <div class="transfer-log">
    <a-modal :visible="modalVisible"
             :footer="null"
             :centered="true"
             :width="'unset'"
             @cancel="closeModal"
             class="transfer-log-modal">
      <template slot="title">
        {{ $t('global.common.log') }}
        <span class="primary-font-color"> {{ currentSlug }}</span>
      </template>

      <a-table
          bordered
          :columns="columns.filter( item => item.filterType || item.filterType === undefined)"
          :data-source="results"
          :loading="loading"
          :pagination="false"
      >
        <template v-for="(column,index) in columns">
          <span :slot="column.slots.title" :key="index">{{ column.singleI18n ? $t(`TransferPage.${column.slots.title}`) : $t(`global.common.${column.slots.title}`) }}</span>
        </template>

        <template slot="userData" slot-scope="data">
          <p v-if="isString(data)">{{ data }}</p>
          <div v-else v-for="(dataItems, index) in data" :key="`userData-outer-${index}`">
            <p v-if="isString(dataItems)">{{ dataItems }}</p>
            <div v-else v-for="(dataItem, index) in dataItems" :key="`userData-inner-${index}`">
              <p>{{ index }}:{{ dataItem }}</p>
            </div>
          </div>
        </template>

        <template slot="formatTimes" slot-scope="times">
          <div class="table-time">{{ times | formatTimesToUTC8 }}</div>
        </template>
      </a-table>
    </a-modal>
  </div>
</template>
<script>
import { getTransferLog } from '@/../api';

const columns = [
    {
    slots: { title: 'data' },
    dataIndex: 'data',
    scopedSlots: { customRender: 'userData' },
    singleI18n: true
  },
  {
    slots: { title: 'created_at' },
    dataIndex: 'created_at',
    scopedSlots: { customRender: 'formatTimes' },
  },
]

export default {
  props: {
    modalVisible: Boolean,
    currentSlug: String,
    closeModal: Function
  },
  data() {
    return {
      columns,
      loading: false,
      results: [
        // {
        //   data: [
        //     {
        //       code:"05",
        //       data:[{num:"B1661236699087644941",player:"TFCTpekora",balance:"85.68"}],
        //       error:[{"num":"B1661236682849667736",player:"TFCTpekora",balance:"5.0"},{num:"B1661236689248299411",player:"TFCTpekora",balance:"10.0"},{num:"B1661236705227382523",player:"TFCTpekora",balance:"20.0"}]
        //     }
        //   ],
        //   created_at: "2022-10-05T16:53:05Z"
        // },
        // {
        //   data: "internal_server_error",
        //   created_at: "2022-10-05T16:53:05Z"
        // },
        // {
        //   data: ["internal_server_error"],
        //   created_at: "2022-10-05T16:53:05Z"
        // },
        // {
        //   data: [{"code":99}],
        //   created_at: "2022-10-05T16:53:05Z"
        // }
      ]
    };
  },
  methods: {
    fetchData(){
      this.loading = true;
      getTransferLog({
        slug: this.currentSlug
      }).then(({ data }) => {
        this.results = data.logs;
      }).finally(()=>{
        this.loading = false;
      });
    },
    isString(str) {
      return typeof(str) === 'string' ? true : false;
    }
  },
  watch: {
    currentSlug: {
      handler(newValue, oldValue) {
        if(newValue){
          this.fetchData();
        }
      },
    },
  }
};
</script>
