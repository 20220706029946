<template>
  <a-modal :visible="formShow" :title="`${dialogAction} ${$t('global.common.defaultLanguage')}`" :footer="null" :width="450" @cancel="closeModal('language')">
    <a-form-model :layout="'vertical'" ref="language" :model="languageForm" :rules="rules">
      <a-form-model-item :label="$t('AgentsPage.languageList')" prop="languageList">
        <a-select v-model="languageForm.language" class="language-select">
          <a-select-option v-for="languageItem in usableLanguageList" :value="languageItem.id" :key="languageItem.id" class="language-select-option">
            <span v-if="iconList.includes(languageItem.abbreviation)"><img :src="require(`images/flag/${languageItem.abbreviation}.svg`)" :alt="`${languageItem.abbreviation} icon`"></span>
            <span>{{ languageItem.name }}</span>
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item>
        <a-button type="primary" @click="languageSubmit()" block>
          {{ dialogAction }}
        </a-button>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import { updateDefaultLanguage } from '@/../api';
import { iconList } from '@/../tools/config';

export default {
  props:{
    formShow: Boolean,
    dialogAction: String,
    closeModal: Function,
    currentLanguage: Object,
    getAgentProfile: Function,
    updateLanguageList: Function,
    usableLanguageList: Array,
  },
  data() {
    return {
      iconList,
      languageForm: {
        language: null,
      },
      rules: {
        language: [
          {
            message: this.$t('global.message.select_value', {value: this.$t('global.common.language')}),
            trigger: 'blur'
          },
        ],
      },
    };
  },
  watch:{
    currentLanguage:{
      handler(newValue, oldValue){
        if(newValue){
          this.languageForm.language = newValue.id;
        }
      },
      immediate: true
    }
  },
  methods: {
    languageSubmit() {
      this.$refs.language.validate((valid) => {
        if (!valid) {
          this.$message.error(this.$t('global.message.failed_to_edit', {value: this.$t('global.common.language')}));
          return false;
          } else {
            if(this.languageForm.language === undefined) {
              this.$message.error(this.$t('global.message.failed_to_edit', {value: this.$t('global.common.language')}));
              return false;
            }

            updateDefaultLanguage({language: this.languageForm.language, id: this.$route.params.id})
              .then((data)=>{
                this.$message.success(this.$t('global.message.success_to_change', {value: this.$t('global.common.language')}));
                this.closeModal('language');
                this.getAgentProfile();
                this.updateLanguageList();
              })
              .catch((err) => {
                this.$message.error(err.response.data.message);
              });

        }
      });
    },
  },
};
</script>

