<template>
  <div class="login-page">
    <div class="login-page-container">
      <div class="login-logo"><img v-if="logo" :src="require(`images/${logo}.png`)"/></div>
      <template v-if="isIosNotSupportedWebView()">
        <p class="webview-text">{{ $t('global.message.browser_not_supported') }}</p>
        <div class="webview-btn">
          <!-- <a :href="redirectUrl">{{ $t('global.action.ok') }}</a> -->
          <a :href="redirectUrl" target="_blank">{{ $t('global.action.ok') }}</a>
        </div>

      </template>
      <template v-else>
        <h1 class="login-title">{{ $t('LoginPage.log_in') }}</h1>
        <div class="login-content">
          <Lang :btnType="'web'" class="login-lang" />
          <a-form-model layout="horizontal" class="login-form" :model="formInline" @submit="login" @submit.native.prevent>
            <a-form-model-item>
              <a-input v-model="formInline.login" :placeholder="$t('global.common.account')" size="large">
                <a-icon slot="prefix" type="user"/>
              </a-input>
            </a-form-model-item>
            <a-form-model-item>
              <a-input v-model="formInline.password" type="password" :placeholder="$t('global.common.password')" size="large">
                <a-icon slot="prefix" type="lock"/>
              </a-input>
            </a-form-model-item>
            <a-form-model-item v-if="!otpCheck">
              <a-input v-model="formInline.otp" type="otp" :placeholder="$t('LoginPage.otp')" size="large">
                <a-icon slot="prefix" type="lock"/>
              </a-input>
            </a-form-model-item>
            <a-form-model-item>
              <a-input type="hidden" v-model="formInline.subdomain"></a-input>
            </a-form-model-item>
            <a-form-model-item>
              <a-button
                type="primary"
                html-type="submit"
                :disabled="formInline.login === '' || formInline.password === '' || formInline.subdomain === ''"
                size="large"
                block
              >
                {{ $t('global.action.submit') }}
              </a-button>
            </a-form-model-item>
          </a-form-model>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
// import storage, { AUTH_TOKEN } from '@/../storage';
import { mapActions } from 'vuex';
import Lang from '@/../src/components/lang';
import { isIosNotSupportedWebView, setLogoFileName } from '@/../tools/common';
import { getOtpIsOpen } from '@/../api';

export default {
  components: {
    Lang,
  },
  data() {
    return {
      redirectUrl: window.location.href,
      formInline: {
        login: '',
        password: '',
        subdomain: '',
        otp: '',
      },
      otpCheck: false,
      logo: ''
    };
  },
  methods: {
    ...mapActions({ goto: 'auth/goto', userlogin: 'auth/userlogin' }),
    isIosNotSupportedWebView,
    setLogoFileName,
    login() {
      const { login, password, subdomain, otp } = this.formInline;
      this.userlogin({ login, password, subdomain, otp_token: otp})
        .then(() => {
              this.afterLogin();
              window.location.reload();
            })
            .catch((err) => {
              this.$message.error(err.response.data.message, 3);
            });
    },
    afterLogin() {
      this.$message.success(this.$t('LoginPage.message.success'), 4);
    },
    userSubDomain() {
      this.formInline.subdomain = window.location.hostname
    },
    getOtpCheck() {
      const host = window.location.host;
      const subdomain = host.split('.')[0];

      getOtpIsOpen({subdomain})
        .then((data)=>{
          this.otpCheck = data.data.in_white_list;
        })
    },
    init(){
      this.userSubDomain();
      this.getOtpCheck();
    }
  },
  mounted() {
    this.logo = setLogoFileName();
    this.init();
  }
};
</script>
