<template>
  <div id="app" :data-lang="$i18n.locale">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  watch: {
    '$i18n.locale': function (newLang, oldLang) {
      document.body.classList.add(`lang_${newLang}`);
      document.body.classList.remove(`lang_${oldLang}`);
    },
  },
  mounted() {
    document.body.classList.add(`lang_${this.$i18n.locale}`);
  },
};
</script>
