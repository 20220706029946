<template>
    <div class="video-limit">
        <div class="video-limit-header">
            <div class="video-limit-title">
                <p>{{ $t('ProfilePage.video_traffic') }}</p>
                <a-button v-if="editable && updatePermission" type="primary" icon="edit" @click="showModal">
                    {{ $t('global.action.edit') }}
                </a-button>
            </div>
        </div>
        <div class="video-limit-content">
          <p>{{ $t('ProfilePage.remain_video_data') }}</p>
          <p class="video-number">{{ quota | formatNumber }}</p>
        </div>
        <a-modal v-model="visible" :title="$t('ProfilePage.update_video_data')" :footer="null"  class="video-limit-dialog" width='380px'>
            <a-form-model-item prop="content">
                <a-input-number v-model="newQuota" />
            </a-form-model-item>
            <a-form-model-item>
                <a-button type="primary" @click="submitForm" block>
                {{ $t('global.action.submit') }}
                </a-button>
            </a-form-model-item>
        </a-modal>
    </div>
</template>
<script>

export default {
  props: {
    editable: Boolean,
    updateQuota: Function,
    quota: Number,
    updatePermission: Boolean
  },
  data() {
    return {
      visible: false,
      newQuota: null,
    };
  },
  methods: {
    showModal() {
      this.visible = true;
    },
    closeModal(){
      this.visible = false;
    },
    submitForm() {
      this.updateQuota({quota: this.newQuota});
      this.closeModal();
    },
  },
};
</script>
