import CryptoJS from 'crypto-js';

export function numberWithCommas(x) {
  if(x) {
    return x.toString().replace(/\.0$/, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return x;
};

export const isIosNotSupportedWebView = () => {
  const ua = navigator.userAgent;
  const isiOS = !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios終端
  const isChrome = ua.match(/Chrome\/([\d.]+)/) || ua.match(/CriOS\/([\d.]+)/);
  const isWebview = !isChrome && ua.match(/(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/);
  const isFbApp = ua.indexOf("FBAV") > -1;
  const isWeixin = ua.indexOf("MicroMessenger") > -1;
  if(isiOS && isWebview && !isFbApp && !isWeixin){
    return true;
  }else{
    return false;
  }
}

export const setLogoFileName = () => {
  if(location.port){
    return 'default';
  }else{
    const hostname = location.hostname;
    const hostnameSplit = hostname.split("-");
    if(hostnameSplit.length === 1){
      return 'default';
    }else{
      const defaultException = ['fatpit'];
      const demoException = ['phw','phwc','bpc'];

      const prefix = hostnameSplit[0].toLowerCase();
      if(defaultException.includes(prefix)){
        return 'default';
      }else if(demoException.includes(prefix)){
        return 'demo';
      }else{
        return prefix;
      }
    }
  }
}

const cipherData = {
  key: 'cbbceccf85a471eb308dea5f95d23ced',
  iv: '0e571c95808ab859'
}

export const encryptData = (data) => {
  const { key, iv } = cipherData;
  const cipherKey = CryptoJS.enc.Utf8.parse(key);
  const cipherIv = CryptoJS.enc.Utf8.parse(iv);
  const stringifyData = JSON.stringify(data);
  const encrypt = CryptoJS.AES.encrypt(stringifyData, cipherKey, { iv: cipherIv });
  const encryptStr = encrypt.toString();
  const reverseStr = encryptStr.split('').reverse().join('');
  return reverseStr;
};

export const decryptData = (encryptStr) => {
  const { key, iv } = cipherData;
  const cipherKey = CryptoJS.enc.Utf8.parse(key);
  const cipherIv = CryptoJS.enc.Utf8.parse(iv);
  const reverseStr = encryptStr.split('').reverse().join('');
  const decrypt = CryptoJS.AES.decrypt(reverseStr, cipherKey, { iv: cipherIv });
  const decryptStr = decrypt.toString(CryptoJS.enc.Utf8);
  const parse = JSON.parse(decryptStr);
  return parse;
};

export const filterDuplicateData = (arrayList, filterType) => {
  if (filterType === 'id') {
    return [...new Map(arrayList.map(item => [item.id, item])).values()];
  } else if (filterType === 'currency') {
    return [...new Set(arrayList)];
  } else if (filterType === 'theme') {
    return arrayList.map(item => ({
      ...item, skin_list: [...new Set(item.skin_list)]
    }));
  }
  return arrayList;
}

export default { numberWithCommas };
