<template>
  <div class="frame exchange-rate-page">
    <a-page-header
        :title="$t('global.menu.ExchangeRate')"
        @back="() => $router.go(-1)"
    >
    </a-page-header>

    <div class="page-content">
      <div class="search-bar">
        <a-row :gutter="[0,8]">
          <a-col :xs="24" :md="15" :xl="10">
            <a-col :xs="24">
              <div class="bold">
                <span>{{ $t('ExchangeRatePage.current_time') }}(UTC+8):</span>
                <CurrentTime />
              </div>
            </a-col>
            <a-col :xs="24">
              <div class="last-time">
                <span>{{ $t('ExchangeRatePage.last_approved_time') }}(UTC+8):</span>
                <span>{{ last_approve_time | formatTimesToUTC8 }}</span>
              </div>
            </a-col>
          </a-col>

          <a-col :xs="24" :md="9" :xl="4">
            <a-col :xs="24">
              <div class="bold">
                <span>{{ $t('ExchangeRatePage.base_currency') }}:</span>
                <span>{{ base_currency }}</span>
              </div>
            </a-col>
          </a-col>

          <a-col :xs="24" :xl="10">
            <a-col :xs="15">
              <a-button class="ant-btn-primary"  @click="fetch_exchange_rate" v-if="this.role.confirm == true">
                {{ $t('ExchangeRatePage.fetch_exchange_rate') }}
              </a-button>
            </a-col>
            <a-col :xs="9">
              <a-button class="ant-btn-primary" @click="showApprove" v-if="this.role.approve && showApproveRateBtn">
                {{ $t('ExchangeRatePage.approve_rate') }}
              </a-button>
            </a-col>
          </a-col>

        </a-row>
      </div>

      <div class="ant-card">
        <div class="ant-card-body">
          <a-table
              bordered
              size="small"
              :columns="columns"
              :row-key="data => data.id"
              :data-source="data"
              :loading="loading"
              :pagination="false"
          >
            <template v-for="column in columns">
              <template :slot="column.slots.title">{{ column.singleI18n ? $t(`ExchangeRatePage.${column.slots.title}`) :$t(`global.common.${column.slots.title}`) }}</template>
            </template>

            <template slot="last_approved_rate" slot-scope="last_approved_rate">
                <span>{{ numeral(last_approved_rate).format('0,0[.][000]') }}</span>
            </template>

            <template slot="ref_rate" slot-scope="ref_rate, row">
              <div class="ref-rate" v-show="pressed_get_rate_btn">
                <p :class="{ 'rate-warning': rateWarning(row) || parseFloat(ref_rate) === 0 }">{{ numeral(ref_rate).format('0,0[.][000]') }}</p>
                <p class="rate-warning" v-if="rateWarning(row) && parseFloat(ref_rate) !== 0">>10%</p>
              </div>
            </template>

            <template slot="tbd_confirm_rate" slot-scope="tbd_confirm_rate, row">
              <div class="confirm-rate-column">
                <div class="confirm-rate-num">
                  <span v-show="tbd_confirm_rate > 0">{{ numeral(tbd_confirm_rate).format('0,0[.][000]') }}</span>
                </div>
                <a-button v-if="role.confirm == true && pressed_get_rate_btn" type="primary" size="small" @click="showConfirmExchangeRate(row)">{{ tbd_confirm_rate > 0 ? $t(`ExchangeRatePage.tbd_modify_rate`) : $t(`ExchangeRatePage.tbd_confirm_rate`) }}</a-button>
              </div>
            </template>

            <template slot="formatTimes" slot-scope="times">
              <div class="table-time" v-show="pressed_get_rate_btn">{{ times | formatTimesToUTC8 }}</div>
            </template>

            <template slot="formatString" slot-scope="string">
              <div>{{ string | formatString }}</div>
            </template>

            <template slot="record" slot-scope="id, row">
              <a-button type="primary" size="small" @click="showRecord(row)">{{ $t('global.action.records') }}</a-button>
            </template>
          </a-table>

          <ExchangeRateLog :recordFormShow="recordFormShow"
                           :exchangeRateInfo="exchangeRateInfo"
                           :closeModal="closeLogForm"
          />

          <ConfirmExchangeRate :exchangeRateInfo="exchangeRateInfo"
                               :show="confirmFormShow"
                               :fetchData="fetch_exchange_rate"
                               :closeModal="closeConfirmForm"/>

          <ApproveRate :data="data"
                       :show="approveFormShow"
                       :approveRate="approveRate"
                       :closeModal="closeApproveForm"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getExchangeRateList, updateCurrencyRates, approveExchangeRate, getMerchantRole } from '@/../api';
import ExchangeRateLog from '@/../src/components/records/exchange_rate_log';
import ConfirmExchangeRate from '@/../src/components/records/confirm_exchange_rate';
import ApproveRate from '@/../src/components/records/approve_rate';
import CurrentTime from '@/../src/components/current_time';
import numeral from 'numeral';

const columns = [
  {
    slots: { title: 'currency' },
    dataIndex: 'currency',
  },
  {
    slots: { title: 'currency_name' },
    dataIndex: 'currency_name',
    singleI18n: true,
  },
  {
    slots: { title: 'last_approved_rate' },
    dataIndex: 'last_approved_rate',
    scopedSlots: { customRender: 'last_approved_rate' },
    align: 'center',
    singleI18n: true,
  },
  {
    slots: { title: 'ref_rate' },
    dataIndex: 'ref_rate',
    scopedSlots: { customRender: 'ref_rate' },
    align: 'center',
    singleI18n: true,
  },
  {
    slots: { title: 'ref_rate_time' },
    dataIndex: 'ref_rate_time',
    scopedSlots: { customRender: 'formatTimes' },
    align: 'center',
    singleI18n: true,
  },
  {
    slots: { title: 'tbd_confirm_rate' },
    dataIndex: 'tbd_confirm_rate',
    scopedSlots: { customRender: 'tbd_confirm_rate' },
    align: 'center',
    width: 130,
    singleI18n: true,
  },
  {
    slots: { title: 'record' },
    dataIndex: 'record',
    scopedSlots: { customRender: 'record'},
    align: 'center',
    width: 90,
    singleI18n: true,
  },
];

export default {
  components: {
    ExchangeRateLog,
    ConfirmExchangeRate,
    CurrentTime,
    ApproveRate
  },
  data() {
    return {
      loading: true,
      confirmFormShow: false,
      recordFormShow: false,
      approveFormShow: false,
      base_currency: '',
      last_approve_time: null,
      exchangeRateInfo: {
        title: '',
        id: null,
        currency: '',
        currency_name: '',
        ref_rate: '',
        last_approved_rate: 0,
        safe_range: 0,
        tbd_confirm_rate: 0,
        first_safe_rate: 0,
        last_safe_rate: 0,
      },
      data: [],
      role: {
        read: false,
        confirm: false,
        approve: false,
      },
      columns,
      pressed_get_rate_btn: false,
    };
  },
  async mounted(){
    await getMerchantRole()
        .then((data)=>{
          this.role = data.data.role.ExchangeRate;
        })
    this.getExchangeRate();
  },
  methods: {
    numeral,
    closeConfirmForm(){
      this.confirmFormShow = false;
    },
    closeLogForm(){
      this.recordFormShow = false;
    },
    closeApproveForm(){
      this.approveFormShow = false;
    },
    getExchangeRate() {
      getExchangeRateList()
          .then((data)=>{
            this.data = data.data.exchange_rate_list;
            this.base_currency = data.data.base_currency;
            this.loading = false;
            this.last_approve_time = data.data.last_approve_time;
          });
    },
    fetch_exchange_rate(){
      this.loading = true;
      updateCurrencyRates()
          .then((data)=>{
            this.data = data.data.exchange_rate_list;
            this.base_currency = data.data.base_currency;
            this.pressed_get_rate_btn = true;
          })
          .finally(()=>{
            this.loading = false;
          });
    },
    approveRate(){
      // let flag = false;
      // this.data.forEach((element) =>{
      //       if(element.tbd_confirm_rate == 0.0){
      //         flag = true;
      //         return this.$message.error( element.currency + ' Not Confirm', 3);
      //       }
      //     }
      // );

      // if(flag == false) {
      this.closeApproveForm();
      this.loading = true;
      approveExchangeRate({base_currency: this.base_currency})
          .then((data)=>{
            this.$message.success(this.$t('global.message.success_to_change',{value: this.$t('global.menu.ExchangeRate')}));
            this.fetch_exchange_rate();
            this.last_approve_time = this.moment().utcOffset("+08:00").format('YYYY-MM-DD HH:mm:ss');
          }).catch(()=>{
            this.$message.error(this.$t('global.message.failed_to_update',{value: this.$t('global.menu.ExchangeRate')}));
          })
          .finally(()=>{
            this.loading = false;
          });
      // }
    },
    showRecord(row) {
      this.setCurrentExchangeInfoData(row);
      this.recordFormShow = true;
    },
    showConfirmExchangeRate(row){
      this.setCurrentExchangeInfoData(row);
      this.confirmFormShow = true;
    },
    showApprove() {
      this.approveFormShow = true;
    },
    setCurrentExchangeInfoData(currentRow){
      this.exchangeRateInfo.id = currentRow.id;
      this.exchangeRateInfo.currency = currentRow.currency;
      this.exchangeRateInfo.currency_name = currentRow.currency_name;
      this.exchangeRateInfo.ref_rate = numeral(currentRow.ref_rate).value();
      this.exchangeRateInfo.last_approved_rate = currentRow.last_approved_rate;
      const safeRange = numeral(currentRow.safe_range).value();
      this.exchangeRateInfo.safe_range = safeRange
      this.exchangeRateInfo.tbd_confirm_rate = currentRow.tbd_confirm_rate;
      const safeRangeAmount = numeral(currentRow.last_approved_rate).multiply(safeRange).divide(100).value();
      this.exchangeRateInfo.first_safe_rate = numeral(currentRow.last_approved_rate).subtract(safeRangeAmount).value();
      this.exchangeRateInfo.last_safe_rate = numeral(currentRow.last_approved_rate).add(safeRangeAmount).value();
      this.exchangeRateInfo.rate = this.rate();
    },
    rate(){
      let rate = parseFloat(this.exchangeRateInfo.tbd_confirm_rate);
      if(rate == 0.0) {
        rate = this.exchangeRateInfo.ref_rate;
      }
      return rate;
    },
    rateWarning(row) {
      const safeRange = numeral(row.safe_range).value();
      const safeRangeAmount = numeral(row.ref_rate).multiply(safeRange).divide(100).value();
      const first_safe_rate = numeral(row.ref_rate).subtract(safeRangeAmount).value();
      const last_safe_rate = numeral(row.ref_rate).add(safeRangeAmount).value();
      const last_approved_rate = numeral(row.last_approved_rate).value();
      return last_approved_rate >= first_safe_rate && last_approved_rate <= last_safe_rate ? false : true;
    }
  },
  computed:{
    showApproveRateBtn(){
      return !!this.data.find(dataItem => Number(dataItem.tbd_confirm_rate) !== 0);
    }
  }
}
</script>
