import storage, { AUTH_TOKEN, CURRENT_USER, AUTH_ROLE } from '@/../storage';
import * as actions from './actions';
import * as getters from './getters';
import * as mutations from './mutations';

const state = {
  // device: 'mobile',
  user: storage.getItem(CURRENT_USER) || '',
  token: storage.getItem(AUTH_TOKEN) || '',
  identity: '',
  parentIdentity: '',
  timeZone: '',
  currentProfile: {},
  ws_url: '',
  agent_channel: '',
  agent_sub_ch: null,
  agent_online_users: 0,
  isMerchantAccount: false,
  role: ''
};

export default {
  state,
  getters,
  actions,
  mutations,
};
