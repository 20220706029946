import { render, staticRenderFns } from "./ArenaModeratorPage.vue?vue&type=template&id=6f55b0f2&"
import script from "./ArenaModeratorPage.vue?vue&type=script&lang=js&"
export * from "./ArenaModeratorPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports