<template>
  <div class="frame agents-page">
    <div class="page-breadcrumb" v-if="id">
      <a-breadcrumb>
        <a-breadcrumb-item><router-link :to="{name: 'Agents'}">{{ $t('global.menu.Agents') }}</router-link></a-breadcrumb-item>
        <!-- <a-breadcrumb-item>{{ this.$route.params.id }}</a-breadcrumb-item> -->
        <a-breadcrumb-item>{{ id }}</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <a-page-header
      :title="$t('global.menu.AgentSubAccount')"
      @back="() => $router.go(-1)"
    >
    <template slot="extra">
      <NewSubAccount :fetchData="fetchData"/>
    </template>
    </a-page-header>
    <div class="page-content">

      <div class="ant-card">
        <div class="ant-card-body">
          <a-table
            bordered
            size="small"
            :columns="columns"
            :row-key="data => data.id"
            :data-source="data"
            :pagination="pagination"
            :loading="loading"
            @change="handleTableChange"
          >
            <template v-for="column in columns">
              <template :slot="column.slots.title">{{ column.singleI18n ? $t(`AgentSubAccountPage.${column.slots.title}`) : $t(`global.common.${column.slots.title}`) }}</template>
            </template>

            <template slot="authority" slot-scope="authority">
              <a-tag :color="authoritys.find(el => el.id == authority).color">
                {{ $t(`AgentSubAccountPage.authority_list.${authoritys.find(el => el.id == authority).name}`) }}
              </a-tag>
            </template>

            <template slot="formatTimes" slot-scope="times">
              <div class="table-time">{{ times | formatTimes }}</div>
            </template>

            <template slot="formatString" slot-scope="string">
              <div>{{ string | formatString }}</div>
            </template>

            <template slot="action" slot-scope="id, row">
              <a-button type="primary" size="small" @click="showEdit(row)">{{ $t('global.action.edit') }}</a-button>
              <a-button icon="delete" size="small" @click="deleteManager(row)"></a-button>
            </template>
          </a-table>
        </div>
      </div>
    </div>
    <a-modal v-model="formShow" :footer="null" :width="380">
      <template slot="title">
        {{ $t('global.action.edit') }}
        <span class="primary-font-color"> {{ managerFormAccount }}</span>
      </template>
      <EditSubAccount :agentSubAccount="agentSubAccount"
                      :closeForm="closeForm"
                      :fetchData="fetchData"/>
    </a-modal>
  </div>
</template>

<script>
import NewSubAccount from '@/../src/components/agents/new_sub_account';
import EditSubAccount from '@/../src/components/agents/edit_sub_account';
import { deleteAgentManager } from '@/../api';

const columns = [
  {
    slots: { title: 'id' },
    dataIndex: 'id',
    align: 'center',
  },
  {
    slots: { title: 'account' },
    dataIndex: 'account',
    scopedSlots: { customRender: 'formatString' },
  },
  {
    slots: { title: 'name' },
    dataIndex: 'name',
    scopedSlots: { customRender: 'formatString' },
  },
  {
    slots: { title: 'authority' },
    dataIndex: 'authority',
    scopedSlots: { customRender: 'authority'},
    singleI18n: true
  },
  {
    slots: { title: 'last_login' },
    dataIndex: 'last_login',
    scopedSlots: { customRender: 'formatTimes' },
  },
  {
    slots: { title: 'action' },
    dataIndex: 'action',
    scopedSlots: { customRender: 'action'},
    align: 'center',
  },
];

export default {
  components: {
    NewSubAccount,
    EditSubAccount,
  },
  props:{
    authoritys: Array,
    data: Array,
    pagination: Object,
    loading: Boolean,
    fetchData: Function,
    id: [String, Number],
  },
  data() {
    return {
      formShow: false,
      managerFormAccount: "",
      agentSubAccount:{
        // password: '',
        name: '',
        authority: '',
        id: 0
      },
      expand: false,
      columns,
    };
  },
  mounted() {
  },
  methods: {
    handleTableChange(pagination, filters, sorter) {
      // const pager = { ...this.pagination };
      // pager.current = pagination.current;
      // this.pagination = pager;
      // this.fetch({
      //   results: pagination.pageSize,
      //   page: pagination.current,
      //   sortField: sorter.field,
      //   sortOrder: sorter.order,
      //   ...filters,
      // });
    },
    showEdit(row) {
      this.formShow = true;
      this.managerFormAccount = row.account;
      this.agentSubAccount.name = row.name;
      this.agentSubAccount.authority = row.authority;
      this.agentSubAccount.id = row.id;
    },
    updateForm(row) {
      this.formShow = false;
      this.fetchData();
    },
    closeForm() {
      this.formShow = false;
    },
    deleteManager(row) {
      const self = this;
      this.$confirm({
        title: self.$t('global.message.delete', {value: row.account}),
        okText: self.$t('global.action.yes'),
        cancelText: self.$t('global.action.no'),
        onOk() {
            deleteAgentManager({id: row.id})
              .then((data)=>{
                self.$message.success(self.$t('global.message.success_to_delete', {value: row.account}));
                self.fetchData();
              })
              .catch((err)=>{
                self.$message.error(err.response.data.message)
              })
        },
        onCancel() {},
      });
    }
  },
};
</script>
