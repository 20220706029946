<template>
  <a-page-header
      :title="'Home'"
    >
  </a-page-header>
</template>

<script>
export default {

}
</script>
