<template>
  <div class="new-agent">
    <a-button class="add-btn" icon="plus" @click="showModal">
      {{ $t('AgentsPage.create_agent') }}
    </a-button>
    <a-modal v-model="visible" :title="$t('AgentsPage.create_agent')" :footer="null" :width="380">
      <a-form-model :layout="'vertical'" ref="createAdminAgent" :model="createAdminAgent" :rules="rules">
        <a-row >
          <a-col :xs="24">

            <a-form-model-item :label="$t('global.common.merchant')" prop="status">
              <a-select v-model="createAdminAgent.merchant">
                <a-select-option v-for="merchant in merchants" :value="merchant.value" :key="merchant.value">
                    {{ merchant.label }}
                </a-select-option>
              </a-select>
            </a-form-model-item>

            <a-form-model-item :label="$t('global.common.account')" prop="account">
              <a-input v-model="createAdminAgent.account" :placeholder="$t('global.message.account_placeholder')" autocomplete="off" />
            </a-form-model-item>

            <a-form-model-item has-feedback :label="$t('global.common.password')" prop="password">
              <a-input v-model="createAdminAgent.password" type="password" :placeholder="$t('global.message.password_placeholder')" autocomplete="off" />
            </a-form-model-item>

            <a-form-model-item has-feedback :label="$t('global.common.confirm_password')" prop="confirm_password">
              <a-input v-model="createAdminAgent.confirm_password" type="password" :placeholder="$t('global.message.confirm_password_placeholder')" autocomplete="off" />
            </a-form-model-item>

            <a-form-model-item :label="$t('global.common.name')" prop="name">
              <a-input v-model="createAdminAgent.name" />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-form-model-item>
          <a-button type="primary" @click="newAdminAgentSubmit()" block>
            {{ $t('global.action.create') }}
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>

export default {
  props:{
    merchants: Array
  },
  data() {
    const checkAccount = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('global.message.fill_input', {value: this.$t('global.common.account')})));
      }else if (value.length < 4) {
        callback(new Error(this.$t('global.message.must_be_at_4_characters', {value: this.$t('global.common.account')})));
      } else if (!/^[a-zA-Z0-9_\.]*$/.test(value)) {
        callback(new Error(this.$t('global.message.must_be_alphanumeric', {value: this.$t('global.common.account')})));
      } else {
        callback();
      }
    };
    const checkPassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('global.message.fill_input', {value: this.$t('global.common.password')})));
      }else if (value.length < 4) {
        callback(new Error(this.$t('global.message.must_be_at_4_characters', {value: this.$t('global.common.password')})));
      } else {
        callback();
      }
    };
    const confirmPassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('global.message.fill_input', {value: this.$t('global.common.confirm_password')})));
      }else if (value !== this.createAdminAgent.password) {
        callback(new Error(this.$t('global.message.two_no_match')));
      } else {
        callback();
      }
    };
    const checkName = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('global.message.fill_input', {value: this.$t('global.common.name')})));
      } else {
        callback();
      }
    };
    return {
      visible: false,
      createAdminAgent: {
        account: '',
        password: '',
        confirm_password: '',
        name: '',
        merchant: '1111'
      },
      rules: {
        account: [{ validator: checkAccount, trigger: 'change' }],
        password: [{ validator: checkPassword, trigger: 'change' }],
        confirm_password: [{ validator: confirmPassword, trigger: 'change' }],
        name: [{ validator: checkName, trigger: 'change' }],
      },
    };
  },
  methods: {
    showModal() {
      this.visible = true;
    },
    newAdminAgentSubmit() {
      this.$refs.createAdminAgent.validate((valid) => {
        if (!valid) {
          this.$message.error(this.$t('global.message.failed_to_create', {value: this.$t('global.common.merchant')}));
          return false;
          } else {
          this.$message.success(this.$t('global.message.success_to_create', {value: this.$t('global.common.merchant')}));
          this.visible = false;
          this.$refs.createAdminAgent.resetFields();
        }
      });
    },
  },
};
</script>

