import Vue from 'vue';
import Vuex from 'vuex';

import auth from './auth';

Vue.use(Vuex);

/*
  computed(){
    ...Vuex.mapState(['count', 'loading', 'user']),
  }
*/

export default new Vuex.Store({
  state: {
    lang: null, // 存放使用者選用的語系
  },
  mutations: {
    setLang(state, value) {
      state.lang = value;
    },
  },
  modules: {
    auth: {
      namespaced: true,
      ...auth,
    },
  },
});
