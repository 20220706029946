<template>
    <a-modal 
      :visible="visible" 
      :title="$t('global.common.log')"
      :footer="null" 
      :width="'75%'"
      :afterClose="clearLog"
      @cancel="closeLogModal"
      class="malay-odds-setting-page"
    >
        
        <a-table
            bordered
            size="small"
            :columns="columns"
            :row-key="data => data.updated_at"
            :data-source="data"
            :loading="logLoading"
            :pagination="pagination"
            @change="handleTableChange"
        >
            <div 
              v-for="column in columns" 
              :slot="column.slots.title" 
              :key="column.slots.title"
              class="title">
              <span v-if="column.slots.title !== 'per_bet_risk_threshold' && column.slots.title !== 'per_round_risk_threshold'">
                {{ column.singleI18n ? $t(`PoolSettingPage.${column.slots.title}`) :$t(`global.common.${column.slots.title}`) }}
              </span>
              <div v-else>
                <p>{{ $t('PoolSettingPage.risk_threshold') }}</p>
                <p>{{ column.slots.title === 'per_bet_risk_threshold' ? $t('PoolSettingPage.per_bet') : $t('PoolSettingPage.per_round') }} (USD)</p>
              </div>
            </div>

            <template slot="formatTimes" slot-scope="times">
              <div class="table-time">{{ times | formatTimesToUTC8 }}</div>
            </template>

            <template slot="formatNumber" slot-scope="amount">
              <div>{{ amount | formatNumber }}</div>
            </template>

            <template slot="formatNumberWithPoint" slot-scope="amount">
              <div>{{ amount | formatNumberWithPoint }}</div>
            </template>


        </a-table>
    </a-modal>
  </template>
  <script>
import { getMalayOddsLogById } from '@/../api';
  
//   const data = [{
//   name: 'php_AM',
//   odds_refresh_frequency: 3,
//   rate: 5.12,
//   max_payout: 15000,
//   last_call_percent: 75,
//   close_bet_percent: 80,
//   updated_at: '2023-12-19 15:17:00'
// },{
//   name: 'php_PM',
//   odds_refresh_frequency: 5,
//   rate: 8,
//   max_payout: 20000,
//   last_call_percent: 65,
//   close_bet_percent: 90,
//   updated_at: '2023-12-19 15:17:55'
// }]


const columns = [
    {
    slots: { title: 'name' },
    dataIndex: 'name',
    filterType: true,
    align: 'center',
  },
  {
    slots: { title: 'odds_refresh_frequency' },
    dataIndex: 'duration_second',
    filterType: true,
    align: 'center',
    singleI18n: true
  },
  {
    slots: { title: 'rate' },
    dataIndex: 'rake_percentage',
    align: 'center',
    scopedSlots: { customRender: 'formatNumberWithPoint' },
    filterType: true,
  },
  {
    slots: { title: 'per_bet_risk_threshold' },
    dataIndex: 'per_bet_risk_threshold',
    scopedSlots: { customRender: 'formatNumber' },
    align: 'center',
    filterType: true,
    singleI18n: true,
  },
  {
    slots: { title: 'per_round_risk_threshold' },
    dataIndex: 'per_round_risk_threshold',
    scopedSlots: { customRender: 'formatNumber' },
    align: 'center',
    filterType: true,
    singleI18n: true,
  },
  {
    slots: { title: 'last_call_percentage' },
    dataIndex: 'last_call_percentage',
    align: 'center',
    filterType: true,
    singleI18n: true,
  },
  {
    slots: { title: 'close_bet_percentage' },
    dataIndex: 'close_bet_percentage',
    align: 'center',
    filterType: true,
    singleI18n: true,
  },
  {
    slots: { title: 'updated_at' },
    dataIndex: 'updated_at',
    scopedSlots: { customRender: 'formatTimes' },
    filterType: true,
  },
  {
    slots: { title: 'account' },
    dataIndex: 'account',
    align: 'center',
    filterType: true,
  },
];
  
  export default {
    props:{
        visible: {
            type: Boolean,
            default: false
        },
        id: {
            type: Number,
            default: null
        },
        clearLog: {
            type: Function,
            default: ()=>{}
        },
        closeLogModal: {
            type:Function,
            default:()=>{}
        }
    },
    data() {
      return {
        columns,
        data: [],
        pagination: {
          current: 1,
          pageSize: 5, // 默认每页显示数量
          // showSizeChanger: true, // 显示可改变每页数量
          // pageSizeOptions: ["5", "10", "20", "50", "100"], // 每页数量选项
          showTotal: (total) => `Total ${total} items`, // 显示总数
          // showSizeChange: (current, pageSize) => (this.pageSize = pageSize), // 改变每页数量时更新显示
          total: 0
        },
        logLoading: false,
        currentId: null,
      };
    },
    async mounted() {},
    methods: {
        handleTableChange(pagination, filters, sorter) {
            const currentPage = this.pagination.pageSize !== pagination.pageSize ? 1 : pagination.current;
            this.pagination.current = currentPage;
            this.pagination.pageSize = pagination.pageSize;

            this.fetch({
                results: pagination.pageSize,
                page: currentPage,
                sortField: sorter.field,
                sortOrder: sorter.order,
                ...filters,
            });
        },
        fetch(form = {}) {
            this.logLoading = true;
            getMalayOddsLogById({id: this.currentId, form}).then(({ data }) => {
                const pagination = { ...this.pagination };
                pagination.total = data.total_count || 0;

                const log = data.log.map(logItem=>{
                    const parseData = JSON.parse(logItem.data);
                    return parseData
                })

                this.data = log;
                this.pagination = pagination;
            }).catch((err)=>{
                this.$message.error(err.response.data.message, 3);
            }).finally(()=>{
                this.logLoading = false;
            });
        },
    },
    computed:{},
    watch:{
        id:{
            handler(newValue){
                this.currentId = newValue;
            },
            immediate: true
        },
        currentId:{
            handler(newValue){
                if(newValue){
                    const { pageSize } = this.pagination;
                    this.pagination.current = 1;
                    this.fetch({results: pageSize, page: 1});
                }
            }
        }
    }
  };
  </script>
  
  
  