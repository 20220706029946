<template>
  <a-layout-footer style="text-align: center">
</a-layout-footer>
</template>
<script>
export default {
  data() {
    return {
    };
  },
};
</script>
