<template>
  <div class="source-dialog">
    <a-form-model :layout="'vertical'" ref="videoSource" :model="sourceForm" :rules="rules">

      <a-form-model-item :label="$t('global.common.provider_name')" prop="provider_name">
        <a-input v-model="sourceForm.provider_name" />
      </a-form-model-item>

      <a-form-model-item has-feedback :label="$t('global.common.video_url')" prop="video_url">
        <a-textarea v-model="sourceForm.video_url" :rows="5" />
      </a-form-model-item>


      <a-form-model-item :label="$t('global.common.status')" prop="statusList">
        <a-select v-model="sourceForm.status">
          <a-select-option v-for="statusItem in statusList" :value="statusItem.value" :key="statusItem.value">
            <span>{{ statusItem.label }}</span>
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-divider class="checkbox-page-divider"/>
      <a-row  class="checkbox-page-min-title" :gutter="16">
        <a-col :sm="24" :md="10">
          <h5>{{ $t('VideoSourcePage.accessable_merchants') }}</h5>
        </a-col>
        <a-col :sm="24" :md="14">
          <a-checkbox class="checkbox" v-model="selectedAll" :indeterminate="indeterminate">
            {{ $t('global.common.all') }}
          </a-checkbox>
        </a-col>
      </a-row>

      <a-row class="checkbox-page" :gutter="16" v-for="(ListItem, key) in defaultPermissionList" :key="key">
        <a-col class="checkbox-page-group-title" :sm="24" :md="10">
          {{ key }}
        </a-col>
        <a-col :sm="24" :md="14">
          <a-checkbox-group class="checkbox-page-group"
                            v-model="sourceForm.permissionList[key]"
                            @change="onChangePermission(key)"
                            :data-group-name="key">
            <template v-for="itemValue in ListItem">
              <a-checkbox class="checkbox" :value="itemValue" :key="itemValue">
                {{ $t(`global.common.active_status_list.${itemValue}`) }}
              </a-checkbox>
            </template>
          </a-checkbox-group>
        </a-col>
      </a-row>
    </a-form-model>
    <div class="drawer-footer">
      <a-button type="primary" @click="videoSourceSubmit(sourceForm)">
        {{ drawerAction }}
      </a-button>
    </div>
  </div>
</template>
<script>
import { updateProviders, createProviders, getMerchantList } from '@/../api';

export default {
  props:{
    drawerAction: String,
    currentUrl: Object
  },
  data() {
    return {
      selectedAll: false,
      indeterminate: false,
      statusList: [
        {
          label: this.$t('global.common.active_status_list.active'),
          value: 'active'
        },
        {
          label: this.$t('global.common.active_status_list.close'),
          value: 'close'
        }
      ],
      sourceForm: {
        id: null,
        provider_name: '',
        video_url: '',
        status: 'active',
        permissionList: {},
      },
      defaultPermissionList:{},
      blankPermissionList:{},
      rules: {
        name: [{ validator: this.checkName, trigger: 'change' }],
        url: [{ validator: this.checkUrl, trigger: 'change' }],
        status: [
          { required: true,
            message: this.$t('global.message.select_value', {value: this.$t('global.common.status')}),
            trigger: 'blur'
          },
        ],
      },
    };
  },
  watch:{
    currentUrl:{
      handler(newValue, oldValue){
        this.fetchGetMerchantList(newValue);
      },
    },
    blankPermissionList(newValue){
        const tempList = JSON.parse(JSON.stringify(newValue));
        Object.keys(tempList).forEach(listItem=>{
          tempList[listItem].push('active');
        })
        this.defaultPermissionList = tempList;
    },
    selectedAll(newValue){
      this.sourceForm.permissionList =  Object.assign({}, newValue ? this.defaultPermissionList : this.blankPermissionList);
      this.indeterminate = false;
    },
    sourceForm(roleForm) {
      this.indeterminate = true;
    },

  },
  mounted(){
    this.fetchGetMerchantList(this.currentUrl);
  },
  methods: {
    fetchGetMerchantList(propData){
      this.clearData();
      getMerchantList()
        .then((res) => {
          const { merchant } = res.data;
          this.blankPermissionList = merchant;
          if(propData){
            this.setData(propData,merchant)
          }
        })
    },
    setData(propData,permissionList){
      if(propData){
        const { id, provider_name, video_url, status, merchant } = propData;
        this.sourceForm.id = id;
        this.sourceForm.provider_name = provider_name;
        this.sourceForm.video_url = video_url;
        this.sourceForm.status = status;
        Object.keys(permissionList).forEach(listItem=>{
          this.sourceForm.permissionList[listItem] = [];
          if(merchant.includes(listItem)){
            this.sourceForm.permissionList[listItem].push('active');
          }
        })
      }
    },
    clearData(){
        this.selectedAll = false;
        this.sourceForm.id = null;
        this.sourceForm.provider_name = '';
        this.sourceForm.video_url = '';
        this.sourceForm.status = 'active';
        this.sourceForm.permissionList = {};
        this.defaultPermissionList = {};
        this.blankPermissionList = {};
    },
    onChangePermission(key) {
      const list = this.sourceForm.permissionList[key];
      if(!list.includes('active') && list.length >= 1) {
        this.sourceForm.permissionList[key].push('active');
      }
      if ( this.sourceForm.permissionList !== this.defaultPermissionList) {
        this.indeterminate = true;
      }
    },
    checkName(rule, value, callback){
      if (value === '') {
        callback(new Error(this.$t('global.message.fill_input', {value: this.$t('global.common.provider_name')})));
      // else if (value.length < 4) {
      //   callback(new Error(this.$t('global.message.must_be_at_4_characters', {value: this.$t('global.common.account')})));
      // } else if (!/^[a-zA-Z0-9_\.]*$/.test(value)) {
      //   callback(new Error(this.$t('global.message.must_be_alphanumeric', {value: this.$t('global.common.account')})));
      } else {
        callback();
      }
    },
    checkUrl(rule, value, callback){
      if (value === '') {
        callback(new Error(this.$t('global.message.fill_input', {value: this.$t('global.common.video_url')})));
      }else{
        callback();
      }
    },
    videoSourceSubmit() {
      this.$refs.videoSource.validate((valid) => {
        if (!valid) {
          if( this.drawerAction === "Edit") {
            this.$message.error(this.$t('global.message.failed_to_edit', {value: this.$t('global.common.video_url')}));
          }
          else {
            this.$message.error(this.$t('global.message.failed_to_create', {value: this.$t('global.common.video_url')}));
          }
          return false;
          } else {
            const { permissionList } = this.sourceForm;
            const merchant = [];
            Object.keys(permissionList).forEach(listItem=>{
                if(permissionList[listItem].includes('active')){
                  merchant.push(listItem)
                }
            })
            if( this.drawerAction === "Edit") {
              const { id, provider_name, status, video_url } = this.sourceForm;
              updateProviders({ providerId: id, merchant, provider_name, status, video_url })
                .then((data) => {
                  this.$message.success(this.$t('global.message.success_to_edit', {value: this.$t('global.common.video_url')}));
                  this.$refs.videoSource.resetFields();
                  this.$emit('update-data', this.sourceForm);
                })
            }
            else {
              const { provider_name, status, video_url } = this.sourceForm;
              createProviders({ merchant, provider_name, status, video_url })
                .then((data) => {
                  this.$message.success(this.$t('global.message.success_to_create', {value: this.$t('global.common.video_url')}));
                  this.$refs.videoSource.resetFields();
                  this.$emit('update-data', this.sourceForm);
                }).catch((err)=>{
                  this.$message.error(this.$t('global.message.failed_to_create',{ value: this.$t('global.common.provider_name')}));
                })
            }
        }
      });
    },
  },
};
</script>

