<template>
  <div class="frame agents-page">
    <a-page-header
      :title="$t('global.menu.AgentList')"
      @back="() => $router.go(-1)"
    >
    <template slot="extra">
        <CreateAdminAgent v-if="editable" :merchants='merchants'/>
    </template>
    </a-page-header>
    <div class="page-content">
      <div class="search-bar">
        <a-form-model ref="searchForm" :model="form">
          <div class="search-group">
            <a-form-model-item ref="agent_id" :label="$t('global.common.account')" prop="agent_id">
              <a-input
                v-model="form.agent_id"
              />
            </a-form-model-item>
            <div class="search-btns">
              <a-button type="primary" icon="search" :loading="loading" @click="onSearch">{{ $t('global.action.search') }}</a-button>
              <a-button :disabled="loading" @click="resetForm">{{ $t('global.action.reset') }}</a-button>
            </div>
          </div>
        </a-form-model>
      </div>
      <div class="ant-card">
        <div class="ant-card-body">
          <a-table
            bordered
            size="small"
            :columns="columns"
            :data-source="agents"
            :pagination="pagination"
            :loading="loading"
            rowKey="account"
            @change="handleTableChange"
            childrenColumnName="child"
          >
            <template v-for="column in columns">
              <template :slot="column.slots.title">{{ $t(`global.common.${column.slots.title}`) }}</template>
            </template>
            <template slot="account" slot-scope="account, row">
              {{ account }} <span class="table-agent-level" :data-level="row.level" v-if="row.level === 'master'">{{ row.level }}</span>
            </template>
            <template slot="rate" slot-scope="rate">
              {{ Math.floor(Number(rate) * 1000)/10 }} ％
            </template>
            <template slot="action" slot-scope="id, row">
              <a-button type="primary" size="small" @click="showEdit(row)">{{ $t('global.action.edit') }}</a-button>
              <router-link :to="{ name: 'AdminAgentSubAccount', params: { id: 1 }}">
                <a-button size="small" class="manager-btn">{{ $t('global.common.manager') }}</a-button>
              </router-link>
            </template>
          </a-table>
        </div>
      </div>
    </div>
    <a-modal v-model="formShow" :footer="null" :width="380">
      <template slot="title">
        {{ $t('global.action.edit') }}
        <span class="primary-font-color"> {{ agentAccount }}</span>
      </template>
      <EditAdminAgent :adminAgent="adminAgent"
                      :merchants='merchants'
                      @update-data="updateForm"/>
    </a-modal>
  </div>
</template>

<script>
import { 
  getAgents, 
  // getAgentById 
} from '@/../api';
import CreateAdminAgent from '@/../src/components/agents/create_admin_agent';
import EditAdminAgent from '@/../src/components/agents/edit_admin_agent';

const columns = [
  {
    slots: { title: 'account' },
    dataIndex: 'account',
    scopedSlots: { customRender: 'account' },
    // 放帳號、代理等級（master）會有特別標示
  },
  {
    slots: { title: 'rate' },
    dataIndex: 'rate',
    scopedSlots: { customRender: 'rate' },
    align: 'center',
  },
  {
    slots: { title: 'agent_count' },
    dataIndex: 'agent_counts',
    align: 'center',
  },
  {
    slots: { title: 'player_count' },
    dataIndex: 'player_counts',
    align: 'center',
  },
  {
    slots: { title: 'theme' },
    dataIndex: 'theme',
    align: 'center',
  },
  {
    slots: { title: 'action' },
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' },
    align: 'center',
  },
];

export default {
  components: {
    CreateAdminAgent,
    EditAdminAgent
  },
  data() {
    return {
      pagination: {},
      loading: false,
      columns,
      form: {
        agent_id: this.$route.query.agent,
      },
      merchants: [
        {
          label: '1111',
          value: '1111',
        },
        {
          label: '2222',
          value: '2222'
        }
      ],
      agents: [],
      adminAgent: {},
      formShow: false,
      agentAccount: "",
      editable: true
    };
  },
  mounted() {
    this.fetch();
    if (this.$route.query.agent) {
      this.onSearch();
    }
  },
  methods: {
    handleTableChange(pagination, filters, sorter) {
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
      this.fetch({
        results: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
      });
    },
    fetch(params = {}) {
      this.loading = true;
      getAgents({
        results: 10,
        ...params,
      }).then(({ data }) => {
        const pagination = { ...this.pagination };
        // Read total count from server
        // pagination.total = data.totalCount;
        pagination.total = data.agents.length;
        this.agents = data.agents;
        this.pagination = pagination;
        this.editable = data.editable;
      }).finally(()=>{
        this.loading = false;
      });
    },
    onSearch() {
      if(this.loading) return;
      const { agent_id } = this.form;
      this.$refs.searchForm.validate(valid => {
        if (valid) {
          this.fetch({ agent_id });
        } else {
          // console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm() {
      if(this.loading) return;
      this.$refs.searchForm.resetFields();
      this.fetch({});
    },
    showEdit(row) {
      this.formShow = true;
      this.agentAccount = row.account;
      this.adminAgent.name = row.name;
      this.adminAgent.manager = row.manager;
    },
    updateForm(row) {
      this.formShow = false;
    },
  },
};
</script>
