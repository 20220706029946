<template>
  <div class="frame agents-page">
    <a-page-header
      :title="identity ? $t(`global.menu.${title}`) : ''"
      @back="() => $router.go(-1)"
    >
    </a-page-header>
    <div class="page-content">
      <div class="search-bar">
        <a-form-model ref="searchForm" :model="form">
          <div class="search-group">
            <a-form-model-item ref="agent_account" :label="identity ? $t(accountTitle) : ''" prop="agent_account">
              <a-input
                v-model.trim="form.agent_account"
              />
            </a-form-model-item>
            <Currency :inputCurrency.sync="form.currency"
                      :inputMaster.sync="form.link_master_id"
                      :showCurrency="false"
                      :showLinkMaster="true"
                      :showMaster="false"
                      :showAgent="false"
                      :currencyRequired="true"/>
            <a-form-model-item ref="status" :label="$t('global.common.business_status')" prop="status">
              <a-select v-model="form.status">
                <a-select-option v-for="status in statusList" :value="status.value" :key="status.value">
                  {{ $t(`global.common.active_status_list.${status.label}`) }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </div>
          <div class="search-group">
            <div class="search-btns">
              <a-button type="primary" icon="search" :loading="loading" @click="onSearch">{{ $t('global.action.search') }}</a-button>
              <a-button :disabled="loading" @click="resetForm">{{ $t('global.action.reset') }}</a-button>
            </div>
          </div>
        </a-form-model>
      </div>
      <div class="ant-card">
        <div class="ant-card-body">
          <a-table
            bordered
            size="small"
            :columns="columns"
            :data-source="agents"
            :pagination="pagination"
            :loading="loading"
            rowKey="row_key_id"
            @change="handleTableChange"
            childrenColumnName="child_present"
          >
            <template v-for="column in columns">
              <template :slot="column.slots.title">{{ column.singleI18n ? $t(`AgentsPage.${column.slots.title}`) : $t(`global.common.${column.slots.title}`) }}</template>
            </template>
            <template slot="account" slot-scope="account, row">
              {{ account }} <span class="table-agent-level" :data-level="row.level" v-if="row.level === 'master'">{{ row.level }}</span>
            </template>
            <template slot="rate" slot-scope="rate">
              {{ (rate * 100).toFixed(2) }}
            </template>
            <template slot="risk_odd" slot-scope="risk_odd">
              {{ (risk_odd * 1).toFixed(2) }}
            </template>
            <!-- <template slot="odds_type" slot-scope="odds_type">
              {{ odds_type }}
            </template> -->
            <template slot="active" slot-scope="active">
              <div class="table-status-item">
                <span class="status-icon" :data-status="active ? 'active' : 'close'"></span>
                <span class="status-text">{{ $t(`global.common.active_status_list.${active ? 'active' : 'close'}`) }}</span>
              </div>
            </template>
            <!-- <template slot="formatTimes" slot-scope="times">
              <div class="table-time">{{ times | formatTimes }}</div>
            </template>
            <template slot="formatString" slot-scope="string">
              <div>{{ string | formatString }}</div>
            </template> -->
          </a-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { 
  getMasterAgentLink, 
  getPagePermissions, 
  getMerchantRole 
} from '@/../api';
import Currency from '@/../src/components/currency_selection';

const columns = [
  {
    slots: { title: 'account' },
    dataIndex: 'master_account',
    scopedSlots: { customRender: 'account' },
  },
  {
    slots: { title: 'arena' },
    dataIndex: 'title',
    align: 'center',
  },
  {
    slots: { title: 'link_master' },
    dataIndex: 'link_master_account',
    align: 'center',
  },
  {
    slots: { title: 'risk_odd' },
    dataIndex: 'risk_odd',
    scopedSlots: { customRender: 'risk_odd' },
    align: 'center',
  },
  {
    slots: { title: 'odds_type' },
    dataIndex: 'odds_type',
    align: 'center',
  },
  {
    slots: { title: 'business_status' },
    dataIndex: 'master_status',
    scopedSlots: { customRender: 'active' },
    align: 'center',
  },
];

export default {
  components: {
    Currency,
  },
  data() {
    return {
      data: [],
      displayRate: false,
      pagination: {
        current: 1,
        pageSize: 10, // 默认每页显示数量
        showSizeChanger: true, // 显示可改变每页数量
        pageSizeOptions: ["10", "20", "30", "40"], // 每页数量选项
        showTotal: (total) => `Total ${total} items`, // 显示总数
        showSizeChange: (current, pageSize) => (this.pageSize = pageSize), // 改变每页数量时更新显示
        total: 0
      },
      expand: false,
      loading: false,
      form: {
        currency: '',
        agent_account: this.$route.query.agent,
        status: 'active',
        link_master_id: null
      },
      statusList: [
        {
          label: 'all',
          value: 'all'
        },
        {
          label: 'active',
          value: 'active'
        },
        {
          label: 'close',
          value: 'close'
        }
      ],
      rules: {
      },
      agents: [],
      premission_action: {
        create: false,
        read: false,
        update: false,
        edit: false,
      },
    };
  },
  async mounted() {
    await getMerchantRole().then((data) => {
      this.displayRate = data.data.role.AgentSource?.display_rate;
    });
  },
  computed: {
    ...mapState({ currentProfile: state => state.auth.currentProfile, 
                  identity: state => state.auth.identity,
                  parentIdentity: state => state.auth.parentIdentity,
                  isMerchantAccount: state => state.auth.isMerchantAccount}),
    isMerchant(){
      const isMerchantIdentity = this.identity === 'merchant' || (this.identity === 'manager' && this.parentIdentity === 'merchant');
      return isMerchantIdentity;
    },
    accountTitle(){
      return this.isMerchant ? 'AgentsPage.master_account' : 'global.common.account' ;
    },
    title(){
      return this.isMerchant ? 'MasterAgentSource' : 'AgentSource';
    },
    hasRatePermission() {
      return this.displayRate;
    },
    columns(){
      const baseColumns = [...columns];

      const rateColumn = {
        slots: { title: 'rate' },
        dataIndex: 'rate',
        scopedSlots: { customRender: 'rate' },
        align: 'center',
      };

      if (this.hasRatePermission) {
        const indexToInsert = baseColumns.findIndex(column => column.dataIndex === 'link_master_account');
        if (indexToInsert !== -1) {
          baseColumns.splice(indexToInsert + 1, 0, rateColumn);
        }
      }

      return baseColumns;
    }
    // rate_min(){
    //   const fee = this.currentProfile.fee;
    //   const parseFee = parseFloat(fee);
    //   return !isNaN(parseFee) && typeof(parseFee) === 'number' ? parseFee * 100 : 0;
    // },
  },
  methods: {
    handleTableChange(pagination, filters, sorter) {
      const currentPage = this.pagination.pageSize !== pagination.pageSize ? 1 : pagination.current;
      this.pagination.current = currentPage;
      this.pagination.pageSize = pagination.pageSize;
      this.fetch({
        results: pagination.pageSize,
        page: currentPage,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
      });
    },
    fetch(params = {}) {
      this.loading = true;
      const { status } = this.form;
      getMasterAgentLink({
        status,
        ...params,
      }).then(({ data }) => {
        const pagination = { ...this.pagination };
        pagination.total = data.agents.length;
        this.agents = data.agents;
        this.pagination = pagination;
        this.editable = data.editable;
      }).finally(()=>{
        this.loading = false;
      });
    },
    onSearch() {
      if(this.loading) return;
      const { agent_account, link_master_id } = this.form;
      this.$refs.searchForm.validate(valid => {
        if (valid) {
          this.fetch({ agent_account, link_master_id });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm() {
      if(this.loading) return;
      this.$refs.searchForm.resetFields();
      this.form.link_master_id = null;
      // this.fetch({});
    },
  },
  watch: {
    identity: {
      handler(newValue, oldValue){
        if(newValue){
          if(newValue === 'manager'){
            getPagePermissions(this.$route.name)
            .then((data)=>{
              if(data.data.actions !== undefined) {
                this.premission_action.create = data.data.actions.create || false;
                this.premission_action.read = data.data.actions.read || false;
                this.premission_action.update = data.data.actions.update || false;
                this.premission_action.edit = data.data.actions.edit || false;
              }
            })
          }else{
            this.premission_action.create = true;
            this.premission_action.read = true;
            this.premission_action.update = true;
            this.premission_action.edit = true;
          }
        }
      },
      immediate: true,
    },
    // isMerchantAccount: {
    //   handler(newValue,oldValue){
    //     if(newValue === false){
    //     // this is sub account
    //       const remove_item = ['rate']
    //       columns.forEach((item, index, object)=> {
    //         if(remove_item.includes(item.title_name)){
    //           object.splice(index, 1);
    //         }
    //       })
    //     }
    //   },
    //   immediate: true
    // },
  }
};
</script>
