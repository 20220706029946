<template>
  <div class="frame agents-show-page">
    <div class="page-breadcrumb">
      <a-breadcrumb>
        <a-breadcrumb-item><router-link :to="{
          name: 'AgentList',
          params: { updatedAgentData: propAgentData },
        }">{{ $t("global.menu.AgentList") }}</router-link></a-breadcrumb-item>
        <a-breadcrumb-item>{{ agent.account }}</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <a-page-header class="agent-page-header" @back="() => $router.go(-1)">
      <template slot="title">
        <span class="title-account">{{ agent.account }}</span>
        <a-tag class="primary-tag tag-round agent-tag" v-if="agent.is_master_agent">{{ agent.level }}</a-tag>
      </template>
    </a-page-header>
    <div class="page-content agent-content">
      <!-- <a-row :gutter="[16,16]">
        <a-col :xs="24" :sm="12" :xl="8"> -->
      <a-card :title="$t('global.common.profile')">
        <span slot="extra">
          <MerchantEditAgentDialog v-if="agent.editable && updatePermission" :id="id"  @update-data="updateAgent" :data="agent"/>
        </span>
        <Info :key="updatePermission" :updatePermission="updatePermission" :data="agent" :getAgentProfile="init" />
      </a-card>
      <div
        v-if="agent.game_type" 
        :class="{ 'limit-container': true }">
        <RoundBettingLimit 
        :data="agent" 
        :betLimit="betLimit" 
        :updateLimit="updateLimit"
        :updatePermission="updatePermission" />
        <BetBettingLimit 
          :data="agent" 
          :betLimit="betLimit" 
          :updateLimit="updateLimit"
          :updatePermission="updatePermission" />
        <DrawLimit 
          v-if="agent.is_master_agent && agent.is_third_party.active" 
          :data="agent" 
          :maxDraw="maxDraw" 
          :updateLimit="updateLimit"
          :updatePermission="updatePermission" 
        />
        <AlarmQuota
          v-if="!agent.is_master_agent && agent.prepaid_fund_active" 
          :data="agent" 
          :updateAlarmQuota="updateAlarmQuota"
          :updatePermission="updatePermission" 
        />
      </div>
      <div>
        <DrawLimit 
          v-if="agent.is_master_agent && agent.game_type && !agent.is_third_party.active" 
          :data="agent" 
          :maxDraw="maxDraw" 
          :updateLimit="updateLimit"
          :updatePermission="updatePermission" 
        />
        <VideoDataLimit 
          v-if="agent.is_third_party.active" 
          :editable="agent.editable" 
          :quota="agent.is_third_party.quota" 
          :updateQuota="updateQuota"
          :updatePermission="updatePermission" 
        />
      </div>
      <!-- </a-col>
      </a-row> -->
    </div>
  </div>
</template>

<script>
import {
  getAgentProfileFindById,
  getBetLimit,
  updateAgentBetLimit,
  getMerchantRole,
  updateQuotaById,
  updatePrepaidFundLimit
} from "@/../api";

import MerchantEditAgentDialog from "@/../src/components/agents/merchant_edit_agent_dialog";
import RoundBettingLimit from "@/../src/components/round_betting_limit";
import BetBettingLimit from "@/../src/components/bet_betting_limit";
import DrawLimit from "@/../src/components/draw_limit";
import VideoDataLimit from "@/../src/components/video_data_limit";
import Info from "@/../src/components/info";
import AlarmQuota from "@/../src/components/agents/AlarmQuota";

export default {
  components: {
    MerchantEditAgentDialog,
    RoundBettingLimit,
    BetBettingLimit,
    DrawLimit,
    Info,
    VideoDataLimit,
    AlarmQuota,
  },
  data() {
    return {
      agent: {
        is_third_party: {
          active: false,
          quota: 0,
        },
      },
      id: this.$route.params.id,
      updatePermission: false,
      betLimit: {
        meron_wala: {
          per_bet: null,
          per_round: {
            min: null,
            max: null,
          },
        },
        draw: {
          per_bet: null,
          per_round: {
            min: null,
            max: null,
          },
        },
      },
      maxDraw: null,
      propAgentData: {},
      showVideoLimit: true,
    };
  },
  created() {
    if (this.$route.params.agentData) {
      this.propAgentData = this.$route.params.agentData;
    }
  },
  async mounted() {
    if (this.$route.params.id) {
      await this.init();
      this.fetchGetBetLimit();
      await getMerchantRole().then((data) => {
        this.updatePermission = data.data.role.AgentShow?.update;
      });
    }
  },
  methods: {
    setLimit(data) {
      const { meron, draw, max_draw } = data;
      this.maxDraw = max_draw;
      this.betLimit.meron_wala = meron;
      this.betLimit.draw = draw;
    },
    updateLimit(type, param, callback) {
      const i18nParam = type === "max_draw" ? "round_limit" : `player_${type}`;
      const betLimit = JSON.parse(JSON.stringify(this.betLimit));
      let drawLimit;
      const { meron_wala, draw } = param;
      const { id } = this;
      switch (type) {
        case "round_limit":
          betLimit.draw.per_round.max = draw.max;
          betLimit.meron_wala.per_round.max = meron_wala.max;
          break;
        case "bet_limit":
          betLimit.draw.per_bet = draw.per_bet;
          betLimit.draw.per_round.min = draw.min;
          betLimit.meron_wala.per_bet = meron_wala.per_bet;
          betLimit.meron_wala.per_round.min = meron_wala.min;
          break;
        case "max_draw":
          drawLimit = param;
          break;
        default:
          return;
      }
      const currentLimit = type === "max_draw" ? { drawLimit } : { betLimit };
      updateAgentBetLimit({ id, ...currentLimit })
        .then((res) => {
          this.$message.success(
            this.$t("global.message.success_to_change", {
              value: this.$t(`global.common.${i18nParam}`),
            })
          );
          callback();
          this.setLimit(res.data);
        })
        .catch(() => {
          this.$message.error(
            this.$t("global.message.failed_to_update", {
              value: this.$t(`global.common.${i18nParam}`),
            })
          );
        });
    },
    fetchGetBetLimit() {
      const { id } = this;
      getBetLimit({ id }).then(({ data }) => {
        this.setLimit(data);
      });
    },
    async init() {
      const { id } = this;
      await getAgentProfileFindById({ id }).then(({ data }) => {
        this.agent = {...this.agent, ...data};
      });
    },
    async updateAgent() {
      await this.init();
      if (this.propAgentData.agents) {
        const currentAgent = this.propAgentData.agents.find(
          (agentItem) => agentItem.id === this.agent.agent_id
        );
        if (currentAgent) {
          currentAgent.client_theme = this.agent.theme;
          currentAgent.currency = this.agent.currency;
          currentAgent.active = this.agent.status === "active" ? true : false;
        }
      }
    },
    async updateQuota(form) {
      const { id } = this;
      await updateQuotaById({ id, form }).then(() => {
        this.$message.success(
          this.$t("global.message.success_to_change", {
            value: this.$t('ProfilePage.video_traffic'),
          })
        );
        this.init();
      }).catch(() => {
        this.$message.error(
          this.$t("global.message.failed_to_update", {
            value: this.$t('ProfilePage.video_traffic'),
          })
        );
      });
    },
    async updateAlarmQuota(form) {
      const { id } = this;
      await updatePrepaidFundLimit({ id, ...form }).then(() => {
        this.$message.success(
          this.$t("global.message.success_to_change", {
            value: this.$t('ProfilePage.alarm_quota'),
          })
        );
        this.init();
      }).catch(() => {
        this.$message.error(
          this.$t("global.message.failed_to_update", {
            value: this.$t('ProfilePage.alarm_quota'),
          })
        );
      });
    },
  },
};
</script>
