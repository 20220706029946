<template>
  <div class="frame malay-odds-setting-page">
    <a-page-header
      :title="$t('global.menu.MalayOddsSetting')"
      @back="() => $router.go(-1)"
    >
    <template slot="extra">
      <template>
        <a-button v-if="createPermission" class="add-btn" icon="plus" @click="createOdds">
            {{ $t('global.common.create_setting') }}
        </a-button>
      </template>
    </template>
    </a-page-header>
    <div class="page-content">
      <div class="search-bar">
        <a-form-model ref="searchForm" :model="form" :rules="rules">
          <div class="search-group">
            <a-form-model-item ref="name" :label="$t('global.common.name')" prop="name">
              <a-input
                  v-model="form.name"
              />
            </a-form-model-item>
          </div>
          <div class="search-group">
            <div class="search-btns">
              <a-button type="primary" icon="search" :loading="loading" @click="onSearch">{{ $t('global.action.search') }}</a-button>
              <a-button :disabled="loading" @click="resetForm">{{ $t('global.action.reset') }}</a-button>
            </div>
          </div>
        </a-form-model>
      </div>
      <div class="ant-card">
        <div class="ant-card-body">
          <div class="table-control">
            <a-popover trigger="click" placement="bottomRight">
              <template slot="content">
                <div class="table-filter-content">
                  <a-checkbox class="table-filter-check"
                              v-for="(item, index) in columns"
                              :key="item.dataIndex"
                              :defaultChecked="item.filterType"
                              :disabled="item.disabledFilter"
                              @click="columnsChange(index, item)"
                              :value="item.dataIndex">
                    <span v-if="item.slots.title !== 'per_bet_risk_threshold' && item.slots.title !== 'per_round_risk_threshold'">
                      {{ item.singleI18n ? $t(`PoolSettingPage.${item.slots.title}`) :$t(`global.common.${item.slots.title}`) }}
                    </span>
                    <span v-else>
                      {{ item.slots.title === 'per_bet_risk_threshold' ? $t('PoolSettingPage.per_bet') : $t('PoolSettingPage.per_round') }}
                    </span>
                  </a-checkbox>
                </div>
              </template>
              <a-button icon="filter"></a-button>
            </a-popover>
          </div>
          <a-table
            bordered
            size="small"
            :columns="columns.filter( item => item.filterType || item.filterType === undefined)"
            :data-source="data"
            :pagination="pagination"
            :loading="loading"
            :row-key="data => data.id"
            @change="handleTableChange"
          >
            <div 
              v-for="column in columns" 
              :slot="column.slots.title" 
              :key="column.slots.title"
              class="title">
              <span v-if="column.slots.title !== 'per_bet_risk_threshold' && column.slots.title !== 'per_round_risk_threshold'">
                {{ column.singleI18n ? $t(`PoolSettingPage.${column.slots.title}`) :$t(`global.common.${column.slots.title}`) }}
              </span>
              <div v-else>
                <p>{{ $t('PoolSettingPage.risk_threshold') }}</p>
                <p>{{ column.slots.title === 'per_bet_risk_threshold' ? $t('PoolSettingPage.per_bet') : $t('PoolSettingPage.per_round') }} (USD)</p>
              </div>
            </div>

            <template slot="formatTimes" slot-scope="times">
              <div class="table-time">{{ times | formatTimesToUTC8 }}</div>
            </template>

            <template slot="formatNumber" slot-scope="amount">
              <div>{{ amount | formatNumber }}</div>
            </template>

            <template slot="formatNumberWithPoint" slot-scope="amount">
              <div>{{ amount | formatNumberWithPoint }}</div>
            </template>

            <template slot="action" slot-scope="id, row">
              <a-button v-if="row.edit_able && updatePermission" type="primary" size="small" @click="editOdds(row)">{{ $t('global.action.edit') }}</a-button>
              <a-button v-if="row.delete_able && deletePermission" icon="delete" size="small" @click="deleteOdds(row)"></a-button>
            </template>

            <template slot="log" slot-scope="id, row">
              <a-button v-if="viewLogPermission" type="primary" size="small" @click="openLogModal(row)">{{ $t('global.common.log') }}</a-button>
            </template>
          </a-table>
        </div>
      </div>

      <MalaySettingDialog 
        :settingFormData="settingFormData"
        :visible="formShow"
        :fetchData="onSearch"
        :closeModal="closeFormModal"
      />

      <MalaySettingLogDialog 
        :visible="logDialog.visible"
        :id="logDialog.id"
        :clearLog="clearLog"
        :closeLogModal="closeLogModal"
      />

    </div>
  </div>
</template>

<script>
import { getMalayOddsList, createMalayOdds, updateMalayOdds, deleteMalayOdds, getMerchantRole } from '@/../api';
import MalaySettingDialog from '@/../src/components/pool/malay_setting_dialog';
import MalaySettingLogDialog from '@/../src/components/pool/malay_setting_log_dialog';

// const data = [{
//   id: 101234,
//   name: 'php_AM',
//   duration_second: 3,
//   rake_percentage: 5.12,
//   per_round_risk_threshold: 15000,
//   last_call_percentage: 75,
//   close_bet_percentage: 80,
//   updated_at: '2023-12-19 15:17:00'
// },{
//   id: 101235,
//   name: 'php_PM',
//   duration_second: 5,
//   rake_percentage: 8,
//   per_round_risk_threshold: 20000,
//   last_call_percentage: 65,
//   close_bet_percentage: 90,
//   updated_at: '2023-12-19 15:17:00'
// }]


const columns = [
  {
    slots: { title: 'id' },
    dataIndex: 'id',
    filterType: true,
    align: 'center',
    disabledFilter: true,
  },
  {
    slots: { title: 'name' },
    dataIndex: 'name',
    align: 'center',
    filterType: true,
  },
  {
    slots: { title: 'odds_refresh_frequency' },
    dataIndex: 'duration_second',
    filterType: true,
    align: 'center',
    singleI18n: true
  },
  {
    slots: { title: 'rate' },
    dataIndex: 'rake_percentage',
    align: 'center',
    scopedSlots: { customRender: 'formatNumberWithPoint' },
    filterType: true,
  },
  {
    slots: { title: 'per_bet_risk_threshold' },
    dataIndex: 'per_bet_risk_threshold',
    scopedSlots: { customRender: 'formatNumber' },
    align: 'center',
    filterType: true,
    singleI18n: true,
  },
  {
    slots: { title: 'per_round_risk_threshold' },
    dataIndex: 'per_round_risk_threshold',
    scopedSlots: { customRender: 'formatNumber' },
    align: 'center',
    filterType: true,
    singleI18n: true,
  },
  {
    slots: { title: 'last_call_percentage' },
    dataIndex: 'last_call_percentage',
    align: 'center',
    filterType: true,
    singleI18n: true,
  },
  {
    slots: { title: 'close_bet_percentage' },
    dataIndex: 'close_bet_percentage',
    align: 'center',
    filterType: true,
    singleI18n: true,
  },
  {
    slots: { title: 'updated_at' },
    dataIndex: 'updated_at',
    scopedSlots: { customRender: 'formatTimes' },
    filterType: true,
    align: 'right'
  },
  {
    slots: { title: 'action' },
    dataIndex: 'action',
    scopedSlots: { customRender: 'action'},
    align: 'center',
    // fixed: 'right',
    width: 120,
    filterType: true,
  },
  {
    slots: { title: 'log' },
    dataIndex: 'log',
    scopedSlots: { customRender: 'log'},
    align: 'center',
    // fixed: 'right',
    width: 70,
    filterType: true,
  },
];

export default {
  components: {
    MalaySettingDialog,
    MalaySettingLogDialog
  },
  data() {
    return {
      data: [],
      createPermission: false,
      updatePermission: false,
      pagination: {
        current: 1,
        pageSize: 10, // 默认每页显示数量
        showSizeChanger: true, // 显示可改变每页数量
        pageSizeOptions: ["10", "20", "30", "40"], // 每页数量选项
        showTotal: (total) => `Total ${total} items`, // 显示总数
        showSizeChange: (current, pageSize) => (this.pageSize = pageSize), // 改变每页数量时更新显示
        total: 0
      },
      expand: false,
      loading: false,
      columns,
      form: {
        name: '',
      },
      rules: {},
      settingFormData: {
        id: null,
        name:'',
        duration_second: 0,
        rake_percentage: 0,
        per_round_risk_threshold: 0,
        per_bet_risk_threshold: 0,
        last_call_percentage: 0,
        close_bet_percentage: 0,
      },
      formShow: false,
      logDialog:{
        visible: false,
        id: null,
      }
    };
  },
  async mounted(){
    // this.onSearch();
    await getMerchantRole().then((data) => {
        this.createPermission = data.data.role.MalayOddsSetting?.create;
        this.updatePermission = data.data.role.MalayOddsSetting?.update;
        this.deletePermission = data.data.role.MalayOddsSetting?.delete;
        this.viewLogPermission = data.data.role.MalayOddsSetting?.view_log;
      });
  },
  methods: {
    handleTableChange(pagination, filters, sorter) {
      const currentPage = this.pagination.pageSize !== pagination.pageSize ? 1 : pagination.current;
      this.pagination.current = currentPage;
      this.pagination.pageSize = pagination.pageSize;

      const name = this.form.name !== '' ? this.form.name : undefined;

      this.fetch({
        results: pagination.pageSize,
        page: currentPage,
        name,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
      });
    },
    fetch(form = {}) {
      this.loading = true;
      getMalayOddsList({ form }).then(({ data }) => {
        const pagination = { ...this.pagination };
        pagination.total = data.total_count || 0;

        this.data = data.odds_profile;
        this.pagination = pagination;
      }).catch((err)=>{
        this.$message.error(err.response.data.message, 3);
      }).finally(()=>{
        this.loading = false;
      });
    },
    onSearch() {
      if(this.loading) return;
      const { pageSize } = this.pagination;
      this.pagination.current = 1;
      const name = this.form.name !== '' ? this.form.name : undefined;
      this.$refs.searchForm.validate(valid => {
        if (valid) {
          this.fetch({ name, results: pageSize, page: 1 });
        }
      });
    },
    resetForm() {
      if(this.loading) return;
      this.$refs.searchForm.resetFields();
    },
    columnsChange(index, item) {
      item.filterType = !item.filterType;
    },
    closeFormModal(){
      this.formShow = false;
    },
    createOdds(){
      this.settingFormData.id = null;
      this.settingFormData.name = '';
      this.settingFormData.duration_second = 1;
      this.settingFormData.rake_percentage = 0;
      this.settingFormData.per_round_risk_threshold = 0;
      this.settingFormData.per_bet_risk_threshold = 0;
      this.settingFormData.last_call_percentage = 0;
      this.settingFormData.close_bet_percentage = 0;
      this.formShow = true;
    },
    editOdds(row) {
      this.settingFormData.id = row.id;
      this.settingFormData.name = row.name;
      this.settingFormData.duration_second = row.duration_second;
      this.settingFormData.rake_percentage = row.rake_percentage;
      this.settingFormData.per_round_risk_threshold = row.per_round_risk_threshold;
      this.settingFormData.per_bet_risk_threshold = row.per_bet_risk_threshold;
      this.settingFormData.last_call_percentage = row.last_call_percentage;
      this.settingFormData.close_bet_percentage = row.close_bet_percentage;
      this.formShow = true;
    },
    deleteOdds(row) {
      const { id } = row;
      const self = this;
      const { pageSize } = this.pagination;
      this.$confirm({
        title: self.$t('global.message.delete', {value: row.name}),
        okText: self.$t('global.action.yes'),
        cancelText: self.$t('global.action.no'),
        onOk() {
          deleteMalayOdds({id})
            .then(() =>{
              self.$message.success(self.$t('global.message.success_to_delete', {value: row.name}));
              self.fetch({
                results: pageSize,
                page: 1
              });
            }).catch((err)=>{
              this.$message.error(err.response.data.message, 3);
            })
        },
        onCancel() {},
      });
    },
    openLogModal(row){
      this.logDialog.visible = true;
      this.logDialog.id = row.id;
    },
    closeLogModal(){
      this.logDialog.visible = false;
    },
    clearLog(){
      this.logDialog.id = null;
    }
  },
};
</script>

