<template>
  <AgentSubAccount :authoritys='authoritys'
                   :data='data'
                   :pagination='pagination'
                   :loading='loading'
                   :fetchData='fetchData' />
</template>

<script>
import { getAgentManagerList, getAgentAllRoles } from '@/../api';
import AgentSubAccount from '@/../src/components/agents/agent_sub_account';

export default {
  components: {
    AgentSubAccount,
  },
  data() {
    return {
      authoritys: [
        // {
        //   id: 1,
        //   name: 'read',
        //   color: 'blue',
        // },
        // {
        //   id: 2,
        //   name: 'editor',
        //   color: 'pink',
        // },
      ],
      data: [
        // {
        //   id: 1,
        //   account: 'Account',
        //   name: 'Name',
        //   authority: 1,
        //   last_login: '2021-08-11 10:23:01',
        // },
        // {
        //   id: 2,
        //   account: 'jjjjj1111',
        //   name: 'Name',
        //   authority: 2,
        //   last_login: '2021-08-11 10:23:01',
        // },
      ],
      pagination: {},
      loading: false,
    };
  },
  created(){
    this.fetchData();
    getAgentAllRoles()
      .then((data)=>{
        this.authoritys = data.data.role;
      })
  },
  methods: {
    fetchData(params = {}) {
      this.loading = true;
      getAgentManagerList({
        results: 10,
        ...params,
      }).then(({ data }) => {
        const pagination = { ...this.pagination };
        // Read total count from server
        // pagination.total = data.totalCount;
        pagination.total = data.managers.length;
        this.data = data.managers;
        this.pagination = pagination;
      })
      .finally(()=>{
        this.loading = false;
      });
    },
  },
};
</script>
