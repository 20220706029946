<template>
    <a-modal v-model="visibleHistory" :title="$t('AgentCreditPointsPage.edited_record')" :footer="null" width="60vw" :afterClose="historyClose">
      <a-table
        bordered
        size="small"
        royKey="account"
        :data-source="historyAgents"
        :columns="historyColumns"
        :pagination="historyPagination"
        @change="handleHistoryTableSequence"
        :loading="historyLoading"
      >
        <template v-for="column in historyColumns">
          <template :slot="column.slots.title">{{ column.singleI18n ? $t(`AgentCreditPointsPage.${column.slots.title}`) : $t(`global.common.${column.slots.title}`) }}</template>
        </template>
        <template slot="type" slot-scope="type">
          {{ type == 'increase' ? $t(`AgentCreditPointsPage.increase_points`) : $t(`AgentCreditPointsPage.decrease_points`) }}
        </template>
        <template slot="updated_at" slot-scope="updated_at">
          {{ (updated_at) | formatTimesToUTC8 }}
        </template>
      </a-table>
    </a-modal>
</template>
<script>
    import { 
        getPrepaidFundsUpdateLog
    } from '@/../api';
    const historyColumns = [  
        {
            slots: { title: 'type' },
            scopedSlots: { customRender: 'type' },
            dataIndex: 'type',
            key: 'type',
            align: 'center',
            singleI18n: false,
            width: 100,
        },
        {
            slots: { title: 'points' },
            scopedSlots: { customRender: 'modify_amount' },
            dataIndex: 'modify_amount',
            key: 'modify_amount',
            align: 'center',
            singleI18n: true,
        },
        {
            slots: { title: 'points_before_change' },
            scopedSlots: { customRender: 'before_balance' },
            dataIndex: 'before_balance',
            key: 'before_balance',
            align: 'center',
            singleI18n: true,
        },
        {
            slots: { title: 'points_after_change' },
            scopedSlots: { customRender: 'after_balance' },
            dataIndex: 'after_balance',
            key: 'after_balance',
            align: 'center',
            singleI18n: true,
        },
        {
            slots: { title: 'edited_account' },
            scopedSlots: { customRender: 'account' },
            dataIndex: 'account',
            key: 'account',
            align: 'center',
            singleI18n: true,
        },
        {
            slots: { title: 'edited_time' },
            scopedSlots: { customRender: 'updated_at' },
            dataIndex: 'updated_at',
            key: 'updated_at',
            align: 'center',
            singleI18n: true,
        },
    ];
    export default {
      props: {
        inputVisibleHistory: {
          type: Boolean
        },
        historyId: {
          type: Number
        },
        inputHistoryClose:{
          type: Function
        }
      },
      data() {
        return {
            visibleHistory: false,
            historyColumns: historyColumns,
            historyPagination:{
                current: 1,
                pageSize: 10, 
                showSizeChanger: true, 
                pageSizeOptions: ["10", "20", "50"], 
                showTotal: (total) => `Total ${total} items`, 
                showSizeChange: (current, pageSize) => (this.pageSize = pageSize), 
                total: 0
            },
            historyAgents:[],
            historyLoading: false,
        };
      },
      methods: {
        handleHistoryTableSequence(pagination, filters, sorter){
            // console.log('handleHistoryTableSequence');
            this.historyPagination.current = pagination.current;
            this.historyPagination.pageSize = pagination.pageSize;
            this.historyFetch();
        },
        historyClose(){
            this.inputHistoryClose();
            this.historyAgents = [];
            this.historyPagination.current = 1;
        },
        async historyFetch() {
            if(this.historyLoading) return;
            this.historyLoading = true;
            const { current:page, pageSize:results } = this.historyPagination;
            const thisHistoryGetData = `agent_id=${this.historyId}&page=${page}&results=${results}`;
            await getPrepaidFundsUpdateLog(thisHistoryGetData)
                .then(({ data }) => {
                  // console.log(data);
                  this.historyAgents = data.log;
                  this.historyPagination.total = data.total;
                })
                .catch((err) => {
                  this.$message.error(this.$t(`global.message.failed_to_fetch`,{ value: `${this.historyId} ${this.$t('AgentCreditPointsPage.edited_record')}` }));
                }).finally(()=>{
                  this.historyLoading = false;
                });
        },
      },
      watch: {
        inputVisibleHistory:{
            handler(newValue, oldValue){
                this.visibleHistory = newValue;
                this.visibleHistory && this.historyFetch();  
            },
        },
        historyId:{
            handler(newValue, oldValue){
                this.historyId = newValue;
            },
        },
      },  
    };
</script>