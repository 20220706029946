<template>
  <a-form-model :layout="'vertical'" ref="adminForm" :model="adminForm" :rules="rules">

    <a-form-model-item :label="$t('global.common.change_password')" prop="password">
      <a-input-password v-model="adminForm.password" :placeholder="$t('global.message.password_placeholder')" />
    </a-form-model-item>

    <a-form-model-item :label="$t('global.common.name')" prop="name">
      <a-input v-model="adminForm.name" />
    </a-form-model-item>

    <a-form-model-item :label="$t('global.common.role')" prop="role">
      <a-select v-model="adminForm.role">
        <a-select-option v-for="role in roles" :value="role.id" :key="role.id">
          <a-tag :color="role.color">{{ role.name }}</a-tag>
        </a-select-option>
      </a-select>
    </a-form-model-item>
    <a-form-model-item>
      <a-button type="primary" @click="adminFormSubmit(adminForm)" block>
        {{ $t('global.action.submit') }}
      </a-button>
    </a-form-model-item>
  </a-form-model>
</template>
<script>
import { getMerchantAllRoles, updateSubMerchant } from '@/../api';

export default {
  props: {
    adminForm: Object,
  },
  data() {
    const checkPassword = (rule, value, callback) => {
      if(value && value.length < 6){
        callback(new Error(this.$t('global.message.must_be_at_6_characters', {value: this.$t('global.common.password')})));
      }else{
        callback();
      }
    };
    return {
      roles: [
        // {
        //   id: 1,
        //   name: 'Super',
        //   color: 'red',
        // },
        // {
        //   id: 2,
        //   name: 'Operator',
        //   color: 'blue',
        // },
      ],
      rules: {
        password: [{ validator: checkPassword, trigger: 'change' }],
        role: [
          { required: true,
            message: this.$t('global.message.select_value', {value: this.$t('global.common.role')}),
            trigger: 'blur'
          },
        ],
      },
    };
  },
  created() {
    getMerchantAllRoles()
      .then((data)=>{
        this.roles = data.data.role;
      })
  },
  methods: {
    adminFormSubmit() {
      const { password, name, role, id } = this.adminForm
      this.$refs.adminForm.validate((valid) => {
        if (!valid) {
          this.$message.error(this.$t('global.message.failed_to_update', {value: this.$t('global.common.account')}));
          return false;
          } else {
            updateSubMerchant({name, role_id: role, id, password: password ? password : undefined})
              .then((data)=>{
              if (data.data.code == 'OK') {
                this.$message.success(this.$t('global.message.success_to_change', {value: this.$t('global.common.account')}));
              } else {
                this.$message.error(this.$t('global.message.failed_to_update', {value: this.$t('global.common.account')}));
              }
                this.$emit('update-data', this.adminForm);
              })
              .catch((err)=>{
                this.$message.error(err.response.data.message)
              })
        }
      });
    },
  },
};
</script>

