<template>
  <div class="frame players-page">
    <a-page-header
      :title="$t('global.menu.Players')"
      @back="() => $router.go(-1)"
    >
    </a-page-header>
    <div class="page-content">
      <div class="search-bar">
        <a-form-model ref="searchForm" :model="form">
          <div class="search-group">
            <Currency :inputCurrency.sync="form.currency"
                      :inputMaster.sync="form.master"
                      :inputAgent.sync="form.agent"
                      :showCurrency="false"
                      :showMaster="false"/>
            <a-form-model-item ref="player_account" :label="$t('global.common.player_account')" prop="player_account">
              <a-input
                v-model="form.player_account"
              />
            </a-form-model-item>
            <a-form-model-item ref="player_name" :label="$t('global.common.player_name')" prop="player_name">
              <a-input
                v-model="form.player_name"
              />
            </a-form-model-item>

            <a-form-model-item 
              v-if="role === 'merchant'"
              ref="risk_type" 
              :label="$t('global.common.risk_control')" 
              prop="risk_type">
              <a-select v-model="form.risk_type">
                <a-select-option 
                  v-for="riskItem in riskTypes" 
                  :value="riskItem.value" 
                  :key="riskItem.key">
                {{ $t(`global.common.risk_type_list.${riskItem.key}`) }}
              </a-select-option>
              </a-select>
            </a-form-model-item>
          </div>
          <p class="remind-text" v-if="role === 'merchant'">* {{ $t('global.message.criteria_be_selected') }}</p>
          <div class="search-group">
            <div class="search-btns">
              <a-button type="primary" icon="search" :loading="loading" @click="onSearch">{{ $t('global.action.search') }}</a-button>
              <a-button :disabled="loading" @click="resetForm">{{ $t('global.action.reset') }}</a-button>

            </div>
          </div>
          <!-- <div class="search-group">
            <Datetime :input.sync="form.date"/>
            <div class="search-btns">
              <a-button type="primary" icon="search" :loading="searchLoading" @click="onSearch">{{ $t('global.action.search') }}</a-button>
              <a-button @click="resetForm">{{ $t('global.action.reset') }}</a-button>
            </div>
          </div> -->
        </a-form-model>
      </div>
      <div class="ant-card">
        <div class="ant-card-body">
          <div class="table-control">
            <a-popover trigger="click" placement="bottomRight">
              <template slot="content">
                <div class="table-filter-content">
                  <a-checkbox class="table-filter-check"
                              v-for="(item, index) in columns"
                              :key="item.dataIndex"
                              :defaultChecked="item.filterType"
                              :disabled="item.disabledFilter"
                              @click="columnsChange(index, item)"
                              :value="item.dataIndex">
                    {{ item.singleI18n ? $t(`PlayersPage.${item.slots.title}`) : $t(`global.common.${item.slots.title}`) }}
                  </a-checkbox>
                </div>
              </template>
              <a-button icon="filter"></a-button>
            </a-popover>
          </div>

          <a-table
            bordered
            size="small"
            :columns="columns.filter(item => (item.filterType || item.filterType === undefined) && (item.dataIndex !== 'action' || riskEditPremission))"
            :row-key="playerList => playerList.id"
            :data-source="playerList"
            :pagination="pagination"
            :loading="loading"
            @change="handleTableChange"
          >
            <template v-for="column in columns">
              <template :slot="column.slots.title">{{ column.singleI18n ? $t(`PlayersPage.${column.slots.title}`) : $t(`global.common.${column.slots.title}`) }}</template>
            </template>

            <template slot="parent_name" slot-scope="parent_name">
              <span class="table-link" @click="gotoAgent(parent_name)">{{ parent_name }}</span>
            </template>

            <template slot="formatTimes" slot-scope="times">
              <div class="table-time">{{ times | formatTimes }}</div>
            </template>

            <template slot="formatString" slot-scope="string">
              <div>{{ string | formatString }}</div>
            </template>

            <template slot="formatNumberWithPoint" slot-scope="amount">
              <div>{{ amount | formatNumberWithPoint }}</div>
            </template>

            <template slot="account" slot-scope="account,row">
              <div class="account-column">
                <span>{{ account | formatString }}</span>
                <a-button 
                  v-if="riskEditPremission" 
                  class="risk_btn" 
                  type="primary" 
                  size="small" 
                  @click="editLimit(row)">
                  {{ $t('global.action.risk_edit') }}
                </a-button>
              </div>
            </template>
          </a-table>
        </div>
      </div>
    </div>
    <LimitForm
      :closeModal="closeModal"
      :formShow="formShow"
      :betLimit="betLimit"
      :game_type="game_type"
      :refreshCurrentPlayerLimit="refreshCurrentPlayerLimit"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { 
  getPlayerList,
  getMerchantRole,
  getRiskTypes,
  // getManageDescendantList 
  } from '@/../api';
import Datetime from '@/../src/components/datetime_picker';
// import AgentPicker from '@/../src/components/agent_picker';
import Currency from '@/../src/components/currency_selection';
import LimitForm from '@/../src/components/bet_limit_form';

const columns = [
  {
    slots: { title: 'merchant' },
    dataIndex: 'parent_of_parent_name',
    filterType: true,
  },
  {
    slots: { title: 'agent' },
    dataIndex: 'parent_name',
    scopedSlots: { customRender: 'parent_name' },
    filterType: true,
  },
  {
    slots: { title: 'account' },
    dataIndex: 'account',
    scopedSlots: { customRender: 'account' },
    filterType: true,
    disabledFilter: true,
  },
  {
    slots: { title: 'name' },
    dataIndex: 'name',
    filterType: true,
    disabledFilter: true,
  },
  {
    slots: { title: 'balance' },
    dataIndex: 'wallet_balance',
    align: 'right',
    scopedSlots: { customRender: 'formatNumberWithPoint' },
    filterType: true,
    singleI18n: true,
  },
  // {
  //   slots: { title: 'email' },
  //   dataIndex: 'email',
  // },
  // {
  //   slots: { title: 'fee' },
  //   dataIndex: 'fee',
  //   align: 'right',
  //   scopedSlots: { customRender: 'formatNumberWithPoint' },
  //   filterType: true,
  //   singleI18n: true,
  // },
  // {
  //   slots: { title: 'total_deposit' },
  //   dataIndex: 'total_deposit',
  //   align: 'right',
  //   scopedSlots: { customRender: 'formatNumberWithPoint' },
  //   filterType: true,
  //   singleI18n: true,
  // },
  // {
  //   slots: { title: 'total_withdraw' },
  //   dataIndex: 'total_withdraw',
  //   align: 'right',
  //   scopedSlots: { customRender: 'formatNumberWithPoint' },
  //   filterType: true,
  //   singleI18n: true,
  // },
  // {
  //   slots: { title: 'total_win_lose' },
  //   dataIndex: 'total_win_lose',
  //   align: 'right',
  //   scopedSlots: { customRender: 'formatNumberWithPoint' },
  //   filterType: true,
  //   singleI18n: true,
  // },
  {
    slots: { title: 'last_login' },
    dataIndex: 'last_sign_in_at',
    scopedSlots: { customRender: 'formatTimes' },
    filterType: true,
  },
  {
    slots: { title: 'current_sign_in_ip' },
    dataIndex: 'current_sign_in_ip',
    scopedSlots: { customRender: 'formatString' },
    filterType: true,
    singleI18n: true,
  },
  {
    slots: { title: 'created_at' },
    dataIndex: 'created_at',
    scopedSlots: { customRender: 'formatTimes' },
    filterType: true,
  },
];
// id account email fee balance created_at last_sign_in_at current_sign_in_ip
// total_deposit total_withdraw total_win_lose
export default {
  components: {
    Datetime,
    // AgentPicker,
    Currency,
    LimitForm,
  },
  data() {
    return {
      riskTypes: [],
      riskEditPremission: false,
      data: [],
      pagination: {
        current: 1,
        pageSize: 10, // 默认每页显示数量
        showSizeChanger: true, // 显示可改变每页数量
        pageSizeOptions: ["10", "20", "30", "40"], // 每页数量选项
        showTotal: (total) => `Total ${total} items`, // 显示总数
        showSizeChange: (current, pageSize) => (this.pageSize = pageSize), // 改变每页数量时更新显示
        total: 0
      },
      expand: false,
      loading: false,
      playerList: [],
      columns,
      form: {
        date: [new Date(), new Date()],
        player_account: this.$route.query.player_account || '',
        player_name: this.$route.query.player_name || '',
        agent: null,
        currency: '',
        master: null,
        risk_type: '',
      },
      betLimit: {
        id: null,
        is_reduce_limit: false,
        no_login: false,
        meron_wala: {
          per_bet: null,
          per_round: {
            min: null,
            max: null,
          },
        },
        draw: {
          per_bet: null,
          per_round: {
            min: null,
            max: null,
          },
        },
      },
      formShow: false,
      rules: {},
      game_type: '',
    };
  },
  async mounted() {},
  computed:{
    ...mapGetters({timeZone: 'auth/timeZone'}),
    ...mapState({ role: state => state.auth.role}),
  },
  watch:{
    role:{
      async handler(newValue){
        if(newValue === 'merchant'){
          try {
            const riskTypesData = await getRiskTypes();
            this.riskTypes = riskTypesData.data.risk_types;
          } catch (error) {
            //console.error(error);
          }

          try {
            const merchantRoleData = await getMerchantRole();
            this.riskEditPremission = merchantRoleData.data.role.Players?.risk_edit ?? false;
          } catch (error) {
            //console.error(error);
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    handleTableChange(pagination, filters, sorter) {
      const currentPage = this.pagination.pageSize !== pagination.pageSize ? 1 : pagination.current;
      this.pagination.current = currentPage;
      this.pagination.pageSize = pagination.pageSize;

      // const { date } = this.form;
      this.fetch({
        results: pagination.pageSize,
        page: currentPage,
        // date: date,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
      });
    },
    fetch(params = {}) {
      this.loading = true;
      const { agent, player_account, player_name, risk_type } = this.form;
      getPlayerList({
        agent, player_account, player_name, risk_type, ...params,
      }).then(({ data }) => {
        const pagination = { ...this.pagination };
        pagination.total = data.total;
        this.game_type = data.game_type;
        this.playerList = data.playerList;
        this.pagination = pagination;
      }).finally(()=>{
        this.loading = false;
      });
    },
    onSearch() {
      if(this.role === 'merchant' && !this.form.agent && !this.form.player_account.trim() && !this.form.player_name.trim()){
        return this.$message.error(this.$t('global.message.criteria_be_selected'));
      }
      if(this.loading) return;
      const { pageSize } = this.pagination;
      this.pagination.current = 1;
      this.$refs.searchForm.validate(valid => {
        if (valid) {
          this.fetch({ results: pageSize, page: 1 });
        } else {
          // console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm() {
      if(this.loading) return;
      this.$refs.searchForm.resetFields();
    },
    columnsChange(index, item) {
      item.filterType = !item.filterType;
    },
    gotoAgent(agent) {
      let routeData = this.$router.resolve({ name: 'Agents', query: {agent: agent}});
      window.open(routeData.href, '_blank');
    },
    editLimit(row) {
      this.betLimit.account = row.account;
      this.betLimit.id = row.id;
      this.betLimit.meron_wala = row.bet_limit.wala_limit;
      this.betLimit.draw = row.bet_limit.draw_limit;
      this.betLimit.is_reduce_limit = row.is_reduce_limit;
      this.betLimit.no_login = !row.active;
      this.formShow = true;
    },
    closeModal(){
      this.formShow = false;
    },
    refreshCurrentPlayerLimit(data, id){
      const currentPlayer = this.playerList.find(playerItem=>playerItem.id === id);
      if(currentPlayer){
        currentPlayer.bet_limit.draw_limit = data.draw;
        currentPlayer.bet_limit.meron_limit = data.meron;
        currentPlayer.bet_limit.wala_limit = data.wala;
        currentPlayer.is_reduce_limit = data.is_reduce_limit;
        currentPlayer.active = !data.no_login;
      }
    },
  },
};
</script>
