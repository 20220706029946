// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// import Rails from "@rails/ujs"
// import "channels"

// Rails.start()

import TurbolinksAdapter from 'vue-turbolinks';
import Vue from 'vue';
import { Plugin } from 'vue-fragment'
import Antd, { message } from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
// import '../assets/application.scss';
// import '../assets/application_dark.scss';
// if use more theme "application.scss" and "application_dark.scss" must be close
import i18n from '../i18n';
import moment from 'moment';
import { tz } from 'moment-timezone';
import infiniteScroll from 'vue-infinite-scroll';

import router from '../router';
import store from '../store';
import App from '../app.vue';
import ActionCableVue from 'actioncable-vue';
import numeral from 'numeral';

Vue.config.productionTip = false;
Vue.use(Plugin);
Vue.use(Antd);
Vue.use(TurbolinksAdapter);
Vue.use(infiniteScroll);
Vue.prototype.moment = moment;
Vue.prototype.tz = tz;
Vue.use(ActionCableVue, {
  debug: true,
  debugLevel: 'all',
  connectionUrl: process.env.SOCKET_URL,
  connectImmediately: false,
});

Vue.filter('formatTimes',function(value){
  if(!value) return '-';
  return moment(value).utc().format('YYYY-MM-DD HH:mm:ss');
})

Vue.filter('formatDay',function(value){
  if(!value) return '-';
  return moment(value).utc().format('YYYY-MM-DD');
})

Vue.filter('formatMonthly',function(value){
  if(!value) return '';
  return moment(value).utc().format('YYYY-MM');
})

Vue.filter('formatString',function(value){
  if(!value) return '-';
  return value;
})

Vue.filter('formatNumber',function(value){
  if(!value) return 0;
  return numeral(value).format('0,0');
})

Vue.filter('formatNumberWithPoint',function(value){
  if(!value) return '0.00';
  return numeral(value).format('0,0.00');
})

Vue.filter('formatDayToUTC8',function(value){
  if(!value) return '-';
  return moment(value).format('YYYY-MM-DD');
})

Vue.filter('formatMonthlyToUTC8',function(value){
  if(!value) return '';
  return moment(value).format('YYYY-MM');
})

Vue.filter('formatTimesToUTC8',function(value){
  if(!value) return '-';
  return moment(value).format('YYYY-MM-DD HH:mm:ss');
})

message.config({
  duration: 5,
});

const app = new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
});


document.addEventListener('DOMContentLoaded', async() => {

  if(window.location.host.includes('fiesta')){
    await import('../assets/application.scss');
  }else{
    await import('../assets/application_dark.scss');
  }

  app.$mount();
  document.body.appendChild(app.$el);
});

export default app;
