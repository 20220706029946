<template>
  <div class="frame merchants-page">
    <a-page-header
      :title="$t('global.menu.Merchants')"
      @back="() => $router.go(-1)"
    >
      <template slot="extra">
        <CreateMerchant @update-data="init"/>
      </template>
    </a-page-header>
    <div class="page-content">
      <div class="ant-card">
        <div class="ant-card-body">
          <a-table
            bordered
            size="small"
            :columns="columns"
            :row-key="data => data.account"
            :data-source="data"
            :pagination="pagination"
            :loading="loading"
            @change="handleTableChange"
          >
            <template v-for="column in columns">
              <template :slot="column.slots.title">{{ $t(`global.common.${column.slots.title}`) }}</template>
            </template>
            <template slot="status" slot-scope="id, row">
              <div>
                <span :class="`status-light ${row.status}`"></span>
                <span>{{$t(`global.common.active_status_list.${row.status}`)}}</span>
              </div>
            </template>
            <template slot="formatTimes" slot-scope="times">
              <div class="table-time">{{ times | formatTimes }}</div>
            </template>
            <template slot="action" slot-scope="id, row">
              <router-link :to="{ name: 'AdminMerchantAdmin', params: { id: row.id }}">
                <a-button type="primary" size="small">{{ $t('MerchantsPage.admin') }}</a-button>
              </router-link>
              <router-link :to="{ name: 'AdminMerchantRoles', params: { id: row.id }}">
                <a-button size="small" class="role-btn">{{ $t('MerchantsPage.role') }}</a-button>
              </router-link>
            </template>
          </a-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { 
  // getAgentProfile, 
  // getAgentMemberCount, 
  getAdminInsideMerchant } from '@/../api';

import CreateMerchant from '@/../src/components/merchants/create_merchant';
const columns = [
  {
    slots: { title: 'account' },
    dataIndex: 'account',
  },
  {
    slots: { title: 'name' },
    dataIndex: 'name',
  },
  {
    slots: { title: 'business_status' },
    dataIndex: 'status',
    align: 'center',
    scopedSlots: { customRender: 'status'},
  },
  {
    slots: { title: 'agent_count' },
    dataIndex: 'agent_count',
    align: 'center',
  },
  {
    slots: { title: 'player_count' },
    dataIndex: 'player_count',
    align: 'center',
  },
  {
    slots: { title: 'created_at' },
    dataIndex: 'created_at',
    scopedSlots: { customRender: 'formatTimes' },
  },
  {
    slots: { title: 'action' },
    dataIndex: 'action',
    align: 'center',
    scopedSlots: { customRender: 'action'},
  },
];

export default {
  components: {
    CreateMerchant,
  },
  data() {
    return {
      data: [
        // {
        //   id: 1,
        //   account: 'aaa2222',
        //   name: '商號名稱',
        //   status: 'active', //[active,close]
        //   agent_count: '20',
        //   player_count: '3800',
        //   created_at: '21-09-22 20:22:22',
        // }
      ],
      pagination: {},
      expand: false,
      loading: false,
      merchantLists: [],
      columns,
    };
  },
  methods: {
    handleTableChange(pagination, filters, sorter) {
      // const pager = { ...this.pagination };
      // pager.current = pagination.current;
      // this.pagination = pager;
      // this.fetch({
      //   results: pagination.pageSize,
      //   page: pagination.current,
      //   sortField: sorter.field,
      //   sortOrder: sorter.order,
      //   ...filters,
      // });
    },
    init(){
      this.loading = true;
      getAdminInsideMerchant()
        .then((data)=>{
          this.data = data.data.merchants;
        })
        .finally(()=>{
          this.loading = false;
        })
    },
  },
  created(){
  },
  mounted() {
    this.init();
  },
  updated(){
  },
}
</script>
