<template>
  <div class="frame merchants-admin-page">
    <div class="page-breadcrumb" v-if="account">
      <a-breadcrumb>
        <a-breadcrumb-item><router-link :to="{name: 'Merchants'}">{{ $t('global.menu.Merchants') }}</router-link></a-breadcrumb-item>
        <!-- <a-breadcrumb-item>{{ this.$route.params.id }}</a-breadcrumb-item> -->
        <a-breadcrumb-item>{{ account }}</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <a-page-header
      :title="$t('global.menu.MerchantAdmin')"
      @back="() => $router.go(-1)"
    >
      <template slot="extra">
        <template v-if="roles.length >= 1">
          <NewAdmin @update-data="updateAdmin"/>
        </template>
        <template v-else>
          <!-- if role count < 1 -->
          <a-button class="add-btn" icon="user-add" @click="goToRole">
            {{ $t('MerchantAdminPage.create_manager') }}
          </a-button>
        </template>
      </template>
    </a-page-header>
    <div class="page-content">
      <div class="ant-card">
        <div class="ant-card-body">
          <a-table
            bordered
            size="small"
            :columns="columns"
            :row-key="data => data.id"
            :data-source="data"
            :pagination="pagination"
            :loading="loading"
            @change="handleTableChange"
          >
            <template v-for="column in columns">
              <template :slot="column.slots.title">{{ $t(`global.common.${column.slots.title}`) }}</template>
            </template>
            <template slot="role" slot-scope="id, row">
              <a-tag :color="getCurrentRole(row.role,'color')" style="font-size: 16px">{{ getCurrentRole(row.role) }}</a-tag>
            </template>
            <template slot="formatTimes" slot-scope="times">
              <div class="table-time">{{ times | formatTimes }}</div>
            </template>
            <template slot="formatString" slot-scope="string">
              <div>{{ string | formatString }}</div>
            </template>
            <template slot="action" slot-scope="id, row">
              <a-button type="primary" size="small" @click="showEdit(row)">{{ $t('global.action.edit') }}</a-button>
              <a-button icon="delete" size="small" @click="deleteAdmin(row)"></a-button>
            </template>
          </a-table>
        </div>
      </div>
    </div>
    <a-modal v-model="formShow" :footer="null" :width="380">
      <template slot="title">
        {{ $t('global.action.edit') }}
        <span class="primary-font-color"> {{ adminFormAccount }}</span>
      </template>
      <EditAdmin :adminForm="adminForm"
                 @update-data="updateAdmin"/>
    </a-modal>
  </div>
</template>

<script>
import { deleteSubMerchant } from '@/../api';
import NewAdmin from '@/../src/components/merchants/new_admin';
import EditAdmin from '@/../src/components/merchants/edit_admin';
const columns = [
  {
    slots: { title: 'id' },
    dataIndex: 'id',
    align: 'center',
  },
  {
    slots: { title: 'account' },
    dataIndex: 'account',
    scopedSlots: { customRender: 'formatString' },
  },
  {
    slots: { title: 'name' },
    dataIndex: 'name',
    scopedSlots: { customRender: 'formatString' },
  },
  {
    slots: { title: 'role' },
    dataIndex: 'role',
    scopedSlots: { customRender: 'role' },
  },
  {
    slots: { title: 'last_login' },
    dataIndex: 'last_sign_in_at',
    scopedSlots: { customRender: 'formatTimes' },
  },
  {
    slots: { title: 'action' },
    dataIndex: 'action',
    scopedSlots: { customRender: 'action'},
    align: 'center',
  },
];

export default {
  components: {
    NewAdmin,
    EditAdmin,
  },
  props:{
    roles: Array,
    data: Array,
    pagination: Object,
    loading: Boolean,
    fetchData: Function,
    id:[Number, String],
    account: String,
  },
  data() {
    return {
      formShow: false,
      adminForm: {
        id: 0,
        password: '',
        name: '',
        role: '',
      },
      adminFormAccount: "",
      expand: false,
      columns,
    };
  },
  methods: {
    goToRole() {
      const self = this;
      this.$warning({
        title: self.$t('global.common.please_create_role'),
        okText: self.$t('global.action.go'),
        onOk() {
          self.$router.push({ name: "MerchantRoles" });
        },
      });
    },
    showEdit(row) {
      this.formShow = true;
      this.adminForm.id = row.id;
      this.adminFormAccount = row.account;
      this.adminForm.password = row.password;
      this.adminForm.name = row.name;
      this.adminForm.role = row.role;
    },
    updateAdmin(form) {
      this.formShow = false;
      const { id } = this;
      this.fetchData({id});
    },
    handleTableChange(pagination, filters, sorter) {
      const currentPage = this.pagination.pageSize !== pagination.pageSize ? 1 : pagination.current;
      this.pagination.current = currentPage;
      this.pagination.pageSize = pagination.pageSize;

      this.fetchData({
        results: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
      });
    },
    deleteAdmin(row) {
      const { id } = row;
      const self = this;
      const adminID = this.id;
      this.$confirm({
        title: self.$t('global.message.delete', { value: row.account }),
        okText: self.$t('global.action.yes'),
        cancelText: self.$t('global.action.no'),
        onOk() {
          deleteSubMerchant({id})
            .then((data) =>{
              self.$message.success(self.$t('global.message.success_to_delete', { value: row.account }));
              self.fetchData({adminID})
            })
        },
        onCancel() {},
      });
    },
    getCurrentRole(id, returnValue=""){
      const currentRole =  this.roles.find((item, index, array)=>{
        return item.id === id;
      })
      if(returnValue === 'color'){
        return currentRole.color;
      }else{
        return currentRole.name;
      }
    }
  },
}
</script>
