<template>
  <div class="frame video-source-page">
    <a-page-header
      :title="$t('global.menu.VideoSource')"
      @back="() => $router.go(-1)"
    >
      <template slot="extra">
        <a-button class="add-btn" @click="showCreate($t('global.action.create'))">
          {{ `${$t('global.action.create')} ${$t('VideoSourcePage.source')}` }}
        </a-button>
      </template>
    </a-page-header>
    <div class="page-content">
      <div class="ant-card">
        <div class="ant-card-body">
          <a-table
            bordered
            size="small"
            :columns="columns"
            :row-key="data => data.id"
            :data-source="data"
            :pagination="pagination"
            :loading="loading"
            @change="handleTableChange"
          >
            <template v-for="column in columns">
              <template :slot="column.slots.title">{{ column.singleI18n ? $t(`VideoSourcePage.${column.slots.title}`) : $t(`global.common.${column.slots.title}`) }}</template>
            </template>
            <template slot="accessable_merchants" slot-scope="id, row">{{ row.merchant.join() | formatString }}</template>
            <template slot="formatString" slot-scope="string">
              <div>{{ string | formatString }}</div>
            </template>
            <template slot="status" slot-scope="id, row">
              <div>
                <span :class="`status-light ${row.status}`"></span>
                <span>{{$t(`global.common.active_status_list.${row.status}`)}}</span>
              </div>
            </template>
            <template slot="action" slot-scope="id, row">
              <a-button type="primary" size="small" @click="showEdit(row,$t('global.action.edit'))">{{ $t('global.action.edit') }}</a-button>
              <a-button icon="delete" size="small" @click="deleteAdmin(row)"></a-button>
            </template>
          </a-table>
        </div>
      </div>
    </div>
    <a-drawer
      :title="drawerAction"
      :width="680"
      :visible="formShow"
      class="drawer-footer-model"
      @close="closeDrawer"
    >
      <SourceDrawer
        :drawerAction='drawerAction'
        :currentUrl='currentUrl'
        @update-data="updateForm"
      />
    </a-drawer>
  </div>
</template>

<script>
import { getProviders } from '@/../api';
import SourceDrawer from '@/../src/components/video_source/SourceDrawer';

const columns = [
  {
    slots: { title: 'id' },
    dataIndex: 'id',
    align: 'center',
  },
    {
    slots: { title: 'provider_name' },
    dataIndex: 'provider_name',
    align: 'center'
  },
  {
    slots: { title: 'video_url' },
    dataIndex: 'video_url',
    scopedSlots: { customRender: 'formatString'},
    width: '40%'
  },
  {
    slots: { title: 'accessable_merchants' },
    dataIndex: 'accessable_merchants',
    scopedSlots: { customRender: 'accessable_merchants'},
    align: 'center',
    width: '30%',
    singleI18n: true
  },
  {
    slots: { title: 'status' },
    dataIndex: 'status',
    scopedSlots: { customRender: 'status'},
    align: 'center',
  },
  {
    slots: { title: 'action' },
    dataIndex: 'action',
    scopedSlots: { customRender: 'action'},
    align: 'center',
  },
];

export default {
  components: {
    SourceDrawer
  },
  data() {
    return {
      formShow: false,
      drawerAction: '',
      currentUrl: null,
      expand: false,
      columns,
      data: [],
      pagination: {},
      loading: false,
    };
  },
  created(){
    this.fetchData();
  },
  mounted() {
    // getProviders()
    //   .then(({ data }) => {
    //     this.data = data.role
    //     console.log(data)
    //   })
  },
  methods: {
    updateForm(row) {
      this.formShow = false;
      this.fetchData();
    },
    fetchData(params = {}) {
      this.loading = true;
      getProviders({
          results: 10,
          ...params,
        }).then(({ data }) => {
          const pagination = { ...this.pagination };
          // Read total count from server
          // pagination.total = data.totalCount;
          pagination.total = data.role.length;
          this.loading = false;
          this.data = data.role;
          this.pagination = pagination;
        });
    },
    closeDrawer() {
      this.formShow = false;
    },
    showCreate(drawerAction) {
      this.formShow = true;
      this.drawerAction = drawerAction;
      if(this.currentUrl){
        this.currentUrl = null;
      }
    },
    goToRole() {
      const self = this;
      this.$warning({
        title: self.$t('global.message.please_create_role'),
        okText: self.$t('global.action.go'),
        onOk() {
          self.$router.push({ name: "MerchantRoles" });
        },
      });
    },
    showEdit(row,drawerAction) {
      this.formShow = true;
      this.drawerAction = drawerAction;
      this.currentUrl = row;
    },
    updateAdmin(form) {
      this.formShow = false;
      const { id } = this;
      this.fetchData({id});
    },
    handleTableChange(pagination, filters, sorter) {
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
      this.fetchData({
        results: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
      });
    },
    deleteAdmin(row) {
      const { id } = row;
      const self = this;
      this.$confirm({
        title: self.$t('global.message.delete', {value: row.account}),
        okText: self.$t('global.action.yes'),
        cancelText: self.$t('global.action.no'),
        onOk() {
          deleteSubMerchant({id})
            .then((data) =>{
              self.$message.success(self.$t('global.message.success_to_delete', {value: row.account}));
            })
        },
        onCancel() {},
      });
    }
  },
}
</script>
