<template>
  <div class="frame win-lose-page">
    <a-page-header
      :title="$t('global.menu.win_lose')"
      @back="() => $router.go(-1)"
    >
    </a-page-header>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
